import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { SelectComponent } from '../select/select.component';

export const DAYS = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  0: 'SUNDAY',
} as const;

export interface Day {
  label: string;
  value: string;
  dateString: string;
  weekDay: 1 | 2 | 3 | 4 | 5 | 6 | 0;
}

export const days: Readonly<Day[]> = [
  { label: 'Monday', value: 'MONDAY', dateString: 'Mon', weekDay: 1 },
  { label: 'Tuesday', value: 'TUESDAY', dateString: 'Tue', weekDay: 2 },
  { label: 'Wednesday', value: 'WEDNESDAY', dateString: 'Wed', weekDay: 3 },
  { label: 'Thursday', value: 'THURSDAY', dateString: 'Thu', weekDay: 4 },
  { label: 'Friday', value: 'FRIDAY', dateString: 'Fri', weekDay: 5 },
  { label: 'Saturday', value: 'SATURDAY', dateString: 'Sat', weekDay: 6 },
  { label: 'Sunday', value: 'SUNDAY', dateString: 'Sun', weekDay: 0 },
] as const;

@Component({
  selector: 'sympheny-select-day',
  templateUrl: './select-day.component.html',
  styleUrls: ['./select-day.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, SelectComponent],
})
export class SelectDayComponent extends BaseFormComponent<
  Day,
  FormFieldConfig
> {
  public readonly days = days;
  @Input() public multiple = false;
}
