import {
  ConversionTechnology,
  ConversionTechnologySchemaV2,
  EnergyCarrier,
  EnergyCarrierSchema,
  Hub,
  ImportExport,
  ImportExportV2Schema,
  IntraHubNetworkLink,
  NetworkLink,
  NetworkLinkV1Schema,
  NetworkTechnology,
  NetworkTechnologyV2Schema,
  OnSiteSolarResource,
  OnSiteSolarResourceV2Schema,
  Scenario,
  SeasonalOperations,
  StorageTechnology,
  StorageTechnologySchema,
  TechnologyPackages,
  TechnologyPackagesList,
} from '@sympheny/project/data-access';
import { PlanLimitation } from '@sympheny/user/data-access';

import { EnergyDemand, EnergyDemandV2Schema } from '../energy-demands';
import { Profile } from '../profile';
import { ScenarioVariant, ScenarioVariantSchema } from '../scenario-variant';
import { Stage, StageSchema } from '../stage';

export interface ScenarioState {
  onSiteSolarResources: OnSiteSolarResource[];
  energyDemands: EnergyDemand[];
  scenarioId: string | null;
  selectedHubGuid: string | null;
  reloadHubDiagram: Date;
  hubs: Hub[];
  status: boolean;
  importsExports: ImportExport[];
  conversionTechnologies: ConversionTechnology[];
  technologyPackages: TechnologyPackages[];
  seasonalOperations: SeasonalOperations[];
  storageTechnologies: StorageTechnology[];
  intraHubNetworkLink: IntraHubNetworkLink[];
  networkLinks: NetworkLink[];
  networkTechnologies: NetworkTechnology[];
  energyCarriers: EnergyCarrier[];
  stages: Stage[];
  profiles: Profile[];
  scenarioVariants: ScenarioVariant[];
  details: Scenario;
}

export interface DataConfig<EXTRA_DATA> {
  reloadHubDiagram?: boolean;
  extra?: EXTRA_DATA;
  onSuccess?: (param: any) => void;
  onError?: (param: any) => void;
}

export const getDataFor = [
  'onSiteSolarResources',
  'energyDemands',
  'hubs',
  'importsExports',
  'conversionTechnologies',
  'technologyPackages',
  'storageTechnologies',
  'intraHubNetworkLink',
  'networkLinks',
  'networkTechnologies',
  'energyCarriers',
  'stages',
  'profiles',
  'scenarioVariants',
] as const;

export type DataKey = (typeof getDataFor)[number] &
  Partial<keyof ScenarioState>;

export const ScenarioStateData: Record<DataKey, any> = {
  onSiteSolarResources: OnSiteSolarResourceV2Schema,
  energyDemands: EnergyDemandV2Schema,
  hubs: Hub,
  importsExports: ImportExportV2Schema,
  conversionTechnologies: ConversionTechnologySchemaV2,
  technologyPackages: TechnologyPackagesList,
  intraHubNetworkLink: IntraHubNetworkLink,
  storageTechnologies: StorageTechnologySchema,
  networkLinks: NetworkLinkV1Schema,
  networkTechnologies: NetworkTechnologyV2Schema,
  energyCarriers: EnergyCarrierSchema,
  stages: StageSchema,
  profiles: Profile,
  scenarioVariants: ScenarioVariantSchema,
} as const;

export const ScenarioStateExtraData: Record<DataKey, any> = {
  networkLinks: null,
  onSiteSolarResources: null,
  energyDemands: null,
  hubs: null,
  importsExports: null,
  conversionTechnologies: null,
  technologyPackages: null,
  intraHubNetworkLink: null,
  storageTechnologies: null,
  networkTechnologies: null,
  energyCarriers: null,
  stages: null,
  profiles: null,
  scenarioVariants: null,
} as const;

export const PlanLimitMap: Record<DataKey, keyof PlanLimitation | null> = {
  hubs: 'maxHubs',
  stages: null,
  conversionTechnologies: 'maxConversionTechs',
  storageTechnologies: 'maxStorageTechs',
  onSiteSolarResources: 'maxSolarResources',
  energyDemands: 'maxEnergyDemands',
  importsExports: null,
  technologyPackages: null,
  intraHubNetworkLink: null,
  networkLinks: 'maxNetworkLinks',
  networkTechnologies: 'maxNetworkTechs',
  energyCarriers: null,
  profiles: null,
  scenarioVariants: 'maxScenarioVariants',
};
