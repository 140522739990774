<mat-form-field
  [attr.data-cy]="dataCy || label"
  [class.full-width]="config?.fullWidth ?? true"
>
  <mat-label *ngIf="label">{{ label | transloco }}</mat-label>
  <input
    matInput
    placeholder="hh:mm"
    [formControl]="formControl"
    type="string"
    mask="Hh:m0"
    [dropSpecialCharacters]="false"
    [errorStateMatcher]="matcher"
    #inputElement
  />
  <mat-hint *ngIf="requiredHint"
    >* {{ 'FORM.requiredHint' | transloco }}</mat-hint
  >
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>

<sympheny-info-button *ngIf="hint" [tooltip]="hint"> </sympheny-info-button>
