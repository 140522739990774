import { differenceInMilliseconds, parseISO } from 'date-fns';

import { Direction } from './direction';

function byAttribute<T>(
  values: T[] | undefined,
  attribute: keyof T,
  direction: Direction
) {
  if (!values?.length) {
    return values;
  }
  const dir = direction === Direction.DESC ? -1 : 1;
  const compareFn = (a: any, b: any) => {
    if (!a[attribute]) {
      // If attribute is non existing put it on the top of the list
      return dir;
    }

    const aDate = parseISO(a[attribute]);
    const bDate = parseISO(b[attribute]);
    const difference = differenceInMilliseconds(aDate, bDate);
    return difference * dir;
  };

  return [...values].sort(compareFn);
}

export function sortByDate<T>(values?: T[]) {
  return {
    byAttribute: (attribute: keyof T, direction: Direction = Direction.ASC) =>
      byAttribute(values, attribute, direction),
  };
}
