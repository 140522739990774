import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoPipe } from '@ngneat/transloco';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';

@Component({
  selector: 'sympheny-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  imports: [
    ToggleComponent,
    TranslocoPipe,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatSlideToggleModule,
  ],
})
export class ToggleComponent extends BaseFormComponent<
  boolean,
  FormFieldConfig
> {
  @Output() public readonly toggleChange = new EventEmitter<boolean>();

  public change(event: { checked: boolean }) {
    this.toggleChange.emit(event.checked);
  }
}
