import { maxBy, minBy } from 'lodash';

import { Graph, Link, Node } from '../model';
import { nodeHeight } from '../utils/node';

export const fillHeight = ({
  graph: data,
  settings,
}: Readonly<Graph<any, any>>) => {
  const nodes = data.getNodes();
  const links = data.getLinks();

  let top = false;
  let bottom = false;
  const { extend } = data;

  data.forEachLink((link: Link) => {
    if (link.circularLinkType === 'top') {
      top = true;
    } else if (link.circularLinkType === 'bottom') {
      bottom = true;
    }
  });

  const minY0 = minBy(nodes, (node: Node) => node.y0)?.y0 ?? 0;
  const maxY1 = maxBy(nodes, (node: Node) => node.y1)?.y1 ?? 0;
  const currentHeight = maxY1 - minY0;
  let padding = settings.padding * 2;
  if (top) {
    padding += settings.padding;
  }
  const chartHeight = settings.height - padding;
  const ratio = chartHeight / currentHeight;
  const heightRatio = ratio;
  if (ratio !== 1) {
    nodes.forEach((node) => {
      const nHeight = nodeHeight(node) * heightRatio;
      node.setValue('y0', (node.y0 - minY0) * ratio);
      node.setValue('y1', node.y0 + nHeight);
    });

    links.forEach((link: Link) => {
      link.setValue('y0', (link.y0 - minY0) * ratio);
      link.setValue('y1', (link.y1 - minY0) * ratio);
      link.setValue('width', link.width * ratio);
    });
  }
};
