import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';
import { toLonLat } from 'ol/proj';
import { Observable } from 'rxjs';

import { MapHubLayer } from '../layer/map-hub-layer';
import { EnergyResourceForm } from '../model/map-gis.model';

@Injectable({
  providedIn: 'root',
})
export class MapGisService {
  private readonly jrc =
    this.environmentService.getValue('apiServices') + 'jrc/';

  constructor(
    private http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {}

  public postSolarResource(
    formData: EnergyResourceForm,
    mapLayer: MapHubLayer
  ): Observable<number[]> {
    const lonLat = toLonLat(mapLayer.getCenter());

    let data: any = [];

    if (formData.solarAngleMethod?.toUpperCase() === 'OPTIMIZE') {
      data = [
        {
          lon: lonLat[0],
          lat: lonLat[1],
          area: formData.resourceArea,
          slope: null,
          azimuth: null,
        },
      ];
    } else if (formData.solarAngleMethod?.toUpperCase() === 'CUSTOM') {
      data = [
        {
          lon: lonLat[0],
          lat: lonLat[1],
          area: formData.resourceArea,
          slope: formData.slope,
          azimuth: formData.azimuth,
        },
      ];
    } else if (formData.solarAngleMethod?.toUpperCase() === 'AGGREGATE') {
      const actualTotalArea = formData.selectedFeatures.reduce(
        (totalArea, f) => {
          if (f.get('area')) return totalArea + f.get('area');
          return 0;
        },
        0
      );

      data = formData.selectedFeatures.map((f) => {
        const area = f.get('area');
        const slope = f.get('slope') != null ? f.get('slope') : 90;
        const azimuth = f.get('azimuth');
        return {
          lon: lonLat[0],
          lat: lonLat[1],
          area: area * (formData.resourceArea / actualTotalArea),
          slope: slope,
          azimuth: azimuth,
        };
      });
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<number[]>(
      `${this.jrc}solar/profile`,
      data,
      httpOptions
    );
  }

  public postWindResource(formData: EnergyResourceForm, mapLayer: MapHubLayer) {
    const lonLat = toLonLat(mapLayer.getCenter());
    const data = {
      lon: lonLat[0],
      lat: lonLat[1],
      height: formData.height,
      z: formData.roughnessLength,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<number[]>(
      `${this.jrc}wind/profile`,
      data,
      httpOptions
    );
  }
}
