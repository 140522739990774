<section
  class="sympheny"
  [class.sympheny--navigation_open]="navigationOpen$ | async"
>
  <div class="sympheny--main">
    <router-outlet></router-outlet>
  </div>
  <sympheny-banner-list class="sympheny--banner"></sympheny-banner-list>
  <sympheny-header class="sympheny--header"></sympheny-header>
  <sympheny-sidenav class="sympheny--menu"></sympheny-sidenav>
</section>
