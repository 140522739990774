import { FormGroup } from '@angular/forms';

export const mapModelToForm = <FG extends FormGroup, Z>(
  form: FG,
  data: Z,
  extraFormData: any = {},
) => {
  const formData = {};
  Object.keys(form.value).forEach((key) => {
    formData[key] = data[key] ?? null;
  });

  return { ...formData, ...extraFormData };
};
