import { parse, format } from 'date-fns';
import { z } from 'zod';

import { SymphenyNumber } from './number';

export const SymphenyUtcDate = () =>
  z
    .string()
    .transform((d) =>
      d ? new Date(d.charAt(d.length - 1) === 'Z' ? d : `${d}Z`) : null
    );

export const SymphenyDate = () =>
  z.union([z.string().datetime(), z.date()]).transform((d) => (d ? d : null));

export const SymphenyYear = () => SymphenyNumber(0);

export const SymphenyDateCustom = () =>
  z.string().transform((d) => parseDate(d));

export const SymphenyDateEuCustom = () =>
  z.union([z.string(), z.date()]).transform((d) => parseEuDate(d));

export const SymphenyDateEuSave = () =>
  z
    .union([z.string(), z.date()])
    .nullish()
    .transform((d) => (d ? formatEuDate(d) : null));

export const SymphenyDateSave = () =>
  z
    .union([z.string().datetime(), z.date()])
    .nullish()
    .transform((d) => (d ? formatEuDate(d) : null));

export const formatDateTime = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const parsed = parse(value, 'MM/dd/yy HH:mm', new Date());

  return parsed ?? null;
};

export const parseDate = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  return parse(value, 'MM/dd/yy', new Date());
};

export const parseEuDate = (value: string | Date | undefined) => {
  if (!value) {
    return null;
  }
  if (typeof value !== 'string') {
    return value;
  }
  return parse(value, 'dd/MM/yyyy', new Date());
};

export const formatDate = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const parsed = parse(value, 'MM/dd/yy', new Date());

  return parsed ?? null;
};

export const formatEuDate = (value: string | Date | undefined) => {
  if (!value) {
    return null;
  }

  let toParse: Date;
  if (typeof value === 'string') {
    toParse = parse(value, 'dd/MM/yyyy', new Date());
  } else {
    toParse = value;
  }

  const parsed = format(toParse, 'dd/MM/yyyy');

  return parsed ?? null;
};
