<h2 *ngIf="this.executionsLeft > 1">
  {{
    'project.executions.left' | transloco: { executionsLeft: executionsLeft }
  }}
</h2>
<h2 *ngIf="this.executionsLeft === 1">
  {{ 'project.execution.left' | transloco: { executionsLeft: executionsLeft } }}
</h2>
<h2 *ngIf="this.executionsLeft === 0">
  {{
    'project.no-execution.left' | transloco: { executionsLeft: executionsLeft }
  }}
</h2>

<p>
  {{ 'project.pricing.plan' | transloco: { pricingPlan: pricingPlan } }}
  <a
    target="_blank"
    href="https://support.app.sympheny.com/user-guide/latest/type-of-user"
    >{{ 'learn.more' | transloco }}</a
  >.
</p>

<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="false">
    {{ (this.executionsLeft > 0 ? 'Cancel' : 'OK') | transloco }}
  </button>

  <button
    *ngIf="this.executionsLeft > 0"
    mat-raised-button
    type="button"
    color="primary"
    (click)="continue ? submitExecution() : closeDialog()"
  >
    {{ 'Continue' | transloco }}
  </button>
</div>
