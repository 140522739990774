import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { TechnologyAdvancedPriceComponentSchemaV1 } from './price-component.model';

export const TechnologyCapacityOptionEnum = z.enum(['optimize', 'specify']);
export type TechnologyCapacityOption = z.infer<
  typeof TechnologyCapacityOptionEnum
>;

export const NetworkLinkV1Schema = z.object({
  networkLinkGuid: z.string().nullish(),
  length: SymphenyNumber().nullish(),
  technologyCapacity: TechnologyCapacityOptionEnum.nullish(),
  installedCapacity: SymphenyNumber().nullish(),
  uniDirectionalFlow: z.boolean().nullish(),
  mustBeInstalled: z.boolean().nullish(),
  node1Guid: z.string().nullish(),
  node1Name: z.string().nullish(),
  node2Guid: z.string().nullish(),
  node2Name: z.string().nullish(),
  networkTechnologyName: z.string().nullish(),
  networkTechnologyGuid: z.string().nullish(),
  costComponents: z.array(TechnologyAdvancedPriceComponentSchemaV1).nullish(),
  created: SymphenyDate().nullish(),
  updated: z.string().nullish(),
});

export const NetworkLinkListV1Schema = NetworkLinkV1Schema.omit({
  costComponents: true,
});

export const NetworkLinkCostComponentsDtoSchema =
  TechnologyAdvancedPriceComponentSchemaV1.pick({
    name: true,
    value: true,
    category: true,
    complexityFactor: true,
    categoryId: true,
  });

export const NetworkLinkV1Dto = NetworkLinkV1Schema.pick({
  mustBeInstalled: true,
  length: true,
  technologyCapacity: true,
  installedCapacity: true,
  uniDirectionalFlow: true,
  node1Guid: true,
  node2Guid: true,
  networkLinkGuid: true,
  networkTechnologyGuid: true,
}).extend({
  costComponents: z.array(NetworkLinkCostComponentsDtoSchema).nullish(),
});

export type NetworkLinkV1 = z.infer<typeof NetworkLinkV1Schema>;

export const NetworkLinkV2Schema = NetworkLinkV1Schema.extend({
  networkLoss: SymphenyNumber().nullish(),
  networkLossProfileId: z.number().nullish(),
  minimumCapacity: SymphenyNumber().nullish(),
  maximumCapacity: SymphenyNumber().nullish(),
  name: z.string().nullish(),
});

export const NetworkLinkListV2Schema = NetworkLinkV2Schema.omit({});

export type NetworkLinkV2 = z.infer<typeof NetworkLinkV2Schema>;

export const NetworkLinkV2Dto = NetworkLinkV2Schema.pick({
  mustBeInstalled: true,
  length: true,
  technologyCapacity: true,
  installedCapacity: true,
  uniDirectionalFlow: true,
  node1Guid: true,
  node2Guid: true,
  networkLinkGuid: true,
  networkTechnologyGuid: true,
  costComponents: true,
  networkLossProfileId: true,
  networkLoss: true,
  name: true,
  maximumCapacity: true,
  minimumCapacity: true,
});

export type NetworkLink = NetworkLinkV1 & NetworkLinkV2;
export type NetworkLinkCostComponentsDto = z.infer<
  typeof NetworkLinkCostComponentsDtoSchema
>;
