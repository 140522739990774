import { minBy, maxBy } from 'lodash-es';

import { Node } from '../model';
export const getNode = (nodeId: string, nodeMap: Map<string, Node>): Node => {
  const node = nodeMap.get(nodeId);
  if (!node) throw new Error(`Node "${nodeId}" not found`);

  return node;
};

export function nodeCenter(node: Readonly<Pick<Node, 'y0' | 'y1'>>) {
  return (node.y0 + node.y1) / 2;
}
export function nodeHeight(node: Node | Node) {
  return node.y1 - node.y0;
}

export function nodeWidth(node: Node | Node) {
  return node.x1 - node.x0;
}

export const sortNodeBy = (field: keyof Node) => {
  return (n1: Node, n2: Node) => (n1[field] < n2[field] ? -1 : 1);
};

export const sortNodeByReverse = (field: keyof Node) => {
  return (n1: Node, n2: Node) => (n1[field] < n2[field] ? 1 : -1);
};

export const sortNodeByColumnRow = () => {
  return (n1: Node, n2: Node) => {
    const i1 = [n1.column, n1.row].join('_');
    const i2 = [n2.column, n2.row].join('_');

    return i1 < i2 ? -1 : 1;
  };
};

export function centerFromNodes(nodes: Node[]) {
  const y0 = minBy(nodes, (n) => n.y0)?.y0;
  const y1 = maxBy(nodes, (n) => n.y1)?.y1;

  return { center: nodeCenter({ y0, y1 }), y0, y1 };
}

export function centerFromSourceTarget(
  sourceNodes: Node[],
  targetNodes: Node[],
) {
  const centerSource = centerFromNodes(sourceNodes);
  const centerTarget = centerFromNodes(targetNodes);

  if (sourceNodes.length === 0) return centerTarget;
  if (targetNodes.length === 0) return centerSource;

  const center = centerSource.center + centerTarget.center;

  return { center: center / 2 };
}
