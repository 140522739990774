<div class="flex flex-col gap-5 mb-5">
  <div class="px-4 font-medium">
    {{ stepTitle | transloco }}
    <a *ngIf="templateUrl" [href]="templateUrl">
      ({{ 'SCENARIO.template.profile' | transloco }})
    </a>
    <a
      *ngIf="supportUrl"
      class="ml-2"
      target="_blank"
      [href]="supportUrl"
      [matTooltip]="'learn.more.tooltip' | transloco"
    >
      <small> {{ 'SCENARIO.learn.more' | transloco }} </small>
    </a>
  </div>

  <ng-content></ng-content>
</div>
