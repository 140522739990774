<div class="card mx-3 p-5 min-h-[150px]">
  <h2 class="mb-2 flex items-center gap-2">
    <span class="font-bold text-xl">
      <ng-content select="[title]"></ng-content>
    </span>
    <div>
      <sympheny-info-button
        *ngIf="tooltip"
        [tooltip]="tooltip"
      ></sympheny-info-button>
    </div>
    <sympheny-button
      (action)="addItem.emit()"
      [disabled]="disabled$ | async"
      *ngIf="canEdit$ | async"
      [dataCy]="subject + '.create.new'"
    >
      {{ 'general.addNew' | transloco }}
    </sympheny-button>
  </h2>

  <p
    class="none-defined"
    *ngIf="(loading$ | async) === false && items?.length === 0"
  >
    {{ 'REVIEW.not.defined' | transloco }}
  </p>

  <sympheny-loader [loading]="loading$ | async"></sympheny-loader>

  <div class="crud-list" *ngIf="items?.length > 0">
    <div
      class="crud-list__item"
      *ngFor="let item of items; let i = index"
      data-cy="crud-list__item"
    >
      <sympheny-crud-list-action
        [subject]="subject"
        [disabled]="disabled$ | async"
        [readonly]="readonly$ | async"
        [item]="item"
        [disableEdit]="disableEdit"
        (editItem)="editItem.emit($event)"
        (deleteItem)="deleteItem.emit($event)"
      ></sympheny-crud-list-action>
      <div>
        <div>
          {{ item[key] }}
        </div>
        <div class="crud-list__item-subtext">
          <p *ngIf="subText">{{ subText(item) }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
