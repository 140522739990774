<div class="worksheet-column-selection">
  {{ value?.column }}:

  <mat-form-field>
    <mat-select
      placeholder="Select the category"
      [ngModel]="value?.key"
      (ngModelChange)="changeCategory($event)"
    >
      <mat-option *ngFor="let key of keys" [value]="key">
        {{ buildingInformationConfiguration[key].label | transloco }}
      </mat-option>
      <mat-option value="other">Other</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <ng-container *ngIf="value?.key === 'other'; else selectColumn">
      <input
        matInput
        [ngModel]="value?.customName"
        (ngModelChange)="change($event)"
        placeholder="Type here a fieldname..."
      />
    </ng-container>
    <ng-template #selectColumn>
      <mat-select
        placeholder="Select the field"
        [ngModel]="value?.field"
        (ngModelChange)="change($event)"
      >
        <mat-option *ngFor="let info of buildingInfo" [value]="info.key">
          {{ info.label | transloco }}
        </mat-option>
      </mat-select>
    </ng-template>
  </mat-form-field>
  <button
    mat-icon-button
    matTooltip="Clear input"
    type="button"
    (click)="clear()"
  >
    <mat-icon>clear</mat-icon>
  </button>
</div>
