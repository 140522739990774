<sympheny-select
  *ngIf="formControl"
  [dataCy]="dataCy || 'hub-select'"
  [formControl]="formControl"
  labelKey="hubName"
  valueKey="hubGuid"
  [label]="multiple ? 'HUBS.hubs.label' : 'HUBS.hub.label'"
 [placeholder]="multiple ? 'HUBS.hubs.placeholder' : 'HUBS.hub.placeholder'"
  [config]="{ requiredHint: required, }"
  [errorMessages]="errorMessages"
  [hint]="hint"
  [options]="hubs$ | async"
  [multiple]="multiple"
  (selectionChange)="selectionChange.emit($event)"
  [translateOptions]="false"
>
</sympheny-select>
