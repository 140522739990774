import { Analysis } from './analysis';
import { Project } from './project.model';

export class ProjectDetails extends Project {
  public projectOwner: string;
  public analyses: Analysis[];
  public projectOwnerEmail: string;

  public editableByCurrentUser: boolean;
}
