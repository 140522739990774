import { NgModule } from '@angular/core';
import { DatabaseDataAccessEnergyDemandsModule } from '@sympheny/database/data-access/energy-demands';
import { DatabaseDataAccessImpexModule } from '@sympheny/database/data-access/impex';
import { DatabaseDataAccessOnSiteResourcesModule } from '@sympheny/database/data-access/on-site-resources';
import { ProjectSupplyTechnologyDataAccessModule } from '@sympheny/database/data-access/supply-technology';
import { ProjectEnergyCarrierDataAccessModule } from '@sympheny/project/energy-carrier/data-access';

/**
 * Should only be imported in the application root module
 */
@NgModule({
  imports: [
    DatabaseDataAccessEnergyDemandsModule.forRoot(),
    ProjectEnergyCarrierDataAccessModule.forRoot(),
    DatabaseDataAccessOnSiteResourcesModule.forRoot(),
    ProjectSupplyTechnologyDataAccessModule.forRoot(),
    DatabaseDataAccessImpexModule.forRoot(),
  ],
})
export class DatabaseDataAccessInitModule {}
