import { NgIf, AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';
import { SelectComponent } from '@sympheny/ui/form';

@Component({
  selector: 'sympheny-stages-select',
  templateUrl: './stages-select.component.html',
  styleUrls: ['./stages-select.component.scss'],
  standalone: true,
  imports: [NgIf, SelectComponent, ReactiveFormsModule, AsyncPipe],
})
export class StagesSelectComponent implements OnInit, ControlValueAccessor {
  @Input() public required = false;
  @Input() public hint: string;
  @Input() public dataCy: string;
  public formControl = null;
  public changeFn: (option: string) => void;
  public touchFn: () => void;

  public errorMessages = {
    required: 'Hub is required',
  };

  public readonly stages$ = this.scenarioStore.selectValue('stages');

  constructor(
    private readonly ngControl: NgControl,
    private readonly scenarioStore: ScenarioStore,
  ) {
    this.ngControl.valueAccessor = this;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public writeValue(obj: any): void {}

  public registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  public ngOnInit() {
    this.formControl = this.ngControl.control as FormControl;
  }
}
