import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { MustBeInstalledInHubsEnum } from './enums';
import { TechnologyAdvancedPriceComponentSchemaV2 } from './price-component.model';
import {
  HubEnergyCarrierSchema,
  TechnologyModeDtoV2,
  TechnologyModeSchema,
  TechnologyModeSchemaV2,
} from './technology.model';

const ConversionTechnologyBaseSchema = z.object({
  advancedPVModel: z.boolean().nullish(),
  category: z.string().nullish(),
  comesFromDb: z.string().nullish(),
  conversionTechnologyGuid: z.string().nullish(),
  costComponents: z.array(TechnologyAdvancedPriceComponentSchemaV2).nullish(),
  created: SymphenyDate().nullish(),
  efficiencyPVCell: SymphenyNumber().nullish(),
  fixedEmbodiedCo2: SymphenyNumber().nullish(),
  fixedInvestmentCost: SymphenyNumber().nullish(),
  fixedOmCostChf: SymphenyNumber().nullish(),
  fixedOmCostPercent: SymphenyNumber().nullish(),
  hubs: z.array(HubEnergyCarrierSchema).nullish(),
  lifetime: SymphenyNumber().nullish(),
  mustBeInstalledInHubs: MustBeInstalledInHubsEnum.nullish(),
  mutuallyExclusiveGroup: z.string().nullish(),
  notes: z.string().nullish(),
  partOfTechnologyPackage: z.boolean().nullish(),
  processName: z.string().nullish(),
  pvSizingFactorWpeakM2: SymphenyNumber().nullish(),
  safetyMargin: SymphenyNumber().nullish(),
  systemEfficiency: SymphenyNumber().nullish(),
  technologyCapacity: z.string().nullish(),
  technologyCategory: z.string().nullish(),
  technologyOptional: z.boolean().nullish(),
  updated: SymphenyDate().nullish(),
  source: z.string().nullish(),
  variableCapturedCo2: SymphenyNumber().nullish(),
  variableEmbodiedCo2: SymphenyNumber().nullish(),
  variableEmittedCo2: SymphenyNumber().nullish(),
  variableInvestmentCost: SymphenyNumber().nullish(),
  variableOmCost: SymphenyNumber().nullish(),
  variableOmCostYear: SymphenyNumber().nullish(),
  virtual: z.boolean().nullish(),
});

export const ConversionTechnologySchemaV2 =
  ConversionTechnologyBaseSchema.extend({
    technologyModes: z.array(TechnologyModeSchemaV2),
    stages: z.array(z.string()).nullish(),
    fixedSalvageValue: SymphenyNumber().nullish(),
    variableSalvageValueCHF: SymphenyNumber().nullish(),
    variableSalvageValuePercent: SymphenyNumber().nullish(),
    fixedReplacementCost: SymphenyNumber().nullish(),
    variableReplacementCostCHF: SymphenyNumber().nullish(),
    variableReplacementCostPercent: SymphenyNumber().nullish(),
    variableOmCostPercent: SymphenyNumber().nullish(),
  });

export const ConversionTechnologyDtoV2 = ConversionTechnologySchemaV2.pick({
  comesFromDb: true,
  costComponents: true,
  fixedEmbodiedCo2: true,
  fixedInvestmentCost: true,
  fixedOmCostChf: true,
  fixedOmCostPercent: true,
  fixedReplacementCost: true,
  fixedSalvageValue: true,
  lifetime: true,
  mustBeInstalledInHubs: true,
  notes: true,
  source: true,
  stages: true,
  technologyCategory: true,
  variableCapturedCo2: true,
  variableEmittedCo2: true,
  variableEmbodiedCo2: true,
  variableInvestmentCost: true,
  variableOmCost: true,
  variableOmCostPercent: true,
  variableReplacementCostCHF: true,
  variableReplacementCostPercent: true,
  variableSalvageValueCHF: true,
  variableSalvageValuePercent: true,
  variableOmCostYear: true,
  virtual: true,
  processName: true,
}).extend({
  conversionTechnologyModes: z.array(TechnologyModeDtoV2),
  hubGuids: z.array(z.string()),
  exchangeCurrency: z.string().nullish(),
  exchangeRate: SymphenyNumber().nullish(),
});

export const ConversiontTechnologyListV2Schema =
  ConversionTechnologySchemaV2.pick({
    conversionTechnologyGuid: true,
    processName: true,
    created: true,
    updated: true,
    hubs: true,
    virtual: true,
    mustBeInstalledInHubs: true,
    lifetime: true,
  }).extend({ conversionTechnologyModes: z.array(TechnologyModeSchema) });

export type ConversionTechnologyV2 = z.infer<
  typeof ConversionTechnologySchemaV2
>;

export type ConversionTechnologyListV2 = z.infer<
  typeof ConversiontTechnologyListV2Schema
>;
