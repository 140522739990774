import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BackgroundService } from './background.service';

@NgModule({
  imports: [CommonModule],
})
export class GisBackgroundModule {
  public static forRoot<T>(): ModuleWithProviders<GisBackgroundModule> {
    return {
      ngModule: GisBackgroundModule,
      providers: [BackgroundService],
    };
  }
}
