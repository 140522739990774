import { AbstractControl, ValidationErrors } from '@angular/forms';

export const startsWithDigit = (
  formControl: AbstractControl,
): ValidationErrors | null => {
  if (
    formControl.value &&
    ((formControl.value as string).startsWith('0') ||
      (formControl.value as string).startsWith('1') ||
      (formControl.value as string).startsWith('2') ||
      (formControl.value as string).startsWith('3') ||
      (formControl.value as string).startsWith('4') ||
      (formControl.value as string).startsWith('5') ||
      (formControl.value as string).startsWith('6') ||
      (formControl.value as string).startsWith('7') ||
      (formControl.value as string).startsWith('8') ||
      (formControl.value as string).startsWith('9'))
  ) {
    return { startsWithDigit: true };
  } else {
    return null;
  }
};
