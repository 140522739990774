import { Provider } from '@angular/core';

import {
  AnalysisService,
  ConversionTechnologyService,
  ProjectsService,
  ScenarioService,
  TechnologyPackagesService,
} from './service';
import { CrudService } from './service/crud.service';
import { EnergyCarrierService } from './service/energy-carrier.service';
import { HubService } from './service/hub.service';
import { ImportsExportsService } from './service/importsExports.service';
import { IntraHubNetworkLinksService } from './service/intra-hub-network-links.service';
import { NetworkLinkService } from './service/network-link.service';
import { NetworkTechnologyService } from './service/network-technology.service';
import { StorageTechnologyService } from './service/storage-technologie.service';
import {
  SolarResourcesV1Service,
  SolarResourcesV2Service,
  SolarResourcesService,
} from './solar-resources';
import { AnalysisScenarioState } from './state/project-details/analysis-scenario.state';
import { ProjectAnalysisState } from './state/project-details/project-analysis.state';
import { ProjectDetailsState } from './state/project-details/project-details.state';
import { ProjectState } from './state/project-details/project.state';
import { ProjectsStore } from './state/projects.store';

export const provideProjectDataAccess = (): Provider[] => [
  ProjectsStore,
  ProjectsService,
  AnalysisService,
  ScenarioService,
  SolarResourcesService,
  SolarResourcesV1Service,
  SolarResourcesV2Service,
  HubService,
  ImportsExportsService,
  ConversionTechnologyService,
  TechnologyPackagesService,
  StorageTechnologyService,
  ProjectsStore,
  ProjectDetailsState,
  ProjectState,
  ProjectAnalysisState,
  AnalysisScenarioState,
  IntraHubNetworkLinksService,
  NetworkLinkService,
  NetworkTechnologyService,
  EnergyCarrierService,
  {
    provide: CrudService,
    useExisting: SolarResourcesService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: HubService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: ImportsExportsService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: ConversionTechnologyService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: TechnologyPackagesService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: StorageTechnologyService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: IntraHubNetworkLinksService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: NetworkLinkService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: NetworkTechnologyService,
    multi: true,
  },
  {
    provide: CrudService,
    useExisting: EnergyCarrierService,
    multi: true,
  },
];
