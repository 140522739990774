import { SymphenyNumber, UploadFileSchema } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const EnergyDemandV1Schema = z.object({
  demandSalePrice: SymphenyNumber().nullish(),
  energyCarrierGuid: z.string().nullish(),
  energyCarrierName: z.string().nullish(),
  energyDemandGuid: z.string().nullish(),
  energyDemandMetadataBuildingAge: z.string().nullish(),
  energyDemandMetadataBuildingType: z.string().nullish(),
  energyDemandMetadataDbOrganization: z.string().nullish(),
  energyDemandMetadataGuid: z.string().nullish(),
  energyDemandMetadataName: z.string().nullish(),
  energyDemandMetadataOption: z.string().nullish(),
  energyDemandMetadataReferencedAreaM2: SymphenyNumber().nullish(),
  energyDemandMetadataSpecificEnergyDemandValueKWhM2:
    SymphenyNumber().nullish(),
  energyDemandMetadataTotalAnnualDemand: SymphenyNumber().nullish(),
  energyDemandMetadataType: z.string().nullish(),
  energyDemandName: z.string().nullish(),
  energyDemandUserSavedMetadataGuid: z.string().nullish(),
  energyDemandUserSavedMetadataName: z.string().nullish(),
  energyDemandUserSavedMetadataReferenceArea: SymphenyNumber().nullish(),
  hubGuid: z.string().nullish(),
  hubName: z.string().nullish(),
  multiplicationFactor: SymphenyNumber().nullish(),
  multiplicationFactorPreview: SymphenyNumber().nullish(),
  scalingFactor: SymphenyNumber().nullish(),
});

export const EnergyDemandV1ListSchema = EnergyDemandV1Schema.extend({});

export const EnergyDemandV1DtoSchema = EnergyDemandV1Schema.pick({
  demandSalePrice: true,
  energyCarrierGuid: true,
  energyDemandGuid: true,
  hubGuid: true,
  multiplicationFactor: true,
  scalingFactor: true,
}).extend({
  possibleOption: z.string().nullish(),
  profileType: z.string().nullish(),
  name: z.string().nullish(),
  fileRequestDto: UploadFileSchema().nullish(),
  referencedAreaM2: SymphenyNumber().nullish(),
  specificEnergyDemandValueKWhM2: SymphenyNumber().nullish(),
  totalAnnualDemand: SymphenyNumber().nullish(),
});

export type EnergyDemandV1 = z.infer<typeof EnergyDemandV1Schema>;
export type EnergyDemandV1Dto = z.infer<typeof EnergyDemandV1DtoSchema>;
