// Custom validator to check that two fields match
import { AbstractControl } from '@angular/forms';

export function sameValueIgnoreCase(value: string) {
  const ignoreCase = value.toLowerCase();
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    return control.value.toLowerCase() === ignoreCase
      ? {
          unique: true,
        }
      : null;
  };
}
