import { AbstractControl, ValidationErrors } from '@angular/forms';

export function NotNegative(control: AbstractControl): ValidationErrors | null {
  const inputNumber = control.value;
  if (inputNumber < 0) {
    return { negative: true };
  } else {
    return null;
  }
}
