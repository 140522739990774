import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoadDataService } from '@sympheny/utils/data-access';

import {
  SolarEnergyOrgDatabaseProfileCollection,
  SolarEnergyDatabaseProfileCollection,
  SolarEnergySavedProfileCollection,
} from './solar-resources';

@NgModule({
  imports: [CommonModule],
})
export class DatabaseDataAccessOnSiteResourcesModule {
  public static forRoot(): ModuleWithProviders<DatabaseDataAccessOnSiteResourcesModule> {
    return {
      ngModule: DatabaseDataAccessOnSiteResourcesModule,
      providers: [
        SolarEnergyDatabaseProfileCollection,
        SolarEnergySavedProfileCollection,
        SolarEnergyOrgDatabaseProfileCollection,
        {
          multi: true,
          provide: LoadDataService,
          useExisting: SolarEnergyDatabaseProfileCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: SolarEnergySavedProfileCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: SolarEnergyOrgDatabaseProfileCollection,
        },
      ],
    };
  }
}
