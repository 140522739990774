<mat-form-field appearance="fill">
  <mat-label *ngIf="label">{{ label | transloco }}</mat-label>
  <input
    matInput
    autocomplete="off"
    [attr.data-cy]="dataCy || label"
    [formControl]="formControl"
    [placeholder]="placeholder"
    [max]="max"
    [min]="min"
    [matDatepicker]="picker"
    (dateChange)="dateChange.emit()"
    #inputElement
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  <mat-hint *ngIf="requiredHint"
    >* {{ 'FORM.requiredHint' | transloco }}</mat-hint
  >
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>

<sympheny-info-button *ngIf="hint" [tooltip]="hint" [link]="hintLink">
</sympheny-info-button>
