import { SymphenyDate } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const ScenarioSchema = z.object({
  scenarioGuid: z.string(),
  scenarioName: z.string(),
  readyForExecution: z.boolean().nullish().default(false),
  preparingExecutionV2: z.boolean().nullish().default(false),
  updated: SymphenyDate().nullish(),
  variant: z.boolean().nullish().default(false),
  masterScenarioGuid: z.string().nullish(),
});

export type Scenario = z.infer<typeof ScenarioSchema>;
