import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';

import { FeatureFlag } from './feature-flag';

@Injectable()
export class FeatureFlagService {
  constructor(private readonly environmentService: EnvironmentService) {}

  public isEnabled(featureFlag: FeatureFlag): boolean {
    const featureFlags = localStorage.getItem(`featureFlag_${featureFlag}`);

    if (featureFlags) return featureFlags === 'true';
    const flags = this.environmentService.getValue('featureFlag');
    return (flags && flags[featureFlag]) ?? false;
  }

  public isDisabled(featureFlag: FeatureFlag): boolean {
    return !this.isEnabled(featureFlag);
  }
}
