export const colors = [
  '#7b3d69',
  '#250edf',
  '#abaa09',
  '#867434',
  '#89d995',
  '#9d1a41',
  '#581f69',
  '#f1c7c1',
  '#d42170',
  '#786be3',
  '#16cb98',
  '#c52475',
  '#9dd5df',
  '#3732cb',
  '#6275b9',
  '#85fb5f',
];
