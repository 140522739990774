<div
  *transloco="let t"
  role="alert"
  class="alert shadow-lg"
  [class.alert-info]="type === 'info'"
  [class.alert-success]="type === 'success'"
  [class.alert-error]="type === 'error'"
  [class.alert-warning]="type === 'warning'"
>
  <svg
    *ngIf="type || icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    class="stroke-current shrink-0 w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      [attr.d]="path"
    />
  </svg>
  <div class="flex flex-col items-center">
    <h3 *ngIf="alertTitle" class="font-bold">{{ t(alertTitle) }}</h3>
    <div *ngIf="message" [class.text-xs]="alertTitle">{{ t(message) }}</div>
  </div>

  <div *ngIf="actions && actions.length">
    <button
      class="btn btn-sm"
      [ngClass]="action.class ?? ''"
      *ngFor="let action of actions"
    >
      {{ t(action.label) }}
    </button>
  </div>
</div>
