import { Injectable, WritableSignal, computed, signal } from '@angular/core';

import { BreadcrumbModel } from './breadcrumb/breadcrumb.model';

const breadcrumbsValue = (
  breadcrumbs: BreadcrumbModel[],
  variables: Record<string, string>,
) => {
  return breadcrumbs.map((breadcrumb) => ({
    ...breadcrumb,
    label: variables[breadcrumb.key] ?? breadcrumb.label,
    translateLabel: breadcrumb.translateLabel ?? true,
    translateSubLabel: breadcrumb.translateSubLabel ?? true,
  }));
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbState {
  public readonly labels = computed(() =>
    breadcrumbsValue(this.breadCrumbs(), this.variables()),
  );

  private readonly variables: WritableSignal<Record<string, string>> = signal(
    {},
  );
  private readonly breadCrumbs: WritableSignal<BreadcrumbModel[]> = signal([]);

  public set(key: string, value: string) {
    this.variables.set({
      ...this.variables(),
      [key]: value,
    });
  }

  public setBreadcrumbs(breadcrumbs: BreadcrumbModel[]) {
    this.breadCrumbs.set(breadcrumbs);
  }
}
