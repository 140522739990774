import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectsService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {}

  protected readonly base = this.environmentService.getValue('base');
  protected readonly exchangeRate =
    this.environmentService.getValue('apiExchangeRates');

  /**
   * Get all suggested technologies
   */

  getAllSuggestedTechnologies(scenarioGuid: string): Observable<any> {
    return this.http.get(this.base + 'suggested-technologies');
  }
}
