<mat-expansion-panel hideToggle class="panel-profile" [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h2><ng-content select="[summary-title]"></ng-content></h2>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="panel-content">
    <ng-content select="[summary-content]"></ng-content>
  </div>
  <hr />
  <div class="panel-note">
    <h3>Note</h3>
    <p>
      {{ notes ?? 'N/A' }}
    </p>
    <ng-content select="[summary-notes]"></ng-content>
  </div>
  <div>
    <p *ngIf="source !== false" class="mt-2">
      Source:
      <a *ngIf="source !== null" target="_blank" [href]="sourceLink">
        {{ source }}
      </a>
    </p>
    <p *ngIf="lastUpdated !== false" class="mt-2">
      Last updated:
      {{ lastUpdated ? (lastUpdated | date: 'yyyy-MM-dd hh:mm:ss') : 'N/A' }}
    </p>
  </div>
</mat-expansion-panel>
