import { LinkData } from './link';
import { NodeData } from './node';

export type SankeyData<
  NODE_TYPE extends NodeData = NodeData,
  LINK_TYPE extends LinkData = LinkData,
> = {
  links: LINK_TYPE[];
  nodes: NODE_TYPE[];
};
