import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  findLatestDataZ,
  mapData,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo } from 'rxjs';

import { CrudService } from './crud.service';
import { ImportExport, ProjectVersion } from '../model';
import { DynamicCo2, DynamicTariff } from '../model/dynamic-tariff';
import {
  ImportExportV1Dtochema,
  ImportExportV1Listchema,
  ImportExportV1Schema,
} from '../model/import-export.model.v1';
import {
  ImportExportV2Dtochema,
  ImportExportV2ListSchema,
  ImportExportV2Schema,
  ImportExportV2WithProfileFiles,
} from '../model/import-export.model.v2';

@Injectable()
export class ImportsExportsService extends CrudService<ImportExport> {
  public readonly guidKey = 'guid';
  public readonly key = 'importsExports';

  /**
   * Get imports or exports by Guid
   *
   * @param type: string
   * @param ImpexGuid: string
   */

  public getImportExportByGuid(
    projectVersion: ProjectVersion,
    impexGuid: string,
    type: string,
    scenarioVariantGuid: string | null,
  ): Promise<any> {
    const params = scenarioVariantGuid
      ? { scenarioVariantGuid: scenarioVariantGuid }
      : null;
    const url =
      projectVersion === 'V2'
        ? `${this.base}v2/impex/${type.toUpperCase()}/${impexGuid}`
        : `${this.base}impex/${type.toUpperCase()}/${impexGuid}`;
    const schema =
      projectVersion === 'V2' ? ImportExportV2Schema : ImportExportV1Schema;
    return firstValueFrom(
      this.http
        .get<ResponseModel<ImportExport>>(url, { params })
        .pipe(mapDataZ(schema)),
    );
  }

  /**
   * Get imports or exports dynamic tariff by Guid
   *
   * @param type: string
   * @param ImpexGuid: string
   */

  public getImportExportDynamicTariffByGuid(
    impexGuid: string,
    type: string,
  ): Promise<DynamicTariff[]> {
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<{ tariffs: DynamicTariff[] }>
        >(`${this.base}impex/${type.toUpperCase()}/${impexGuid}/dynamic-tariff?name=dynamic_tariff`)
        .pipe(mapData('tariffs')),
    );
  }

  /**
   * Get imports or exports dynamic co2 by Guid
   *
   * @param type: string
   * @param ImpexGuid: string
   */

  public getImportExportDynamicCO2ByGuid(
    profileGuid: string,
  ): Promise<DynamicCo2[]> {
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<{ dynamicCo2Profile: DynamicCo2[] }>
        >(`${this.base}dynamic-co2/${profileGuid}`)
        .pipe(mapData('dynamicCo2Profile')),
    );
  }

  // @Deserialize(ImportExport)
  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    const url =
      projectVersion === 'V2'
        ? `${this.base}v2/scenarios/${scenarioGuid}/impexes`
        : `${this.base}scenarios/${scenarioGuid}/impexes`;
    const schema =
      projectVersion === 'V2'
        ? ImportExportV2ListSchema
        : ImportExportV1Listchema;
    return this.http
      .get<ResponseModel<ImportExport[]>>(url)
      .pipe(mapDataZ(schema));
  }

  // @Deserialize(ImportExport)
  public override async create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<ImportExport>,
  ) {
    const url =
      projectVersion === 'V2'
        ? `${this.base}v2/scenario/${scenarioGuid}/impex`
        : `${this.base}scenario/${scenarioGuid}/impex`;
    const dataSchema =
      projectVersion === 'V2' ? ImportExportV2Dtochema : ImportExportV1Dtochema;
    const schema =
      projectVersion === 'V2' ? ImportExportV2Schema : ImportExportV1Schema;
    if (projectVersion === 'V2')
      await this.uploadV2Profiles(
        scenarioGuid,
        data as ImportExportV2WithProfileFiles,
      );
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<ImportExport[]>
        >(url, mapDataRequest(dataSchema, data))
        .pipe(findLatestDataZ(schema, 'created')),
    );
  }

  // @Deserialize(ImportExport)
  public override async update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    impexGuid: string,
    data: Partial<ImportExport>,
  ) {
    const url =
      projectVersion === 'V2'
        ? `${this.base}v2/scenarios/impex/${impexGuid}`
        : `${this.base}scenarios/impex/${impexGuid}`;
    if (projectVersion === 'V2')
      await this.uploadV2Profiles(
        scenarioGuid,
        data as ImportExportV2WithProfileFiles,
      );
    const dataSchema =
      projectVersion === 'V2' ? ImportExportV2Dtochema : ImportExportV1Dtochema;
    const schema =
      projectVersion === 'V2' ? ImportExportV2Schema : ImportExportV1Schema;
    return firstValueFrom(
      this.http
        .put<ResponseModel<ImportExport>>(url, mapDataRequest(dataSchema, data))
        .pipe(mapDataZ(schema)),
    );
  }
  public async patch(
    scenarioGuid: string,
    impexGuid: string,
    data: Partial<ImportExport>,
  ) {
    const projectVersion: ProjectVersion = 'V1' as any;
    const url = `${this.base}scenarios/${scenarioGuid}/impex/${impexGuid}`;

    const dataSchema =
      projectVersion === 'V2' ? ImportExportV2Dtochema : ImportExportV1Dtochema;
    const schema =
      projectVersion === 'V2' ? ImportExportV2Schema : ImportExportV1Schema;
    if (projectVersion === 'V2')
      await this.uploadV2Profiles(
        scenarioGuid,
        data as ImportExportV2WithProfileFiles,
      );
    return firstValueFrom(
      this.http
        .patch<
          ResponseModel<ImportExport>
        >(url, mapDataRequest(dataSchema.deepPartial(), data))
        .pipe(mapDataZ(schema)),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    impexGuid: string,
    type: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(this.base + 'impex/' + type.toUpperCase() + '/' + impexGuid)
        .pipe(mapTo(impexGuid)),
    );
  }

  private async uploadV2Profiles(
    scenarioGuid: string,
    impex: ImportExportV2WithProfileFiles,
  ) {
    const updatePromise: Promise<void>[] = [];

    updatePromise.push(
      this.uploadProfileAndUpdateId(
        scenarioGuid,
        impex,
        'maximumHourlyEnergyAvailableProfileFile',
        'maximumHourlyEnergyAvailableProfileId',
      ),
    );
    if (impex.dynamicTarifSelected) {
      impex.energyPriceCHFkWh = null;
      updatePromise.push(
        this.uploadProfileAndUpdateId(
          scenarioGuid,
          impex,
          'hourlyEnergyPriceProfileFile',
          'hourlyEnergyPriceProfileId',
        ),
      );
    } else {
      impex.hourlyEnergyPriceProfileId = null;
    }
    if (impex.dynamicCo2Selected) {
      impex.co2IntensityKgCo2kWhCo2CompensationKgCo2kWh = null;
      updatePromise.push(
        this.uploadProfileAndUpdateId(
          scenarioGuid,
          impex,
          'dynamicCo2ProfileFile',
          'dynamicCo2ProfileId',
        ),
      );
    } else {
      impex.dynamicCo2ProfileId = null;
    }

    await Promise.all(updatePromise);
    return impex;
  }
}
