<div class="flex flex-col gap-1">
  <sympheny-checkbox
    label="Display Co2 range on the map"
    [(ngModel)]="co2Range"
    (changeChecked)="toggleCo2Range.emit($event)"
  ></sympheny-checkbox>
  <sympheny-select-dataset
    [datasets]="datasets$ | async"
    [selectedDataset]="selectedDataset"
    [disabled]="disabled"
    [canEdit]="canEdit"
    [translate]="false"
    (changeDataset)="onChangeDataset($event)"
    (delete)="onDeleteDataset($event)"
  ></sympheny-select-dataset>
  <sympheny-select-building-information
    [buildingInfoOptions]="buildingInfoOptions"
    (changeBuildingInfo)="onChangeBuildingInfo($event)"
  ></sympheny-select-building-information>
  <div *ngIf="!address; else showAddress">
    <p>
      <b>Please select a Hub in the map</b>
    </p>
  </div>

  <ng-template #showNoDatasetAddress>
    <div>
      <p>
        <b>The selected hub has no dataset entry</b>
      </p>
    </div>
  </ng-template>
  <ng-template #showSelectDataWarning>
    <div *ngIf="!buildingInfoOptions?.infoSelect">
      <p>
        <b
          >Please select the data to be displayed in the Data Category dropdown
          above</b
        >
      </p>
    </div>
  </ng-template>
  <ng-template #showAddress>
    <ng-container
      *ngIf="
        (selectedDataset && datasetAddress) || !selectedDataset;
        else showNoDatasetAddress
      "
    >
      <ng-container
        *ngIf="buildingInfoOptions?.infoSelect; else showSelectDataWarning"
      >
        <ng-container *ngFor="let info of display">
          <div *ngIf="buildingInformationConfiguration[info] as configuration">
            <strong class="font-bold text-primary">
              <span>{{ configuration.label | transloco }}</span>
            </strong>
            <ng-container
              *ngIf="configuration.label === 'Others'; else buildingInfo"
            >
              <ng-container *ngFor="let value of otherInfo">
                <sympheny-building-information-field
                  [customField]="value.custom_name"
                  [displayDataset]="!!selectedDataset"
                  [address]="address"
                  [datasetAddress]="datasetAddress"
                ></sympheny-building-information-field>
              </ng-container>
            </ng-container>
            <ng-template #buildingInfo>
              <ng-container *ngFor="let value of configuration.values">
                <sympheny-building-information-field
                  [keyLabel]="value"
                  [displayDataset]="!!selectedDataset"
                  [address]="address"
                  [datasetAddress]="datasetAddress"
                ></sympheny-building-information-field>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>

        <div *ngIf="!selectedDataset">
          <strong class="font-bold text-primary pt-2">
            <span style="color: #1abc9c"><em>Data powered by</em></span>
          </strong>
          <a
            *ngIf="addressProcessed?.sep"
            target="_blank"
            href="https://www.swissenergyplanning.ch/"
          >
            <img
              alt="interactive connection"
              src="https://static.wixstatic.com/media/f323df_4e5482ae5c3549bead07490e7f3ec12a~mv2.png/v1/fill/w_204,h_72,al_c,q_85,usm_0.66_1.00_0.01/Logo-SEP-web.webp"
              style="float: middle; width: 45px"
            />
          </a>
          <a
            *ngIf="addressProcessed?.geoadmin"
            target="_blank"
            href="https://www.geoadmin.ch/"
          >
            Geoadmin
          </a>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
