import { computePaths } from './compute';
import { OrthogonalPathData, Link, Graph } from '../../../model';

const computeCircularPath = (
  link: Link,
  { x: sx, y: sy, height: sh }: OrthogonalPathData,
  { x: tx, y: ty, height: th }: OrthogonalPathData,
) => {
  const height = sh < th ? th : sh;
  const width = 20;
  const x1 = sx + width;
  const y1 = sy + height;
  const x2 = tx - width;

  return `M ${sx} ${sy}
          L ${x1} ${sy}
          L ${x1} ${y1}
          L ${x2} ${y1}
          L ${x2} ${ty}
          L ${tx} ${ty} `;
};
const drawLine = (
  source: OrthogonalPathData,
  target: OrthogonalPathData,
  breakOffset: number,
) => {
  const { x: sx, y: sy } = source;
  const { x: tx, y: ty } = target;
  //const x1 = sx + 20;

  if (sy === ty || sx === tx) {
    return `M ${sx} ${sy} L ${tx} ${ty}`;
  }

  return `M ${sx} ${sy} H ${sx + breakOffset} V ${ty} H ${tx}`;
};

const computeNormalPath = (
  link: Link,
  source: OrthogonalPathData,
  target: OrthogonalPathData,
  verticalLink:
    | { source: OrthogonalPathData; target: OrthogonalPathData }
    | undefined,
) => {
  //const x1 = sx + 20;
  const { breakOffset } = link.orthogonalPathData;

  if (verticalLink) {
    return [
      drawLine(verticalLink.source, verticalLink.target, breakOffset),
      drawLine(source, target, breakOffset),
    ].join(' ');
  }

  return drawLine(source, target, breakOffset);
};

const sortOrthogonalLinks =
  (key: 'source' | 'target') => (l1: Link, l2: Link) => {
    const o1 = l1.orthogonalPathData![key];
    const o2 = l2.orthogonalPathData![key];
    const offset = 10;

    if (o1.y - o2.y < offset) return -1;

    if (o1.y === o2.y) console.warn('oops');

    return 1;
  };

const createPath = (link: Link) => {
  const { orthogonalPathData } = link;

  if (!orthogonalPathData) {
    return;
  }
  link.setValue(
    'path',
    computeNormalPath(
      link,
      orthogonalPathData.source,
      orthogonalPathData.target,
      orthogonalPathData.verticalLink,
    ),
  );
};

export const computeOrthogonalPaths = (graph: Graph<any, any>) => {
  const { graph: data } = graph;

  computePaths(graph);

  // Create the path
  data.forEachLink(createPath);

  return;
};
