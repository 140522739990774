import { Injectable } from '@angular/core';
import { ProjectState } from '@sympheny/project/data-access';
import { BreadcrumbService as UiBreadcrumbService } from '@sympheny/ui/breadcrumb';
import { Observable, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private initialised = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly breadcrumbService: UiBreadcrumbService,
    private readonly projectState: ProjectState,
  ) {}

  public init() {
    if (this.initialised) {
      return;
    }

    this.changeBreadcrumbs(this.projectState.projectName$, ':projectId');
    this.changeBreadcrumbs(
      this.projectState.activeAnalysisName$,
      ':analysisId',
    );
    this.initialised = true;
  }

  public destroy() {
    this.breadcrumbService.set(':projectId', '');
    this.breadcrumbService.set(':analysisId', '');
    this.breadcrumbService.set(':scenarioId', '');
    this.destroy$.next();
    this.initialised = false;
  }

  private changeBreadcrumbs(
    obs$: Observable<string | null | undefined>,
    key: string,
  ) {
    return obs$
      .pipe(
        tap((label) => {
          this.breadcrumbService.set(key, label ?? '');
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public setScenarioBreadcrumbs(scenarioName: string) {
    this.breadcrumbService.set(':scenarioId', scenarioName);
  }
}
