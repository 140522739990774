import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';
import { EnvironmentService } from '@sympheny/utils/environment';

import { SUPPORT, SUPPORT_KEYS } from './support-links';

@Component({
  selector: 'sympheny-scenario-step',
  templateUrl: './scenario-step.component.html',
  styleUrls: ['./scenario-step.component.scss'],
  standalone: true,
  imports: [NgIf, MatTooltipModule, TranslocoPipe],
})
export class ScenarioStepComponent {
  @Input() public stepTitle: string;
  @Input() public templateProfile: string;
  @Input() public link: string;
  public templateUrl: string;
  public supportUrl: string;

  @Input() public set info(info: SUPPORT_KEYS) {
    const files = SUPPORT[info];
    this.templateUrl = this.getUrl('templateUrl', files?.template);
    this.supportUrl = this.getUrl('supportUrl', files?.supportPage);
  }

  constructor(
    private readonly environmentService: EnvironmentService<
      Record<string, string>
    >,
  ) {}

  private getUrl(type: string, file: string) {
    if (!file) {
      return null;
    }
    return `${this.environmentService.getValue(type)}${file}`;
  }
}
