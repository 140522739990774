import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoPipe } from '@ngneat/transloco';

import { DialogActionsComponent } from '../dialog-actions/dialog-actions.component';

export interface DialogData {
  question?: string;
  title: string;
  isCopy?: boolean;
  submitLabel?: string;
}

@Component({
  selector: 'sympheny-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  standalone: true,
  imports: [DialogActionsComponent, TranslocoPipe],
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) {}

  public submitAction() {
    this.dialogRef.close(true);
  }
}
