import { Injectable } from '@angular/core';
import {
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo } from 'rxjs';

import { CrudService } from './crud.service';
import {
  EnergyCarrier,
  EnergyCarrierDtoSchema,
  EnergyCarrierSchema,
  ProjectVersion,
} from '../model';

@Injectable()
export class EnergyCarrierService extends CrudService<EnergyCarrier> {
  public readonly guidKey = 'energyCarrierGuid';
  public readonly key = 'energyCarriers';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<
        ResponseModel<{ energyCarriers: EnergyCarrier[] }>
      >(`${this.base}scenarios/${scenarioGuid}/carriers`)
      .pipe(mapDataZ(EnergyCarrierSchema, 'energyCarriers'));
  }

  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<EnergyCarrier>,
  ) {
    const saveData = mapDataRequest(EnergyCarrierDtoSchema, {
      allowVirtualLoad: false,
      colorHexCode: data.colorHexCode,
      energyCarrierName: data.energyCarrierName,
      energyCarrierSubtype: data.subtypeKey,
      energyCarrierType: data.typeKey,
    });
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<EnergyCarrier>
        >(`${this.base}scenarios/${scenarioGuid}/carriers`, saveData)
        .pipe(mapDataZ(EnergyCarrierSchema)),
    );
  }

  public override createMultiple(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<EnergyCarrier>[],
  ) {
    return firstValueFrom(
      this.http
        .post<ResponseModel<{ energyCarriers: EnergyCarrier[] }>>(
          `${this.base}scenarios/${scenarioGuid}/energy-carriers`,
          data.map((d) => ({
            allowVirtualLoad: false,
            ...d,
          })),
        )
        .pipe(mapDataZ(EnergyCarrierSchema, 'energyCarriers')),
    );
  }

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    energyCarrierGuid: string,
    data: Partial<EnergyCarrier>,
  ) {
    const saveData = mapDataRequest(EnergyCarrierDtoSchema, {
      allowVirtualLoad: false,
      colorHexCode: data.colorHexCode,
      energyCarrierName: data.energyCarrierName,
      energyCarrierSubtype: data.subtypeKey,
      energyCarrierType: data.typeKey,
    });

    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ energyCarriers: EnergyCarrier[] }>
        >(`${this.base}carriers/${energyCarrierGuid}`, saveData)
        .pipe(mapDataZ(EnergyCarrierSchema)),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    energyCarrierGuid: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(`${this.base}scenarios/carriers/${energyCarrierGuid}`)
        .pipe(mapTo(energyCarrierGuid)),
    );
  }
}
