import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapData, ResponseModel } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, mapTo, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Analysis,
  AnalysisDetails,
  AnalysisRequestDtoV,
  AnalysisRequestDtoV1,
  AnalysisRequestDtoV2,
  AnalysisRequestV2DtoSchema,
  ProjectVersion,
} from '../model';

type AnalyseResponse = ResponseModel<Analysis>;

export type ExecutionInfo = { numberOfExecutionsLeft: number };

@Injectable()
export class AnalysisService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {}

  private readonly base = this.environmentService.getValue('base');

  /**
   * Get executions info
   */
  public getExecutionsInfo(): Promise<ExecutionInfo> {
    return firstValueFrom(
      this.http
        .get<ResponseModel<ExecutionInfo>>(
          this.base + 'analyses/execution-info'
        )
        .pipe(mapData())
    );
  }

  /**
   * Get analysis details for project details
   */
  public getAnalysisDetails(
    projectGuid: string,
    analysisGuid: string
  ): Observable<AnalysisDetails> {
    return this.http
      .get<ResponseModel<AnalysisDetails>>(
        `${this.base}projects/${projectGuid}/analysis/${analysisGuid}`
      )
      .pipe(map((res) => res.data));
  }

  /**
   * Create new analysis for a project
   */
  public createAnalysis(
    projectGuid: string,
    analysisName: string
  ): Observable<Analysis> {
    return this.http
      .post<ResponseModel<Analysis>>(
        this.base + 'projects/' + projectGuid + '/analyses',
        {
          analysisName: analysisName,
        }
      )
      .pipe(mapData());
  }

  /**
   * Update analysis for a project
   */
  public updateAnalysis(
    projectGuid: string,
    analysisGuid: string,
    analysisName: string
  ): Observable<Analysis> {
    return this.http
      .post<AnalyseResponse>(
        `${this.base}projects/${projectGuid}/analysis/${analysisGuid}`,
        {
          analysisName: analysisName,
        }
      )
      .pipe(map((res) => res.data));
  }

  /**
   * Delete analysis
   */
  public deleteAnalysis(analysisGuid: string) {
    return this.http
      .delete(`${this.base}analysis/${analysisGuid}`)
      .pipe(mapTo(analysisGuid));
  }

  /**
   * Copy analysis
   */
  public copyAnalysis(analysisGuid: string): Observable<Analysis> {
    return this.http
      .put<AnalyseResponse>(`${this.base}analyses/${analysisGuid}/copy`, '')
      .pipe(map((res) => res.data));
  }

  /**
   * Submit data analysis data for execution
   */
  public executeAnalysis(
    projectVersion: ProjectVersion,
    analysisGuid: string,
    data: AnalysisRequestDtoV
  ): Observable<any> {
    return projectVersion === 'V2'
      ? this.executeAnalysisV2(
          analysisGuid,
          data as AnalysisRequestDtoV2 & { scenarios: string[] }
        )
      : this.executeAnalysisV1(analysisGuid, data as AnalysisRequestDtoV1);
  }

  private executeAnalysisV2(
    analysisGuid: string,
    data: AnalysisRequestDtoV2 & { scenarios: string[] }
  ) {
    const executions = data.scenarios.map((scenarioId) =>
      AnalysisRequestV2DtoSchema.parse({
        ...data,
        scenarioId,
      })
    );

    return this.http
      .post<AnalyseResponse>(
        `${this.environmentService.senseApi}solver/jobs`,
        executions
      )
      .pipe(map((res) => res.data));
  }

  private executeAnalysisV1(analysisGuid: string, data: AnalysisRequestDtoV1) {
    return this.http
      .post<AnalyseResponse>(
        `${this.base}analyses/executions/${analysisGuid}`,
        {
          ...data,
        }
      )
      .pipe(map((res) => res.data));
  }

  /**
   * Stop data analysis data execution
   */
  public stopAnalysis(analysisGuid: string): Observable<any> {
    return this.http.delete(this.base + 'analyses/executions/' + analysisGuid);
  }
}
