<div class="sympheny-banner" [ngClass]="bannerColor">
  <div>
    <ng-content></ng-content>
    <ng-container *ngIf="text">{{ text }}</ng-container>
    <ng-template [cdkPortalOutlet]></ng-template>
  </div>

  <button mat-icon-button (click)="onDismiss()" *ngIf="!disableDismiss">
    <mat-icon>close</mat-icon>
  </button>
</div>
