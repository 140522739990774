import { Injectable } from '@angular/core';

export interface LoadParameters {
  ewz: boolean;
  organization: string;
  organizationId: string;
}

@Injectable()
export abstract class LoadDataService {
  public abstract load(params: LoadParameters): void;
}
