import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type DataValue<T> = T[] | null | undefined;

function exclude<T, K extends keyof T>(
  key: keyof T,
  data: DataValue<T>,
  excluded: T[K]
): T[] {
  if (!data || data.length === 0) return [];
  if (!exclude) return data;

  return data.filter((d) => d[key] !== excluded);
}

export function excludeValue<T, K extends keyof T>(key: K, data: DataValue<T>) {
  return (o: Observable<T[K]>): Observable<DataValue<T>> =>
    o.pipe(map((value) => exclude(key, data, value)));
}
