import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard as Auth0Guard } from '@auth0/auth0-angular';
import { redirectToRightPlan } from '@sympheny/user/guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: '',
    data: { breadcrumb: { skip: true } },
    loadChildren: () =>
      import('@sympheny/user/auth0').then((m) => m.UserAuth0Module),
  },
  {
    path: '',
    data: { breadcrumb: { skip: true } },
    loadChildren: () =>
      import('@sympheny/ui/error-pages').then((m) => m.UiErrorPagesModule),
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects'),
    data: { breadcrumb: 'project.projects' },
    canActivate: [Auth0Guard, redirectToRightPlan()],
  },
  {
    path: 'projects',
    loadChildren: () => import('./project-details'),
    data: { breadcrumb: 'project.projects' },
    canActivate: [Auth0Guard, redirectToRightPlan()],
  },
  {
    path: 'dashboard',
    data: {
      breadcrumb: { skip: true },
    },
    loadChildren: () =>
      import('@sympheny/dashboard/result').then((r) => r.DashboardShareRoutes),
  },
  {
    path: 'account',
    loadChildren: () => import('@sympheny/user/profile'),
    data: { breadcrumb: 'Profile' },
    canActivate: [Auth0Guard, redirectToRightPlan()],
  },
  {
    path: 'docs',
    loadChildren: () =>
      import('@sympheny/api-docs').then((m) => m.ApiDocsModule),
    data: { breadcrumb: 'API Docs' },
    canActivate: [Auth0Guard, redirectToRightPlan()],
  },
  {
    path: 'docs/api',
    loadChildren: () => import('@sympheny/swagger'),
    data: { breadcrumb: 'API Docs' },
    canActivate: [Auth0Guard, redirectToRightPlan()],
  },
  {
    path: 'database',
    loadChildren: () =>
      import('@sympheny/database/feature-edit').then(
        (m) => m.DatabaseFeatureEditModule,
      ),
    data: { breadcrumb: 'DATABASE.database' },
    canActivate: [Auth0Guard, redirectToRightPlan()],
  },

  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      // relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
