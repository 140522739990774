import { Injectable } from '@angular/core';
import { CrudService, ProjectVersion } from '@sympheny/project/data-access';
import {
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, map } from 'rxjs';

import {
  NewScenarioVariantSchema,
  ScenarioVariant,
  ScenarioVariantSchema,
} from './scenario-variant.model';

@Injectable()
export class ScenarioVariantService extends CrudService<ScenarioVariant> {
  public readonly guidKey = 'guid';
  public readonly key = 'scenarioVariants';

  public override list(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    masterScenarioGuid: string | null
  ) {
    return this.http
      .get<ResponseModel<ScenarioVariant[]>>(
        `${this.base}master-scenario/${
          masterScenarioGuid ?? scenarioGuid
        }/scenario-variants`
      )
      .pipe(mapDataZ(ScenarioVariantSchema));
  }

  /**
   * Create a Stage
   */
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<ScenarioVariant>
  ) {
    return this.createVariant(data);
  }

  public createVariant(data: Partial<ScenarioVariant>) {
    return firstValueFrom(
      this.http
        .post<ResponseModel<ScenarioVariant>>(
          `${this.base}scenario-variants`,
          mapDataRequest(NewScenarioVariantSchema, data)
        )
        .pipe(mapDataZ(ScenarioVariantSchema))
    );
  }

  /**
   * Delete Stage
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    scenarioVariantGuid: string
  ) {
    return this.deleteScenario(scenarioVariantGuid);
  }

  public deleteScenario(scenarioVariantGuid: string) {
    return firstValueFrom(
      this.http
        .delete(`${this.base}scenario-variants/${scenarioVariantGuid}`)
        .pipe(map(() => scenarioVariantGuid))
    );
  }
}
