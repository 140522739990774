import { Injectable } from '@angular/core';
import { Dataset } from '@sympheny/gis/datasets';
import { Address, LayerType } from '@sympheny/project/scenario/data-access';
import { Observable } from 'rxjs';

import { MapperDataType } from './mapper';
import { DataType, ScenarioMapState } from './scenario-map.store';
import { HubProcessed } from '../layer/map-hub-layer';

@Injectable()
export abstract class MapStore {
  public readonly dataSetAddress$: Observable<any>;
  public abstract getLayers<
    TYPE extends LayerType,
    DATA = MapperDataType[TYPE],
  >(layerType: TYPE): DATA[];

  public abstract getLayer<TYPE extends LayerType, DATA = MapperDataType[TYPE]>(
    layerType: TYPE,
    layerId: string,
  ): DATA | null;

  public abstract selectAddress(
    address: Address | null,
    processed: HubProcessed | null,
  );
  public abstract showFeatureData(features: any[]);
  public abstract getView();
  public abstract toggleCo2Range(toggle: boolean);
  public abstract delete(
    layerType: LayerType,
    scenarioId: string,
    layerId: string,
  );
  public abstract create<TYPE extends LayerType>(
    layerType: TYPE,
    scenarioId: string,
    data: Partial<DataType[TYPE]>,
    extra?: any,
  );
  public abstract update<TYPE extends LayerType>(
    layerType: TYPE,
    scenarioId: string,
    layerId: string,
    data: Partial<DataType[TYPE]>,
    extra?: any,
  );
  public abstract selectValue<K extends keyof ScenarioMapState>(
    key: K,
  ): Observable<ScenarioMapState[K]>;
  public abstract changeDataset(dataset: Dataset | null);

  public abstract getLayers$<
    TYPE extends LayerType,
    DATA = MapperDataType[TYPE],
  >(layerType: TYPE): Observable<DATA[]>;
  public abstract loadLayer(layerType: LayerType, layerId: string);
  public abstract getLayer$(layerType: LayerType, layerId: string);
}
