export class ProfileValue {
  public demandValue: number;
  public period: number;
}

export class Profile {
  public id: number;
  public name: string;
  public values: ProfileValue[];
}
