import { Map as OlMap } from 'ol';
import SearchNominatim from 'ol-ext/control/SearchNominatim';

import { zoomToCoordinates } from './zoom-to-center';

interface SearchBarConfig {
  placeholder: string;
  zoom?: number;
  onSelect?: (coordinates: number[]) => void;
}

export function addMapSearchBar(map: OlMap, config?: SearchBarConfig) {
  // Search Bar
  const searchBar: any = new SearchNominatim({
    className: 'sympheny-map-search-bar',
    maxHistory: -1,
    collapsed: false,
    polygon: false,
    placeholder: config?.placeholder ?? 'Search address...',
    noCollapse: true,
    typing: 750,
  });
  searchBar.setProperties({ copy: null });
  searchBar.addEventListener('select', (e: any) => {
    zoomToCoordinates(map, e.coordinate, { zoom: config?.zoom });
    searchBar.collapse(true);
    if (config?.onSelect) {
      config.onSelect(e.coordinate);
    }
  });
  map.addControl(searchBar);

  return searchBar;
}
