import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatePickerComponent } from './datepicker/datepicker.component';
import { InputComponent } from './input/input.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputYearComponent } from './input-year/input-year.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { SelectComponent } from './select/select.component';
import { SelectDayComponent } from './select-day/select-day.component';
import { SelectListComponent } from './select-list/select-list.component';
import { SelectMonthComponent } from './select-month/select-month.component';
import { SelectTimeComponent } from './select-time/select-time.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ToggleComponent } from './toggle/toggle.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

const imports = [
  InputNumberComponent,
  ToggleButtonComponent,
  ToggleComponent,
  SelectComponent,
  SelectListComponent,
  InputComponent,
  RadioGroupComponent,
  CheckboxComponent,
  TimePickerComponent,
  TextareaComponent,
  InputYearComponent,
  DatePickerComponent,
  SelectDayComponent,
  SelectMonthComponent,
  SelectTimeComponent,
  AutocompleteComponent,
];

@NgModule({
  imports: [...imports],
  exports: [...imports, ReactiveFormsModule, FormsModule],
})
export class UiFormModule {}
