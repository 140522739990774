<mat-expansion-panel
  [hideToggle]="disabled"
  class="panel-profile"
  [expanded]="!disabled && expanded"
  [disabled]="disabled"
>
  <mat-expansion-panel-header
    [expandedHeight]="'48px'"
    [collapsedHeight]="'48px'"
  >
    <mat-panel-title>
      <ng-content select="[section-title]"></ng-content>
      <ng-container *ngIf="title">{{ title | transloco }}</ng-container>
      <ng-container *ngIf="sectionTitle">{{
        sectionTitle | transloco
      }}</ng-container>
      <sympheny-info-button
        *ngIf="titleInfo"
        [tooltip]="titleInfo"
      ></sympheny-info-button>
    </mat-panel-title>
    <mat-panel-description *ngIf="description">
      {{ description | transloco }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="panel-content">
    <ng-content select="[section-content]"></ng-content>
  </div>
</mat-expansion-panel>
