import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@fp-tools/angular-state';
import { mapData, ResponseModel } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { map, Observable, of } from 'rxjs';

export class SolarEnergySavedProfile {
  public guid: string;
  public averageProfileValue: number;
  public maximumProfileValue: number;
  public minimumProfileValue: number;
  public name: string;
  public notes: string;
}

@Injectable()
export class SolarEnergySavedProfileCollection extends Collection<
  SolarEnergySavedProfile,
  any
> {
  private readonly base = this.environmentService.getValue('base');
  protected readonly idKey = 'guid';

  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService
  ) {
    super();
  }

  public find(guid: string): Observable<SolarEnergySavedProfile | null> {
    if (!guid) {
      return of(null);
    }

    return this.data$.pipe(
      map(
        (items) =>
          (items && items.find((item) => item[this.idKey] === guid)) ?? null
      )
    );
  }

  protected fetchApi(): Observable<SolarEnergySavedProfile[]> {
    return this.http
      .get<ResponseModel<{ responseDtos: SolarEnergySavedProfile }>>(
        `${this.base}user-solar-resources`
      )
      .pipe(mapData('responseDtos'));
  }
}
