import { NgIf, AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DataSetState } from '@sympheny/gis/datasets';
import { DragAndDropService } from '@sympheny/gis/utils';
import {
  Hub,
  NetworkLink,
  NetworkTechnology,
  Project,
} from '@sympheny/project/data-access';
import {
  IconButtonConfiguration,
  IconButtonListConfiguration,
} from '@sympheny/ui/button';
import { IconButtonListComponent } from '@sympheny/ui/button';
import { UserState } from '@sympheny/user/data-access';
import { Feature } from 'ol';

import { DrawHubService } from '../layer/draw-hub.service';
import { MapGisToggleComponent } from '../map-gis/map-gis-toggle/map-gis-toggle.component';
import { MapGisComponent } from '../map-gis/map-gis.component';
import { DrawNetworkLinkService } from '../map-gis/network-link/draw-network-link.service';
import { defaultConfiguration, MapConfiguration } from '../model/configuration';
import { HubChangeEvent } from '../model/events';
import { downloadGisData } from '../utils/download-gis-data';

@Component({
  selector: 'sympheny-scenario-map',
  templateUrl: './scenario-map.component.html',
  styleUrls: ['./scenario-map.component.scss'],
  providers: [DrawNetworkLinkService, DragAndDropService, DrawHubService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MapGisToggleComponent,
    IconButtonListComponent,
    MapGisComponent,
    AsyncPipe,
  ],
})
export class ScenarioMapComponent implements OnChanges {
  @Input() public editableHubId?: string;
  @Input() public scenarioId!: string;
  @Input() public newHub?: boolean;
  @Input() public zoomToHub?: string;
  @Input() public energyResourceHubGuid?: string;
  @Input() public hubs?: Hub[];
  @Input() public networkLink?: NetworkLink;
  @Input() public showMapBuildingInfo?: boolean;
  @Input() public networkTechnologies: NetworkTechnology[] | null = null;
  @Input() public project: Project;

  @Output() public readonly networkLinkChange =
    this.drawNetworkLinkService.changeDetails$;
  @Output() public readonly hubChange = new EventEmitter<HubChangeEvent>();
  @Output() public readonly selectedFeatures = new EventEmitter<
    Feature<any>[]
  >();

  @ViewChild(MapGisComponent, { static: false })
  public readonly mapGis: MapGisComponent | null = null;
  public readonly gisEnabled$ = this.userState.gisOn$;
  public showMap = true;
  public locked = true;
  public canEdit = true;

  public configuration = defaultConfiguration;

  public actionConfiguration: IconButtonListConfiguration = {
    buttons: [],
  };

  constructor(
    private readonly datasetState: DataSetState,
    private readonly userState: UserState,
    private readonly drawNetworkLinkService: DrawNetworkLinkService,
  ) {
    this.datasetState.load();
  }

  @HostBinding('class') public get hostClass() {
    return 'block h-full ';
  }
  public get showActions() {
    return (
      this.configuration.displayToggle ||
      this.showMapBuildingInfo ||
      this.configuration.customerLayers ||
      this.configuration.importGisData
    );
  }

  public get mapHeight() {
    if (this.configuration.fullHeight) {
      return '100%';
    }

    return this.showActions ? '500px' : '100%';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['project']) {
      this.canEdit = this.project?.rights?.canEdit ?? false;
      this.locked = this.project?.rights?.locked ?? false;
    }
  }

  @Input() set config(config: Partial<MapConfiguration>) {
    this.configuration = {
      ...defaultConfiguration,
      ...config,
    };

    const buttons: IconButtonConfiguration[] = [];

    if (config.download) {
      buttons.push({
        action: () => this.download(),
        tooltip: 'map.download.gis',
        icon: 'cloud_download',
      });
    }

    if (config.customerLayers) {
      buttons.push({
        action: () => this.toggleLayerConfiguration(),
        tooltip: 'map.layer.edit.customer',
        icon: 'layers',
      });
    }

    if (config.importGisData) {
      buttons.push({
        action: () => this.toggleBuildingInformation(),
        tooltip: 'BUILDING_LAYER.buildingInfo',
        icon: 'dataset_linked',
      });
    }

    this.actionConfiguration = { buttons };
  }

  download() {
    const layers = this.mapGis?.downloadableLayers as any;
    if (!layers) {
      return;
    }
    downloadGisData(this.scenarioId, layers);
  }

  toggleLayerConfiguration() {
    this.mapGis?.toggleLayerConfiguration();
  }

  toggleBuildingInformation() {
    this.mapGis?.toggleBuildingInformation();
  }
}
