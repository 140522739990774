<div
  id="selectDeselectControlBar"
  class="select-deselect-control-bar ol-unselectable ol-control"
>
  <div id="switchSelectDeselect" class="select-deselect-button-group">
    <button
      [class.select-deselect-button-active]="!deselectFeatures"
      (click)="changeMethod('ADD')"
    >
      <mat-icon matTooltip="Add surfaces">add</mat-icon>
    </button>
    <button
      [class.select-deselect-button-active]="deselectFeatures"
      (click)="changeMethod('DELETE')"
    >
      <mat-icon matTooltip="Remove surfaces">delete</mat-icon>
    </button>
  </div>
  <div class="select-deselect-button-group">
    <button
      [class.select-deselect-button-active]="selectType === 'CLICK'"
      (click)="changeMode('CLICK')"
    >
      <mat-icon matTooltip="Select by clicking">ads_click</mat-icon>
    </button>
    <button
      [class.select-deselect-button-active]="selectType === 'DRAG'"
      (click)="changeMode('DRAG')"
    >
      <mat-icon matTooltip="Select by dragging">highlight_alt</mat-icon>
    </button>
    <button (click)="selectAllFeatures()">
      <mat-icon matTooltip="Select all">auto_awesome_motion</mat-icon>
    </button>
  </div>
</div>

<sympheny-alert
  *ngIf="!isHubSelected"
  message="VALIDATION.select.hub.to.select.surface"
  type="warning"
></sympheny-alert>
