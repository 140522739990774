export class BuildingAgeOrStandardResponseDto {
  public buildingAgeOrStandard: string;
  public specificEnergyDemandValueKWhM2: number;
  public displayValue: string;
  public energyDemandMetadataGuid: string;
  public dbOrganization?: any;
}

export class EnergyDemandDatabase {
  public buildingType: string;
  public minimumProfileValue: number;
  public maximumProfileValue: number;
  public averageProfileValue: number;
  public totalProfileValue: number;
  public fullLoadHours: number;
  public buildingAgeOrStandardResponseDtos: BuildingAgeOrStandardResponseDto[];
}

export class EnergyDemandAgeDatabase {
  public energyDemandMetadataGuid: string;
  public energyDemandMetadataName: string;
  public dbOrganisation?: any;
  public energyDemandType: string;
  public buildingType: string;
  public buildingAgeOrStandard: string;
  public specificEnergyDemandValueKWhM2: number;
  public notes: string;
  public source: string;
  public minimumProfileValue: number;
  public maximumProfileValue: number;
  public averageProfileValue: number;
  public totalProfileValue: number;
  public fullLoadHours: number;
}
