import { Direction, sortByDate } from '@sympheny/utils/sort';
import { map } from 'rxjs/operators';
import { ZodTypeAny, z } from 'zod';

import { ResponseModel } from '../model/response.model';

/** @deprecated use zod mapResponseDataZ */
function mapResponseData<R extends ResponseModel>(
  r: R,
  field?: keyof R['data'],
) {
  return field ? r.data[field] : r.data;
}

function parseZod<T>(schema: ZodTypeAny, data: T) {
  const parsed = Array.isArray(data)
    ? z.array(schema).safeParse(data)
    : schema.safeParse(data);

  if (parsed.success === false) {
    console.warn(parsed.error);
    console.log(data);
  }

  return parsed.success ? parsed.data : data;
}

function mapResponseDataZ<R extends ResponseModel>(
  schema: ZodTypeAny,
  r: R,
  field?: keyof R['data'],
) {
  const data = field ? r.data[field] : r.data;

  return parseZod(schema, data);
}

export function mapDataRequest<R>(schema: ZodTypeAny, data: R) {
  const parsed = schema.safeParse(data);

  if (parsed.success === false) {
    console.warn(parsed.error);
    console.log(data);
  }

  return parsed.success ? parsed.data : data;
}

/** @deprecated use zod mapDataZ */
export function mapData<R extends ResponseModel>(field?: keyof R['data']) {
  return map((r: R) => mapResponseData(r, field));
}
export function mapDataZ<R extends ResponseModel>(
  schema: ZodTypeAny,
  field?: keyof R['data'],
) {
  return map((r: R) => mapResponseDataZ(schema, r, field));
}
export function mapResponse<DATA>(schema: ZodTypeAny) {
  return map((data: DATA) => parseZod(schema, data));
}
export function findByGuidZ<R extends ResponseModel>(
  schema: ZodTypeAny,
  guidKey: string,
  guid: string,
  field?: keyof R['data'],
) {
  return map((r: R) => {
    const data = mapResponseDataZ(schema, r, field) as [];

    return data.find((h) => (h[guidKey] as any) === guid)!;
  });
}

/** @deprecated use zod findLatestDataZ */
export function findByGuid<R extends ResponseModel>(
  guidKey: string,
  guid: string,
  field?: keyof R['data'],
) {
  return map((r: R) => {
    const data = mapResponseData(r, field) as [];

    return data.find((h) => (h[guidKey] as any) === guid)!;
  });
}

/** @deprecated use zod findLatestDataZ */
export function findLatestData<R extends ResponseModel>(
  key: string,
  field?: keyof R['data'],
) {
  return map((r: R) => {
    const data = mapResponseData(r, field) as [];
    const sorted = sortByDate(data).byAttribute(key, Direction.DESC);

    return sorted && sorted[0];
  });
}

export function findLatestDataZ<R extends ResponseModel>(
  schema: ZodTypeAny,
  key: string,
  field?: keyof R['data'],
) {
  return map((r: R) => {
    const data = mapResponseDataZ(schema, r, field) as [];
    const sorted = sortByDate(data).byAttribute(key, Direction.DESC);

    return sorted && sorted[0];
  });
}
