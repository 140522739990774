<ng-container *transloco="let t">
  <div
    *ngIf="type !== 'blank'"
    class="px-4 font-bold justify-center bg-gray-200 h-14 flex items-center text-center"
  >
    {{ t(summary.label, summary.params) }}
  </div>
  <div
    class="p-4 justify-center items-baseline text-center h-8 flex gap-1"
    [ngClass]="cssClass"
  >
    <sympheny-format-value
      [format]="summary"
      [element]="element"
    ></sympheny-format-value>

    <span *ngIf="showSuffix" class="text-gray-500 text-xs block">
      {{ t(summary.suffix, summary.params) }}
    </span>
  </div>
</ng-container>

<ng-template [cdkPortalOutlet]></ng-template>
