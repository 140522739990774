import { maxBy } from 'lodash-es';

import { Graph, Link, Node } from '../model';
type G_EL = d3.Selection<any, undefined, null, undefined>;

export const drawInfo = <NODE_TYPE extends Node, LINK_TYPE extends Link>(
  { graph: data, settings }: Graph<NODE_TYPE, LINK_TYPE>,
  g: G_EL,
  graphWidth: number,
  graphHeight: number,
  padding: { left: number; top: number },
) => {
  const { x0, x1, y0, y1 } = data.extend;
  let start = 150;
  let startCenter = 75;
  let end = x1 - start * 2;
  let endCenter = x1 - startCenter;
  const y = 20;
  const nodePadding = settings.minNodePaddingVertical / 2;

  data.forEachNodeAtColumn(0, (nodes) => {
    const maxWidth = maxBy(nodes, (n) => n.nodeWidth);
    // start += data.extend.x0 - paddingBox;

    if (maxWidth) {
      startCenter = maxWidth.nodePositionCenterX;
      start = padding.left + maxWidth.x1 + nodePadding;
    }
  });
  data.forEachNodeAtColumn(data.maxColumns(), (nodes) => {
    const maxWidth = maxBy(nodes, (n) => n.nodeWidth);
    // start += data.extend.x0 - paddingBox;

    if (maxWidth) {
      endCenter = maxWidth.nodePositionCenterX + padding.left;
      end = maxWidth.x0 - nodePadding;
    }
  });
  g.append('rect')
    .attr('x', start ?? 150)
    .attr('y', 0)
    .attr('height', graphHeight + 200)
    .attr('width', end - start)
    .style('text-anchor', 'middle')
    .style('fill', '#f4f4f4');

  g.append('text').attr('x', startCenter).attr('y', y).text('Inputs');
  g.append('text')
    .attr('x', 0)
    .attr('x', x1 / 2)
    .style('text-anchor', 'middle')
    .attr('y', y)
    .text('Technologies');
  g.append('text')
    .attr('x', endCenter)
    .style('text-anchor', 'middle')
    .attr('y', y)
    .text('Outputs');
};
