/** Inspired on https://observablehq.com/@tomshanley/sankey-circular-deconstructed */

import { ElementRef } from '@angular/core';
import * as d3 from 'd3';

import { Setup, GraphSetup } from '../draw/graph-setup';
import { DrawLinkProps, drawLinks, updateLink } from '../draw/link';
import { drawNode } from '../draw/node';
import { TextProps, createTextElement, updateText } from '../draw/text-element';
import { SankeyData, Link, Node } from '../model';
import { nodeCenter } from '../utils/node';

export const drawSankeyCirculair = <
  DATA extends SankeyData,
  NODE_TYPE extends Node,
  LINK_TYPE extends Link,
>(
  data: DATA,
  setup: Setup<NODE_TYPE, LINK_TYPE>,
  tooltip: ElementRef,
) => {
  console.log('------------ draw sankey');
  const marginRight = 75;
  const graphSetup = new GraphSetup<any, any>(setup, tooltip, marginRight);
  graphSetup.generateSankeyData(data);

  const { width, height, padding } = graphSetup.settings;
  const svg = d3
    .create('svg')
    .attr('width', width + padding + padding)
    .attr('height', height + padding + padding);

  const g = svg
    .append('g')
    .attr('transform', 'translate(' + padding + ',' + padding + ')');

  const textProps: TextProps<Node> = {
    anchor: 'left',
    y: (d) => nodeCenter(d),
    // nodeWidth: (d) => (d.hasTarget ? null : marginRight),
    // wordBreak: (d) => !d.hasTarget,
    x: (d) => d.x1 + 5,
  };
  const linkProps: DrawLinkProps = {
    width: (d) => Math.max(1, d.width),
  };

  const { nodeText } = graphSetup;
  const { node } = drawNode(graphSetup, g, svg, (n: Node) => updateDrawing(n));
  const { text } = createTextElement(node as any, nodeText, textProps);
  const { linkData, linkPaths } = drawLinks(graphSetup, g, svg, linkProps);
  //const { arrows } = drawArrows(graphSetup, links, linkG);

  const updateDrawing = (n?: Node) => {
    if (n) graphSetup.dragSankey(n);
    updateLink(graphSetup, linkData, linkPaths);
    updateText(text, nodeText, textProps);
    // updateArrow(arrows);
  };

  return svg.node();
};
