import { linkHorizontal } from 'd3-shape';

import { computeCircularPathString } from './compute-circulair-path';
import {
  calcVerticalBuffer,
  computeCircularPathData,
} from './compute-circulair-path-data';
import { Graph, GraphData, Link, Node } from '../model';

const computeNormalPath = (link: Link, source: Node, target: Node) => {
  const normalPath = linkHorizontal()
    .source((d: any) => {
      const x = source.x0 + (source.x1 - source.x0);
      const y = d.y0;
      return [x, y];
    })
    .target((d: any) => {
      const x = target.x0;
      const y = d.y1;
      return [x, y];
    });

  return normalPath(link as any);
};

export const computeLinkPaths = (graph: Graph<any, any>) => {
  const { graph: data } = graph;

  // calc vertical offsets per top/bottom links
  const topLinks = data.filterLinks((l: Link) => l.circularLinkType === 'top');
  /* topLinks = */ calcVerticalBuffer(topLinks, graph, data);

  const bottomLinks = data.filterLinks(
    (l: Link) => l.circularLinkType === 'bottom',
  );

  /* bottomLinks = */ calcVerticalBuffer(bottomLinks, graph, data);

  data.forEachLink((link: Link) => computeLinkPath(link, data, graph));
};

const computeLinkPath = (
  link: Link,
  data: GraphData,
  graph: Graph<any, any>,
) => {
  const minY = data.getMinY();
  const { source, target } = data.getNodeLinks(link);
  if (link.circular) {
    link.setValue(
      'circularPathData',
      computeCircularPathData(link, source, target, data, graph, minY),
    );
    link.setValue('path', computeCircularPathString(link));
  } else {
    link.setValue('path', computeNormalPath(link, source, target));
  }
};

export const recalculateLinkPaths = (graph: Graph<any, any>) => {
  const { graph: data } = graph;

  data.forEachLink((link: Link) => computeLinkPath(link, data, graph));
};
