import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Deserialize } from '@sympheny/utils/rxjs';
import { plainToClass } from 'class-transformer';
import { firstValueFrom, mapTo } from 'rxjs';

import { Dataset, DatasetAddress, DatasetSummary } from './dataset.model';

@Injectable()
export class DatasetService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService
  ) {}

  protected readonly gis =
    this.environmentService.getValue('apiServices') + 'gis/';

  @Deserialize(DatasetSummary)
  list() {
    return firstValueFrom(
      this.http.get<DatasetSummary[]>(`${this.gis}datasets`)
    );
  }

  @Deserialize(Dataset)
  create(dataset: Partial<Dataset>) {
    return firstValueFrom(
      this.http.post<Dataset>(
        `${this.gis}datasets`,
        plainToClass(DatasetAddress, dataset)
      )
    );
  }

  @Deserialize(Dataset)
  delete(dataset_id: string) {
    return firstValueFrom(
      this.http
        .delete<Dataset>(`${this.gis}datasets/${dataset_id}`)
        .pipe(mapTo(dataset_id))
    );
  }

  loadDataForScenario(dataSetId: string, scenarioId: string) {
    return firstValueFrom(
      this.http.get<Dataset>(`${this.gis}datasets/${dataSetId}`, {
        params: { scenarioId },
      })
    );
  }
}
