import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonComponent } from './button/button.component';
import { ButtonContainerComponent } from './button-container/button-container.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { IconButtonListComponent } from './icon-button-list/icon-button-list.component';
import { IconFabButtonComponent } from './icon-fab-button/icon-fab-button.component';
import { InfoButtonComponent } from './info-button/info-button.component';
import { MoreButtonComponent } from './more-button/more-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    TranslocoModule,
    FavoriteComponent,
    IconFabButtonComponent,
    InfoButtonComponent,
    ButtonComponent,
    IconButtonComponent,
    IconButtonListComponent,
    MoreButtonComponent,
    ButtonContainerComponent,
  ],
  exports: [
    IconButtonListComponent,
    MoreButtonComponent,
    FavoriteComponent,
    InfoButtonComponent,
    ButtonComponent,
    ButtonContainerComponent,
    IconFabButtonComponent,
    IconButtonComponent,
  ],
})
export class UiButtonModule {}
