import { nullOrUndefined } from '@sympheny/utils/rxjs';
const locale = 'en-CH';

export const formatThousands = (value: number | null) => {
  if (nullOrUndefined(value)) return '';
  if (value < 1000) return value / 1000;
  return Math.round(value / 1000);
};

export const formatThousandsToPrintFormat = (value: number | null) => {
  if (nullOrUndefined(value)) return '';
  const thousandsValue = formatThousands(value) as number;

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 3,
  }).format(thousandsValue);
};

export const formatNumberToPrintFormat = (value: number | null, digits = 3) => {
  if (nullOrUndefined(value)) return '';

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: digits,
  }).format(value);
};
