import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UploadFile } from '@sympheny/ui/upload';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Deserialize } from '@sympheny/utils/rxjs';
import { Observable } from 'rxjs';

import { Profile, UserPreferences } from '../model/account.model';

@Injectable()
export class ProfileService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {}

  private readonly profileApi = `${this.environmentService.backOfficeApi}users/profile`;
  /**
   * Get profile info
   */
  @Deserialize(Profile)
  public profile(): Observable<Profile> {
    return this.http.get<Profile>(`${this.profileApi}`);
  }

  /**
   * Save profile data
   *
   * @param profile
   */
  @Deserialize(UserPreferences)
  public savePreferences(
    preferences: UserPreferences
  ): Observable<UserPreferences> {
    return this.http.put<UserPreferences>(
      `${this.profileApi}/preferences`,
      preferences
    );
  }

  /**
   * Save profile image
   *
   * @param imageData
   */
  @Deserialize(Profile)
  public uploadProfilePicture(imageData: UploadFile): Observable<string> {
    return this.http.put<string>(
      `${this.profileApi}/profile-picture`,
      this.createMultiPartFormdata(imageData)
    );
  }

  /**
   * Save organisation image
   *
   * @param imageData
   */
  @Deserialize(Profile)
  public uploadOrganisationPicture(imageData: UploadFile): Observable<string> {
    return this.http.put<string>(
      `${this.profileApi}/organization-picture`,
      this.createMultiPartFormdata(imageData)
    );
  }
  private createMultiPartFormdata(imageData: UploadFile) {
    const formData = new FormData();
    formData.append('file', imageData.file);

    return formData;
  }
}
