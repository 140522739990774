import { NgIf } from '@angular/common';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import { TranslocoPipe } from '@ngneat/transloco';
import { PlanLimitation } from '@sympheny/user/data-access';

export interface ExecutionsLeftModalData {
  pricingPlan: PlanLimitation;
  executionsLeft: number;
  continue: boolean;
}

@Component({
  selector: 'sympheny-executions-left-modal',
  templateUrl: './executions-left-modal.component.html',
  styleUrls: ['./executions-left-modal.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogModule, MatButtonModule, TranslocoPipe],
})
export class ExecutionsLeftModalComponent implements OnInit {
  // Inputs
  public executionsLeft: number;
  public pricingPlan: string;
  public continue: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ExecutionsLeftModalData,
    public dialogRef: MatDialogRef<ExecutionsLeftModalComponent>,
    private _dialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    this.executionsLeft = this.data.executionsLeft;
    this.pricingPlan = this.data.pricingPlan.name;
    this.continue = this.data.continue;
  }

  public submitExecution() {
    // Close dialog
    this.dialogRef.close({
      continueButton: true,
    });
  }

  public closeDialog() {
    this._dialog.closeAll();
  }
}
