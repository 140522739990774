// eslint-disable-next-line @nx/enforce-module-boundaries
import { UploadFile } from '@sympheny/ui/upload';
import { fromLonLat } from 'ol/proj';

import { SecondaryOwner } from './secondary-owner.model';
import { InputOutputEnergyCarrier } from './technology.model';

export type ProjectVersion = 'V1' | 'V2';

export const ProjectVersions: Record<ProjectVersion, string> = {
  V1: 'Sympheny current version (V2)',
  V2: 'Sympheny beta version (V3)',
};

export class ProjectDetailsImage {
  url: string;
  guid: string;
  cover: boolean;
}

export class Project {
  projectName: string;
  projectGuid: string;
  created: Date;
  updated: Date;
  version: ProjectVersion;
  images: ProjectDetailsImage[];
  coverImage: any;
  processing: boolean;
  originalDefaultProjectGuid: string;
  gisCentroidX: number;
  gisCentroidY: number;
  zoomExtentXmax: number;
  zoomExtentXmin: number;
  zoomExtentYmax: number;
  zoomExtentYmin: number;
  favorite: boolean;
  public lock: boolean;
  public lockUserEmail: string;
  public ownedByCurrentUser: boolean;
  public lockTime: any;
  public secondaryOwners: SecondaryOwner[];
  public shared: boolean;

  public rights = {
    locked: false,
    lockedByMe: false,
    canEdit: false,
    favorite: false,
  };

  public secondaryOwner: SecondaryOwner | null = null;

  public addRights(email: string) {
    this.secondaryOwner =
      (this.secondaryOwners?.some &&
        this.secondaryOwners?.find((s) => s.email === email)) ??
      null;
    const canEdit = this.ownedByCurrentUser || this.secondaryOwner?.canEdit;

    const lockedByMe = this.lockUserEmail === email;
    const locked = canEdit && this.lock && !lockedByMe;
    const favorite =
      (this.ownedByCurrentUser && this.favorite) ||
      this.secondaryOwner?.favorite;

    this.rights = {
      locked,
      lockedByMe,
      canEdit: canEdit ?? false,
      favorite: favorite ?? false,
    };

    this.shared = this.secondaryOwners?.length > 0;

    return this;
  }

  public lockByMe() {
    this.rights = {
      ...this.rights,
      lockedByMe: true,
    };
  }

  getCoordinates(): [number, number] | null {
    if (!this.gisCentroidY || !this.gisCentroidX) {
      return null;
    }

    return [this.gisCentroidX, this.gisCentroidY];
  }

  getOlCoordinates() {
    if (!this.gisCentroidY || !this.gisCentroidX) {
      return null;
    }

    return fromLonLat([this.gisCentroidX, this.gisCentroidY]);
  }

  getExtent() {
    if (!this.zoomExtentXmin || !this.zoomExtentYmin) {
      return null;
    }

    return [
      this.zoomExtentXmin,
      this.zoomExtentYmin,
      this.zoomExtentXmax,
      this.zoomExtentYmax,
    ];
  }
}

export class ExecutionResult {
  dashboardUrl: string;
  executionSubmitted: string;
  scenarios: ExecutionScenario[];
}

export class ExecutionScenario {
  scenarioName: string;
  status: string;
  statusMessage: string;
  paretoPointsCompleted: string;
  filepath: string;
  inputFilepath: string;
  outputFilepath: string;
}

export class ExecutionOption {
  objective1: string;
  objective2: string;
  numberOfParetoPoints: number;
  scenarios: string[];
}

export class EnergyDemandProfile {
  averageProfileValue: number;
  buildingAgeOrStandard: string;
  buildingType: string;
  energyDemandMetadataGuid: string;
  energyDemandMetadataName: string;
  energyDemandType: string;
  maximumProfileValue: number;
  minimumProfileValue: number;
  notes: string;
  source: string;
  specificEnergyDemandValueKWhM2: number;
  totalProfileValue: number;
  totalAnnualDemand: number;
  referencedAreaM2: number;
  fullLoadHours: number;
  selectedProfileName: string;
  possibleOption: string;
}

export class EnergyDemandSavedProfile {
  guid: string;
  averageProfileValue: number;
  maximumProfileValue: number;
  minimumProfileValue: number;
  referenceArea: string;
  notes: string;
}

export class EnergyProfile {
  energyDemandGuid: string;
  hubGuid: string;
  energyCarrierGuid: string;
  scalingFactor: number;
}

export class SolarEnergyProfile {
  guid: string;
  name: string;
  optimal: boolean;
  orientation: string;
  slope: number;
  slopeAndOrientation: string;
}

export class ConversionTechnologyPackages {
  guid: string;
  technologyOptional: boolean;
  conversionTechnology: any;
}

export class StorageTechnologyPackages {
  guid: string;
  technologyOptional: boolean;
  storageTechnology: any;
}

class _InputOutputEnergyCarrier {
  energyCarrierName: string;
  energyCarrierGuid: string;
  energyCarrierType: string;
  fixedInputShare: number;
  outputEfficiency: number;
  customInputEfficiencyActivated: boolean;
  customOutputEfficiencyActivated: boolean;
  customSeasonalityValues: CustomSeasonalityValues[];
  outputEfficiencyProfileFile?: UploadFile;
  outputEfficiencyProfileId: number;
  created: string;
  primary: boolean;
  subtypeDisplayName: string;
  subtypeKey: string;
  typeDisplayName: string;
  typeKey: string;
  type: 'INPUT' | 'OUTPUT';
}

export class CustomSeasonalityValues {
  month: string;
  value: number;
}

export class StorageTechnologyProfile {
  storageTechGuid: string;
  storageTechnologyName: string;
  category: string;
  technologyCategory: string;
  notes: string;
  source: string;
  capacity: number;
  variableInvestmentCost: number;
  fixedInvestmentCost: number;
  variableOmCost: number;
  fixedOmCostChf: number;
  fixedOmCostPercent: number;
  lifetime: number;
  maximumChargingRate: number;
  maximumDischargingRate: number;
  standbyLoss: number;
  minimumSoc: number;
  maximumCapacity: number;
  minimumCapacity: number;
  variableEmbodiedCo2: number;
  fixedEmbodiedCo2: number;
  storageChargingEfficiency: number;
  storageDischargingEfficiency: number;
  storageCarrier: InputOutputEnergyCarrier;
  created: string;
  updated: string;
}

export class NetworkTechnologyProfile {
  databaseTechGuid: string;
  networkTechnologyName: string;
  networkLoss: number;
  fixedInvestmentCost: number;
  variableInvestmentCost: number;
  fixedOmCost: number;
  fixedOmCostChf: number;
  fixedOmCostPercent: number;
  lifetime: number;
  maximumCapacity: number;
  minimumCapacity: number;
  variableEmbodiedCo2: number;
  fixedEmbodiedCo2: number;
  created: string;
  updated: string;
  energyCarrier: InputOutputEnergyCarrier;
  notes: string;
  source: string;
  networkSize: string;
  technologyCategory: string;
}

export class StorageTechnologySavedProfile {
  storageTechGuid: string;
  name: string;
  category: string;
  notes: string;
  storedEnergyCarrierGuidelines: string;
  subcategory: string;
}

export class NetworkTechnologySavedProfile {
  category: string;
  databaseTechGuid: string;
  energyCarrierGuidelines: string;
  name: string;
  notes: string;
  subcategory: string;
}

export class NewEnergyCarrier {
  energyCarrierType: string;
  energyCarrierSubtype: string;
  customEnergyCarrierSubtype: string | null;
  type?: string;
  subtypeKey?: string;
  typeKey?: string;
  subtypeDisplayName?: string;
  fixedShare?: number;
  energyCarrierName?: string;
}

import { z } from 'zod';
export class CostType {
  name: string;
  value: string;
}

export class EwzYear {
  product: string;
  year: string;
  guid: string;
}
export const TimeOfUseSchema = z.object({
  name: z.string(),
  // TODO limit to months
  months: z.array(z.string()),
  // TODO limit to days
  days: z.array(z.string()),
  // TODO limit to time
  startTime: z.string(),
  endTime: z.string(),
});

export type TimeOfUse = z.infer<typeof TimeOfUseSchema>;
