import {
  HttpInterceptorFn,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

import { SymphenyErrorHandler } from './error.handler';
import { eTagInterceptor } from './interceptor/e-tag.interceptor';

export const provideHttpClientWithInterceptors = (
  ...interceptors: HttpInterceptorFn[]
) => {
  return [
    SymphenyErrorHandler,
    { provide: ErrorHandler, useExisting: SymphenyErrorHandler },
    AuthHttpInterceptor,
    provideHttpClient(withInterceptors([eTagInterceptor, interceptors].flat())),
  ];
};
