import { AbstractControl } from '@angular/forms';

export function MaxFileSizeValidator(maxFileSizeMb: number) {
  return (file: any) => {
    if (file instanceof File && file.size > maxFileSizeMb) {
      return {
        fileMinSize: {
          requiredSize: maxFileSizeMb,
          actualSize: file.size,
          file,
        },
      };
    }

    return null;
  };
}

export function MaxFileSizeBytesValidator(maxFileSizeMb: number) {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }
    const file = control.value as File;
    const size = file.size / 1024 / 1024;
    if (size > maxFileSizeMb) {
      return {
        fileMinSize: {
          requiredSize: maxFileSizeMb,
          actualSize: file.size,
          file,
        },
      };
    }

    return null;
  };
}
