import z from 'zod';

export const ResultsOnSiteResourcesSchema = z.object({
  name: z.string(),
  stage: z.string(),
  hub: z.string(),
  onsiteAreaResource: z.number(),
  onsiteEnergy: z.array(z.number()),
  energyCarrier: z.string(),
  monthlyEnergy: z.array(z.number()),
});

export type ResultsOnSiteResources = z.infer<
  typeof ResultsOnSiteResourcesSchema
>;
