<div class="user-guide-text">
  New to Sympheny? We'll help you get started. See our user guide
  <a
    class="user-guide-link"
    target="_blank"
    href="https://support.app.sympheny.com/user-guide/latest/"
    >here</a
  >.
</div>
<div class="user-guide-text">
  Have a look at the release notes of the latest version
  <a
    class="user-guide-link"
    target="_blank"
    href="https://support.app.sympheny.com/whats-new/latest/2023-march-pre-release-v3/"
    >here</a
  >.
</div>
