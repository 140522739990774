<div [attr.data-cy]="dataCy || label">
  <mat-slide-toggle
    [formControl]="formControl"
    color="primary"
    (change)="change($event)"
  >
    {{ label | transloco }}
    <ng-content></ng-content>
  </mat-slide-toggle>
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</div>
