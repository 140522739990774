import z from 'zod';

export const ResultCo2Schema = z.object({
  stage: z.string(),
  total: z.number().nullish(),
  techs: z.number().nullish(),
  imports: z.number().nullish(),
  exports: z.number().nullish(),
});

export type ResultCo2 = z.infer<typeof ResultCo2Schema>;
