import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[symphenyMapLoad]',
  standalone: true,
})
export class MapLoadDirective implements AfterViewInit, OnDestroy {
  @Input() mapUuid: string;
  @Output() mapLoaded = new EventEmitter<void>();

  private readonly observer: MutationObserver;

  constructor(private readonly elementRef: ElementRef) {
    this.observer = new MutationObserver((mutations) => {
      if (document.getElementById(this.mapUuid)) {
        this.mapLoaded.emit();
        this.observer.disconnect();
        return;
      }
    });
  }

  ngAfterViewInit() {
    this.observer.observe(this.elementRef.nativeElement, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
