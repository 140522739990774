import { scaleSequential, interpolateCool } from 'd3';

import { Graph } from './graph';
import { Link } from './link';
import { Node } from './node';

export const DefaultGraph: Graph<Node, Link> = {
  graph: undefined,
  useVirtualRoutes: true,
  linkColor: (link, nodes: { source: Node; target: Node }) => {
    if (link.circular) {
      return 'red';
    } else if (link.type === 'virtual') {
      return 'yellow';
    } else if (link.type === 'replaced') {
      return 'blue';
    } else {
      return 'black';
    }
  },
  nodeColor: (d: any) => {
    return scaleSequential(interpolateCool).domain([0, 1000])(d.x0);
  },
  nodeText: (d) => d.name,
  nodeTooltip: (d) => d.name,
  linkTooltip: (d) => `${d.source} -> ${d.target}`,
  getNodeID: (d: any) => d.name,
  settings: {
    paddingRatio: null,
    nodePadding: 25,
    verticalMargin: 25,
    baseRadius: 10,
    nodeDimensions: (node: any) => ({ width: 10, height: 50 }),
    scale: 0.3,
    iterations: 30,
    minNodePaddingHorizontal: 50,
    minNodePaddingVertical: 20,
    virtualNodePadding: 3,
    circularLinkGap: 5,
    width: 1000,
    padding: 25,
    height: 500,
    nodeAllowedInFirstColumn: (node: any) => true,
    nodeAllowedInLastColumn: (node: any) => true,
  },
  arrow: {
    draw: false,
    length: 10,
    gapLength: 70,
    headSize: 4,
    path: (link: Link) => link.path,
    color: 'darkSlate',
  },
  tooltip: null,
};
