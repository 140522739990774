<h2>Plan limit reached. Please consider upgrading.</h2>

<p class="mb-8" *ngIf="!limit">
  You are on the {{ pricingPlan }} plan which allows
  {{ maxParallelExecutions }} parallel execution(s). However, these projects and
  analyses are currently being executed:
</p>
<p class="mb-8" *ngIf="limit">
  You are on the {{ pricingPlan }} plan and have reached its limits. You can
  upgrade your account to remove this limit.
  <a
    target="_blank"
    href="https://support.app.sympheny.com/user-guide/latest/type-of-user"
    >Learn more</a
  >.
</p>

<ng-container *ngIf="!limit">
  <p *ngFor="let analyse of analyses">
    <ng-container>
      {{ analyse.projectName }} - {{ analyse.analysisName }}
    </ng-container>
  </p>

  <p class="mt-8">
    You must wait for the already running executions to finish before submitting
    a new one.
  </p>
  <p class="mt-8">
    You can also upgrade your account to remove this limit.
    <a
      target="_blank"
      href="https://support.app.sympheny.com/user-guide/latest/type-of-user"
      >Learn more</a
    >.
  </p>
</ng-container>

<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="false">OK</button>
</div>
