import { z } from 'zod';

export const UploadFileSchema = () =>
  z.object({
    fileName: z.string().nullish(),
    encodedFile: z.any().nullish(),
    file: z.any().nullish(),
  });

export interface UploadFile {
  fileName: string;
  encodedFile: string | ArrayBuffer;
  file: File;
}
