export class PredefinedEnergyCarrier {
  displayName: string;
  key: string;
  subtypes: Subtypes[];
}

export class Subtypes {
  key: string;
  displayName: string;
}
