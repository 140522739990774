export class DatasetField {
  custom_name: string;
  value: any;
  default_name: string;
}

export class DatasetAddress {
  egid: number;
  fields: DatasetField[];
}

export class Dataset {
  dataset_id: string;
  dataset_name: string;
  addresses: DatasetAddress[];
}

export class DatasetSummary {
  dataset_id: string;
  dataset_name: string;
  hub_labels: string[];
  number_of_addresses: number;
}
