import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';

import { AbstractImportCollection } from '../../abstract-impex.collection';

@Injectable()
export class UserImportCollection extends AbstractImportCollection {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, {
      db: 'user',
      type: 'IMPORT',
      categoryMapper: 'products',
      technologyMapper: 'years',
      guid: 'guid',
    });
  }
}
