import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoPipe } from '@ngneat/transloco';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';

@Component({
  selector: 'sympheny-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  standalone: true,
  imports: [TranslocoPipe, MatFormFieldModule, NgIf],
})
export class ToggleButtonComponent<T = any> extends BaseFormComponent<
  T,
  FormFieldConfig
> {
  @Input() public labelTrue: string;
  @Input() public labelFalse: string;

  @Input() public valueTrue: any = true;
  @Input() public valueFalse: any = false;

  @Output() public readonly valueChange = new EventEmitter<T>();

  public toggleValue(value: T) {
    this.formControl.setValue(value);
    this.valueChange.emit(value);
  }
}
