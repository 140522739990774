<button
  mat-icon-button
  [color]="color"
  [disabled]="disabled"
  type="button"
  (click)="action.emit()"
  [class.small]="size === 'small'"
  [class.custom-color]="color === 'custom'"
  aria-hidden="false"
  [attr.aria-label]="tooltip | transloco: tooltipParams"
  [attr.data-cy]="dataCy"
  [matTooltip]="tooltip | transloco"
>
  <mat-icon [fontSet]="fontSet">{{ icon }}</mat-icon>

  <ng-content></ng-content>
</button>
