import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, map, mapTo, switchMap } from 'rxjs';

import { LayerHttpService } from './layer-http.service';
import { CustomerLayer } from './model/customer-layer';
import { LayerType } from './model/layer.type';

@Injectable()
export class CustomerLayerService extends LayerHttpService<CustomerLayer> {
  public readonly layerType = LayerType.customerLayer;

  private readonly baseUrl = `${this.gis}layers-presigned`;

  public override list(scenarioId: string) {
    const results = this.http
      .get<CustomerLayer[]>(`${this.baseUrl}`)
      .pipe(
        switchMap((layers) =>
          combineLatest(
            layers.map((layer) => this.addUrlToLayer(scenarioId, layer))
          )
        )
      );

    return results;
  }

  public override get(scenarioId: string, layerId: string) {
    return this.http.get<CustomerLayer>(`${this.baseUrl}/${layerId}`);
  }

  public override async create(scenarioId: string, layer: CustomerLayer) {
    const presignedLayer = await this.getPresignedLayer(layer);
    const request = this.http
      .post<CustomerLayer>(`${this.baseUrl}`, presignedLayer)
      .pipe(
        switchMap((savedLayer) => this.addUrlToLayer(scenarioId, savedLayer))
      );

    return firstValueFrom(request);
  }
  private addUrlToLayer(scenarioId: string, layer: CustomerLayer) {
    return this.get(scenarioId, layer.layer_id).pipe(
      map((detail) => ({
        ...layer,
        ...detail,
      }))
    );
  }

  public override async update(
    scenarioId: string,
    layerId: string,
    layer: CustomerLayer
  ) {
    const presignedLayer = await this.getPresignedLayer(layer);
    const request = this.http
      .put<CustomerLayer>(`${this.baseUrl}/${layerId}`, presignedLayer)
      .pipe(
        switchMap((savedLayer) => this.addUrlToLayer(scenarioId, savedLayer))
      );

    return firstValueFrom(request);
  }

  public override delete(scenarioId: string, layerId: string) {
    return firstValueFrom(
      this.http.delete(`${this.gis}layers/${layerId}`).pipe(mapTo(layerId))
    );
  }
  private getPresignedLayer(layer: CustomerLayer) {
    return this.getPresignedUrl(layer, {
      layer_id: layer.layer_id,
      layer_name: layer.layer_name,
      layer_color: layer.layer_color,
    });
  }
}
