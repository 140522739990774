import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@sympheny/ui/dialog';
import { filter } from 'rxjs';

export function initCheckForUpdates(updateService: CheckForUpdateService) {
  return () => updateService.init();
}

@Injectable()
export class CheckForUpdateService {
  constructor(
    appRef: ApplicationRef,
    swUpdate: SwUpdate,
    private readonly dialogService: DialogService
  ) {
    swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe((evt) => {
        this.openConfirmationDialog();
      });
  }

  public init() {
    return true;
  }

  private openConfirmationDialog() {
    this.dialogService.openConfirmationDialog(
      {
        title: 'reload.title',
        submitLabel: 'reload.button',
      },
      () => document.location.reload()
    );
  }
}
