import { z } from 'zod';

export const ScenarioJobDatasetsSchema = z.object({
  datasets: z.array(z.object({ id: z.string(), name: z.string() })),
  aggregated: z.array(
    z.object({
      name: z.string(),
      datasets: z.array(z.object({ id: z.string(), name: z.string() })),
    })
  ),
});
export type ScenarioJobDatasets = z.infer<typeof ScenarioJobDatasetsSchema>;

export const ScenarioJobDatasetSchema = z.object({
  id: z.string(),
  name: z.string(),
});
export type ScenarioJobDataset = z.infer<typeof ScenarioJobDatasetSchema>;
