import { Fill, Stroke, Style } from 'ol/style';
const generateStyle = (color: string) => {
  return new Style({
    stroke: new Stroke({
      color: color,
    }),
    fill: new Fill({
      color: color,
    }),
  });
};

export const Co2Range: Record<string, Style> = {
  '0': generateStyle('#209664'),
  '0 - 5': generateStyle('#77bfa2'),
  '5 - 10': generateStyle('#96c380'),
  '10 - 15': generateStyle('#eae235'),
  '15 - 20': generateStyle('#f2af58'),
  '20 - 25': generateStyle('#e98b89'),
  '> 25': generateStyle('#dc3e3b'),
};
