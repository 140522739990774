export type DB_TYPES =
  | 'database'
  | 'user'
  | 'database-org'
  | 'customer'
  | 'ewz';

export type TECHNOLOGY_TYPE =
  | 'conversion-tech'
  | 'storage-tech'
  | 'network-tech'
  | 'tech-package'
  | 'energy-demand'
  | 'solar-resource'
  | 'imports'
  | 'exports';

export interface TECH {
  label: string;
  value: TECHNOLOGY_TYPE;
  presigned?: boolean;
  canUpload?: boolean;
  canDeleteItem?: boolean;
  canDeleteCategory?: boolean;
  valueLabel?: string;
  valueKey?: string;
  categoryLabel?: string;
  categoryKey?: string;
}

interface DB {
  label: string;
  value: string;
  options: TECH[];
}

export const technologies: TECH[] = [
  {
    label: 'Conversion Technologies',
    value: 'conversion-tech',
    valueLabel: 'technologyName',
    valueKey: 'conversionTechGuid',
    canDeleteItem: true,
    canDeleteCategory: true,
  },
  {
    label: 'Storage Technologies',
    value: 'storage-tech',
    valueLabel: 'name',
    valueKey: 'storageTechGuid',
    canDeleteItem: true,
    canDeleteCategory: true,
  },
  {
    label: 'Technology Packages',
    value: 'tech-package',
    valueLabel: 'name',
    valueKey: 'guid',
    categoryLabel: 'name',
    categoryKey: 'guid',
    canDeleteCategory: true,
  },
  {
    label: 'Network Technologies',
    value: 'network-tech',
    valueLabel: 'name',
    valueKey: 'databaseTechGuid',
    canDeleteItem: true,
    canDeleteCategory: true,
  },
];

export const onSiteResources: TECH[] = [
  {
    label: 'Solar Resources',
    value: 'solar-resource',
    presigned: true,
  },
];

export const energyDemands: TECH[] = [
  {
    label: 'Energy Demands',
    value: 'energy-demand',
    presigned: true,
  },
];

export const impex: TECH[] = [
  {
    label: 'Imports',
    value: 'imports',
    valueLabel: 'year',
    valueKey: 'guid',
    canUpload: false,
    canDeleteItem: true,
  },
  {
    label: 'Exports',
    value: 'exports',
    valueLabel: 'year',
    valueKey: 'guid',
    canUpload: false,
    canDeleteItem: true,
  },
];

export const techs: DB = {
  label: 'Technologies',
  value: 'technologies',
  options: technologies,
};

export const databases: DB[] = [
  {
    label: 'Energy Demands',
    value: 'energy-demands',
    options: energyDemands,
  },
  {
    label: 'On-site resources',
    value: 'on-site-resources',
    options: onSiteResources,
  },
  {
    label: 'Imports/Exports',
    value: 'impex',
    options: impex,
  },
  techs,
];
