import { Injectable } from '@angular/core';
import { firstValueFrom, mapTo } from 'rxjs';

import { LayerHttpService } from './layer-http.service';
import { LayerType } from './model/layer.type';
import { NetworkGis } from './model/network-gis';

@Injectable()
export class NetworkService extends LayerHttpService<NetworkGis> {
  readonly layerType = LayerType.networkLayers;
  private getBaseUrl(scenarioId: string) {
    return `${this.gis}scenarios/${scenarioId}/networks`;
  }

  override list(scenarioId: string) {
    return this.http.get<NetworkGis[]>(`${this.getBaseUrl(scenarioId)}`);
  }

  override get(scenarioId: string, layerId: string) {
    return this.http.get<NetworkGis>(
      `${this.getBaseUrl(scenarioId)}/${layerId}`
    );
  }

  override create(scenarioId: string, layer: NetworkGis) {
    const request = this.http.post<NetworkGis>(
      `${this.getBaseUrl(scenarioId)}/${layer.network_id}/${layer.link_id}`,
      layer
    );

    return firstValueFrom(request);
  }

  override update(scenarioId: string, layerId: string, layer: NetworkGis) {
    const request = this.http.post<NetworkGis>(
      `${this.getBaseUrl(scenarioId)}/${layerId}`,
      layer
    );
    return firstValueFrom(request);
  }

  override delete(scenarioId: string, layerId: string) {
    return firstValueFrom(
      this.http
        .delete<NetworkGis>(`${this.getBaseUrl(scenarioId)}/${layerId}`)
        .pipe(mapTo(layerId))
    );
  }
}
