import z from 'zod';

export const ResultsEnergyDiagramSchema = z.object({
  nodes: z.array(
    z.object({
      type: z.string(),
      label: z.string(),
      name: z.string(),
      energyCarrier: z.string(),
      value: z.number(),
    }),
  ),
  links: z.array(
    z.object({
      source: z.string(),
      target: z.string(),
      energyCarrier: z.string(),
      values: z.array(z.number()),
    }),
  ),
});

export type ResultsEnergyDiagram = z.infer<typeof ResultsEnergyDiagramSchema>;
