import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UploadFile } from '@sympheny/ui/upload';
import { mapData, ResponseModel } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Deserialize } from '@sympheny/utils/rxjs';
import { classToPlain } from 'class-transformer';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectDto } from './project.dto';
import { Project, ProjectDetails, SecondaryOwner } from '../model';

type ProjectsResponse = ResponseModel<{ projects: Project[] }>;

@Injectable()
export class ProjectsService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {}

  private readonly base = this.environmentService.getValue('base');

  /**
   * Get all projects
   */
  @Deserialize(Project)
  public all(): Observable<Project[]> {
    return this.http
      .get<ProjectsResponse>(`${this.base}projects`)
      .pipe(map((data) => data.data.projects));
  }

  /**
   * Create new project
   */
  @Deserialize(Project)
  public create(project: ProjectDto): Observable<Project> {
    return this.http
      .post<
        ResponseModel<Project>
      >(`${this.base}projects`, classToPlain(project))
      .pipe(map((data) => data.data));
  }

  /**
   * Delete project
   */
  public deleteProject(projectGuid: string) {
    return firstValueFrom(
      this.http
        .delete<ProjectsResponse>(`${this.base}projects/${projectGuid}`)
        .pipe(map((data) => data.data.projects)),
    );
  }

  /**
   * Copy project
   */
  @Deserialize(Project)
  public copy(projectGuid: string) {
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<Project>
        >(`${this.base}projects/copy/${projectGuid}`, '')
        .pipe(mapData()),
    );
  }

  /**
   * Update project
   */
  @Deserialize(Project)
  public update(projectGuid: string, project: ProjectDto): Observable<Project> {
    return this.http
      .put<
        ResponseModel<Project>
      >(`${this.base}projects/${projectGuid}/metadata`, classToPlain(project))
      .pipe(mapData());
  }
  /**
   * Update project
   */
  @Deserialize(Project)
  public migrate(projectGuid: string): Promise<void> {
    return firstValueFrom(
      this.http.put<void>(`${this.base}projects/migrate-v2/${projectGuid}`, {}),
    );
  }

  /**
   * Get project details
   */
  @Deserialize(ProjectDetails)
  public getProjectDetails(id: string): Observable<ProjectDetails> {
    return this.http
      .get<ResponseModel<ProjectDetails>>(`${this.base}projects/${id}`)
      .pipe(mapData());
  }

  @Deserialize(Project)
  public addDefaultProject(project: Project) {
    return this.http.post(
      `${this.base}projects/default/${project.projectGuid}`,
      {},
    );
  }

  public removeDefaultProject(project: Project) {
    return this.http.delete(
      `${this.base}projects/default/${project.projectGuid}`,
      {},
    );
  }

  /**
   * Reinitialise project
   */
  public reinitialise(project: Project) {
    const originalProjectId = project.originalDefaultProjectGuid; // || 'esp-example-project-guid';
    return firstValueFrom(
      this.http.post(
        `${this.base}projects/default/readd/${originalProjectId}`,
        {},
      ),
    );
  }

  public upload(projectGuid: string, imageData: UploadFile) {
    return this.http
      .put<
        ResponseModel<ProjectDetails>
      >(`${this.base}projects/${projectGuid}`, imageData)
      .pipe(mapData());
  }

  public lock(projectGuid: string) {
    return this.lockUnlock(projectGuid, true);
  }

  public unlock(projectGuid: string) {
    return this.lockUnlock(projectGuid, false);
  }

  private lockUnlock(projectGuid: string, lock: boolean) {
    return firstValueFrom(
      this.http.put(`${this.base}projects/lock/${projectGuid}`, {
        lock,
      }),
    );
  }

  public updateSecondaryOwners(
    projectGuid: string,
    secondaryOwners: SecondaryOwner[],
  ) {
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<ProjectDetails>
        >(`${this.base}projects/secondary-owners/${projectGuid}`, secondaryOwners)
        .pipe(mapData()),
    );
  }
}
