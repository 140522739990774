import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';

const url = 'database-technology-packages?fromOrg=true';
const urlCategory = `${url}/technology-categories?fromOrg=true`;

@Injectable()
export class OrgDatabaseTechnologyPackageCollectionService extends AbstractTechnologyPackagesCollection {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, url, urlCategory);
  }
}
