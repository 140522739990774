// eslint-disable-next-line @nx/enforce-module-boundaries
import { NodeType } from '@sympheny/project/data-access';

import { Node } from '../utils/model';

export type EhubType = 'sankey' | 'ehub';

export type EhubNodeType = 'ENERGY CARRIER';
export type EhubNode = Node & {
  capacity: number;
  edgeLabel: string;
  label?: string;
  type: NodeType;
  unit: { unit: string };
  color: string;
  energyCarrier: string;
};
