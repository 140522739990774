import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService, ProjectVersion } from '@sympheny/project/data-access';
import {
  mapData,
  ResponseModel,
  SymphenyErrorHandler,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, Observable } from 'rxjs';

import { EnergyDemandV1Service } from './energy-demand-v1.service';
import { EnergyDemandV2 } from './energy-demand-v2.model';
import { EnergyDemandV2Service } from './energy-demand-v2.service';
import { EnergyDemand, EnergyDemandDto } from './energy-demand.model';
import { ProfileValue } from '../profile';

export enum EnergyDemandApiTypes {
  database,
  save,
}

@Injectable()
export class EnergyDemandService extends CrudService<
  EnergyDemand,
  EnergyDemandDto
> {
  public readonly guidKey = 'energyDemandGuid';
  public readonly key = 'energyDemands';

  constructor(
    http: HttpClient,
    environmentService: EnvironmentService,
    errorHandler: SymphenyErrorHandler,
    private readonly v1Service: EnergyDemandV1Service,
    private readonly v2Service: EnergyDemandV2Service,
  ) {
    super(http, environmentService, errorHandler);
  }

  private getService(projectVersion: ProjectVersion) {
    return projectVersion === 'V1' ? this.v1Service : this.v2Service;
  }

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.getService(projectVersion).list(projectVersion, scenarioGuid);
  }

  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: any,
    type: EnergyDemandApiTypes,
  ) {
    if (projectVersion === 'V2') {
      return this.v2Service.create(projectVersion, scenarioGuid, data, type);
    }
    return this.v1Service.create(projectVersion, scenarioGuid, data, type);
  }

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: any,
    type: EnergyDemandApiTypes,
  ) {
    if (projectVersion === 'V2') {
      return this.v2Service.update(
        projectVersion,
        scenarioGuid,
        guid,
        data,
        type,
      );
    }
    return this.v1Service.update(
      projectVersion,
      scenarioGuid,
      guid,
      data,
      type,
    );
  }
  public patch(
    scenarioGuid: string,
    guid: string,
    data: Partial<EnergyDemand>,
  ) {
    const url = `${this.base}scenarios/${scenarioGuid}/energy-demands/${guid}`;

    return firstValueFrom(
      this.http.patch<ResponseModel<EnergyDemand>>(url, data),
    );
  }

  public confirmMultiplicationFactor(guid: string) {
    const url = `${this.base}energy-demands/${guid}/confirm-multiplication-factor-preview`;

    return firstValueFrom(this.http.put<ResponseModel<any>>(url, {}));
  }

  public getVariant(guid: string, scenarioVariantGuid: string) {
    const url = `energy-demands/${guid}?scenarioVariantGuid=${scenarioVariantGuid}`;
    return firstValueFrom(
      this.http
        .get<ResponseModel<EnergyDemandV2>>(`${this.base}${url}`)
        .pipe(mapData()),
    );
  }

  public getDetail(
    projectVersion: ProjectVersion,
    guid: string,
    scenarioVariantGuid?: string | null,
  ) {
    return this.getService(projectVersion).getDetail(guid, scenarioVariantGuid);
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    type: string,
  ) {
    return this.v1Service.delete(
      projectVersion,
      scenarioGuid,
      guid,

      type,
    );
  }

  /**
   * Get all energy demands for a scenario
   */
  public getEnergyDemandProfileView(
    energyDemandGuid: string,
    scenarioVariantGuid: string | null,
  ): Promise<ProfileValue[]> {
    const params = scenarioVariantGuid
      ? { scenarioVariantGuid: scenarioVariantGuid }
      : null;
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<{ energyDemandProfiles: ProfileValue[] }>
        >(`${this.base}energy-demands/${energyDemandGuid}/profile`, { params })
        .pipe(mapData('energyDemandProfiles')),
    );
  }

  public async getTempProfile(
    energyDemandGuid: string,
  ): Promise<ProfileValue[]> {
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<{ energyDemandProfiles: ProfileValue[] }>
        >(`${this.base}energy-demands/${energyDemandGuid}/profile-api-service-preview`)
        .pipe(mapData('energyDemandProfiles')),
    );
  }

  /**
   * Save energy profile after successfully uploading/saving energy demand
   */
  public saveEnergyDemandUserProfile(data: any): Observable<any> {
    return this.http.post(`${this.base}energy-demands/saved-profiles`, data);
  }

  public async download(energyDemandGuid: string, fileName: string) {
    const response = await firstValueFrom(
      this.http.get(
        `${this.base}energy-demands/${energyDemandGuid}/profile/download`,
        { observe: 'response', responseType: 'arraybuffer' },
      ),
    );

    const blob = new Blob([response.body], {
      type: response.headers.get('content-type'),
    });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    a.remove();
  }
}
