// !FIXME
// CHECKME
// Only add the ones needed for energycarriersource
export class EnergyCarrierSource {
  energyCarrierName: string;
  energyCarrierGuid: string;
  energyCarrierType: string;
  typeKey: string;
  typeDisplayName: string;
  energyCarrierSubtype: string;
  subtypeKey: string;
  subtypeDisplayName: string;
  customEnergyCarrierSubtype: string;
  created: string;
  fixedInputShare: string;
  outputEfficiency: string;
  customOutputEfficiencyActivated: boolean;
  type?: string;
  fixedShare?: number;
  isSecondMode?: boolean;
  isThirdMode?: boolean;
  primary?: boolean;
  allowVirtualLoad?: boolean;
  onSiteSolarResource: OnSiteSolarResource | null;
}

export function isSiteResource(energyCarrier: EnergyCarrier): boolean {
  return energyCarrier.typeKey === 'ON_SITE_RESOURCE';
}
import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { OnSiteSolarResource } from '../solar-resources';

export const EnergyCarrierSchema = z.object({
  colorHexCode: z.string().nullish(),
  created: z.string().nullish(),
  customInputEfficiencyActivated: z.boolean().nullish(),
  customOutputEfficiencyActivated: z.boolean().nullish(),
  customSeasonalityValues: z
    .array(
      z
        .object({ month: z.string().nullish(), value: SymphenyNumber() })
        .nullish(),
    )
    .nullish(),
  energyCarrierGuid: z.string().nullish(),
  energyCarrierName: z.string().nullish(),
  fixedInputShare: SymphenyNumber().nullish(),
  outputEfficiency: SymphenyNumber().nullish(),
  outputEfficiencyProfileId: SymphenyNumber().nullish(),
  primary: z.boolean().nullish(),
  subtypeDisplayName: z.string().nullish(),
  subtypeKey: z.string().nullish(),
  typeDisplayName: z.string().nullish(),
  typeKey: z.string().nullish(),
});

export const EnergyCarrierDtoSchema = z.object({
  allowVirtualLoad: z.boolean().nullish(),
  colorHexCode: z.string().nullish(),
  energyCarrierName: z.string(),
  energyCarrierSubtype: z.string(),
  energyCarrierType: z.string(),
});

export type EnergyCarrier = z.infer<typeof EnergyCarrierSchema>;
