// Iteratively assign the column for each node.
// Nodes are assigned the maximum column of incoming neighbors plus one;
// nodes with no incoming links are assigned column zero, while

import { groupBy } from 'lodash-es';

import { Graph, GraphData, Link, Node } from '../model';

const computeNodeDepth = (data: Readonly<GraphData>) => {
  let nodes: Node[],
    x = 0;
  // const next = new Set<string>/
  const nextNodeIds = new Set<string>();

  const sortedNodes = data.getNodes().filter((n) => !!n);

  for (nodes = sortedNodes; nodes.length; ++x) {
    nextNodeIds.clear();
    nodes.forEach((node) => {
      node.setValue('column', x);

      data.getSourceLinks(node).forEach((link: Link) => {
        const nextId = link.target;
        if (nextId && !link.circular) {
          nextNodeIds.add(nextId);
        }
      });
    });

    if (nodes.length === nextNodeIds.size) {
      console.warn('something is wrong here !!!');
      nodes = [];
    } else {
      nodes = [];
      nextNodeIds.forEach((nodeId) => {
        const node = data.getNode(nodeId);
        nodes.push(node);
      });
    }
  }
  data.forEachNode((node) => {
    if (!node.hasTarget) node.setValue('column', x);
  });
  data.computeColumns();
  return x;
};

const computeSourceHeightsForNode = (
  columnHeights: Record<number, number>,
  node: Node,
  data: Readonly<GraphData>,
) => {
  const sourceLinks = data
    .getTargetLinks(node)
    .filter((s) => s.source !== node._id);

  sourceLinks.forEach((s) => {
    const source = data.getNodeSource(s);
    if (!node.column) {
      node.setValue('row', columnHeights[source.column]);
      columnHeights[source.column] += 1;
    }
    if (node.circular || node.column <= source.column) return;

    computeSourceHeightsForNode(columnHeights, source, data);
  });
};

const computeTargetHeightsForNode = (
  columnHeights: Record<number, number>,
  node: Node,
  data: Readonly<GraphData>,
) => {
  if (node.row !== undefined) {
    return;
  }

  // find targets and assign next node column
  const targetLink = data.getSourceLinks(node);
  if (targetLink.length === 0) {
    return;
  }
  if (targetLink.length > 1) {
    return;
  }
  node.setValue('row', columnHeights[node.column]);
  columnHeights[node.column] += 1;

  const target = data.getNodeTarget(targetLink[0]);

  if (node.circular) return;

  if (node.column >= target.column) {
    computeTargetHeightsForNode(columnHeights, target, data);
  }

  if (node.column >= target.column) return;
  computeSourceHeightsForNode(columnHeights, node, data);

  // TODO check if target has more than one source, add next index of that column there,
};

const computeNodeHeights = (totalDepths: number, data: Readonly<GraphData>) => {
  // const next = new Set<string>/
  const sortedNodes = data.getNodes().filter((n) => !!n);
  if (sortedNodes.length === 0) return;
  const nodes = groupBy(sortedNodes, (n) => n.column);
  const sortedKeys = Object.keys(nodes).sort();

  const columnHeights = sortedKeys.reduce((r, column) => {
    r[column] = 0;

    return r;
  }, {});
  const firstNodes = nodes[sortedKeys[0]].sort((n1, n2) => {
    const t1 = data.getSourceLinks(n1);
    const t2 = data.getSourceLinks(n2);
    const s1 = t1[0]?.source;
    const s2 = t2[0]?.source;

    if (s1 === s2) return -1;
    return 1;
  });

  // TODO sort the nodes on same target
  firstNodes
    //.sort(sortOnSameTarget(data))

    .forEach((node, nextHeight) => {
      // if (nextHeight > 2) return;
      computeTargetHeightsForNode(columnHeights, node, data);
    });
};

// nodes with no outgoing links are assigned the maximum column.
export const computeNodeDepths = (graph: Readonly<Graph<any, any>>) => {
  const { graph: data } = graph;

  const totalDepths = computeNodeDepth(data);
  // Align end nodes to the end of the graph
  computeNodeHeights(totalDepths, data);

  // assign column numbers, and get max value
  data.forEachNode((node) => {
    node.setValue('column', node.column ?? totalDepths);

    node.setValue('row', node.row ?? 0);

    //node.column = Math.floor(align.call(null, node, x));
  });
  data.computeColumns();
  return data;
};
