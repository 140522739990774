import { AbstractControl, ValidationErrors } from '@angular/forms'; // Invalid Characters for all names: Energy Carriers, Scenario Names, PENDING(Scenario Components)

// Invalid Characters for all names: Energy Carriers, Scenario Names, PENDING(Scenario Components)
const listOfInvalidCharacters: RegExp[] = [
  /</,
  />/,
  /{/,
  /}/,
  /,/,
  /;/,
  /:/,
  /\?/,
  /\|/,
  /&/,
  /\\/,
  /\//,
  /\*/,
];
let validation: boolean[] = [];

export const invalidCharacters = (
  formControl: AbstractControl,
): ValidationErrors | null => {
  validation = [];
  if (!formControl.value) {
    return null;
  }
  listOfInvalidCharacters.forEach((character) => {
    validation.push(character.test(formControl.value as string));
  });
  return validation.includes(true) ? { invalidCharacter: true } : null;
};

export function invalidCharactersFunc(name: string): boolean {
  validation = [];
  if (name) {
    listOfInvalidCharacters.forEach((character) => {
      validation.push(character.test(name as string));
    });
    return validation.includes(true);
  }

  return false;
}
