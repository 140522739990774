import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from '@sympheny/ui/form';

import { buildingInformationConfiguration } from '../model/building-information';
import { SelectedBuildingInfo } from '../model/select-building-info';

interface KeyLabel {
  key: string;
  label: string;
}

@Component({
  selector: 'sympheny-select-building-information',
  templateUrl: './select-building-information.component.html',
  styleUrls: ['./select-building-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SelectComponent, ReactiveFormsModule],
})
export class SelectBuildingInformationComponent implements OnChanges {
  @Input() buildingInfoOptions: SelectedBuildingInfo | null;
  @Output() changeBuildingInfo = new EventEmitter<SelectedBuildingInfo>();

  buildingDataAll = new UntypedFormControl();
  readonly buildingDataList = this.buildValues();

  ngOnChanges() {
    if (!this.buildingInfoOptions) {
      this.buildingDataAll.setValue([]);
      return;
    }

    const values: string[] = [];
    Object.keys(this.buildingInfoOptions).forEach((key) => {
      if (this.buildingInfoOptions![key as keyof SelectedBuildingInfo]) {
        values.push(key);
      }
    });
    this.buildingDataAll.setValue(values);
  }

  public buildingInfoChange() {
    const valueSelected = this.buildingDataAll.value;
    const selectedBuildingInfo: SelectedBuildingInfo = {
      infoSelect: valueSelected.length > 0,
      buildingInfo: valueSelected.includes('buildingInfo'),
      energyInfo: valueSelected.includes('energyInfo'),
      roofInfo: valueSelected.includes('roofInfo'),
      facadeInfo: valueSelected.includes('facadeInfo'),
      minergieInfo: valueSelected.includes('minergieInfo'),
      others: valueSelected.includes('others'),
    };

    this.changeBuildingInfo.emit(selectedBuildingInfo);
  }

  private buildValues(): KeyLabel[] {
    const keys: Array<keyof SelectedBuildingInfo> = [
      'buildingInfo',
      'energyInfo',
      'roofInfo',
      'facadeInfo',
      'minergieInfo',
      'others',
    ];

    return keys.map((key) => ({
      key,
      label: buildingInformationConfiguration[key].label,
    }));
  }
}
