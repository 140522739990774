<mat-drawer-container
  class="block h-full"
  [hasBackdrop]="false"
  [id]="mapUuid + '__container'"
>
  <mat-drawer-content class="block h-full">
    <div class="map-content block h-full">
      <div [id]="mapUuid" class="map-container block w-full h-full">
        <!-- Select Feature Interaction -->
        <sympheny-select-features
          *ngIf="config.select.features"
          [hubId]="energyResourceHubGuid"
          (selectedFeatures)="selectedFeaturesEmitter($event)"
        >
        </sympheny-select-features>
        <sympheny-feature-info
          [mapUuid]="mapUuid"
          [scenarioId]="scenarioId"
          [featureData]="featureData$ | async"
          [hubs]="hubs"
          (openBuildingInfo)="onOpenBuildingInfo()"
        >
        </sympheny-feature-info>
      </div>
      <sympheny-change-basemap
        (changeBaseMap)="changeBaseMap($event)"
        symphenyMapLoad
        [mapUuid]="mapUuid"
        (mapLoaded)="loadMap()"
      ></sympheny-change-basemap>
    </div>
  </mat-drawer-content>
  <mat-drawer
    position="end"
    #editDrawer
    mode="side"
    class="w-64 pl-4"
    [opened]="layerConfigurationOpen || buildingInformationOpen"
  >
    <ng-template #editLayerConfiguration>
      <sympheny-layer-configuration
        [editableLayers]="editableLayers$ | async"
        [disabled]="disabled"
        [canEdit]="canEdit"
        (save)="saveLayer($event)"
        (delete)="deleteLayer($event)"
      ></sympheny-layer-configuration>
    </ng-template>
    <ng-template #newLayerConfigurationTemplate>
      <sympheny-new-layer-configuration
        *ngIf="newLayerConfiguration; else editLayerConfiguration"
        [newLayer]="newLayerConfiguration"
        (save)="createLayer($event)"
        (cancel)="cancelNewLayer()"
      ></sympheny-new-layer-configuration
    ></ng-template>
    <div *ngIf="buildingInformationOpen; else newLayerConfigurationTemplate">
      <sympheny-building-information
        [disabled]="disabled"
        [canEdit]="canEdit"
        [address]="selectedAddress$ | async"
        [addressProcessed]="addressProcessed$ | async"
        [datasetAddress]="selectedDatasetAddress$ | async"
        [selectedDataset]="selectedDataset$ | async"
        [buildingInfoOptions]="buildingInfoOptions"
        (changeBuildingInfo)="buildingInfoOptions = $event"
        (toggleCo2Range)="toggleCo2Range($event)"
      ></sympheny-building-information>
    </div>
  </mat-drawer>
</mat-drawer-container>
<sympheny-map-info [hubs]="hubs"></sympheny-map-info>
