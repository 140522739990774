<button
  type="button"
  [disabled]="disabled"
  class="btn btn-circle btn-primary shadow drop-shadow"
  [class.btn-xs]="size === 'xsmall'"
  [class.btn-sm]="size === 'small'"
  [class.btn-md]="size === 'regular'"
  (click)="action.emit()"
  aria-hidden="false"
  [attr.aria-label]="tooltip | transloco"
  [attr.data-cy]="dataCy || tooltip"
  [matTooltip]="tooltip | transloco"
>
  <sympheny-icon
    [fontSet]="fontSet"
    [size]="size"
    [icon]="icon"
  ></sympheny-icon>
</button>
