import { ComponentType } from '@angular/cdk/overlay';
import { InjectionToken, TemplateRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FormatValueDefault } from '@sympheny/format-value';

export interface TableColumnBase<T> {
  label?: string;
  labelFn?: () => string;
  key: keyof T | string;
  childKey?: string;
  defaultValue?: string | number;
  maxValues?: number;
  type?: string;
  disableSorting?: boolean;
  tooltip?: string;
  cssClass?: string;
  cssClassFn?: (element: T) => string;
  translatePrefix?: string;
}

export type TableColumnDefault<T> = FormatValueDefault<T> & TableColumnBase<T>;

export type ActionFn<T> = (element: T, index: number) => void;

export interface TableDefinedActionDisabled {
  viewDetails?: boolean;
  download?: boolean;
  edit?: boolean;
  delete?: boolean;
}
export interface TableDefinedAction<T> {
  label: string;
  view?: ActionFn<T>;
  viewDetails?: ActionFn<T>;
  download?: ActionFn<T>;
  edit?: ActionFn<T>;
  delete?: ActionFn<T>;
}

export interface TableAction<T> {
  tooltip: string;
  tooltipParams?: any;
  icon: string;
  action: ActionFn<T>;
  readOnly?: (element: T) => boolean;
  hidden?: (element: T) => boolean;
  color?: ThemePalette;
  label?: string;
  disabled?: boolean;
  dataCy?: string;
}

export interface TableColumnAction<T> extends TableColumnDefault<T> {
  type: 'action';
  readOnly?: (element: T) => boolean;
  action: TableAction<T>;
}

export interface TableColumnComponent<T> extends TableColumnDefault<T> {
  type: 'component';
  component: ComponentType<any>;
  data?: any;
  token?: string | InjectionToken<any>;
}

export interface TableColumnTemplate<T> extends TableColumnDefault<T> {
  type: 'template';
  template: TemplateRef<any>;
}

export interface TableCellData<T, D = any> {
  element: T;
}

export type TableColumn<T> =
  | TableColumnDefault<T>
  | TableColumnAction<T>
  | TableColumnComponent<T>
  | TableColumnTemplate<T>;

export interface TableConfiguration<T> {
  columns: TableColumn<T>[];
  /**
   * Define a custom list of actions, they will be the first in the actions column
   */
  customActions?: TableAction<T>[];
  /**
   * Define predefined actions, they will be added in a fixed order, after the custom actions
   */
  definedActions?: TableDefinedAction<T>;
}

export const TABLE_CELL_TOKEN = new InjectionToken('sympheny/TABLE/CELL');
