import { nullOrUndefined } from '@sympheny/utils/rxjs';

// Custom validator to check that two fields match
export function AtLeastOne(controls: string[]) {
  return (formGroup: any) => {
    if (!formGroup) return null;

    const atLeastOneFilled = controls.map(
      (control) => !nullOrUndefined(formGroup.controls[control]?.value),
    );

    // Set error on matchingControl if validation fails
    if (atLeastOneFilled) {
      controls.forEach((control) => {
        formGroup.controls[control]?.setErrors(null);
      });
      return null;
    } else {
      controls.forEach((control) => {
        formGroup.controls[control]?.setErrors({ atLeastOne: true });
      });
      return { atLeastOne: true };
    }
  };
}
