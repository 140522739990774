<button
  [matMenuTriggerFor]="menu"
  [disabled]="isDisabled"
  class="color-picker--selector"
  type="button"
>
  <label><ng-content></ng-content></label>
  <div class="color-picker--color" [style.background-color]="value"></div>
</button>
<mat-menu #menu="matMenu">
  <div class="color-picker--menu">
    <button
      mat-icon-button
      *ngFor="let color of colors"
      class="color-picker--color"
      [style.background-color]="color"
      (click)="changeColor(color)"
      type="button"
    ></button>
  </div>
</mat-menu>
