import { HttpClient } from '@angular/common/http';
import { Collection } from '@fp-tools/angular-state';
import { DatabaseDetailsService, DB_TYPES } from '@sympheny/database/model';
import {
  ImportExport,
  ImportExportType,
  ProjectVersion,
} from '@sympheny/project/data-access';
import {
  LoadDataService,
  mapData,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { LoadParameters } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable, catchError, firstValueFrom, map, of } from 'rxjs';

class CategoryCollection extends Collection<string, any> {
  protected idKey: any;

  constructor(
    private readonly http: HttpClient,
    private readonly url: string,
    private readonly categoryMapper: string,
    private readonly fromOrg: boolean,
  ) {
    super();
  }

  protected fetchApi(): Observable<string[]> {
    return this.http
      .get<ResponseModel<any>>(`${this.url}`, {
        params: { fromOrg: this.fromOrg },
      })
      .pipe(
        map(
          (d) =>
            d.data?.[this.categoryMapper]
              ?.filter((dd) => !!dd)
              .map((value) => ({ label: value, value: value })),
        ),
      );
  }
}

export interface Settings {
  db: DB_TYPES | 'ewz';
  type: ImportExportType;
  saveUrl?: string;
  categoryMapper: string;
  technologyMapper: string;
  guid: string;
}

const DbMapping: Partial<Record<DB_TYPES | 'ewz', string>> = {
  user: 'user',
  database: 'database',
  'database-org': 'database',
  ewz: 'ewz',
};
export abstract class AbstractImportCollection
  implements DatabaseDetailsService<ImportExport>, LoadDataService
{
  private readonly categoryCollection: CategoryCollection;

  public readonly categories$: Observable<any[]>;
  public categories;

  private readonly fromOrg: boolean;

  private readonly url: string;
  private readonly categoryUrl: string;

  private loadData = true;
  private organization = '';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly environmentService: EnvironmentService,
    private readonly settings: Settings,
  ) {
    const base = `${
      this.environmentService.base
    }impex/${settings.type.toUpperCase()}/profile-types/${
      DbMapping[this.settings.db]
    }`;

    this.fromOrg = settings.db === 'database-org';
    this.url = `${base}/products`;

    this.categoryUrl = `${this.url}`;

    this.categoryCollection = new CategoryCollection(
      httpClient,
      this.categoryUrl,
      this.settings.categoryMapper,
      this.fromOrg,
    );
    this.categoryCollection.initialize();
    this.categories$ = this.categoryCollection.data$;

    this.categories$.subscribe((c) => (this.categories = c));
  }

  public create(projectVersion: ProjectVersion, data: Partial<ImportExport>) {
    const profileType =
      this.settings.db === 'database-org'
        ? this.organization
        : DbMapping[this.settings.db];
    const url = `${this.environmentService.base}impex/profile-types/${profileType}`;
    return firstValueFrom(
      this.httpClient.post<
        ResponseModel<{ conversionTechnologies: ImportExport[] }>
      >(url, data),
    ).then(() => this.categoryCollection.load());
  }

  public load(params: LoadParameters) {
    this.loadData = this.settings.db !== 'ewz' || params.ewz;
    this.organization = params.organization;
  }

  public reload(): void {
    if (!this.loadData) {
      return;
    }
    this.categoryCollection.load();
  }

  public getTechnologyCategoryDetails(categories: string[]): Observable<any[]> {
    return this.httpClient
      .get<ResponseModel<any>>(`${this.categoryUrl}/${categories[0]}`, {
        params: { fromOrg: this.fromOrg },
      })
      .pipe(mapData(this.settings.technologyMapper));
  }

  public getDetails(
    projectVersion: ProjectVersion,
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any> {
    return this.httpClient
      .get<ResponseModel<any>>(
        `${
          this.environmentService.base
        }impex/${this.settings.type.toUpperCase()}/${guid}`,
        {
          params: { exchangeRate, fromOrg: this.fromOrg },
        },
      )
      .pipe(
        mapData(),
        catchError(() => of([])),
      );
  }

  public update(
    projectVersion: ProjectVersion,
    guid: string,
    data: Partial<ImportExport>,
  ) {
    const url = `${this.environmentService.base}impex/${guid}/profile-types/`;
    return firstValueFrom(
      this.httpClient.put<
        ResponseModel<{ conversionTechnologies: ImportExport[] }>
      >(`${url}`, data),
    );
  }

  public delete(guid: string) {
    return firstValueFrom(
      this.httpClient
        .delete(
          `${this.environmentService.base}impex/${this.settings.type}/${guid}/profile-types`,
          {
            // params: { fromOrg: this.fromOrg },
          },
        )
        .pipe(map(() => guid)),
    );
  }

  public async deleteCategory(category: string): Promise<string> {
    const techs = await firstValueFrom(
      this.getTechnologyCategoryDetails([category]),
    );

    await Promise.all(techs?.map((t) => this.delete(t[this.settings.guid])));

    return category;
  }
}
