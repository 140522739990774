<sympheny-icon-button
  *ngFor="let button of configuration?.buttons"
  [color]="button.color ?? 'primary'"
  [disabled]="button.disabled ?? false"
  [tooltip]="button.tooltip"
  [icon]="button.icon"
  [fontSet]="button.fontSet"
  (action)="onAction(button)"
  [dataCy]="button.dataCy ?? button.icon"
></sympheny-icon-button>
