import { NgIf } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';

@Component({
  selector: 'sympheny-scenario-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  standalone: true,
  imports: [MatExpansionModule, InfoButtonComponent, TranslocoPipe, NgIf],
})
export class FormSectionComponent {
  @Input() public disabled: boolean;
  @Input() public expanded: boolean;
  // @deprecated
  @Input() public title: string;
  @Input() public sectionTitle: string;
  @Input() public titleInfo: string;
  @Input() public description: string;

  @ViewChild(MatExpansionPanel) public readonly panel: MatExpansionPanel;

  public open() {
    this.panel.open();
  }
}
