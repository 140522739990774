import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@fp-tools/angular-state';
import { mapData, ResponseModel } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { map, Observable, of } from 'rxjs';

export interface EnergyDemandSavedUserProfile {
  guid: string;
  name: string;
  notes: string | null;
  energyReferencedArea: number;
  minimumProfileValue: number;
  maximumProfileValue: number;
  averageProfileValue: number;
}

@Injectable()
export class EnergyDemandSavedUserProfileCollection extends Collection<
  EnergyDemandSavedUserProfile,
  any
> {
  private readonly base = this.environmentService.getValue('base');
  protected readonly idKey = 'guid';

  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService
  ) {
    super();
  }

  public find(guid: string): Observable<EnergyDemandSavedUserProfile | null> {
    if (!guid) {
      return of(null);
    }

    return this.data$.pipe(
      map(
        (items) =>
          (items && items.find((item) => item[this.idKey] === guid)) ?? null
      )
    );
  }

  protected fetchApi(): Observable<EnergyDemandSavedUserProfile[]> {
    return this.http
      .get<ResponseModel<{ userEnergyDemands: EnergyDemandSavedUserProfile }>>(
        `${this.base}energy-demands/saved-profiles`
      )
      .pipe(mapData('userEnergyDemands'));
  }

  public override load(): void {
    super.initialize();
    super.load();
  }
}
