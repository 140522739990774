import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DragAndDropChangeEvent } from '@sympheny/gis/utils';
import { LayerType } from '@sympheny/project/scenario/data-access';
import { ColorPickerComponent } from '@sympheny/ui/color-picker';
import { DialogService } from '@sympheny/ui/dialog';
import { CheckboxComponent } from '@sympheny/ui/form';
import { FeatureCollection } from 'geojson';
import { Stroke, Style } from 'ol/style';

import { defaultLayerColor } from '../../layer/map-layer';

export interface Data {
  layer_name: string;
  layer_color: string;
  feature_collection: FeatureCollection;
}

export interface NewLayerEvent {
  layerType: LayerType;
  data: Data;
}

@Component({
  selector: 'sympheny-new-layer-configuration',
  templateUrl: './new-layer-configuration.component.html',
  styleUrls: ['./new-layer-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ColorPickerComponent,
    CheckboxComponent,
    MatButtonModule,
  ],
})
export class NewLayerConfigurationComponent implements OnChanges {
  @Input() newLayer!: DragAndDropChangeEvent;
  @Output() readonly cancel = new EventEmitter<void>();
  @Output() readonly save = new EventEmitter<NewLayerEvent>();

  readonly newLayerForm = new FormGroup({
    name: new FormControl<string | null>('', { nonNullable: true }),
    color: new FormControl<string | null>(''),
    share: new FormControl<boolean>(false, { nonNullable: true }),
  });

  constructor(private dialogService: DialogService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.newLayerForm.setValue({
      name: this.newLayer.title,
      color: defaultLayerColor,
      share: false,
    });
    this.changeColor();
  }

  changeColor() {
    this.newLayer.vectorLayer?.setStyle(
      new Style({
        stroke: new Stroke({
          color: this.newLayerForm.value.color ?? defaultLayerColor,
        }),
      }),
    );
  }

  onSave() {
    if (this.newLayerForm.invalid) {
      return;
    }

    const { color, name, share } = this.newLayerForm.value;

    this.save.emit({
      layerType: share
        ? LayerType.customerLayer
        : LayerType.customerScenarioLayer,
      data: {
        layer_name: name!,
        layer_color: color!,
        feature_collection: this.newLayer.geoJson,
      },
    });
  }

  onCancel() {
    const confirmed = () => {
      this.cancel.emit();
    };

    this.dialogService.openConfirmationDialog(
      {
        title: `Are you sure`,
        question: 'This layer is not saved yet.',
      },
      confirmed,
    );
  }
}
