import { Injectable } from '@angular/core';
import {
  findByGuid,
  mapData,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { Deserialize } from '@sympheny/utils/rxjs';
import { firstValueFrom, mapTo } from 'rxjs';
import { map } from 'rxjs/operators';

import { CrudService } from './crud.service';
import { EnergyDiagram, EnergyDiagramSchema } from '../hubs/energy-diagram';
import { Hub, HubDiagram, ProjectVersion } from '../model';

@Injectable()
export class HubService extends CrudService<Hub> {
  readonly guidKey = 'hubGuid';
  readonly key = 'hubs';

  /**
   * Get hubs diagram, for v1
   */
  public getHubsDiagram(scenarioGuid: string, hubGuid: string[]) {
    return this.http
      .post<ResponseModel<{ diagrams: HubDiagram[] }>>(
        `${this.base}scenarios/${scenarioGuid}/diagram`,
        {
          hubGuids: hubGuid,
        },
      )
      .pipe(map((response) => response.data));
  }

  /**
   * Get energy diagram for v2
   */
  public getEnergyDiagram(
    scenarioGuid: string,
    stageGuid: string,
    hubGuid: string,
  ) {
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<EnergyDiagram>
        >(`${this.base}v2/scenarios/${scenarioGuid}/stages/${stageGuid}/hubs/${hubGuid}/energy-diagram`)
        .pipe(mapDataZ(EnergyDiagramSchema)),
    );
  }

  /**
   * Get all hubs for a scenario
   */
  @Deserialize(Hub)
  override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<ResponseModel<Hub[]>>(`${this.base}scenarios/${scenarioGuid}/hubs`)
      .pipe(mapData());
  }

  /**
   * Create a hub
   */
  @Deserialize(Hub)
  override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<Hub>,
  ) {
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<Hub>
        >(`${this.base}scenarios/${scenarioGuid}/hubs`, data)
        .pipe(mapData()),
    );
  }

  /**
   * Update hub name
   */
  @Deserialize(Hub)
  override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    hubId: string,
    data: Partial<Hub>,
  ) {
    return firstValueFrom(
      this.http
        .put<ResponseModel<Hub[]>>(`${this.base}scenarios/hubs/${hubId}`, data)
        .pipe(findByGuid(this.guidKey, hubId)),
    );
  }

  /**
   * Delete hub
   */

  override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    hubId: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(`${this.base}scenarios/hubs/${hubId}`)
        .pipe(mapTo(hubId)),
    );
  }
}
