import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { FeatureFlagDirective } from './feature-flag.directive';
import { FeatureFlagService } from './feature-flag.service';

@NgModule({
  imports: [CommonModule, FeatureFlagDirective],
  exports: [FeatureFlagDirective],
})
export class FeatureFlagModule {
  public static forRoot(): ModuleWithProviders<FeatureFlagModule> {
    return {
      ngModule: FeatureFlagModule,
      providers: [FeatureFlagService],
    };
  }
}
