import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, map } from 'rxjs';

// If needed somewhere else move out of database module
@Injectable()
export class EnervisionService {
  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly httpClient: HttpClient
  ) {}
  public getPresignedUrl(): Promise<string> {
    return firstValueFrom(
      this.httpClient
        .get<{ url: string }>(
          `${this.environmentService.backOfficeApi}sagemaker-presigned`
        )
        .pipe(map((r) => r.url))
    );
  }
}
