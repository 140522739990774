<div *ngIf="showValue">
  <ng-container *ngIf="displayAsLink; else displayValue">
    <a class="my-2 block" [href]="value" target="_blank">
      {{ keyLabel.label | transloco }}
    </a>
  </ng-container>

  <ng-template #displayValue>
    <p>
      <b [innerHTML]="customField ?? keyLabel.label | transloco"></b><b>: </b>
      <ng-container *ngIf="displayAsNumber; else displayNormal">
        {{ value | number }}
      </ng-container>
      <ng-template #displayNormal>
        {{ value }}
      </ng-template>
      <span *ngIf="keyLabel?.suffix" [innerHTML]="keyLabel.suffix"></span>
    </p>
  </ng-template>
</div>
