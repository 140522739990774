import { Injectable } from '@angular/core';
import { DatasetAddress, DatasetField } from '@sympheny/gis/datasets';
import { Address } from '@sympheny/project/scenario/data-access';
import { read, utils, WorkBook, WorkSheet } from 'xlsx';

export interface ParseField {
  field: keyof Address;
  column: string | null;
  customName: string | null;
}

export interface FileReadResult {
  worksheets: string[];
  columns: Record<string, string[]>;
}

@Injectable()
export class ExcelParserService {
  private file: File;
  private wb: WorkBook;

  readFile(file: File): Promise<FileReadResult> {
    return new Promise((resolve, reject) => {
      this.file = file;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const ab: ArrayBuffer = e.target.result;
        const wb: WorkBook = read(ab);

        const parsedResult: FileReadResult = {
          worksheets: wb.SheetNames,
          columns: {},
        };

        wb.SheetNames.forEach((sheetName) => {
          parsedResult.columns[sheetName] = this.getSheetHeaders(
            wb.Sheets[sheetName]
          );
        });

        this.wb = wb;

        resolve(parsedResult);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  private getSheetHeaders(sheet: WorkSheet) {
    const headerRegex = new RegExp("^([A-Za-z]+)1='(.*)$");

    const cells = utils.sheet_to_formulae(sheet);
    return cells
      .filter((item) => headerRegex.test(item))
      .map((item) => item.split("='")[1]);
  }

  public parseData(
    worksheet: string,
    egidField: string,
    fields: Array<ParseField>
  ): DatasetAddress[] {
    const sheet = this.wb.Sheets[worksheet];
    const data: Record<string, string>[] = utils.sheet_to_json(sheet);

    const result: DatasetAddress[] = data.map((row) => {
      const rowFields: DatasetField[] = fields.map((field) => ({
        custom_name: field.customName ?? '',
        value: row[field.column] ?? '',
        default_name: field.field ?? '',
      }));

      return {
        egid: Number(row[egidField]),
        fields: rowFields,
      };
    });

    return result;
  }
}
