export const apiLinks = [
  {
    routerLink: '/docs/api/web-app',
    queryParams: { select: 'all' },
    label: 'Web App',
  },
  {
    routerLink: '/docs/api/web-app',
    queryParams: { select: 'v2' },
    label: 'Web App V2',
  },
  {
    routerLink: '/docs/api/web-app',
    queryParams: { select: 'sequence' },
    label: 'Web App Sequence',
  },
  {
    routerLink: '/docs/api/api-services',
    label: 'Api Services',
  },
  {
    // replace demo by the name of your page
    routerLink: '/docs/documentation/doc',
    label: 'Api Documentation',
  },
];
