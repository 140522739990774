export class PlanLimitation {
  public maxEnergyCarriersPerHub: number;
  public maxEnergyDemands: number;
  public maxHubs: number;
  public maxSolarResources: number;
  public maxOtherResources: number;
  public maxImports: number;
  public maxExports: number;
  public maxConversionTechs: number;
  public maxConversionModes: number;
  public maxStorageTechs: number;
  public maxNetworkTechs: number;
  public maxNetworkLinks: number;
  public maxSimultaneousExecutions: number;
  public maxExecutionTime: number;
  public maxExecutionTimeWeek: number;
  public maxParetoPoints: number;
  public maxProjects: number;
  public maxAnalyses: number;
  public maxScenarios: number;
  public maxExecutions: number;
  public organizationDb: boolean;
  public shareProject: boolean;
  public sagemakerOn: boolean;
  public projectV2: boolean;
  public maxScenarioVariants: number;
  public scenarioVariants: boolean;
  public advancedCostComponents: boolean;
  public intraHubNetwork: boolean;

  public name: string;
  public id: string;
}
