<div class="flex items-center">
  <sympheny-select
    class="flex-1"
    [formControl]="dataset"
    label="map.dataset.select"
    [options]="allDatasets"
    labelKey="dataset_name"
    valueKey="dataset_id"
    (selectionChange)="onSelectDataset($event)"
  >
  </sympheny-select>

  <sympheny-more-button
    *ngIf="canEdit"
    [disabled]="disabled"
    [configuration]="moreConfiguration"
  ></sympheny-more-button>
</div>
