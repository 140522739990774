import { meanBy } from 'lodash';

import { Graph, GraphExtend, GraphSettings, GraphData } from '../model';
import { ascendingBreadth } from '../utils/breath';
import { nodeCenter, nodeHeight } from '../utils/node';
import { numberOfNonSelfLinkingCycles } from '../utils/self-linking';

// For each node in each column, check the node's vertical position in relation to its targets and sources vertical position
// and shift up/down to be closer to the vertical middle of those targets and sources
function relaxLeftAndRight(
  alpha: number,
  extend: Readonly<GraphExtend>,
  data: Readonly<GraphData>,
) {
  const columnsLength = data.maxColumns();

  data.forEachColumn((nodes) => {
    const n = nodes.length;
    const column = nodes[0].column;

    nodes.forEach(function (node) {
      // check the node is not an orphan
      const nHeight = nodeHeight(node);
      const sourceLinks = data.getSourceLinks(node);
      const targetLinks = data.getTargetLinks(node);

      if (sourceLinks.length || targetLinks.length) {
        if (node.partOfCycle && numberOfNonSelfLinkingCycles(node, data) > 0) {
          /* empty */
        } else if (column === 0 && n === 1) {
          node.setNodeY(extend.y1 / 2 - nHeight / 2);
        } else if (column === columnsLength - 1 && n === 1) {
          node.setNodeY(extend.y1 / 2 - nHeight / 2);
        } else {
          let avg = 0;

          const avgTargetY = meanBy(sourceLinks, (link) =>
            nodeCenter(data.getNodeTarget(link)),
          );
          const avgSourceY = meanBy(targetLinks, (link) =>
            nodeCenter(data.getNodeSource(link)),
          );

          if (avgTargetY && avgSourceY) {
            avg = (avgTargetY + avgSourceY) / 2;
          } else {
            avg = avgTargetY || avgSourceY;
          }

          const dy = (avg - nodeCenter(node)) * alpha;
          // positive if it node needs to move down

          node.setValue('y0', node.y0 + dy);
          node.setValue('y1', node.y1 + dy);
        }
      }
    });
  });
}
// For each column, check if nodes are overlapping, and if so, shift up/down
function resolveCollisions(
  data: GraphData,
  extend: Readonly<GraphExtend>,
  sankey: Readonly<GraphSettings>,
) {
  const { nodePadding, minNodePaddingHorizontal } = sankey;
  data.forEachColumn((nodes) => {
    const n = nodes.length;
    let node;
    let dy;
    let y = extend.y0;
    let i;

    // Push any overlapping nodes down.
    nodes.sort(ascendingBreadth);

    for (i = 0; i < n; ++i) {
      node = nodes[i];
      dy = y - node.y0;

      if (dy > 0) {
        node.y0 += dy;
        node.y1 += dy;
      }
      y = node.y1 + nodePadding;
    }

    // If the bottommost node goes outside the bounds, push it back up.
    dy = y - nodePadding - extend.y1;
    if (dy > 0) {
      y = node.y0 -= dy;
      node.y1 -= dy;

      // Push any overlapping nodes back up.
      for (i = n - 2; i >= 0; --i) {
        node = nodes[i];
        dy = node.y1 + minNodePaddingHorizontal /*nodePadding*/ - y;
        if (dy > 0) {
          node.y0 -= dy;
          node.y1 -= dy;
        }
        y = node.y0;
      }
    }
  });
}

export const resolveCollisionsAndRelax = (graph: Graph<any, any>) => {
  const { iterations } = graph.settings;
  const data = graph.graph;

  for (let alpha = 1, n = iterations; n > 0; --n) {
    relaxLeftAndRight((alpha *= 0.99), data.extend, data);
    resolveCollisions(data, data.extend, graph.settings);
  }
};

export const resolveCollisionsAndRelaxEhub = (graph: Graph<any, any>) => {
  const nodePadding = 10;
  const data = graph.graph;
  const extend = data.extend;

  for (let alpha = 1, n = 30; n > 0; --n) {
    relaxLeftAndRight((alpha *= 0.99), data.extend, data);
    resolveCollisions(data, data.extend, graph.settings);
  }

  // For each of he nodes check if the there is only one target, if so than make everything on one link.
  // At one point we will need to check if there is no overlay with other nodes

  data.forEachColumn((nodes) => {
    const newSize = extend.y1 / nodes.length - nodePadding;
    // const toHigh = newSize < nHeight;

    // let nextY0 = extend.y0;

    const sorted = nodes.sort((n1, n2) => (n1.y0 < n2.y0 ? -1 : 1));

    // sorted.forEach((node, i) => {
    //   if (toHigh) {
    //     node.setNodeY(nextY0, newSize);

    //     nextY0 = node.y1 + nodePadding;
    //   }
    // });

    nodes.forEach((node, i) => {
      const sourceLinks = data.getSourceLinks(node);

      // The end of the loop
      // or there is are more targets with me as the source
      if (sourceLinks.length !== 1) return;

      const target = data.getNodeTarget(sourceLinks[0]);
      const targetLinks = data.getTargetLinks(target);

      // No alignment needed more than one or none
      if (targetLinks.length === 1) {
        const targetHeight = nodeHeight(target);
        const center = nodeCenter(node);
        const y = center - targetHeight / 2;

        const prevNode = sorted[i - 1];
        const nextNode = sorted[i - 1];

        // TODO  test if gap is still big enough

        target.setNodeY(y, targetHeight);
      }
    });
  });
};
