export enum OpenLayerStyles {
  monoChrome = 'monoChrome',
  satellite = 'satellite',
  gray = 'gray',
}

export const openLayerStyles: Record<OpenLayerStyles, string> = {
  [OpenLayerStyles.monoChrome]:
    'mapbox://styles/julienmarquant/cl620xeiu001w14n0vp0uxmby',
  // TODO wait until this PR is merged and then update ol-mapbox-style
  // https://github.com/openlayers/ol-mapbox-style/pull/693
  [OpenLayerStyles.satellite]:
    'mapbox://styles/julienmarquant/cl70j9xxc002i15ladr4ra1x2',
  [OpenLayerStyles.gray]:
    'https://api.maptiler.com/maps/ch-swisstopo-lbm-grey/style.json',
} as const;

export const mapTillerStyles = [OpenLayerStyles.gray];

export const openStreetMapAttributions = [
  '&#169; <a href=""https://www.openstreetmap.org/copyright" target="_blank"">OpenStreetMap</a> contributors.',
  '&#169; Solar Radiation PVGIS <a href="https://european-union.europa.eu/legal-notice_en" target="_blank">European Union, 1995-2022</a>.',
  '&#169; SEP <a href="https://www.swissenergyplanning.ch/" target="_blank">by geoimpact</a>.',
];

export const mapBoxTiler = [
  '&#169; <a href=""https://www.maptiler.com/copyright" target="_blank"">MapTiler</a> contributors.',
  '&#169; <a href=""https://www.openstreetmap.org/copyright" target="_blank"">OpenStreetMap</a> contributors.',
  '&#169; Solar Radiation PVGIS <a href="https://european-union.europa.eu/legal-notice_en" target="_blank">European Union, 1995-2022</a>.',
  '&#169; SEP <a href="https://www.swissenergyplanning.ch/" target="_blank">by geoimpact</a>.',
];

export const attributions: Record<OpenLayerStyles, string[]> = {
  [OpenLayerStyles.monoChrome]: openStreetMapAttributions,
  [OpenLayerStyles.satellite]: openStreetMapAttributions,
  [OpenLayerStyles.gray]: mapBoxTiler,
};
