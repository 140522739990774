import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormLogComponent, FormLogService } from '@sympheny/ui/form-log';
import { LoaderComponent } from '@sympheny/ui/loader';

import { DialogActionsComponent } from '../dialog-actions/dialog-actions.component';

@Component({
  selector: 'sympheny-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss'],
  providers: [FormLogService],
  standalone: true,
  imports: [
    DialogActionsComponent,
    NgIf,
    InfoButtonComponent,
    LoaderComponent,
    TranslocoPipe,
    FormLogComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class DialogFormComponent implements OnChanges {
  @Input() public form!: FormGroup;
  @Input() public contentLabel!: string;
  @Input() public tooltip?: string;
  @Input() public editMode!: boolean;
  @Input() public loading = false;
  @Input() public hideActions = false;
  @Input() public hideTitle = false;
  @Input() public hideLog = false;

  @Input() public submitLabel?: string;
  @Input() public disableLog = false;
  @Output() public readonly submitForm = new EventEmitter<void>();

  constructor(private readonly formLogService: FormLogService) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['form']) {
      this.formLogService.registerForm(this.form);
    }
  }

  public onSubmitForm() {
    if (this.form.invalid) {
      return;
    }

    this.submitForm.emit();
  }
}
