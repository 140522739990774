<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <div class="p-6">
    <h2 *ngIf="!hideTitle">
      <ng-container *ngIf="contentLabel">
        {{ (editMode ? 'general.edit' : 'general.new') | transloco }}
        {{ contentLabel | transloco }}
      </ng-container>
      <ng-content select="[title]"></ng-content>
      <sympheny-info-button
        *ngIf="tooltip"
        [tooltip]="tooltip"
      ></sympheny-info-button>
    </h2>
    <div class="pb-4 max-h-[75VH] overflow-auto">
      <sympheny-loader
        [loading]="loading"
        class="!absolute inset-1/2"
      ></sympheny-loader>
      <ng-content></ng-content>
      <ng-content select="[content]"></ng-content>
    </div>
    <sympheny-dialog-actions
      *ngIf="!hideActions"
      [submitLabel]="
        submitLabel
          ? (submitLabel | transloco)
          : ((editMode ? 'general.save' : 'general.add') | transloco)
      "
      [disabled]="form.invalid || loading"
    >
      <div class="flex gap-4 items-center">
        <sympheny-form-log
      *ngIf="!hideLog"></sympheny-form-log>

        <ng-content select="[actions]"></ng-content>
      </div>
      <div dialog-actions-additional-buttons>
        <ng-content select="[additional-buttons]"></ng-content>
        <ng-content select="[dialog-actions-additional-buttons]"></ng-content>
      </div>
    </sympheny-dialog-actions>
  </div>
</form>
