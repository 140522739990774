import * as ol from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import { Vector as VectorLayer } from 'ol/layer';
import { AnimationOptions } from 'ol/View';

export const MAP_DEFAULTS = {
  minVectorLayerZoom: 12,
  defaultZoom: 18,
};

interface ZoomConfig {
  zoom?: number;
}

function animatedZoom(
  map: ol.Map,
  options: AnimationOptions & { center: number[] }
) {
  if (
    !options.center ||
    options.center.length < 2 ||
    !options.center[0] ||
    !options.center[1]
  ) {
    return;
  }
  map.getView().animate({
    zoom: MAP_DEFAULTS.defaultZoom,
    duration: 500,
    ...options,
  });
}

export function zoomToCoordinates(
  map: ol.Map,
  coordinates: number[] | null | Coordinate,
  config: ZoomConfig = {}
) {
  if (!coordinates || !coordinates[0]) {
    return;
  }

  animatedZoom(map, { ...config, center: coordinates });
}

export function zoomToCenterOfLayer(
  map: ol.Map,
  layer: VectorLayer<any>,
  config: ZoomConfig = {}
) {
  const center = getCenter(layer.getSource().getExtent());

  animatedZoom(map, { ...config, center });
}

export function zoomToExtentOfLayer(
  map: ol.Map,
  layer: VectorLayer<any>,
  padding?: number[]
) {
  const extent = layer.getSource().getExtent();
  if (!extent || !extent[0] || extent[0] === Infinity) {
    return;
  }

  map.getView().fit(extent, { duration: 500, padding });
}

export function zoomToExtent(map: ol.Map, extent: number[]) {
  if (!extent || !extent[0] || extent[0] === Infinity) {
    return;
  }

  map.getView().fit(extent, { duration: 500 });
}
