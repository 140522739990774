import { Injectable } from '@angular/core';
import { ProjectVersion } from '@sympheny/project/data-access';
import { Observable } from 'rxjs';

@Injectable()
export abstract class DatabaseDetailsService<TECH> {
  public abstract readonly categories$: Observable<any[]>;
  public abstract reload(): void;
  public abstract getTechnologyCategoryDetails(
    categories: string[],
  ): Observable<any[]>;
  public abstract getDetails(
    projectVersion: ProjectVersion,
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any>;
  public abstract create(
    projectVersion: ProjectVersion,
    partialData: Partial<TECH>,
    ...extraParams: any
  ): Promise<TECH | TECH[] | any | void>;
  public abstract update(
    projectVersion: ProjectVersion,
    gui: string,
    data: Partial<TECH>,
  ): Promise<TECH | TECH[] | any | void>;
  public abstract delete(gui: string): Promise<string>;
  public abstract deleteCategory(gui: string): Promise<string>;
}
