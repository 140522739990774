import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@fp-tools/angular-state';
import { mapData, ResponseModel } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { concatMap, Observable, Subject, takeUntil, timer } from 'rxjs';

export interface DatabaseLog {
  startTime: Date;
  endTime: Date;
  result: string;
  type: string;
  excelFile: string;
  userEmail: string;
  profileType: string;
}

const TIMEOUT = 2000;

@Injectable()
export class DatabaseLogCollection extends Collection<DatabaseLog, any> {
  protected readonly idKey = 'startTime';
  private readonly base = this.environmentService.getValue('base');

  private readonly endReload$ = new Subject<void>();

  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {
    super();
  }

  protected override fetchApi(): Observable<DatabaseLog[]> {
    return timer(0, TIMEOUT).pipe(
      concatMap(() =>
        this.http
          .get<ResponseModel<DatabaseLog[]>>(`${this.base}db-update`)
          .pipe(mapData(), takeUntil(this.endReload$))
      )
    );
  }

  public listenToData() {
    this.endReload$.next();
    this.load();
  }
  public stop() {
    this.endReload$.next();
  }
}
