import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@sympheny/user/auth0';
import { PlanService, PlanType } from '@sympheny/user/plan';
import { EnvironmentService } from '@sympheny/utils/environment';
import { map, of, switchMap } from 'rxjs';
const isPlan = (
  planService: PlanService,
  environment: EnvironmentService,

  plan: PlanType | undefined,
  currentPlan?: PlanType | undefined,
) => {
  const redirectOnPlan = [PlanType.ENY_MAP];

  if (currentPlan && redirectOnPlan.indexOf(currentPlan) >= 0) {
    if (plan === currentPlan) {
      return true;
    } else {
      window.location.href = environment.getApp('sympheny');
      return false;
    }
  }
  const redirects = {
    [PlanType.ENY_MAP]: environment.getApp('enymap'),
  };

  for (const p of redirectOnPlan) {
    if (plan === p) {
      window.location.href = redirects[p];
      return false;
    }
  }

  return true;
};
export function redirectToRightPlan(
  currentPlan?: PlanType | undefined,
): CanActivateFn {
  return () => {
    const planService: PlanService = inject(PlanService);
    const authService: AuthService = inject(AuthService);
    const environment: EnvironmentService = inject(EnvironmentService);

    return authService.isAuthenticated$.pipe(
      switchMap((auth) =>
        auth
          ? planService
              .getPlan$()
              .pipe(
                map((plan) =>
                  isPlan(planService, environment, plan, currentPlan),
                ),
              )
          : of(false),
      ),
    );
  };
}
