import { Graph, GraphData, Link, LinkImpl, Node, NodeImpl } from '../model';

const createReplacedLinks = (
  inputGraph: Readonly<GraphData>,
  link: Link,
  targetNode: Node,
  sourceNode: Node,
  vNodeIndex: number,
  vLinkIndex: number,
  value: number | null,
) => {
  link.setValue('type', 'replaced');
  let virtualNodeIndex = vNodeIndex;
  let virtualLinkIndex = vLinkIndex;

  const totalToCreate = targetNode.column - sourceNode.column - 1;
  for (let n = 0; n < totalToCreate; n++) {
    virtualNodeIndex = virtualNodeIndex + 1;
    const newNode = new NodeImpl({
      //get the next index number
      name: 'virtualNode' + virtualNodeIndex,
      _id: 'virtualNode' + virtualNodeIndex,
      index: 'v' + virtualNodeIndex,
      partOfCycle: false,
      value: value ?? link.value,
      column: sourceNode.column + (n + 1),
      row: sourceNode.row - (n + 1),
      virtual: true,
      replacedLink: link.index,
    } as Node);
    inputGraph.addNode(newNode._id, newNode);

    const vMinus1 = virtualNodeIndex - 1;
    const newLink = new LinkImpl({
      source: n === 0 ? sourceNode._id : 'virtualNode' + vMinus1,
      //   newLink.sourceIndex =
      target: newNode._id,
      value: value ?? link.value,
      index: 'virtualLink' + virtualLinkIndex,
      type: 'virtual',
      parentLink: link.index,
    } as Link);

    virtualLinkIndex = virtualLinkIndex + 1;
    inputGraph.addLink(newLink);
  }

  const lastLink = new LinkImpl({
    source: 'virtualNode' + virtualNodeIndex,
    target: targetNode._id,

    value: value ?? link.value,
    index: 'virtualLink' + virtualLinkIndex,
    type: 'virtual',
    parentLink: link.index,
  } as Link);

  virtualLinkIndex = virtualLinkIndex + 1;
  inputGraph.addLink(lastLink);

  return { virtualLinkIndex, virtualNodeIndex };
};

export const createVirtualNodes = (
  graph: Readonly<Graph<any, any>>,
  value: number | null = null,
) => {
  const { useVirtualRoutes, graph: data } = graph;

  if (useVirtualRoutes) {
    let virtualNodeIndex = -1;
    let virtualLinkIndex = 0;

    data.forEachLink((link: Link) => {
      const { target: targetNode, source: sourceNode } =
        data.getNodeLinks(link);

      if (
        !targetNode ||
        !sourceNode ||
        targetNode.column - sourceNode.column < 2
      ) {
        link.setValue('type', 'normal');
      } else {
        const replaced = createReplacedLinks(
          data,
          link,
          targetNode,
          sourceNode,
          virtualNodeIndex,
          virtualLinkIndex,
          value,
        );

        virtualLinkIndex = replaced.virtualLinkIndex;
        virtualNodeIndex = replaced.virtualNodeIndex;

        // TODO add replaced link to array
      }
    });
  }

  data.removeLinksFromIndex('replaced');
};
