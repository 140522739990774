<ng-container *ngIf="languages.length > 1">
  <sympheny-icon-button
    icon="language"
    [matMenuTriggerFor]="languageMenu"
    color="custom"
  ></sympheny-icon-button>
  <mat-menu #languageMenu>
    <button
      *ngFor="let language of languages"
      mat-menu-item
      (click)="changeLanguage.emit(language.id)"
    >
      <span [class.text-primary]="language.id === currentLanguage">
        {{ language.label }}
      </span>
    </button>
  </mat-menu>
</ng-container>
