import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { CustomSeaonalityValueSchema } from './seasonal-operation.model';

export const TechnologyModeEnergyCarrierSchema = z.object({
  energyCarrierGuid: z.string().nullish(),
  typeKey: z.string().nullish(),
  typeDisplayName: z.string().nullish(),
  subtypeKey: z.string().nullish(),
  subtypeDisplayName: z.string().nullish(),
  energyCarrierName: z.string().nullish(),
  colorHexCode: z.string().nullish(),
  fixedInputShare: SymphenyNumber().nullish(),
  customInputShareActivated: z.boolean().nullish().default(false),

  inputShareProfileId: SymphenyNumber().nullish(),
  outputEfficiency: SymphenyNumber().nullish(),
  customOutputEfficiencyActivated: z.boolean().nullish(),
  customInputEfficiencyActivated: z.boolean().nullish(),
  customSeasonalityValues: z.array(CustomSeaonalityValueSchema).nullish(),
  outputEfficiencyProfileId: SymphenyNumber().nullish(),
  outputEfficiencyProfileFile: z.any().nullish(),
  created: SymphenyDate().nullish(),
  primary: z.boolean().nullish(),
});
export const TechnologyModeEnergyCarrierDtoSchema = z.object({
  customInputShareActivated: z.boolean().nullish().default(false),
  customOutputEfficiencyActivated: z.boolean().nullish().default(false),
  customInputEfficiencyActivated: z.boolean().nullish(),
  outputEfficiencyProfileFile: z.any().nullish(),
  customSeasonalityValues: z.array(CustomSeaonalityValueSchema).nullish(),
  energyCarrierGuid: z.string(),
  fixedInputShare: SymphenyNumber().nullish(),
  inputShareProfileId: SymphenyNumber().nullish(),
  outputEfficiency: SymphenyNumber().nullish(),
  outputEfficiencyProfileId: SymphenyNumber().nullish(),
  primary: z.boolean().nullish().default(false),
  type: z.string(),
});

export const TechnologyModeSchemaV1 = z.object({
  technologyModeGuid: z.string().nullish(),
  inputEnergyCarriers: z.array(TechnologyModeEnergyCarrierSchema).nullish(),
  outputEnergyCarriers: z.array(TechnologyModeEnergyCarrierSchema).nullish(),
  seasonalOperationName: z.string().nullish(),
  seasonalOperationValue: z.string().nullish(),
  primary: z.boolean().nullish(),
  allowedOperationProfileId: SymphenyNumber().nullish(),
});
export const TechnologyModeDtoV1 = z.object({
  energyCarriers: z.array(TechnologyModeEnergyCarrierDtoSchema).nullish(),
  primary: z.boolean().nullish(),
  seasonalOperation: z.string().nullish(),
});

export const TechnologyModeSchemaV2 = z.object({
  technologyModeGuid: z.string().nullish(),
  inputEnergyCarriers: z.array(TechnologyModeEnergyCarrierSchema).nullish(),
  outputEnergyCarriers: z.array(TechnologyModeEnergyCarrierSchema).nullish(),
  seasonalOperationName: z.string().nullish(),
  seasonalOperationValue: z.string().nullish(),
  primary: z.boolean().nullish(),
  allowedOperationProfileId: SymphenyNumber().nullish(),

  capacity: SymphenyNumber().nullish(),
  curtailmentLimitation: SymphenyNumber().nullish(),
  maximumAnnualOutput: SymphenyNumber().nullish(),
  maximumCapacity: SymphenyNumber().nullish(),
  minimumAnnualOutput: SymphenyNumber().nullish(),
  minimumCapacity: SymphenyNumber().nullish(),
  allowedOperationProfileFile: SymphenyNumber().nullish(),
  peakPower: SymphenyNumber().nullish(),
});
export const TechnologyModeDtoV2 = TechnologyModeSchemaV2.pick({
  primary: true,
  capacity: true,
  maximumCapacity: true,
  maximumAnnualOutput: true,
  minimumAnnualOutput: true,
  curtailmentLimitation: true,
  minimumCapacity: true,
  allowedOperationProfileId: true,
  peakPower: true,
}).extend({
  isHourly: z.boolean().default(false),
  energyCarriers: z.array(TechnologyModeEnergyCarrierDtoSchema).nullish(),
  seasonalOperation: z.string().nullish(),
});

export type TechnologyModeEnergyCarrier = z.infer<
  typeof TechnologyModeEnergyCarrierSchema
>;
export type TechnologyModeV2Dto = z.infer<typeof TechnologyModeDtoV2>;
export type InputOutputEnergyCarrier = TechnologyModeEnergyCarrier;

export const TechnologyModeSchema = TechnologyModeSchemaV1.merge(
  TechnologyModeSchemaV2,
);

export const HubEnergyCarrierSchema = z.object({
  hubGuid: z.string().nullish(),
  hubName: z.string().nullish(),
  updated: SymphenyDate().nullish(),
  created: SymphenyDate().nullish(),
});

export type TechnologyMode = z.infer<typeof TechnologyModeSchema>;
export type TechnologyModeV2 = z.infer<typeof TechnologyModeSchemaV2>;
