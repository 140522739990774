<form (ngSubmit)="onSubmit()" [formGroup]="uploadForm">
  <h1 mat-dialog-title>Import GIS dataset</h1>

  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Dataset name</mat-label>
      <input
        matInput
        formControlName="name"
        placeholder="Type here..."
        [maxLength]="100"
        style="width: 100%"
      />
      <mat-hint>* {{ 'FORM.requiredHint' | transloco }}</mat-hint>
    </mat-form-field>
    <ngx-file-drag-drop
      *ngIf="!processed && !loading"
      formControlName="file"
      activeBorderColor="#3F51B5"
      [multiple]="false"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      (valueChanged)="onFileChange($event)"
    >
    </ngx-file-drag-drop>

    <mat-spinner
      *ngIf="loading"
      mode="indeterminate"
      class="spinner"
    ></mat-spinner>
    <ng-container *ngIf="processed">
      <h2>Select mapping</h2>
      <div class="general-parameters">
        <mat-form-field>
          <mat-select
            placeholder="Select the worksheet"
            formControlName="worksheet"
            (valueChange)="changeWorksheet($event)"
          >
            <mat-option
              *ngFor="let worksheet of worksheets"
              [value]="worksheet"
              >{{ worksheet }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>EGID</mat-label>
          <mat-select
            placeholder="Select egid mapping column"
            formControlName="location"
          >
            <mat-option *ngFor="let column of selectedColumns" [value]="column">
              {{ column }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <sympheny-upload-gis-form
        formControlName="fields"
        [excludeField]="uploadForm.value.location"
      ></sympheny-upload-gis-form>
    </ng-container>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close type="button">
      {{ 'Cancel' | transloco }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="uploadForm.invalid"
      type="submit"
    >
      Submit
    </button>
  </div>
</form>
