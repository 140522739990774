import { NgIf, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoPipe } from '@ngneat/transloco';
import { DataSetState } from '@sympheny/gis/datasets';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';

import { ExcelParserService, ParseField } from './excel-parser.service';
import { UploadGisFormComponent } from './upload-gis-form/upload-gis-form.component';

@Component({
  selector: 'sympheny-upload-gis-data',
  templateUrl: './upload-gis-data.component.html',
  styleUrls: ['./upload-gis-data.component.scss'],
  providers: [ExcelParserService],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgxFileDragDropModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    UploadGisFormComponent,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class UploadGisDataComponent {
  processed = false;
  worksheets: string[] = [];
  columns: Record<string, string[]> = {};
  selectedColumns: string[] = [];
  loading = false;

  readonly uploadForm = new FormGroup({
    fields: new FormControl<ParseField[]>(
      { value: [], disabled: true },
      Validators.required,
    ),
    file: new FormControl<File | null>(null, Validators.required),
    name: new FormControl<string>('', Validators.required),
    worksheet: new FormControl<string>(
      { value: '', disabled: true },
      Validators.required,
    ),
    locationProvider: new FormControl<string>(
      { value: 'egid', disabled: true },
      Validators.required,
    ),
    location: new FormControl<string | null>(
      { value: null, disabled: true },
      Validators.required,
    ),
  });

  constructor(
    private readonly excelParserService: ExcelParserService,
    private readonly dataSetState: DataSetState,
    private readonly dialog: MatDialog,
  ) {}

  async onSubmit() {
    if (!this.uploadForm.valid) {
      return;
    }
    const { worksheet, location, fields, name } = this.uploadForm.value;
    const filledInFields = fields!.filter((f) => f.field || f.customName);

    const addresses = this.excelParserService.parseData(
      worksheet!,
      location!,
      filledInFields,
    );

    await this.dataSetState.create({ dataset_name: name, addresses });

    this.dialog.closeAll();
  }

  async onFileChange(files: File[]) {
    if (!files || files.length !== 1) {
      return;
    }

    this.loading = true;
    this.processed = false;

    const parsedFile = await this.excelParserService.readFile(files[0]);
    this.columns = parsedFile.columns;
    this.worksheets = parsedFile.worksheets;

    this.fillFormWithDefaults();

    this.loading = false;
    this.processed = true;
  }

  private fillFormWithDefaults() {
    this.uploadForm.get('fields')?.enable();
    this.uploadForm.get('worksheet')?.enable();
    this.uploadForm.get('location')?.enable();
    this.uploadForm.get('locationProvider')?.enable();

    let egid: null | string = null;
    const worksheet =
      this.worksheets.find((w) => {
        egid = this.findColumn(w, 'egid');
        return egid;
      }) ?? this.worksheets[0];
    this.uploadForm.patchValue({ worksheet, location: egid });

    this.changeWorksheet(worksheet);
  }

  changeWorksheet(selectedWorksheet: string) {
    const columns = selectedWorksheet
      ? this.columns[selectedWorksheet] ?? []
      : [];
    this.selectedColumns = columns;

    const fields: ParseField[] = columns.map(
      (column) =>
        ({
          column,
        }) as ParseField,
    );
    this.uploadForm.patchValue({ fields });
  }

  private findColumn(worksheet: string, value: string): null | string {
    const column = this.columns[worksheet]?.find(
      (c) => c.toLowerCase() === value,
    );
    if (!column) {
      return null;
    }

    return column;
  }
}
