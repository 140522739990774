import { Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { UserState } from '@sympheny/user/data-access';
import { LocalCacheService } from '@sympheny/utils/data-access';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;

  constructor(
    public auth0: Auth0Service,
    private readonly userState: UserState,
    private readonly localCache: LocalCacheService,
  ) {
    this.isAuthenticated$ = this.auth0.isAuthenticated$;
    this.isAuthenticated$.subscribe((res) => {
      if (res) {
        this.userState.login();
      } else {
        this.loginWithRedirect();
      }
    });
  }

  public init() {
    return '';
  }
  /**
   * Get access token
   */
  public getAccessToken(): Observable<string> {
    return this.auth0.getAccessTokenSilently();
  }

  /**
   * Auth0 loginWithRedirect method
   */
  public loginWithRedirect(): void {
    this.auth0.loginWithRedirect({ appState: { target: '' } });
  }

  /**
   * Logout user on the backend
   */
  public logout(): void {
    this.userState.logout();
    this.auth0.logout({ logoutParams: { returnTo: document.location.origin } });
    this.localCache.clearCache();
  }
}
