import { NgIf, NgFor, AsyncPipe, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { EnergyCarrier, Hub, HubService } from '@sympheny/project/data-access';
import { Stage } from '@sympheny/project/scenario/data-access';
import { EhubComponent } from '@sympheny/ui/diagram-ehub';
import { SelectComponent, SymphenyFormControl } from '@sympheny/ui/form';
import { nullOrUndefined } from '@sympheny/utils/rxjs';
import { sortBy } from 'lodash-es';

import { mapLink, mapNode } from './energy-diagram.utils';
@Component({
  selector: 'sympheny-hubs-energy-diagram',
  templateUrl: './energy-diagram.component.html',
  styleUrls: ['./energy-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    EhubComponent,
    NgFor,
    AsyncPipe,
    ReactiveFormsModule,
    SelectComponent,
    JsonPipe,
    TranslocoPipe,
  ],
})
export class HubsEnergyDiagramComponent {
  public inputData = signal<{
    hubs: Hub[];
    stages: Stage[];
    energyCarriers: EnergyCarrier[];
    reload: number;
  }>({ hubs: [], energyCarriers: [], stages: [], reload: 0 });

  // Inputs
  @Input() public scenarioId!: string;
  @Output() public readonly selectedHubGuidEmitter = new EventEmitter<string>();

  public readonly form = new FormGroup({
    hub: new SymphenyFormControl('', {
      label: 'ANALYSIS.execution.dashboard.hub',
      key: 'hubs',
    } as any),
    stage: new SymphenyFormControl('', {
      label: 'ANALYSIS.execution.dashboard.stage',
      key: 'stages',
    } as any),
  });
  public valueChange = toSignal(this.form.valueChanges);

  public noData = computed(async () => {
    const { energyCarriers, hubs, stages } = this.inputData();
    if (hubs.length === 0 || energyCarriers.length === 0 || stages.length === 0)
      return true;

    const ds = await this.dataset();
    return ds.links.length === 0 || ds.nodes.length === 0;
  });
  public readonly dataset = computed(async () => {
    const { energyCarriers, hubs, stages } = this.inputData();

    if (!energyCarriers?.length || !hubs?.length || !stages?.length)
      return null;

    const { hub, stage } = this.valueChange();
    if (nullOrUndefined(hub) || nullOrUndefined(stage)) return null;

    const { links, nodes } = await this.hubService.getEnergyDiagram(
      this.scenarioId,
      stage,
      hub,
    );
    const mappedLinks = links.map((l) => mapLink(energyCarriers, l));
    const mappedNodes = nodes.map((n) => mapNode(energyCarriers, n));

    return { links: mappedLinks, nodes: mappedNodes };
  });

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly hubService: HubService,
    private readonly router: Router,
  ) {
    this.listenOnQueryParams();
  }

  @Input()
  public set hubs(hubs: Hub[]) {
    const sorted = sortBy(hubs, (hub) => hub.hubName);
    if (!this.form.value.hub) {
      this.form.get('hub').setValue(sorted?.[0]?.hubGuid);
    }
    this.inputData.set({ ...this.inputData(), hubs: sorted });
  }
  @Input()
  public set stages(stages: Stage[]) {
    const sorted = sortBy(stages, (stage) => stage.name);
    if (!this.form.value.stage) {
      this.form.get('stage').setValue(sorted?.[0]?.guid);
    }
    this.inputData.set({ ...this.inputData(), stages: sorted });
  }
  @Input()
  public set energyCarriers(energyCarriers: EnergyCarrier[]) {
    this.inputData.set({ ...this.inputData(), energyCarriers });
  }
  @Input()
  public set reload(reload: Date) {
    this.inputData.set({ ...this.inputData(), reload: reload.getTime() });
  }

  public changeFilter() {
    const { hub, stage } = this.valueChange();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { hub, stage },
      queryParamsHandling: 'merge',
    });
  }

  private listenOnQueryParams() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const stage = params['stage'] ?? this.form.value.stage ?? null;
      const hub = params['hub'] ?? this.form.value.hub ?? null;
      this.form.setValue({ stage, hub });
    });
  }
}
