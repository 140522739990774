import * as d3 from 'd3';

import { mouseOut, opacity } from './const';
import { showTooltip } from './tooltip';
import { Graph, GraphData, Node, Link } from '../model';
import { nodeHeight, nodeWidth } from '../utils/node';

type G_EL = d3.Selection<SVGGElement, undefined, null, undefined>;
type SVG = d3.Selection<SVGSVGElement, undefined, null, undefined>;

export type DrawNodeProps = {
  linkOpacity?: number;
};
export const drawNode = <NODE_TYPE extends Node, LINK_TYPE extends Link>(
  graphSetup: Graph<NODE_TYPE, LINK_TYPE>,
  g: G_EL,
  svg: SVG,
  dragEvent: (node: NODE_TYPE) => void,
) => {
  const nodes = graphSetup.graph.getNodes();
  const { nodeColor } = graphSetup;

  const nodeG = g
    .append('g')
    .attr('class', 'nodes')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 10)
    .selectAll('g');
  const dragHandler = d3
    .drag()
    .on('start', dragNode)
    .on('drag', dragNode)
    .on('end', dragNode);

  function dragNode(this: any, event, d) {
    d3.select(this as unknown as any)
      .attr('cx', event.x)
      .attr('cy', event.y)
      .attr('x', event.x)
      .attr('y', event.y);
    const height = nodeHeight(d);
    const width = nodeWidth(d);
    d.x0 = event.x;
    d.y0 = event.y;
    d.x1 = d.x0 + width;
    d.y1 = d.y0 + height;
    dragEvent(d as NODE_TYPE);
  }

  const nodeData = nodeG.data(nodes);

  const node = nodeData.enter().append('g');

  const nodeRect = node
    .append('rect')
    .attr('x', (d) => d.x0)
    .attr('y', (d) => d.y0)
    .attr('height', (d) => d.y1 - d.y0)
    .attr('width', (d) => d.x1 - d.x0)
    .style('fill', nodeColor as any)
    .style('opacity', opacity.highlight)
    .attr('id', (d) => d._id)
    .on('mouseover', mouseOverNode(node, svg, graphSetup))
    .on('mouseout', mouseOut(svg, graphSetup.tooltip));

  dragHandler(nodeRect as any);

  return { nodeG, node, nodeData };
};

export const mouseOverNode = <NODE_TYPE extends Node, LINK_TYPE extends Link>(
  nodes: any,
  svg: SVG,
  {
    nodeTooltip,
    graph,
    nodeColor,
    tooltip,
    settings,
  }: Graph<NODE_TYPE, LINK_TYPE>,
) => {
  return (d) => {
    const _id = d.srcElement.id;
    const node = graph.getNode(_id) as NODE_TYPE;

    nodes.selectAll('rect').style('opacity', highlightNodes(_id, graph));

    svg.selectAll('.sankey-link').style('opacity', (l: any) => {
      return l.source === _id || l.target === _id ? 1 : 0.3;
    });

    nodes.selectAll('text').style('opacity', highlightNodes(_id, graph));

    const tooltipText = nodeTooltip(node);

    if (tooltipText)
      showTooltip(
        nodeTooltip(node),
        nodeColor(node),
        tooltip,
        settings.width,
        d,
      );
  };
};

export const highlightNodes =
  (name: string, graph: GraphData) => (node: Readonly<Node>) => {
    let op = 0.3;

    if (node.name === name) {
      op = 1;
    }

    graph.getSourceLinks(node).forEach((link) => {
      if (link.target === node._id) {
        op = 1;
      }
    });

    graph.getTargetLinks(node).forEach((link) => {
      if (link.source === node._id) {
        op = 1;
      }
    });

    return op;
  };
