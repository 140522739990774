import { importProvidersFrom } from '@angular/core';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';

export const provideSnackBar = () => {
  return [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'middle',
        verticalPosition: 'top',
      },
    },

    importProvidersFrom(MatSnackBarModule),
  ];
};
