import {
  Inject,
  Injectable,
  ModuleWithProviders,
  NgModule,
  Optional,
} from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class MyTranslateService {
  public readonly langChanges$ = this.translocoService.langChanges$;

  constructor(private readonly translocoService: TranslocoService) {}
}

export function translateFactory(
  translocoService: TranslocoService,
  myService: MyTranslateService
) {
  return {
    init: () => {
      myService.langChanges$.subscribe((lang) => {
        if (translocoService.getActiveLang() !== lang) {
          translocoService.setActiveLang(lang);
        }
      });
    },
  };
}

@NgModule({
  exports: [TranslocoModule],
  imports: [TranslocoModule],
})
export class TranslocoLazyModule {
  constructor(@Optional() @Inject('LAZY_MODULE') lazy: any) {
    lazy?.init();
  }

  public static forChild(): ModuleWithProviders<TranslocoLazyModule> {
    return {
      ngModule: TranslocoLazyModule,
      providers: [
        {
          provide: 'LAZY_MODULE',
          useFactory: translateFactory,
          deps: [TranslocoService, MyTranslateService],
        },
      ],
    };
  }
}
