import * as d3 from 'd3';

import { opacity, mouseOut } from './const';
import { showTooltip } from './tooltip';
import { Graph, Link, Node } from '../model';

type G_EL = d3.Selection<SVGGElement, undefined, null, undefined>;
type SVG = d3.Selection<SVGSVGElement, undefined, null, undefined>;
export type DrawLinkProps<LINK_TYPE extends Link = Link> = {
  width?: (l: LINK_TYPE) => number | number;
  linkOpacity?: number;
};
export const drawLinks = <NODE_TYPE extends Node, LINK_TYPE extends Link>(
  graphSetup: Graph<NODE_TYPE, LINK_TYPE>,
  g: G_EL,
  svg: SVG,
  { width, linkOpacity }: DrawLinkProps<Link> = {},
) => {
  const { linkColor } = graphSetup;
  const links = graphSetup.graph.filterLinks((d) => !!d.path);
  const linkG = g
    .append('g')
    .attr('class', 'links')
    .attr('fill', 'none')
    .attr('stroke-opacity', linkOpacity ?? 0.2)
    .selectAll('path');

  const linkData = linkG.data(links);

  const link = linkData.enter().append('g');
  const widthFn = width ?? (() => 2);
  const op = linkOpacity ?? opacity.normal;
  const linkPaths = link
    .append('path')
    .attr('class', 'sankey-link')
    .attr('d', (l) => l.path)
    .style('stroke-width', widthFn)
    .style('opacity', op)
    .style('stroke', (l) => {
      const nodes = graphSetup.graph.getNodeLinks(l);

      return linkColor(l as any, nodes);
    })
    .attr('id', (d) => d._id)
    .on('mouseover', mouseOver(svg, graphSetup))
    .on('mouseout', mouseOut(svg, graphSetup.tooltip));
  //.attr("marker-end", "url(#arrow)");

  return { linkG, links, linkData, link, linkPaths };
};

export const updateLink = <NODE_TYPE extends Node, LINK_TYPE extends Link>(
  graphSetup: Graph<NODE_TYPE, LINK_TYPE>,
  linkData,
  linkPaths,
) => {
  linkData.data(graphSetup.graph.filterLinks((d) => !!d.path));
  linkPaths.attr('d', (l) => l.path);
};

export const mouseOver = <NODE_TYPE extends Node, LINK_TYPE extends Link>(
  svg: SVG,
  {
    linkTooltip,
    graph,
    linkColor,
    tooltip,
    settings,
  }: Graph<NODE_TYPE, LINK_TYPE>,
) => {
  return (d) => {
    const _id = d.srcElement.id;
    const link = graph.getLink(_id);
    const { source, target } = graph.getNodeLinks(link);

    svg.selectAll('rect').style('opacity', highlightNodes(link));

    svg.selectAll('.sankey-link').style('opacity', (l: any) => {
      if (l._id === _id) return opacity.highlight;
      if (link.originalNodeIds?.includes(link.target)) return opacity.highlight;

      return opacity.noHighlight;
    });

    svg.selectAll('text').style('opacity', highlightNodes(link));
    showTooltip(
      linkTooltip(
        link,
        graph.getNode(link.source) as NODE_TYPE,
        graph.getNode(link.target) as NODE_TYPE,
      ),
      linkColor(link, { source, target }),
      tooltip,
      settings.width,
      d,
    );
  };
};

export const highlightNodes = (link: Link) => (node: any) => {
  if (!node) {
    return opacity.noHighlight;
  }
  if (link.source === node._id || link.target === node._id)
    return opacity.highlight;

  if (link.originalNodeIds?.includes(node._id)) return opacity.highlight;

  return opacity.noHighlight;
};
