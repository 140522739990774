import { Sort } from '@angular/material/sort';
import { Direction, sortByDate, sortData } from '@sympheny/utils/sort';
import { isDate } from 'date-fns';

export function sortTableData(data: any[], sort: Sort | null) {
  if (
    !sort ||
    !sort.active ||
    sort.direction === '' ||
    !data ||
    data.length === 0
  ) {
    return data;
  }

  const firstValue = data[0][sort.active];

  if (isDate(firstValue)) {
    return sortByDate(data).byAttribute(
      sort.active,
      sort.direction === 'asc' ? Direction.ASC : Direction.ASC
    );
  }

  const [attribute, childAttribute] = sort.active.split('_');

  return sortData(data).byAttribute(attribute, childAttribute, sort.direction);
}
