import { ProjectDetailsImage } from '../../model';

const defaultImage = '/assets/project-placeholder.jpg';
export function getDefaultImage(images: ProjectDetailsImage[]) {
  if (images && images.length > 0) {
    const cover = images.find((image) => image.cover);
    const index = images.findIndex((val) => val.cover);
    if (cover) {
      return images[index].url;
    }
  }

  return defaultImage;
}
