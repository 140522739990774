import { NgIf, NgFor, NgClass, JsonPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { DialogService } from '@sympheny/ui/dialog';

import {
  ActionFn,
  TableAction,
  TableColumn,
  TableConfiguration,
  TableDefinedAction,
  TableDefinedActionDisabled,
} from '../model/table-configuration';
import { TableActionComponent } from '../table-action/table-action.component';
import { TableCellComponent } from '../table-cell/table-cell.component';
import { sortTableData } from '../utils/sort-table';

@Component({
  selector: 'sympheny-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    NgFor,
    InfoButtonComponent,
    NgClass,
    TableCellComponent,
    TableActionComponent,
    MatPaginatorModule,
    TranslocoPipe,
    JsonPipe,
  ],
})
export class TableComponent<T> implements AfterViewInit {
  actions: TableAction<T>[] = [];
  columns: TableColumn<T>[] = [];
  displayColumns: Array<string> = [];
  dataSource: MatTableDataSource<T>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private sort: Sort;

  @Input() loading = false;
  @Input() showPaginator = true;
  @Input() public subject: string;

  @Input() labelParams: Record<string, any> = {};
  private _configuration?: TableConfiguration<T>;
  private _disableDefinedActions?: TableDefinedActionDisabled;

  @Input() public set disableDefinedActions(
    disableDefinedActions: TableDefinedActionDisabled | undefined,
  ) {
    this._disableDefinedActions = disableDefinedActions;
    if (!this._configuration) {
      return;
    }

    this.createActions(
      this._configuration.customActions,
      this._configuration.definedActions,
    );
  }

  @Input() public set configuration(configuration: TableConfiguration<T>) {
    this._configuration = configuration;
    this.columns = configuration.columns;

    this.displayColumns = configuration.columns.map((c) =>
      this.getHeaderName(c),
    );
    this.createActions(
      configuration.customActions,
      configuration.definedActions,
    );

    if (this.actions.length) {
      this.displayColumns.push('actions');
    }
  }

  @Input() set data(data: T[]) {
    this.dataSource.data = sortTableData(data, this.sort);
  }

  @Input() public set updateData(data: { data: T[] }) {
    this.data = data.data;
  }

  constructor(private readonly dialogService: DialogService) {
    this.dataSource = new MatTableDataSource<T>([]);
  }

  getHeaderName({ key, childKey }: TableColumn<T>): string {
    return childKey ? `${key as string}_${childKey}` : (key as string);
  }

  sortData(sort: Sort) {
    this.sort = sort;
    this.dataSource.data = sortTableData(this.dataSource.data, sort);
  }

  ngAfterViewInit() {
    if (this.showPaginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  private createActions(
    customActions: TableAction<T>[] | undefined,
    predefinedActions: TableDefinedAction<T> | undefined,
  ) {
    const actions = [...(customActions ?? [])];

    if (!predefinedActions) {
      this.actions = actions;
      return;
    }

    const {
      label,
      viewDetails,
      edit,
      delete: deleteFn,
      download,
      view,
    } = predefinedActions;

    if (viewDetails) {
      actions.push({
        dataCy: 'table-actions--viewDetails',
        tooltip: 'view.details',
        icon: 'preview',
        action: viewDetails,
        disabled: this._disableDefinedActions?.viewDetails ?? false,
      });
    }
    if (download) {
      actions.push({
        dataCy: 'table-actions--download',
        tooltip: 'download',
        icon: 'cloud_download',
        action: download,
        disabled: this._disableDefinedActions?.download ?? false,
      });
    }
    if (view) {
      actions.push({
        dataCy: 'table-actions--view',
        tooltip: 'view',
        icon: 'visibility',
        action: view,
        disabled: this._disableDefinedActions?.viewDetails ?? false,
      });
    }
    if (edit) {
      actions.push({
        dataCy: 'table-actions--edit',
        tooltip: `edit`,
        icon: 'edit',
        tooltipParams: { label },
        action: edit,
        disabled: this._disableDefinedActions?.edit ?? false,
      });
    }
    if (deleteFn) {
      actions.push({
        dataCy: 'table-actions--delete',
        tooltip: `delete`,
        icon: 'delete',
        color: 'warn',
        tooltipParams: { label },
        action: (element: T, index: number) =>
          this.onDelete(element, index, deleteFn),
        disabled: this._disableDefinedActions?.delete ?? false,
      });
    }

    this.actions = actions;
  }

  private onDelete(element: T, index: number, deleteFn: ActionFn<T>) {
    if (!this.subject) {
      deleteFn(element, index);
      return;
    }

    this.dialogService.openConfirmDelete(`${this.subject}`, () =>
      deleteFn(element, index),
    );
  }
}
