import { HttpClient } from '@angular/common/http';
import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TranslocoModule,
  LangDefinition,
} from '@ngneat/transloco';
import { provideTransloco } from '@ngneat/transloco';
import { provideTranslocoPersistTranslations } from '@ngneat/transloco-persist-translations';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

interface TranslocoRootModuleConfig {
  languages: LangDefinition[];
  prodMode: boolean;
}

export const provideCustomTransloco = ({
  languages,
  prodMode,
}: TranslocoRootModuleConfig) => {
  return [
    provideTransloco({
      config: {
        availableLangs: languages,
        defaultLang: 'en',
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: prodMode,
        missingHandler: {
          logMissingKey: true,
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistTranslations({
      loader: TranslocoHttpLoader,
      storage: { useValue: localStorage },
    }),
    { provide: TRANSLOCO_LOADER, useExisting: TranslocoHttpLoader },
  ];
};

@NgModule({
  exports: [TranslocoModule],
})
export class TranslocoRootModule {
  public static forRoot({
    languages,
    prodMode,
  }: TranslocoRootModuleConfig): ModuleWithProviders<TranslocoRootModule> {
    return {
      ngModule: TranslocoRootModule,
      providers: provideCustomTransloco({ languages, prodMode }),
    };
  }
}
