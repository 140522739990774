import {
  SymphenyDateEuCustom,
  SymphenyDateEuSave,
  SymphenyNumber,
} from '@sympheny/utils/data-access';
import { z } from 'zod';

export const OptimizationSpeedEnum = z.enum(['fast', 'medium', 'granular']);

export type OptimizationSpeed = z.infer<typeof OptimizationSpeedEnum>;

export const OtherParametersV1Schema = z.object({
  exchangeCurrency: z.string(),
  exchangeRate: SymphenyNumber(),
  interestRate: SymphenyNumber().nullish(),
  exchangeRateDate: SymphenyDateEuCustom().nullish(),
  optimizationSpeed: OptimizationSpeedEnum.nullish().default('medium'),
});

export const OtherParametersV1DtoSchema = OtherParametersV1Schema.extend({
  exchangeRateDate: SymphenyDateEuSave().nullish(),
});

const TemporalClustering = ['OFF', 'DEFINED', 'LOW', 'MEDIUM', 'HIGH'] as const;
export const TemporalClusteringEnum = z.enum([
  'OFF',
  'DEFINED',
  'LOW',
  'MEDIUM',
  'HIGH',
]);

export type TemporalClustering = z.infer<typeof TemporalClusteringEnum>;

export const OtherParametersV2Schema = z.object({
  exchangeCurrency: z.string(),
  exchangeRate: SymphenyNumber().nullish(),
  temporalClustering: TemporalClusteringEnum.nullish().default('MEDIUM'),
  temporalResolution: SymphenyNumber().nullish().default(1),
  timeLimit: SymphenyNumber().nullish().default(1),
  timeSteps: SymphenyNumber().nullish().default(8760),
  exchangeRateDate: SymphenyDateEuCustom().nullish(),
  optimizationSpeed: OptimizationSpeedEnum.nullish().default('medium'),
});

export const OtherParametersV2DtoSchema = OtherParametersV2Schema.extend({
  exchangeRateDate: SymphenyDateEuSave().optional().nullable(),
});

export type OtherParametersV1 = z.infer<typeof OtherParametersV1Schema>;

export type OtherParametersV2 = z.infer<typeof OtherParametersV2Schema>;

export type OtherParameters = OtherParametersV1 | OtherParametersV2;
