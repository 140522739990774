import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnologyCollection } from '@sympheny/database/model';
import { NetworkTechnology } from '@sympheny/project/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

@Injectable()
export class UserNetworkTechnologyCollection extends TechnologyCollection<NetworkTechnology> {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, {
      db: 'user',
      technology: 'network-tech',
      saveUrl: `user-network-technologies/saved-profiles`,
      categoryMapper: 'technologyCategories',
      technologyMapper: 'databaseNetworkTechnologies',
      guid: 'databaseTechGuid',
    });
  }
}
