export interface MoreMenuItem {
  label: string;
  tooltip: string;
  action: () => void;
}

export interface MoreButtonConfiguration {
  items: MoreMenuItem[];
  tooltip: string;
}
