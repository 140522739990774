import { Injectable } from '@angular/core';
import {
  addMouseHover,
  BaseMapConfig,
  createBaseMapLayer,
  zoomToCoordinates,
} from '@sympheny/gis/utils';
import { LayerType } from '@sympheny/project/scenario/data-access';
import { Map as OlMap } from 'ol';
import { Attribution, Control, defaults as defaultControls } from 'ol/control';
import ZoomSlider from 'ol/control/ZoomSlider';
import { Coordinate } from 'ol/coordinate';
import { Layer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import View from 'ol/View';
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import SearchNominatim from 'ol-ext/control/SearchNominatim';

import { FullScreenControl } from './utils/full-screen';

type LoadLayerFn = (layerType: LayerType, layerId: string) => void;

@Injectable()
export class MapUtilsService {
  private map!: OlMap;
  private mapUuid!: string;
  private searchBar: typeof SearchNominatim | null = null;
  private layerSwitcherControl: Control | null = null;
  private baseMapLayerGroup!: LayerGroup;

  private loadLayer: LoadLayerFn;

  public initMap(mapUuid: string, view: View, loadLayer: LoadLayerFn) {
    this.loadLayer = loadLayer;
    this.mapUuid = mapUuid;
    const attribution = new Attribution({
      collapsible: true,
    });

    this.baseMapLayerGroup = new LayerGroup({
      properties: { displayInLayerSwitcher: false, rasterLayer: true },
    });

    this.map = new OlMap({
      controls: defaultControls({ attribution: false }).extend([
        new FullScreenControl({}),
        new ZoomSlider(),
        attribution,
      ]),
      view,
      layers: [this.baseMapLayerGroup],
    });

    this.map.set('sympenyId', this.mapUuid);

    return this.map;
  }

  addMapToDiv() {
    this.map.setTarget(this.mapUuid);
  }

  public changeBaseMap(config: BaseMapConfig) {
    createBaseMapLayer(this.baseMapLayerGroup, config);
    this.map.flushDeclutterItems();
  }

  addMapSearchBar() {
    if (this.searchBar) {
      return;
    }
    // Search Bar
    const searchBar: any = new SearchNominatim({
      className: 'search-bar-map',
      maxHistory: -1,
      collapsed: false,
      polygon: false,
      placeholder: 'Search address...',
      noCollapse: true,
      typing: 750,
    });
    searchBar.setProperties({ copy: null });
    searchBar.addEventListener('select', (e: any) => {
      this.map
        .getView()
        .animate({ center: e.coordinate, zoom: 18, duration: 500 });
      searchBar.collapse(true);
    });
    this.searchBar = searchBar;
    this.map.addControl(searchBar);
  }

  public addLayerSwitcher() {
    if (this.layerSwitcherControl) {
      return;
    }
    const options: any = {
      reordering: false,
      onchangeCheck: (l: Layer) => {
        const id = l.get('id');
        const layerType = l.get('layerType');
        if (!id || !layerType) {
          return;
        }

        this.loadLayer(layerType, id);
      },
    };
    const layerSwitcherControl = new LayerSwitcher(options) as Control;
    this.layerSwitcherControl = layerSwitcherControl;
    this.map.addControl(layerSwitcherControl);
  }

  addMouseHover(hoverUid: string | null, getText: (f: any) => string | null) {
    return addMouseHover(this.map, hoverUid, getText);
  }

  zoomTo(coordinates: Coordinate | null) {
    zoomToCoordinates(this.map, coordinates, { zoom: 13 });
  }

  resize() {
    //  this.map.
  }
}
