import { Map as OlMap, MapBrowserEvent, Overlay } from 'ol';
import { BehaviorSubject } from 'rxjs';

import { skipFeaturesAtPixel } from './skip-features-at-pixel';

export function addMouseHover(
  map: OlMap,
  hoverUid: string | null,
  getText: (f: any) => string | null
) {
  const showInfo$ = new BehaviorSubject<string | null>(null);
  if (!hoverUid) {
    return showInfo$;
  }
  const container = document.getElementById(hoverUid);
  const overlay = new Overlay({
    element: container,
    offset: [10, -10],
    positioning: 'bottom-left',
  });
  map.addOverlay(overlay);
  // this.info = document.getElementById('hub-info-hover');
  map.on('pointermove', (evt: MapBrowserEvent<any>) => {
    if (evt.dragging) {
      // this.info.style.display = 'none';
      return;
    }
    const pixel = map.getEventPixel(evt.originalEvent);

    let text: string | null = null;
    map.forEachFeatureAtPixel(
      pixel,
      (f) => {
        text = text || getText(f);
      },
      { layerFilter: skipFeaturesAtPixel }
    );

    if (text !== showInfo$.value) {
      showInfo$.next(text);
    }

    if (text) {
      overlay.setPosition(evt.coordinate);
    }
  });

  return showInfo$;
}
