<sympheny-select
  *ngIf="formControl"
  [formControl]="formControl"
  [dataCy]="dataCy || 'stages-select'"
  label="STAGES.stage"
  labelKey="name"
  valueKey="guid"
  placeholder="STAGES.select.stage"
  [errorMessages]="errorMessages"
  [hint]="hint"
  [multiple]="true"
  [options]="stages$ | async"
  [translateOptions]="false"
>
</sympheny-select>
