import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadParameters } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

import { AbstractTechnologyPackagesCollection } from './abstract-technology-packages.collection';

const url = 'database-technology-package/ewz';
const urlCategory = `${url}/technology-categories`;

@Injectable()
export class EwzTechnologyPackageCollectionService extends AbstractTechnologyPackagesCollection {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, url, urlCategory);
  }

  private ewz = false;

  public override load(params: LoadParameters) {
    this.ewz = params.ewz;
    if (!params.ewz) return;
    super.load(params);
  }

  public override reload(): void {
    if (!this.ewz) return;
    super.reload();
  }
}
