<mat-form-field
  [class.w-full]="config?.fullWidth ?? true"
  [attr.data-cy]="dataCy || label"
  [attr.data-testid]="dataCy || label"
>
  <mat-label *ngIf="label">{{ label | transloco }}</mat-label>
  <input
    matInput
    autocomplete="off"
    [placeholder]="placeholder | transloco"
    [formControl]="formControl"
    type="color"
    cdkFocusInitial="focus"
    [minLength]="config?.maxLength || 0"
    [maxLength]="config?.maxLength || 10000"
    [errorStateMatcher]="matcher"
    #inputElement
  />
  <mat-hint *ngIf="requiredHint">
    * {{ 'FORM.requiredHint' | transloco }}
  </mat-hint>
  <mat-error *ngIf="matcher.isErrorState">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>

<sympheny-info-button *ngIf="hint" [tooltip]="hint" [link]="hintLink">
</sympheny-info-button>
