import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import {
  addCollectionItem,
  makeState,
  removeCollectionItem,
  updateCollectionItem,
} from '@fp-tools/angular-state';
import { BackgroundService } from '@sympheny/gis/background';
import { Dataset, DatasetAddress, DataSetState } from '@sympheny/gis/datasets';
import { Co2Range } from '@sympheny/gis/utils';
import {
  Address,
  CustomerLayer,
  HubFeature,
  LayerHttpService,
  LayerType,
  NetworkGis,
} from '@sympheny/project/scenario/data-access';
import { UserState } from '@sympheny/user/data-access';
import { fromLonLat } from 'ol/proj';
import View from 'ol/View';
import {
  EMPTY,
  firstValueFrom,
  map,
  Observable,
  Subject,
  switchMap,
} from 'rxjs';

import { MapStore } from './map.store';
import { mapper, MapperDataType } from './mapper';
import { HubProcessed } from '../layer/map-hub-layer';
import { MapLayer } from '../layer/map-layer';

export interface ScenarioMapState {
  scenarioId: string | null;
  layer: Record<LayerType, MapLayer[]>;
  dataset: Dataset | null;
  datasetData: DatasetAddress[];
  address: Address | null;
  addressProcessed: HubProcessed | null;
  view: View;
  featureData: any[];
}

const initialState = (): ScenarioMapState => ({
  scenarioId: null,
  layer: {
    [LayerType.customerLayer]: [],
    [LayerType.customerScenarioLayer]: [],
    [LayerType.networkLayers]: [],
    [LayerType.hubs]: [],
  },
  dataset: null,
  datasetData: [],
  address: null,
  addressProcessed: null,
  view: new View({
    center: fromLonLat([8.2275, 46.8182]),
    maxZoom: 20,
    zoom: 8,
  }),
  featureData: [],
});

export interface DataType {
  [LayerType.hubs]: HubFeature;
  [LayerType.customerLayer]: CustomerLayer;
  [LayerType.customerScenarioLayer]: CustomerLayer;
  [LayerType.networkLayers]: NetworkGis;
}

@Injectable()
export class ScenarioMapStore implements OnDestroy, MapStore {
  private readonly state = makeState(initialState());
  private readonly destroy$ = new Subject<void>();
  private readonly services = new Map<LayerType, LayerHttpService>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly dataSetAddress$ = this.state
    .select('address')
    .pipe(
      switchMap((address) => this.getAddressInfoForSelectedAddress(address)),
    );

  constructor(
    private readonly dataSetState: DataSetState,
    private readonly userState: UserState,
    private readonly httpClient: HttpClient,
    @Optional() private readonly backgroundService: BackgroundService,
    @Optional()
    @Inject(LayerHttpService)
    layerServices: LayerHttpService<any>[],
  ) {
    layerServices?.forEach((service) => {
      this.services.set(service.layerType, service);
    });
  }
  xpublic: any;

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setScenarioGuid(scenarioId: string) {
    this.state.reset();
    this.state.set('scenarioId', scenarioId);
    if (!this.userState.isGisOn()) return;

    this.getDataForScenario(scenarioId!);
  }

  public create<TYPE extends LayerType>(
    layerType: TYPE,
    scenarioId: string,
    data: Partial<DataType[TYPE]>,
    extra: any = {},
  ) {
    this.getServiceForType(layerType)
      .create(scenarioId, data, extra)
      .then((result) => this.createLayer(layerType, result));
  }

  public update<TYPE extends LayerType>(
    layerType: TYPE,
    scenarioId: string,
    layerId: string,
    data: Partial<DataType[TYPE]>,
    extra: any = {},
  ) {
    this.getServiceForType(layerType)
      .update(scenarioId, layerId, data, extra)
      .then((result) => this.updateLayer(layerType, result));
  }

  public delete(layerType: LayerType, scenarioId: string, layerId: string) {
    this.getServiceForType(layerType)
      .delete(scenarioId, layerId)
      .then((result) => this.deleteLayer(layerType, layerId));
  }

  public changeDataset(dataset: Dataset | null) {
    this.state.set('dataset', dataset);
    this.state.set('datasetData', []);

    if (!dataset) {
      return;
    }
    this.dataSetState
      .loadDataForScenario(dataset.dataset_id, this.state.get('scenarioId')!)
      .then((data: Dataset | null) => {
        this.state.set('datasetData', data?.addresses ?? []);
      });
  }

  public selectAddress(
    address: Address | null,
    processed: HubProcessed | null,
  ) {
    this.state.set('address', address);
    this.state.set('addressProcessed', processed);
  }

  public showFeatureData(features: any[]) {
    this.state.set('featureData', features);
  }

  public selectValue<K extends keyof ScenarioMapState>(
    key: K,
  ): Observable<ScenarioMapState[K]> {
    return this.state.select(key);
  }

  public getView() {
    return this.state.get('view');
  }

  public getLayers<TYPE extends LayerType, DATA = MapperDataType[TYPE]>(
    layerType: TYPE,
  ): DATA[] {
    const layers = this.state.get('layer');

    return (layers ? layers[layerType] : []) as DATA[];
  }

  public getLayers$<TYPE extends LayerType, DATA = MapperDataType[TYPE]>(
    layerType: TYPE,
  ): Observable<DATA[]> {
    return this.state.select(
      (state) => state.layer[layerType] ?? [],
    ) as Observable<DATA[]>;
  }

  public getLayer<TYPE extends LayerType, DATA = MapperDataType[TYPE]>(
    layerType: TYPE,
    layerId: string,
  ): DATA | null {
    const layers = this.getLayers(layerType);

    return layers?.find((layer) => layer.layerId === layerId) as any;
  }

  public getLayer$(layerType: LayerType, layerId: string) {
    const layers$ = this.getLayers$(layerType);

    return layers$.pipe(
      map((layers) => layers.find((layer) => layer.layerId === layerId)),
    );
  }

  public async loadLayer(layerType: LayerType, layerId: string) {
    const layer = this.getLayer(layerType, layerId);
    if (!layer || layer.features || !layer.url) {
      return;
    }

    const features = await firstValueFrom(this.httpClient.get(layer.url));

    this.updateLayer(layerType, features);
    layer.setData(features);
    return;
  }

  public toggleCo2Range(toggle: boolean) {
    const layers = this.state.get('layer');

    Object.values(layers).forEach((layer) =>
      layer.forEach((l) =>
        toggle ? l.setColorRange('co2_range', Co2Range) : l.setStyle(null),
      ),
    );
  }

  private getServiceForType(layerType: LayerType) {
    const service = this.services.get(layerType);

    if (!service) {
      throw new Error(`No service for ${layerType}`);
    }

    return service;
  }

  private getAddressInfoForSelectedAddress(address: Address | null) {
    if (!address) {
      return EMPTY;
    }

    return this.state.select('datasetData').pipe(
      map((datasetData) => {
        const { egid } = address;
        return datasetData?.find((a) => a.egid === egid) ?? null;
      }),
    );
  }

  private getDataForScenario(scenarioId: string) {
    const types = [
      LayerType.customerScenarioLayer,
      LayerType.customerLayer,
      LayerType.networkLayers,
      LayerType.hubs,
    ];
    return types.map((type) => this.getAllLayers(type, scenarioId));
  }

  private getAllLayers(type: LayerType, scenarioId: string) {
    return firstValueFrom(this.getServiceForType(type).list(scenarioId)).then(
      (layers) => this.addLayers(type, layers),
    );
  }

  private deleteLayer(layerType: LayerType, layerId: string) {
    const existingLayer = this.getLayers(layerType) ?? [];
    this.state.set('layer', {
      ...this.state.get('layer'),
      [layerType]: removeCollectionItem('layerId', existingLayer, layerId),
    });
  }

  private createLayer(layerType: LayerType, layer: any) {
    if (layer.job_id) {
      this.backgroundService?.registerJobId(layer.job_id, layer.name, () => {
        this.getAllLayers(LayerType.hubs, this.state.get('scenarioId'));
      });
      return;
    }

    const builder = mapper[layerType];

    const mapLayer = new builder(layer);

    const existingLayer = this.getLayers(layerType) ?? [];
    this.state.set('layer', {
      ...this.state.get('layer'),
      [layerType]: addCollectionItem(existingLayer, mapLayer),
    });
    this.loadLayer(layerType, layer);
  }

  private updateLayer(layerType: LayerType, layer: any) {
    if (layer.job_id) {
      this.backgroundService?.registerJobId(layer.job_id, layer.name, () => {
        this.getAllLayers(LayerType.hubs, this.state.get('scenarioId'));
      });
      return;
    }
    const builder = mapper[layerType];

    const mapLayer: MapLayer = new builder(layer);

    const existingLayer = this.getLayers(layerType) ?? [];
    this.state.set('layer', {
      ...this.state.get('layer'),
      [layerType]: updateCollectionItem('layerId', existingLayer, mapLayer),
    });
    this.loadLayer(layerType, layer);
  }

  private addLayers(layerType: LayerType, layers: any[]) {
    const builder = mapper[layerType];

    const mapLayers = layers ? layers.map((layer) => new builder(layer)) : [];

    this.state.set('layer', {
      ...this.state.get('layer'),
      [layerType]: mapLayers,
    });
  }
}
