import { TransformFnParams } from 'class-transformer/types/interfaces';
import { format } from 'date-fns';
export const transformToFixed =
  (fractionDigits = 2) =>
  ({ value }: TransformFnParams) => {
    if (value) {
      return Number(value).toFixed(fractionDigits);
    }
    return 0;
  };

export const roundNumber = (value: number, fractionDigits = 2) => {
  if (value) {
    return parseFloat(Number(value).toFixed(fractionDigits));
  }
  return 0;
};

export const transformToDigits =
  (fractionDigits = 2) =>
  ({ value }: TransformFnParams) => {
    return roundNumber(value, fractionDigits);
  };

export const transformToDate = ({ value }: TransformFnParams) => {
  return format(value, 'dd-MM-yyyy');
};
