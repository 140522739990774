import { Injectable } from '@angular/core';
import { filter, ReplaySubject } from 'rxjs';

import { Analysis, Project, ProjectDetails } from '../model';

export enum Events {
  UPDATE_PROJECT,
  UPDATE_ANALYSIS,
}

export interface Action {
  event: Events;
  project?: Project;
  analysis?: Analysis;
}

@Injectable({ providedIn: 'root' })
export class ProjectEvents {
  private readonly actions = new ReplaySubject<Action>(1);
  public readonly actions$ = this.actions.asObservable();

  public updateProject(project: Project | ProjectDetails) {
    this.actions.next({
      event: Events.UPDATE_PROJECT,
      project,
    });
  }

  public updateAnalysis(analysis: Analysis) {
    this.actions.next({
      event: Events.UPDATE_ANALYSIS,
      analysis,
    });
  }

  public getProjectEvents(projectId: string) {
    return this.limitActions(
      (action) => action.project?.projectGuid === projectId
    );
  }

  private limitActions(filterFn: (action: Action) => boolean) {
    return this.actions$.pipe(filter((action) => filterFn(action)));
  }
}
