import { gisStyles } from '@sympheny/gis/utils';
import { LayerType } from '@sympheny/project/scenario/data-access';
import { nullOrUndefined } from '@sympheny/utils/rxjs';
import { FeatureCollection } from 'geojson';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';

import { createLayer } from './create-layer';

export const defaultLayerColor = '#581f69';

export class MapLayer {
  public color: string;
  public style: Style;
  public layer?: VectorLayer<any>;
  public features: Feature<any>[];
  public url?: string;
  public visible = true;

  constructor(
    public readonly layerId: string,
    public readonly layerType: LayerType,
    public readonly title: string,
    public readonly editable = false
  ) {}

  public addFeatures(features: FeatureCollection | undefined | null) {
    if (!features) {
      return;
    }
    this.features = new GeoJSON().readFeatures(features, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
  }

  public addFeature(features: Feature<any> | undefined | null) {
    if (!features) {
      return;
    }
    this.features = new GeoJSON().readFeatures(features, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
  }

  public createLayer() {
    const vectorSource = new VectorSource({
      features: this.features,
    });

    new VectorLayer({
      source: vectorSource,
    });

    const layer = createLayer({
      features: this.features,
      title: this.title,
      id: this.layerId,
      visible: this.visible,
      layerType: this.layerType,
    });
    layer?.setStyle(this.style);

    this.layer = layer;

    return layer;
  }

  public setColorRange(key: any, rangeStyle: Record<string, Style>) {
    this.features?.forEach((feature) => {
      const value = feature.get(key);
      if (nullOrUndefined(value)) {
        feature.setStyle(this.style);
        return;
      }

      const style = rangeStyle[value] ?? this.style;

      feature.setStyle(style);
    });
  }

  public changeColor(color: string | null | undefined) {
    this.color = color ?? defaultLayerColor;
    this.setStyle(
      new Style({
        stroke: new Stroke({
          color: this.color,
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.1)',
        }),
      })
    );
  }

  public setStyle(style: Style | null | undefined) {
    this.style = style ?? gisStyles.savedFeatures;

    this.layer?.setStyle(this.style);
    this.features?.forEach((feature) => feature.setStyle(this.style));
  }

  public get geoJson() {
    const parser = new GeoJSON();
    return parser.writeFeaturesObject(this.features, {
      featureProjection: 'EPSG:3857',
    });
  }

  public customAction(params: any) {
    return this;
  }

  public setData(data: any) {
    console.warn('no implementation');
  }
}
