import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AdditionalConfig,
  FormatValueConfig,
  mapToSchema,
} from '@sympheny/format-value';
import {
  ScenarioState,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import {
  TableAction,
  TableColumn,
  TableDefinedAction,
} from '@sympheny/ui/table';
import { of, Observable } from 'rxjs';
import { ZodTypeAny } from 'zod';

import {
  CrudTableAction,
  CrudTableDetailComponent,
} from '../crud-table-detail/crud-table-detail.component';

export type ColumnConfig<ITEM> = {
  summaryFields: (keyof ITEM)[];
  fieldConfig: Record<keyof ITEM, FormatValueConfig<ITEM>>;
  schema: ZodTypeAny;
  additionalConfig?: AdditionalConfig<ITEM>;
};
@Component({
  selector: 'sympheny-crud-table',
  templateUrl: './crud-table.component.html',
  styleUrls: ['./crud-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CrudTableDetailComponent, AsyncPipe],
})
export class CrudTableComponent<ITEM> {
  @Input() public actions: CrudTableAction[] = [];
  @Input() public items: ITEM[];
  @Input() public tooltip?: string;

  @Input() public columns: TableColumn<ITEM>[];
  @Input() public definedActions?: TableDefinedAction<ITEM>;
  @Input() public customActions?: TableAction<ITEM>[];
  @Input() public subject: string;

  @Input() public labelParams: Record<string, any> = {};

  public readonly disabled$ = this.scenarioState.isLocked$;
  public readonly readonly$ = this.scenarioState.readonly$;
  public readonly canEdit$ = this.scenarioState.canEdit$;

  public loading$: Observable<boolean> = of(false);

  constructor(private readonly scenarioState: ScenarioStore) {}

  @Input() public set scenarioStoreField(field: keyof ScenarioState) {
    if (!field) {
      return;
    }
    this.loading$ = this.scenarioState.isLoading(field);
  }

  @Input() public set columnConfig(columnConfig: ColumnConfig<ITEM>) {
    if (!columnConfig) {
      this.columns = [];
      return;
    }
    const { summaryFields, fieldConfig, schema } = columnConfig;

    this.columns = mapToSchema(summaryFields, fieldConfig, schema, {
      ...(columnConfig.additionalConfig ?? {}),
    });
  }
}
