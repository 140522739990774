import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Dataset, DatasetSummary } from './dataset.model';
import { DatasetService } from './dataset.service';

@Injectable()
export class DataSetState {
  private readonly datasets = new BehaviorSubject<DatasetSummary[]>([]);
  readonly datasets$ = this.datasets.asObservable();

  constructor(private readonly datasetService: DatasetService) {}

  clear() {
    this.datasets.next([]);
  }

  load() {
    if (this.datasets.value.length) {
      return;
    }
    this.reload();
  }

  private reload() {
    this.datasetService.list().then((datasets) => this.datasets.next(datasets));
  }

  create(dataSet: Partial<Dataset>) {
    return this.datasetService.create(dataSet).then((data) => this.reload());
  }

  delete(dataSet: Dataset) {
    this.datasetService.delete(dataSet.dataset_id).then((data) => {
      const datasets = this.datasets.value.filter(
        (d) => d.dataset_id !== dataSet.dataset_id
      );
      this.datasets.next(datasets);
    });
  }

  loadDataForScenario(dataSetId: string, scenarioId: string) {
    return this.datasetService.loadDataForScenario(dataSetId, scenarioId);
  }

  getOne(dataSetId: string) {
    return this.datasets.value.find((d) => d.dataset_id === dataSetId);
  }
}
