import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { PredefinedEnergyCarriersCollection } from './predefined-energy-carriers.collection';

@NgModule({
  imports: [CommonModule],
})
export class ProjectEnergyCarrierDataAccessModule {
  public static forRoot(): ModuleWithProviders<ProjectEnergyCarrierDataAccessModule> {
    return {
      ngModule: ProjectEnergyCarrierDataAccessModule,
      providers: [PredefinedEnergyCarriersCollection],
    };
  }
}
