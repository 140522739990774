<a
  *ngIf="link"
  [routerLink]="link"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{ exact: true }"
  [queryParams]="params"
  class="sidenav__item"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<a *ngIf="href" [href]="href" target="_blank" class="sidenav__item">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<button *ngIf="!href && !link" class="sidenav__item !w-[calc(100%-20px)]">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</button>
<ng-template #content>
  <mat-icon class="sidenav__item--icon" fontSet="material-icons-outlined"
    >{{ icon }}
  </mat-icon>
  <span class="sidenav__item--label" *ngIf="opened">
    <ng-content></ng-content>
  </span>
</ng-template>
