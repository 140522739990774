import * as mapboxgl from 'mapbox-gl';
import MVT from 'ol/format/MVT';
import LayerGroup from 'ol/layer/Group';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { apply } from 'ol-mapbox-style';

import {
  attributions,
  openLayerStyles,
  OpenLayerStyles,
} from './base-map.const';

export function createMapboxBaseLayer(
  baseMapLayerGroup: LayerGroup,
  style: OpenLayerStyles,
) {
  const styleAttributions = attributions[style];
  const styleUrl = openLayerStyles[style];
  const accessToken = mapboxgl.accessToken;

  const mapboxLayer = new VectorTileLayer({
    declutter: true,
    source: new VectorTileSource({
      attributions: styleAttributions,
      format: new MVT(),
    }),
    properties: {
      displayInLayerSwitcher: false,
      rasterLayer: true,
      skipFeatureAtPixel: false,
    },
  });

  apply(baseMapLayerGroup, styleUrl, {
    accessToken,
  });

  baseMapLayerGroup.getLayers().push(mapboxLayer);

  return baseMapLayerGroup;
}
