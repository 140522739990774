import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';
import { AlertComponent } from '@sympheny/ui/alert';
import { Feature } from 'ol';

import {
  SelectFeaturesService,
  SelectMethod,
  SelectMode,
} from '../../services/select-features.service';

@Component({
  selector: 'sympheny-select-features',
  templateUrl: './select-features.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    NgIf,
    TranslocoPipe,
    AlertComponent,
  ],
})
export class SelectFeaturesComponent implements AfterViewInit {
  @Output() public readonly selectedFeatures = new EventEmitter<Feature[]>();

  public deselectFeatures = false;
  public selectType: SelectMode = 'CLICK';
  public isHubSelected = false;

  constructor(private readonly selectFeatureService: SelectFeaturesService) {}

  @Input() public set hubId(hubId: string) {
    this.selectFeatureService.setHubId(hubId);
    this.isHubSelected = !!hubId;
    this.changeMethod('ADD');
    this.changeMode('CLICK');
  }

  public ngAfterViewInit(): void {
    this.selectFeatureService.addControlBar(
      document.getElementById('selectDeselectControlBar'),
    );
  }

  public changeMode(type: SelectMode) {
    this.selectType = type;
    this.selectFeatureService.changeMode(type);
  }

  public changeMethod(method: SelectMethod) {
    this.deselectFeatures = method === 'DELETE';
    this.selectFeatureService.changeMethod(method);
  }

  public selectAllFeatures() {
    this.selectFeatureService.selectAll();
  }
}
