import z from 'zod';

export const ResutlExportSchema = z.object({
  name: z.string(),
  stage: z.string(),
  hub: z.string(),
  capacity: z.number().nullish(),
  capacityBinary: z.number().nullish(),
  monthlyCapacity: z.array(z.number()),
  monthlyEnergy: z.array(z.number()),
  exportEnergy: z.array(z.number()),
  capacityCost: z.number().nullish(),
  energyProfit: z.number().nullish(),
  co2: z.number().nullish(),
  om: z.number().nullish(),
});

export type ResutlExport = z.infer<typeof ResutlExportSchema>;
