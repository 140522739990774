import { Injectable, OnDestroy } from '@angular/core';
import * as ol from 'ol';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MapLayer } from './map-layer';
import { MapConfiguration } from '../model/configuration';
import { MAP_DEFAULTS } from '../model/map_defaults';
import { MapStore } from '../store/map.store';

@Injectable()
export abstract class DrawLayerService implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();
  protected map!: ol.Map;
  protected config!: MapConfiguration;

  constructor(protected readonly scenarioMapStore: MapStore) {}

  public initMap(map: ol.Map, config: MapConfiguration) {
    if (this.map) {
      this.destroy$.next();
    }
    this.map = map;
    this.config = config;

    this.init();

    merge(...this.getEffects())
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  protected abstract init(): void;

  protected abstract getEffects(): Observable<void>[];

  public abstract getDownloadableLayers(): VectorLayer<any>[];

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected zoomToCenter(coordinates: [number, number]) {
    if (!coordinates || !coordinates[0]) {
      return;
    }
    this.map.getView().animate({
      center: coordinates,
      zoom: MAP_DEFAULTS.defaultZoom,
      duration: 500,
    });
  }

  protected setLayer(vectorLayer: VectorLayer<any>, layers: MapLayer[]) {
    vectorLayer.getSource().clear();
    layers.forEach((layer) =>
      vectorLayer.getSource().addFeatures(layer.features),
    );
  }

  protected addToLayer(vectorLayer: VectorLayer<any>, layer: MapLayer) {
    if (!layer.features || layer.features?.length === 0) {
      return;
    }
    vectorLayer.getSource().addFeatures(layer.features);
  }

  protected setLayerGroup(
    layerGroup: LayerGroup | null,
    layers: MapLayer[],
    loadLayer = false,
  ) {
    if (layerGroup === null) {
      return;
    }
    layerGroup.getLayers().clear();
    layers.forEach((layer) => {
      if (loadLayer) {
        this.scenarioMapStore.loadLayer(layer.layerType, layer.layerId);
      }
      layerGroup?.getLayers().push(layer.createLayer());
    });
  }
}
