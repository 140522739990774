import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CrudService } from '@sympheny/project/data-access';

import { EnergyDemandV1Service } from './energy-demands/energy-demand-v1.service';
import { EnergyDemandV2Service } from './energy-demands/energy-demand-v2.service';
import { EnergyDemandService } from './energy-demands/energy-demand.service';
import { CustomerLayerService } from './layers/customer-layer.service';
import { HubGisService } from './layers/hub-gis.service';
import { LayerHttpService } from './layers/layer-http.service';
import { NetworkService } from './layers/network.service';
import { ScenarioCustomerLayerService } from './layers/scenario-customer-layer.service';
import { OtherParameterService } from './other-parameters/other-parameter.service';
import { ProfileService } from './profile';
import { ScenarioVariantService } from './scenario-variant/scenario-variant.service';
import { StageService } from './stage/stage.service';

@NgModule({
  imports: [CommonModule],
})
export class ProjectScenarioDataAccessModule {
  public static forRoot(): ModuleWithProviders<ProjectScenarioDataAccessModule> {
    return {
      ngModule: ProjectScenarioDataAccessModule,
      providers: [
        OtherParameterService,
        {
          provide: LayerHttpService,
          useClass: CustomerLayerService,
          multi: true,
        },
        {
          provide: LayerHttpService,
          useClass: ScenarioCustomerLayerService,
          multi: true,
        },
        {
          provide: LayerHttpService,
          useClass: NetworkService,
          multi: true,
        },
        {
          provide: LayerHttpService,
          useClass: HubGisService,
          multi: true,
        },

        StageService,
        {
          provide: CrudService,
          useExisting: StageService,
          multi: true,
        },

        ScenarioVariantService,
        {
          provide: CrudService,
          useExisting: ScenarioVariantService,
          multi: true,
        },

        ProfileService,
        {
          provide: CrudService,
          useExisting: ProfileService,
          multi: true,
        },

        EnergyDemandV1Service,
        EnergyDemandV2Service,
        EnergyDemandService,
        {
          provide: CrudService,
          useExisting: EnergyDemandService,
          multi: true,
        },
      ],
    };
  }
}
