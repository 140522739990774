import { GraphDataImpl } from './_grap-data.impl';
import {
  Graph,
  GraphData,
  SankeyData,
  Node,
  Link,
  GraphExtend,
  NodeImpl,
  LinkImpl,
} from '../model';

export const computeNodeLinksInitial = (
  data: Readonly<SankeyData>,
  settings: Pick<Graph, 'getNodeID' | 'settings'>,
  extend: GraphExtend,
): GraphData => {
  const graphData = new GraphDataImpl(extend);

  data.nodes

    // First sort so we ensure the same, if we don't then the hub diagram may shift while resizing the window
    //  .sort((n1, n2) => (n1.sortBy < n2.sortBy ? -1 : 1))
    .forEach((node, index) => {
      graphData.addNode(
        settings.getNodeID(node),
        new NodeImpl({ ...node, index } as Node),
      );
    });

  data.links.forEach((link, index) => {
    graphData.addLink(new LinkImpl({ ...link, index } as Link));
  });

  return graphData;
};
