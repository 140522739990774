import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sympheny-loader',
  templateUrl: './loader.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'relative justify-center flex' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class LoaderComponent {
  @Input() public loading: boolean;
}
