import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgressService } from '@sympheny/ui/progress';
import { EnvironmentService } from '@sympheny/utils/environment';
import {
  catchError,
  concatMap,
  EMPTY,
  finalize,
  Subject,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
// Generated by https://quicktype.io

export interface Job {
  job_id: string;
  created_at: string;
  updated_at: string;
  is_done: boolean;
  type: string;
  job_input: null;
  job_result: null;
  error: null;
}

const TIMEOUT = 2000;

const JOB_TYPE = 'background';

@Injectable()
export class BackgroundService {
  private readonly listenToJobIds = new Map<string, () => void>();
  private listenToChanges = false;
  private readonly destroy$ = new Subject<void>();

  private readonly backgroundUrl = `${this.environmentService.gisApi}background`;

  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
    private readonly progressService: ProgressService
  ) {
    this.initialJobs();
  }

  private initialJobs() {
    this.progressService.getForType(JOB_TYPE).forEach((job) =>
      this.registerJobId(job.id, job.name, () => {
        console.log('IMPLEMENT ME');
      })
    );
  }

  private checkForChanges() {
    if (this.listenToChanges) {
      return;
    }

    this.listenToChanges = true;

    timer(TIMEOUT, TIMEOUT)
      .pipe(
        concatMap(() => this.checkIfComplete()),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        }),
        takeUntil(this.destroy$),
        finalize(() => {
          this.listenToChanges = false;
        })
      )
      .subscribe();
  }

  private checkIfComplete() {
    return this.http
      .get<Job[]>(this.backgroundUrl)
      .pipe(tap((jobs) => this.validateJobs(jobs)));
  }

  private validateJobs(jobs: Job[]) {
    const ids = Array.from(this.listenToJobIds.keys());

    ids.forEach((jobId) => {
      const findJob = jobs.find((j) => j.job_id === jobId);

      if (!findJob || !findJob.is_done) {
        return;
      }

      this.listenToJobIds.get(jobId)?.();
      this.listenToJobIds.delete(jobId);
      if (findJob.error) {
        this.progressService.setError(jobId);
      } else {
        this.progressService.setDone(jobId);
      }
    });

    if (this.listenToJobIds.size === 0) {
      this.destroy$.next();
    }
  }

  public registerJobId(jobId: string, name: string, doneFn?: () => void) {
    this.listenToJobIds.set(jobId, () => {
      if (doneFn) doneFn();
    });

    this.progressService.registerProgress({
      type: JOB_TYPE,
      id: jobId,
      name,
      done: false,
      error: false,
    });

    this.checkForChanges();
  }
}
