import { Injectable } from '@angular/core';
import { Collection } from '@fp-tools/angular-state';
import {
  ResponseStatusCode,
  ResponseStatusMessages,
  SymphenyErrorHandler,
} from '@sympheny/utils/data-access';
import { EMPTY, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';

import { Scenario } from '../../model';
import { ScenarioService } from '../../service';

@Injectable()
export class AnalysisScenarioState extends Collection<
  Scenario,
  'scenarioGuid'
> {
  protected readonly idKey = 'scenarioGuid';

  protected fetchApi(): Observable<Scenario[]> {
    return of([]);
  }

  protected override createApi(
    { scenarioName }: Partial<Scenario> & { scenarioName: string },
    analysisGuid: string
  ): Observable<Scenario> {
    return this.scenarioService.createScenario(analysisGuid, scenarioName).pipe(
      catchError((error) => {
        this.errorHandler.handleEspError(error, {
          code: ResponseStatusCode.ESP_400_STATUS,
          message: ResponseStatusMessages.SCENARIO_UNIQUE_NAME,
        });
        return EMPTY;
      })
    );
  }

  protected override saveApi({
    scenarioGuid,
    scenarioName,
  }: Partial<Scenario> & {
    scenarioGuid: string;
    scenarioName: string;
  }): Observable<Scenario> {
    return this.scenarioService.renameScenario(scenarioGuid, scenarioName);
  }

  protected override deleteApi(scenarioGuid: string): Observable<string> {
    return this.scenarioService
      .deleteScenario(scenarioGuid)
      .pipe(mapTo(scenarioGuid));
  }

  public async copy(scenarioGuid: string) {
    const newScenario = await firstValueFrom(
      this.scenarioService.copyScenario(scenarioGuid)
    );

    super.createItem(newScenario);

    return newScenario;
  }

  public override getData(): Scenario[] | null {
    return super.getData();
  }

  constructor(
    private readonly scenarioService: ScenarioService,
    private readonly errorHandler: SymphenyErrorHandler
  ) {
    super();
  }

  public get scenarios() {
    return this.getData() ?? [];
  }

  private getScenario(scenarioGuid: string) {
    return this.scenarios.find((s) => s.scenarioGuid === scenarioGuid);
  }
}
