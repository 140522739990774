import z from 'zod';

export const ResultCostSchema = z.object({
  stage: z.string(),
  netPresent: z.number().nullish(),
  annualized: z.number().nullish(),
  importCost: z.number().nullish(),
  exportRevenue: z.number().nullish(),
  investment: z.number().nullish(),
  replacement: z.number().nullish(),
  om: z.number().nullish(),
  salvageRevenue: z.number().nullish(),
  demandRevenue: z.number().nullish(),
});

export type ResultCost = z.infer<typeof ResultCostSchema>;
