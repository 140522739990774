<mat-form-field [class.w-full]="config?.fullWidth ?? true">
  <mat-label *ngIf="label">{{ label | transloco }}</mat-label>
  <input
    matInput
    autocomplete="off"
    [attr.data-cy]="dataCy || label"
    [placeholder]="placeholder | transloco"
    [formControl]="autoCompleteFormControl"
    cdkFocusInitial="focus"
    type="text"
    [errorStateMatcher]="matcher"
    [matAutocomplete]="auto"
    #inputElement
  />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn()"
  >
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option"
    >
      <span [innerHTML]="option.label"></span>
    </mat-option>
  </mat-autocomplete>

  <sympheny-input-suffix matSuffix [suffix]="suffix" [suffixLabel]="suffixLabel"
    ><ng-content select="[input-suffix]"></ng-content
  ></sympheny-input-suffix>
  <mat-hint *ngIf="requiredHint">
    * {{ 'FORM.requiredHint' | transloco }}
  </mat-hint>
  <mat-error *ngIf="matcher.isErrorState">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>

<sympheny-info-button *ngIf="hint" [tooltip]="hint" [link]="hintLink">
</sympheny-info-button>
