import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  IconButtonConfiguration,
  IconButtonListConfiguration,
} from './icon-button-list.model';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'sympheny-icon-button-list',
  templateUrl: './icon-button-list.component.html',
  styleUrls: ['./icon-button-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, IconButtonComponent],
})
export class IconButtonListComponent {
  @Input() public configuration!: IconButtonListConfiguration;

  public onAction(button: IconButtonConfiguration) {
    button.action();
  }
}
