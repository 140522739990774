import { Injectable } from '@angular/core';
import { BannerConfig, BannerService } from '@sympheny/ui/banner';
import { Account, UserState } from '@sympheny/user/data-access';
import { firstValueFrom, merge, Observable, tap } from 'rxjs';

import { DismissService } from './dismiss.service';
import { MaintenanceInfoComponent } from '../maintenance-info/maintenance-info.component';
import { ShowGtcComponent } from '../show-gtc/show-gtc.component';
import { UserGuideInfoComponent } from '../user-guide-info/user-guide-info.component';

type banners = keyof Pick<
  Account,
  'showMaintenanceInfo' | 'showGtc' | 'showUserGuide'
>;

@Injectable()
export class BannerEffects {
  private initialized = false;

  private readonly bannerConfiguration: Record<
    banners,
    Partial<BannerConfig> & { dismissCall: () => Observable<any>; uid: string }
  > = {
    showUserGuide: {
      color: 'default',
      component: UserGuideInfoComponent,
      dismissCall: () => this.dismissService.dismissUserGuide(),
      uid: 'default',
    },
    showGtc: {
      color: 'error',
      component: ShowGtcComponent,
      disableDismiss: true,
      dismissCall: () => this.dismissService.acceptGTC(),
      uid: 'error',
    },
    showMaintenanceInfo: {
      color: 'default',
      component: MaintenanceInfoComponent,
      dismissCall: () => this.dismissService.dismissMaintenanceInfo(),
      uid: 'showMaintenanceInfo',
    },
  };

  private readonly bannerFields = Object.keys(
    this.bannerConfiguration,
  ) as banners[];

  constructor(
    private readonly bannerService: BannerService,
    private readonly userState: UserState,
    private readonly dismissService: DismissService,
  ) {}

  public init() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    merge(
      ...this.bannerFields.map((field) => this.showHideBanner(field)),
    ).subscribe();
  }

  private showHideBanner(key: banners) {
    return this.userState
      .selectAccount(key)
      .pipe(
        tap((showBanner) =>
          showBanner ? this.showBanner(key) : this.hideBanner(key),
        ),
      );
  }

  public dismiss(key: banners) {
    const configuration = this.bannerConfiguration[key];
    firstValueFrom(configuration.dismissCall()).then(() => {
      this.userState.updateAccountField(key, false);
    });

    this.hideBanner(key);
  }

  private showBanner(key: banners) {
    const configuration = this.bannerConfiguration[key];

    this.bannerService.openBanner({
      ...configuration,
      dismiss: () => this.dismiss(key),
    } as any);
  }

  private hideBanner(key: banners) {
    const configuration = this.bannerConfiguration[key];
    this.bannerService.dismissBanner(configuration.uid);
  }
}
