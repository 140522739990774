<div class="m-8">
  <h1>
    {{ data.title | transloco }}
  </h1>
  <markdown
    class="variable-binding prose"
    [data]="data.content"
    [disableSanitizer]="true"
  ></markdown>
  <sympheny-dialog-actions [hideSubmit]="true" cancelLabel="Close">
  </sympheny-dialog-actions>
</div>
