import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DismissService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {}

  protected readonly base = this.environmentService.getValue('base');
  /**
   * Dismiss user guide
   */
  dismissUserGuide(): Observable<any> {
    return this.http.put(`${this.base}dashboard/user-guide`, {});
  }

  /**
   * Dismiss maintenance info
   */
  dismissMaintenanceInfo(): Observable<any> {
    return this.http.put(`${this.base}dashboard/maintenance-info`, {});
  }

  /**
   * Accept GTC
   */
  acceptGTC(): Observable<any> {
    return this.http.put(`${this.base}dashboard/gtc`, {});
  }
}
