<ng-template [cdkPortalOutlet]></ng-template>
<ng-container *transloco="let t">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'component'"></ng-container>
    <ng-container *ngSwitchCase="'template'"></ng-container>
    <ng-container *ngSwitchCase="'datetime'">
      <span>
        {{ value | date: 'medium' }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'translate'">
      <ng-container *transloco="let t">
        {{ t(translatePrefix + value, format.params) }}
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'array'">
      <p *ngFor="let v of value; let i = index">
        <ng-container *ngIf="i < 5">
          {{ v }}
        </ng-container>
        <span *ngIf="i === 5" [matTooltip]="tooltip" class="pre-line">...</span>
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="'action'">
      <sympheny-icon-button
        *ngIf="value"
        [tooltip]="value.tooltip"
        [tooltipParams]="value.tooltipParams"
        [icon]="value.icon"
        (action)="value.action(element)"
        [color]="value.color || 'primary'"
        [dataCy]="value.dataCy ?? value.icon"
      >
        {{ value.label }}
      </sympheny-icon-button>
    </ng-container>
    <ng-container *ngSwitchDefault> {{ value }} </ng-container>
  </ng-container>
</ng-container>
