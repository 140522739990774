import { Graph, Node } from '../../model';

export const EhubComputeNodePosition = (graph: Graph<any, any>) => {
  const { graph: data } = graph;

  const { extend } = data;
  const contentHeight = extend.y1 - extend.y0;
  const contentWidth = extend.x1 - extend.x0;
  const totalColumns = data.maxColumns() + 1;

  const nodeDistanceY = contentHeight / data.maxNodesInColumns();
  const nodeDistanceX = contentWidth / totalColumns;

  const firstPOS = 50;
  // let nextX =  firstPOS;

  const aggregateNodes: Node[] = [];

  data.forEachColumn((nodes: Node[], columnIndex) => {
    nodes.forEach((node) => {
      const nextY = extend.y0 + nodeDistanceY * node.row;
      let nextX = firstPOS + nodeDistanceX * node.column;
      if (node.sameSourceTarget) {
        nextX = nextX - node.nodeWidth / 2;
      }

      node.setCenterNodeY(nextY);
      node.setCenterNodeX(nextX);
      if (node.aggregate) aggregateNodes.push(node);
    });
  });

  return;
};
