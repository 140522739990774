import {
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { formatNumberToPrintFormat } from '@sympheny/utils/format';

import { EhubType } from './ehub.model';
import {
  EhubFirstColumnAllowed,
  EhubLastColumnAllowed,
  EhubLinkColor,
  EhubLinkTooltip,
  EhubMinHorizontalNodePadding,
  EhubMinVerticalNodePadding,
  EhubNodeColor,
  EhubNodeDimension,
  EhubNodeText,
  EhubNodeTooltip,
  EhubSortBy,
} from './ehub.utils';
import { drawEhub } from '../utils/draw-ehub';
import { drawSankeyCirculair } from '../utils/draw-sankey';

@Component({
  selector: 'sympheny-ehub',
  templateUrl: './ehub.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'w-full h-full block' },
  standalone: true,
})
export class EhubComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public type: EhubType;
  @Input() public dataset: any;
  @Input() public ignoreZeroCapcity = false;

  @ViewChild('chart', { static: true })
  public readonly chart: ElementRef;
  @ViewChild('tooltip', { static: true })
  public readonly tooltip: ElementRef;
  private readonly observer: ResizeObserver;
  private svgNode: SVGElement;
  private width: number;
  private height: number;

  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    this.observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      this.resize(entries[0]!.contentRect);
    });
  }

  public ngOnChanges() {
    this.drawData();
  }

  public ngOnInit(): void {
    this.observer.observe(this.chart.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.unobserve(this.chart.nativeElement);
    this.observer.disconnect();
  }
  private resize(size: DOMRectReadOnly) {
    const padding = 25;
    const newWidth = size.width - padding * 2;
    const newHeight = size.height - padding * 2;

    if (newWidth === this.width && newHeight === this.height) return;
    this.width = newWidth;
    this.height = newHeight;
    this.drawData();
  }

  public restore() {
    this.drawData();
  }

  public download() {
    const serializer = new XMLSerializer();
    const svgBlob = new Blob([serializer.serializeToString(this.svgNode)], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(svgBlob);
    downloadLink.download = 'energy-diagram.svg';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private drawData() {
    const width = this.width;
    const height = this.height;

    if (!this.dataset || !this.chart || !width || !height) return;
    if (height < 0) {
      console.warn('no height defined');
      return;
    }

    this.svgNode && this.chart.nativeElement.removeChild(this.svgNode);

    const dataset = {
      nodes: this.dataset.nodes
        .map((n) => ({ ...n, sortBy: EhubSortBy(n) }))
        .sort((n1, n2) => (n1.sortBy < n2.sortBy ? -1 : 1)),
      links: this.dataset.links,
    };

    if (this.type === 'ehub')
      this.svgNode = drawEhub(
        dataset,
        {
          useVirtualRoutes: true,

          nodeText: EhubNodeText,
          nodeColor: EhubNodeColor(!this.ignoreZeroCapcity),
          nodeTooltip: EhubNodeTooltip,
          linkTooltip: EhubLinkTooltip,
          linkColor: EhubLinkColor(!this.ignoreZeroCapcity),
          settings: {
            width,
            height,
            minNodePaddingVertical: EhubMinVerticalNodePadding,
            minNodePaddingHorizontal: EhubMinHorizontalNodePadding,
            nodeDimensions: EhubNodeDimension,
            nodeAllowedInFirstColumn: EhubFirstColumnAllowed,
            nodeAllowedInLastColumn: EhubLastColumnAllowed,
          },
        },
        this.tooltip,
      );
    if (this.type === 'sankey') {
      // return;
      this.svgNode = drawSankeyCirculair(
        this.dataset,
        {
          useVirtualRoutes: true,
          nodeText: (d: any) => d.edgeLabel ?? d.label ?? d.name,
          nodeColor: (d) => d.color,
          nodeTooltip: (d) => `
          ${d.edgeLabel}<br />
          `,
          linkTooltip: (link, source, target) => `${source.edgeLabel} -> ${
            target.edgeLabel
          }
          <br />
          ${formatNumberToPrintFormat(link.value)} ${link.unit.unit}
          
          `,
          linkColor: (d: any) => d.color,
          settings: {
            width,
            height,
          },
        },
        this.tooltip,
      );
    }

    this.svgNode && this.chart.nativeElement.appendChild(this.svgNode);
  }
}
