<div class="flex justify-between execution-results">
  <form [formGroup]="form" *ngIf="inputData() as inputData">
    <div class="flex gap-2 items-center flex-end">
      <span>{{ 'SCENARIO.energy_diagram.select' | transloco }}:</span>
      <sympheny-select
        class="w-38"
        [options]="inputData.stages"
        formControlName="stage"
        valueKey="guid"
        labelKey="name"
        [translateOptions]="false"
        (selectionChange)="changeFilter()"
      ></sympheny-select>
      <sympheny-select
        class="w-38"
        [options]="inputData.hubs"
        valueKey="hubGuid"
        labelKey="hubName"
        formControlName="hub"
        [translateOptions]="false"
        (selectionChange)="changeFilter()"
      ></sympheny-select>
    </div>
  </form>
</div>
<ng-template #displayHubs>
  <div class="w-full flex-grow pt-2 h-[500px] min-h-[500px]">
    <sympheny-ehub
      *ngIf="dataset() | async as dataset"
      type="ehub"
      [dataset]="dataset"
      [ignoreZeroCapcity]="true"
    ></sympheny-ehub>
  </div>
</ng-template>
<p *ngIf="noData() | async; else displayHubs" class="no-hubs">
  {{ 'HUBS.description' | transloco }}
</p>
