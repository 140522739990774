import { NgIf, NgFor } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Analysis } from '@sympheny/project/data-access';
import { PlanLimitation } from '@sympheny/user/data-access';

export interface NoLimit {
  pricingPlan: PlanLimitation;
  continue: boolean;
  limit: boolean;
}

export interface Limit extends NoLimit {
  maxParallelExecutions: number;
  analyses: Analysis[];
}

export type PlanLimitReachedModalData = NoLimit | Limit;

@Component({
  selector: 'sympheny-plan-limit-reached-modal',
  templateUrl: './plan-limit-reached-modal.component.html',
  styleUrls: ['./plan-limit-reached-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, MatDialogModule, MatButtonModule],
})
export class PlanLimitReachedModalComponent {
  // Inputs
  public readonly maxParallelExecutions: number | undefined;
  public readonly pricingPlan: string;
  public readonly continue: boolean;
  public readonly analyses: any[] = [];
  public readonly limit: boolean;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private data: PlanLimitReachedModalData,
  ) {
    this.limit = this.data.limit;
    this.pricingPlan = this.data.pricingPlan.name;
    this.continue = this.data.continue;
    if (this.limit) {
      this.maxParallelExecutions = (this.data as Limit).maxParallelExecutions;
      this.analyses = (this.data as Limit).analyses;
    }
  }
}
