import { LayerType } from '@sympheny/project/scenario/data-access';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';

interface LayerConfiguration {
  features?: Feature<any>[];
  title: string;
  visible?: boolean;
  zIndex?: number;
  style?: Style;
  id?: string;
  layerType?: LayerType;
}

export function createLayer({
  features = [],
  title,
  visible,
  zIndex = 0,
  style,
  id,
  layerType,
}: LayerConfiguration) {
  const vectorSource = new VectorSource({
    features: features,
  });

  new VectorLayer({
    source: vectorSource,
  });

  const layer = new VectorLayer({
    //minZoom: MAP_DEFAULTS.minVectorLayerZoom,
    properties: { title: title, id, layerType },
    zIndex: zIndex ?? 0,
    visible: visible ?? true,
    source: vectorSource,
  });

  if (style) {
    layer.setStyle(style);
  }

  return layer;
}
