import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';

import { AbstractSolarEnergyDatabaseProfileCollection } from './abstract-solar-energy-database-profile.collection';

@Injectable()
export class SolarEnergyDatabaseProfileCollection extends AbstractSolarEnergyDatabaseProfileCollection {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(false, http, environmentService);
  }
}
