import { Graph, GraphData, Node } from '../model';
import { numberOfNonSelfLinkingCycles } from '../utils/self-linking';

const calculateNodeSize = (
  node: Node,
  nodesLength: number,
  columnsLength: number,
  graph: Readonly<GraphData>,
  i: number,
) => {
  const selfLinkingCycles = numberOfNonSelfLinkingCycles(node, graph);
  const { extend } = graph;
  let y0 = node.y0;
  // if the node is in the last column, and is the only node in that column, put it in the centre
  if (node.column === columnsLength - 1 && nodesLength === 1) {
    y0 = extend.y1 / 2 - node.value * extend.ky;

    // if the node is in the first column, and is the only node in that column, put it in the centre
  } else if (node.column === 0 && nodesLength === 1) {
    y0 = extend.y1 / 2 - node.value * extend.ky;
  }
  // if the node has a circular link
  else if (node.partOfCycle) {
    // if the node has no self links
    if (selfLinkingCycles === 0) {
      y0 = extend.y1 / 2 + i;
    } else if (node.circularLinkType === 'top') {
      y0 = extend.y0 + i;
    } else {
      y0 = extend.y1 - node.value * extend.ky - i;
    }
  } else {
    // if (graph.y0.top === 0 || graph.y1.bottom === 0) {
    // node.y0 = ((graph.y1 - graph.y0) / nodesLength) * i;
    // node.y1 = node.y0 + node.value * graph.ky;
    // } else {
    y0 = (extend.y1 - extend.y0) / 2 - nodesLength / 2 + i;

    // }
  }
  const height = node.value * extend.ky;
  node.setNodeY(y0, height);
};

const sortNodes = (a: Node, b: Node, data: GraphData) => {
  if (a.circularLinkType === b.circularLinkType) {
    return (
      numberOfNonSelfLinkingCycles(b, data) -
      numberOfNonSelfLinkingCycles(a, data)
    );
  } else if (a.circularLinkType === 'top' && b.circularLinkType === 'bottom') {
    return -1;
  } else if (a.circularLinkType === 'top' && b.partOfCycle === false) {
    return -1;
  } else if (a.partOfCycle === false && b.circularLinkType === 'bottom') {
    return -1;
  }

  return 1;
};

// Assign nodes' breadths, and then shift nodes that overlap (resolveCollisions)
export const computeNodeBreadths = (graph: Graph<any, any>) => {
  const { graph: data } = graph;

  const columnsLength = data.maxColumns();

  data.forEachColumn((nodes) => {
    const nodesLength = nodes.length;

    const sortedNodes = nodes.sort((a: Node, b: Node) => sortNodes(a, b, data));

    sortedNodes.forEach((node: Node, i) =>
      calculateNodeSize(node, nodesLength, columnsLength, data, i),
    );
  });
};
