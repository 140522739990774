import { z } from 'zod';

import {
  ConversionTechnologySchemaV1,
  ConversionTechnologyDtoV1,
} from './conversion-technology.model.v1';
import {
  ConversionTechnologySchemaV2,
  ConversionTechnologyDtoV2,
} from './conversion-technology.model.v2';
import {
  StorageTechnologyDtoV1,
  StorageTechnologyDtoV2,
  StorageTechnologySchemaV1,
  StorageTechnologySchemaV2,
} from './storage-technologies.model';

export const TechnologyPackagesSchemaV1 = z.object({
  name: z.string(),
  guid: z.string(),
  conversionTechnologies: z.array(ConversionTechnologySchemaV1),
  storageTechnologies: z.array(StorageTechnologySchemaV1),
});

export const TechnologyPackagesSchemaV2 = z.object({
  name: z.string(),
  guid: z.string(),
  conversionTechnologies: z.array(ConversionTechnologySchemaV2),
  storageTechnologies: z.array(StorageTechnologySchemaV2),
});

export const TechnologyPackagesList = z.object({
  name: z.string(),
  guid: z.string(),
  conversionTechnologies: z.array(z.string()),
  storageTechnologies: z.array(z.string()),
});

const ConversionTechnologyPackageV1 = z.object({
  guid: z.string(),
  technologyOptional: z.boolean().default(false),
  conversionTechnology: ConversionTechnologyDtoV1,
});

const StorageTechnologyPackageV1 = z.object({
  guid: z.string(),
  technologyOptional: z.boolean().default(false),
  storageTechnology: StorageTechnologyDtoV1,
});
export const TechnologyPackagesDtoV1 = z.object({
  name: z.string(),
  conversionTechnologyPackages: z.array(ConversionTechnologyPackageV1),
  storageTechnologyPackages: z.array(StorageTechnologyPackageV1),
});

const ConversionTechnologyPackageV2 = z.object({
  guid: z.string(),
  technologyOptional: z.boolean().default(false),
  conversionTechnology: ConversionTechnologyDtoV2,
});

const StorageTechnologyPackageV2 = z.object({
  guid: z.string(),
  technologyOptional: z.boolean().default(false),
  storageTechnology: StorageTechnologyDtoV2,
});
export const TechnologyPackagesDtoV2 = z.object({
  name: z.string(),
  conversionTechnologyPackages: z.array(ConversionTechnologyPackageV2),
  storageTechnologyPackages: z.array(StorageTechnologyPackageV2),
});

export type TechnologyPackagesV1 = z.infer<typeof TechnologyPackagesSchemaV1>;
export type TechnologyPackagesV2 = z.infer<typeof TechnologyPackagesSchemaV2>;

export type TechnologyPackages = TechnologyPackagesV1 & TechnologyPackagesV2;
