import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { AuthService } from '../auth.service';

@Component({
  selector: 'sympheny-login',
  template: '',
  styles: [],
  standalone: true,
})
export class LoginComponent implements OnInit {
  constructor(
    private auth: AuthService,
    private readonly router: Router,
  ) {}

  public async ngOnInit() {
    const isAuhtenticated = await firstValueFrom(this.auth.isAuthenticated$);

    if (isAuhtenticated) {
      this.router.navigateByUrl('/');
    }
  }
}
