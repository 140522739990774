<ng-template [cdkPortalOutlet]></ng-template>

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'number'">
    <span [class.sympheny--table_align-number]="type === 'number'">
      {{ value | number: '1.2-2' }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'datetime'">
    <span>
      {{ value | date: 'medium' }}
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'link'">
    <a [href]="value" target="_blank">
      {{ value }}
    </a>
  </ng-container>
  <ng-container *ngSwitchDefault> {{ value }} </ng-container>
</ng-container>
