import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

import { ProgressComponent } from './progress/progress.component';

interface Progress {
  type: string;
  id: string;
  done: boolean;
  error: boolean;
  name: string;
}

const job_key = 'sympheny_progress';

@Injectable({ providedIn: 'root' })
export class ProgressService {
  private overlayRef!: OverlayRef;
  private readonly progressMap = new Map<string, Progress>();
  private readonly progressIds$ = new BehaviorSubject<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly progress$ = this.progressIds$.pipe(
    map((ids) => ids.map((id) => this.progressMap.get(id)))
  );
  constructor(private readonly overlay: Overlay) {
    this.checkForJobsInLocalstorage();
  }

  public enableProgress() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    const componentPortal = new ComponentPortal(ProgressComponent);
    this.overlayRef.addPanelClass('example-overlay');
    this.overlayRef.attach(componentPortal);
  }

  public registerProgress(progress: Progress) {
    this.progressMap.set(progress.id, progress);

    this.updateProgress(progress.id);
  }

  public setDone(id: string) {
    const progress = this.progressMap.get(id);

    if (!progress) {
      return;
    }

    progress.done = true;

    this.updateProgress();
  }

  public setError(id: string) {
    const progress = this.progressMap.get(id);

    if (!progress) {
      return;
    }

    progress.error = true;

    this.updateProgress();
  }

  public removeProgress(progressId: string) {
    this.progressIds$.next(
      this.progressIds$.value.filter((id) => id !== progressId)
    );
  }

  public getForType(type: string) {
    return Array.from(this.progressMap.values()).filter(
      (job) => job.type === type
    );
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .bottom('0')
      .right('0');
    return new OverlayConfig({
      positionStrategy: positionStrategy,
    });
  }

  public removeAllProgress() {
    this.progressIds$.next([]);
    this.progressMap.clear();
    this.saveJobsToLocalStorage();
  }

  private updateProgress(progressId?: string) {
    const nextProgress = this.progressIds$.value;

    if (progressId) {
      this.progressIds$.next([progressId, ...nextProgress]);
    } else {
      this.progressIds$.next(nextProgress);
    }

    this.saveJobsToLocalStorage();
  }

  private saveJobsToLocalStorage() {
    const jobsInProgress = Array.from(this.progressMap.values()).filter(
      (j: Progress | null) => j && !j.done && !j.error
    );

    window.localStorage.setItem(job_key, JSON.stringify(jobsInProgress));
  }

  private checkForJobsInLocalstorage() {
    const jobsInStorage = window.localStorage.getItem(job_key);

    const jobs = jobsInStorage ? JSON.parse(jobsInStorage) : [];

    jobs.map((job) => {
      this.registerProgress(job);
    });
  }
}
