import { Injectable } from '@angular/core';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { DragAndDrop } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { GisChangeEvent, MapFeatureService } from './map-feature.service';

export interface DragAndDropChangeEvent extends GisChangeEvent {
  title: string | null;
  vectorLayer: VectorLayer<any> | null;
}

@Injectable()
export class DragAndDropService extends MapFeatureService<
  DragAndDropChangeEvent,
  DragAndDrop
> {
  private dragAndDropInteraction!: DragAndDrop;

  protected defaultValue(): DragAndDropChangeEvent {
    return { geoJson: null, title: null, vectorLayer: null };
  }

  protected addInteraction() {
    if (!this.map || !this.tempSource) {
      return null;
    }

    if (this.dragAndDropInteraction) {
      this.map.removeInteraction(this.dragAndDropInteraction);
    }

    this.dragAndDropInteraction = new DragAndDrop({
      source: this.tempSource,
      formatConstructors: [GeoJSON],
    });

    this.dragAndDropInteraction.on('addfeatures', (event: any) => {
      this.addFileToMap(event.file, event.features);
    });
    this.map.addInteraction(this.dragAndDropInteraction);

    return this.dragAndDropInteraction;
  }

  public addFile(file: File) {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    const event = new DragEvent('addfeatures', { dataTransfer });

    this.dragAndDropInteraction.handleDrop(event);
  }

  private addFileToMap(file: File, features: Feature<any>[]) {
    if (!this.tempSource) {
      return;
    }
    this.tempSource.clear();
    const title = file.name.split('.geojson')[0];

    const vectorSource = new VectorSource({
      features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      properties: { title },
    });

    this.map.addLayer(vectorLayer);
    this.map.getView().fit(vectorSource.getExtent());
    this.updateEvent({
      geoJson: this.getGeoJson(features),
      title,
      vectorLayer,
    });
  }
}
