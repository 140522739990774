import { AuthConfig } from '@auth0/auth0-angular/lib/auth.config';

export interface Auth0Environment {
  publicBaseApi: string;
  auth0Domain: string;
  auth0ClientID: string;
}

export function createAuth0Config<E extends Auth0Environment>(
  environment: E
): AuthConfig {
  return {
    domain: environment.auth0Domain,
    clientId: environment.auth0ClientID,
    authorizationParams: {
      audience: `${environment.publicBaseApi}`,
      redirectUri: `${window.location.origin}/auth-callback`,
    },
    errorPath: '/auth-callback',
    httpInterceptor: {
      allowedList: [
        {
          uri: `${environment.publicBaseApi}*`,
          tokenOptions: {
            authorizationParams: {
              audience: `${environment.publicBaseApi}`,
              redirectUri: `${window.location.origin}/auth-callback`,
            },
            // audience: `${environment.publicBaseApi}`,
          },
        },
      ],
    },
  };
}
