<ng-container *ngIf="gisEnabled$ | async">
  <div class="map-gis--actions" *ngIf="showActions">
    <div>
      <sympheny-map-gis-toggle
        (toggle)="showMap = $event"
      ></sympheny-map-gis-toggle>
    </div>
    <div></div>
    <div *ngIf="showMap" class="map-gis--actions-configuration">
      <sympheny-icon-button-list [configuration]="actionConfiguration">
      </sympheny-icon-button-list>
    </div>
  </div>
  <sympheny-map-gis
    *ngIf="showMap"
    [style.height]="mapHeight"
    [config]="configuration"
    [scenarioId]="scenarioId"
    [editableHubId]="editableHubId"
    [energyResourceHubGuid]="energyResourceHubGuid"
    [hubs]="hubs"
    [zoomToHub]="zoomToHub"
    [newHub]="newHub"
    [networkTechnologies]="networkTechnologies"
    [networkLink]="networkLink"
    [project]="project"
    (hubChange)="hubChange.emit($event)"
    (selectedFeatures)="selectedFeatures.emit($event)"
    [disabled]="locked"
    [canEdit]="canEdit"
  ></sympheny-map-gis>
</ng-container>
