import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectVersion } from '@sympheny/project/data-access';
import {
  mapDataRequest,
  mapDataZ,
  ResponseModel,
  SymphenyErrorHandler,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

import {
  OtherParameters,
  OtherParametersV1DtoSchema,
  OtherParametersV1Schema,
  OtherParametersV2DtoSchema,
  OtherParametersV2Schema,
} from '../other-parameters/other-parameter.model';

@Injectable()
export class OtherParameterService {
  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
    protected readonly errorHandler: SymphenyErrorHandler
  ) {}

  protected readonly base = this.environmentService.getValue('base');

  /**
   * Get other parameters for scenario
   */
  public list(version: ProjectVersion, scenarioGuid: string) {
    return version === 'V2'
      ? this.listV2(scenarioGuid)
      : this.listV1(scenarioGuid);
  }

  private listV1(scenarioGuid: string) {
    return this.http
      .get<ResponseModel<OtherParameters>>(
        `${this.base}scenarios/${scenarioGuid}/otherParameters`
      )
      .pipe(mapDataZ(OtherParametersV1Schema));
  }

  private listV2(scenarioGuid: string) {
    return this.http
      .get<ResponseModel<OtherParameters>>(
        `${this.base}v2/scenarios/${scenarioGuid}/otherParameters`
      )
      .pipe(mapDataZ(OtherParametersV2Schema));
  }

  /**
   * Update other parameters for a scenario
   */
  public update(
    version: ProjectVersion,
    scenarioGuid: string,
    data: Partial<OtherParameters>
  ) {
    return version === 'V2'
      ? this.updateV2(scenarioGuid, data)
      : this.updateV1(scenarioGuid, data);
  }

  private updateV1(scenarioGuid: string, data: Partial<OtherParameters>) {
    return this.http
      .put<ResponseModel<OtherParameters>>(
        `${this.base}scenarios/${scenarioGuid}/otherParameters`,
        mapDataRequest(OtherParametersV1DtoSchema, data)
      )
      .pipe(mapDataZ(OtherParametersV1Schema));
  }

  private updateV2(scenarioGuid: string, data: Partial<OtherParameters>) {
    return this.http
      .put<ResponseModel<OtherParameters>>(
        `${this.base}v2/scenarios/${scenarioGuid}/otherParameters`,
        mapDataRequest(OtherParametersV2DtoSchema, data)
      )
      .pipe(mapDataZ(OtherParametersV2Schema));
  }
}
