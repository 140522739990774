<sympheny-button *ngIf="!editing" (action)="onEdit()" class="button mr-4">
  {{ 'Edit' | transloco }}</sympheny-button
>

<sympheny-button
  *ngIf="editing"
  (action)="onSubmit()"
  buttonType="submit"
  class="button"
>
  {{ 'Submit' | transloco }}</sympheny-button
>
<sympheny-button
  *ngIf="editing"
  (action)="onCancel()"
  color="warn"
  class="button"
>
  {{ 'Cancel' | transloco }}</sympheny-button
>
