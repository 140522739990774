import { Link, Node } from '../model';

export const getLink = (linkId: string, linkMap: Map<string, Link>): Link => {
  const link = linkMap.get(linkId);
  if (!link) throw new Error(`Link "${linkId}" not found`);

  return link;
};

export const getLinkInternalId = (link: Pick<Link, 'source' | 'target'>) => {
  return [link.source, link.target].join('_|_');
};

export function linkHeight(link: Link | Link) {
  return link.y1 - link.y0;
}
export function linkWidth(link: Link | Link) {
  return link.x1 - link.x0;
}
export const getNodeLinks = (link: Link, nodes: Map<string, Node>) => {
  const source = nodes.get(link.source);
  if (!source) throw new Error(`Source Node "${link}" not found`);
  const target = nodes.get(link.target);
  if (!target) throw new Error(`Target Node "${link}" not found`);

  return { source, target };
};
export function getWidth(link: Link, scale = 1) {
  if (!link.value) return 1;

  return link.value * (scale ?? 1);
}
