import { plainToClass } from 'class-transformer';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { mapKeys } from './map-cases';

export interface DeserializeOptions {
  camelCase: boolean;
}

const defaulteserializeOptions: DeserializeOptions = {
  camelCase: false,
};

function deserializeThis<T>(
  targetClass: ClassConstructor<T>,
  value: any,
  options: DeserializeOptions
) {
  const deserializeFunction = (result: T) => {
    const valueD = options.camelCase ? mapKeys(result, 'camel') : result;

    return plainToClass(targetClass, valueD);
  };

  if (value instanceof Observable) {
    return value.pipe(map(deserializeFunction));
  }

  return deserializeFunction(value);
}

/** @deprecated use zod instead */
// tslint:disable-next-line:naming-convention
export function Deserialize<T>(
  targetClass: ClassConstructor<T>,
  options: DeserializeOptions = defaulteserializeOptions
) {
  return (
    _target: unknown,
    _propertyName: string,
    propertyDesciptor: PropertyDescriptor
  ): PropertyDescriptor => {
    const method = propertyDesciptor.value;

    // tslint:disable-next-line:no-any
    propertyDesciptor.value = function (...args: any[]) {
      const result = method.apply(this, args);

      return deserializeThis(targetClass, result, options);
    };

    return propertyDesciptor;
  };
}
