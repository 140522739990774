<button
  [disabled]="disabled"
  mat-icon-button
  class="more-button"
  [matMenuTriggerFor]="menu"
  aria-label="Toggle menu"
  [matTooltip]="configuration.tooltip"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <button
    *ngFor="let item of configuration.items"
    mat-menu-item
    (click)="onAction(item)"
    [matTooltip]="item.tooltip | transloco"
  >
    {{ item.label | transloco }}
  </button>
</mat-menu>
