import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TranslocoPipe } from '@ngneat/transloco';
import { DragAndDropService } from '@sympheny/gis/utils';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';

@Component({
  selector: 'sympheny-add-customer-layer',
  templateUrl: './add-customer-layer.component.html',
  styleUrls: ['./add-customer-layer.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    NgxFileDragDropModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class AddCustomerLayerComponent {
  readonly uploadForm = new FormGroup({
    file: new FormControl<File[] | null>(null, Validators.required),
  });

  constructor(
    private readonly dragAndDropService: DragAndDropService,
    private readonly dialog: MatDialog,
  ) {}

  updateForm() {
    // FIXME fix for drag and drop, inside the library there is no changedetection triggered when drag and drop the file
  }

  onSubmit() {
    if (!this.uploadForm.valid) {
      return;
    }

    this.dragAndDropService.addFile(this.uploadForm.value.file![0]);
    this.dialog.closeAll();
  }
}
