import { Fill, Stroke, Style } from 'ol/style';

const EC_COLOR = {
  ELECTRICAL_ENERGY: {
    ELECTRICITY: '#628249',
    ELECTRICITY_RENEWABLE: '#66bd63',
    CUSTOM: '#628249',
  },
  FUEL_SOLID: {
    WOOD_CHIPS: '#a65628',
    WOOD_PELLETS: '#a65628',
    COAL: '#1a1a1a',
    CUSTOM: '#014636',
  },
  FUEL_LIQUID: {
    OIL: '#9e9ac8',
    CUSTOM: '#67001f',
  },

  FUEL_GASEOUS: {
    GAS: '#c994c7',
    BIOGAS: '#02818a',
    HYDROGEN: '#6a51a3',
    HYDROGEN_PRESSURIZED: '#3f007d',
    CUSTOM: '#ce1256',
  },
  COOLING_ENERGY: {
    COOLING_1: '#0570b0',
    COOLING_2: '#2A87C4',
    COOLING_3: '#4F9ED8',
    COOLING_4: '#99ccff',
    ICE: '#ece7f2',
    CUSTOM: '#0570b0',
  },
  HEATING_ENERGY: {
    HEAT_1: '#4F9ED8',
    HEAT_2: '#99ccff',
    HEAT_3: '#fed976',
    HEAT_4: '#feb24c',
    HEAT_5: '#fd8d3c',
    HEAT_6: '#fc9272',
    HEAT_7: '#fb6a4a',
    HEAT_8: '#ef3b2c',
    HEAT_9: '#cc3300',
    HEAT_AMBIENT: '#fcbba1',
    STEAM_LOW_PRESSURE: '#cc3300',
    CUSTOM: '#fb6a4a',
  },
  ON_SITE_RESOURCE: {
    SOLAR_ROOF: '#ffd92f',
    SOLAR_FACADE: '#ffd92f',
    SOLAR_PARAPET: '#ffd92f',
    WIND: '#D2DBDF',
    HYDRO: '#7FB8CD',
    BIOMASS: '#968061',
    GEOTHERMAL: '#E9B082',
    TIDAL: '#7FCDC4',
    PROCESS_WASTE_HEAT: '#CD8764',
    CUSTOM: '#ffd92f',
  },
  OTHER: {
    CUSTOM: '#e5d8bd',
  },
};

interface EnergyCarierType {
  typeKey?: string;
  subtypeKey?: string;
  colorHexCode?: string;
}

export function getEnergyCarrierColor<ENERGYCARIER extends EnergyCarierType>({
  typeKey: ecType,
  subtypeKey: ecSubType,
  colorHexCode,
}: ENERGYCARIER): string {
  if (colorHexCode && colorHexCode !== 'default') {
    return colorHexCode;
  }

  const type =
    ecType in EC_COLOR ? EC_COLOR[ecType as keyof typeof EC_COLOR] : {};
  const color = ecSubType in type ? type[ecSubType as keyof typeof type] : '';
  return color;
}

export function createEnergyCarrierStyle<ENERGYCARIER extends EnergyCarierType>(
  energyCarrier: ENERGYCARIER,
) {
  const color = getEnergyCarrierColor(energyCarrier);

  return new Style({
    fill: new Fill({ color }),
    stroke: new Stroke({ color, width: 2, lineDash: [4, 8] }),
  });
}
