<div>
  <h2 class="mb-2 flex items-center gap-2">
    <span class="font-bold text-xl">
      <ng-content select="[title]"></ng-content>
    </span>
    <div>
      <sympheny-info-button
        *ngIf="tooltip"
        [tooltip]="tooltip"
      ></sympheny-info-button>
    </div>
    <div class="flex gap-1" *ngIf="canEdit">
      <sympheny-button
        *ngFor="let action of actions"
        (action)="action.action()"
        [disabled]="disabled"
        [dataCy]="action.dataCy || action.label"
      >
        {{ action.label | transloco }}
      </sympheny-button>
    </div>
  </h2>

  <p class="none-defined" *ngIf="!loading && items?.length === 0">
    {{ 'SCENARIO.not-defined' | transloco }}
  </p>

  <sympheny-loader [loading]="loading"></sympheny-loader>

  <div *ngIf="items?.length > 0">
    <sympheny-table
      [data]="items"
      [configuration]="configuration"
      [labelParams]="labelParams"
      [disableDefinedActions]="disableDefinedActions"
      [subject]="subject"
    ></sympheny-table>
  </div>
</div>
