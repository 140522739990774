import { ComponentType } from '@angular/cdk/overlay';
import { InjectionToken, TemplateRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ZodTypeAny } from 'zod';

export type BLANK = 'BLANK';
export interface FormatValueDefault<T> {
  label?: string;
  labelFn?: () => string;
  key: keyof T | string;
  childKey?: string;
  defaultValue?: string | number;
  maxValues?: number;
  type?: string;
  disableSorting?: boolean;
  tooltip?: string;
  cssClass?: string;
  cssClassFn?: (element: T) => string;
  translatePrefix?: string;
  suffix?: string;
  hint?: string;
  hintLink?: string;
  params?: Record<string, unknown>;
}

export interface FormatValueComponentConfig<T> extends FormatValueDefault<T> {
  type: 'component';
  token: string | InjectionToken<unknown>;
  component: ComponentType<any>;
  data?: any;
}

export interface FormatValueTemplate<T> extends FormatValueDefault<T> {
  type: 'template';
  template: TemplateRef<any>;
}

export interface BlankConfig {
  type: 'BLANK';
}
export type ActionFn<T> = (element: T, index: number) => void;

export interface Action<T> {
  tooltip: string;
  tooltipParams?: any;
  icon: string;
  action: ActionFn<T>;
  readOnly?: (element: T) => boolean;
  color?: ThemePalette;
  label?: string;
  disabled?: boolean;
  dataCy?: string;
}

export interface FormatValueAction<T> extends FormatValueDefault<T> {
  type: 'action';
  readOnly?: (element: T) => boolean;
  action: Action<T>;
}

export type FormatValueConfig<T> =
  | FormatValueDefault<T>
  | FormatValueComponentConfig<T>
  | FormatValueTemplate<T>
  | FormatValueAction<T>;
export interface AdditionalConfig<T> {
  actions?: { [index: string]: (element: T, index: number) => void };
  templates?: { [index: string]: TemplateRef<unknown> };
  additionalData?: any;
  data?: any;
}

export const mapToSchema = <T extends Record<string, any>>(
  summaryFields: (keyof T | BLANK)[],
  fieldConfig: Record<keyof T, FormatValueConfig<T>>,
  schema: ZodTypeAny,
  additionalConfig?: AdditionalConfig<T>,
): FormatValueConfig<T>[] => {
  const actions = additionalConfig?.actions ?? {};
  const templates = additionalConfig?.templates ?? {};
  const additionalData = additionalConfig?.additionalData ?? {};
  const data = additionalConfig?.data ?? {};
  const shape = schema._def.shape();

  return summaryFields
    .map((field) =>
      field === 'BLANK'
        ? { type: 'BLANK', key: `BL_${new Date()}` }
        : shape[field]
        ? fieldConfig[field]
        : null,
    )
    .filter((config) => !!config)
    .map((field) => {
      if ('action' in field) {
        field.action = {
          ...(field.action as any),
          action: actions[field.key as string],
        };
      }
      const dataField = 'data' in field ? { ...field.data, ...data } : data;

      return {
        ...field,
        template: templates[field.key as string],
        data: dataField,
        ...additionalData,
      } as unknown as FormatValueConfig<T>;
    });
};
