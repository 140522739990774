<div
  class="bg-map-control !text-map-control-text py-1 px-2 rounded w-44 !fill-white"
>
  <mat-select
    [ngModel]="selectedBaseMap"
    (ngModelChange)="onValueChange($event)"
    class="!text-map-control-text !fill-white"
  >
    <mat-option *ngFor="let option of options" [value]="option.config">
      {{ option.label }}
    </mat-option>
  </mat-select>
</div>
