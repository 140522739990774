<div [id]="mapUuid + '_feature_info'" class="sympheny-feature-info">
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-tab-group
        headerPosition="below"
        (focusChange)="changeSelectedFeature($event.index)"
      >
        <mat-tab
          *ngFor="let feature of featureData"
          [label]="feature.layer.get('title') ?? 'layer'"
        >
          <table mat-table [dataSource]="getProperties(feature) | keyvalue">
            <ng-container matColumnDef="key">
              <th mat-cell *matCellDef="let element">
                {{ translatePrefix + element.key | transloco }}
              </th>
            </ng-container>
            <ng-container matColumnDef="value">
              <td mat-cell *matCellDef="let element">
                <sympheny-feature-info-value
                  [value]="element.value"
                ></sympheny-feature-info-value>
              </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: ['key', 'value']"></tr>
          </table>
          <ng-container *ngIf="feature.layer.get('title') === 'Hubs'">
            <div class="feature-action-buttons">
              <sympheny-icon-button
                class="buildingInfoButton"
                icon="info"
                color="primary"
                tooltip="map.showBuildingInfo"
                (click)="onOpenBuildingInfo()"
              ></sympheny-icon-button>
              <sympheny-icon-button
                *ngIf="editHub"
                class="buildingEditButton"
                icon="edit"
                color="primary"
                tooltip="HUBS.editButton"
                (click)="onEditHub(feature)"
              ></sympheny-icon-button>
            </div>
          </ng-container>
        </mat-tab> </mat-tab-group
    ></mat-card-content>
  </mat-card>
</div>
