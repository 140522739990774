import { Injectable } from '@angular/core';
import { CrudService, ProjectVersion } from '@sympheny/project/data-access';
import {
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo, of } from 'rxjs';

import { StagDtoSchema, Stage, StageSchema } from './stage.model';

@Injectable()
export class StageService extends CrudService<Stage> {
  readonly guidKey = 'guid';
  readonly key = 'stages';

  override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    if (projectVersion === 'V1') {
      return of([]);
    }
    return this.http
      .get<ResponseModel<Stage[]>>(
        `${this.base}scenarios/${scenarioGuid}/stages`
      )
      .pipe(mapDataZ(StageSchema));
  }

  /**
   * Create a Stage
   */
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<Stage>
  ) {
    return firstValueFrom(
      this.http
        .post<ResponseModel<Stage>>(
          `${this.base}scenarios/${scenarioGuid}/stages`,
          mapDataRequest(StagDtoSchema, data)
        )
        .pipe(mapDataZ(StageSchema))
    );
  }

  /**
   * Update Stage name
   */
  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    stageId: string,
    data: Partial<Stage>
  ) {
    return firstValueFrom(
      this.http
        .put<ResponseModel<Stage>>(
          `${this.base}scenarios/${scenarioGuid}/stages/${stageId}`,
          mapDataRequest(StagDtoSchema, data)
        )
        .pipe(mapDataZ(StageSchema))
    );
  }

  /**
   * Delete Stage
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    stageId: string
  ) {
    return firstValueFrom(
      this.http
        .delete(`${this.base}scenarios/${scenarioGuid}/stages/${stageId}`)
        .pipe(mapTo(stageId))
    );
  }
}
