<nav
  [attr.role]="'navigation'"
  class="sidenav"
  [class.closed]="!opened"
  [class.open-overlay]="opened && !pushMode"
>
  <button
    [class.closed]="!opened"
    [class.push-mode]="opened"
    (click)="changeSidenavMode()"
    class="toggle mt-3 fixed btn btn-circle btn-outline bg-white border-gray-sidenav text-gray-sidenav h-6 w-6 p-0 min-h-6 min-w-6 max-w-6 maz-h-6"
    [class.ml-4]="opened"
  >
    <mat-icon>{{ pushMode ? 'chevron_left' : 'chevron_right' }}</mat-icon>
  </button>

  <div
    class="sidenav__content"
    (mouseenter)="openSidenav()"
    (mouseleave)="closeSidenav()"
  >
    <div class="sidenav--list">
      <sympheny-sidenav-item
        [opened]="opened"
        [link]="'projects'"
        aria-hidden="false"
        aria-label="Projects"
        (click)="openSidenav()"
        icon="folder"
      >
        {{ 'project.projects' | transloco }}
      </sympheny-sidenav-item>
      <ng-container *ngIf="projectDetails$ | async as projectDetails">
        <sympheny-sidenav-expansion-item
          (click)="openSidenav()"
          [opened]="opened"
          aria-hidden="false"
          icon="menu_open"
          [attr.aria-label]="'project.analysis' | transloco"
          [label]="'project.analysis' | transloco"
        >
          <sympheny-sidenav-item
            *ngFor="let analysis of projectDetails.analyses"
            [link]="['projects', projectDetails.projectGuid]"
            [params]="{ activeAnalysisGuid: analysis.analysisGuid }"
            [opened]="opened"
          >
            {{ analysis.analysisName }}
          </sympheny-sidenav-item>
        </sympheny-sidenav-expansion-item>

        <ng-container *ngIf="analysisDetails$ | async as analysisDetails">
          <sympheny-sidenav-expansion-item
            (click)="openSidenav()"
            [opened]="opened"
            aria-hidden="false"
            [attr.aria-label]="'project.design' | transloco"
            icon="design_services"
            [label]="'project.design' | transloco"
          >
            <sympheny-sidenav-item
              *ngFor="let scenario of analysisDetails.scenarios"
              [opened]="opened"
              [link]="[
                'projects',
                projectDetails.projectGuid,
                'analysis',
                analysisDetails.analysisGuid,
                'scenario',
                scenario.scenarioGuid
              ]"
            >
              {{ scenario.scenarioName }}
            </sympheny-sidenav-item>
          </sympheny-sidenav-expansion-item>
          <sympheny-sidenav-expansion-item
            (click)="openSidenav()"
            [opened]="opened"
            aria-hidden="false"
            aria-label="Execution"
            icon="video_settings"
            [attr.aria-label]="'project.execution' | transloco"
            [label]="'project.execution' | transloco"
          >
            <sympheny-sidenav-item
              [link]="[
                'projects',
                projectDetails.projectGuid,
                'analysis',
                analysisDetails.analysisGuid,

                projectDetails.version === 'V1'
                  ? 'execution-history'
                  : 'execution'
              ]"
              aria-hidden="false"
              aria-label="Execution History"
              icon="list"
              [opened]="opened"
            >
              {{ 'project.execution.history' | transloco }}
            </sympheny-sidenav-item>
          </sympheny-sidenav-expansion-item>

          <sympheny-sidenav-item
            *ngIf="
              projectDetails.version === 'V1' && analysisDetails$
                | async as analysisDetails
            "
            [link]="[
              'projects',
              projectDetails.projectGuid,
              'analysis',
              analysisDetails.analysisGuid,
              'results'
            ]"
            (click)="openSidenav()"
            icon="ssid_chart"
            aria-hidden="false"
            aria-label="Results"
            [opened]="opened"
          >
            {{ 'project.results' | transloco }}
          </sympheny-sidenav-item>
        </ng-container>
      </ng-container>
    </div>
    <div>
      <sympheny-sidenav-item
        *ngIf="sagemakerOn$ | async"
        (click)="openEnervisionWindow()"
        icon="api"
        aria-hidden="false"
        aria-label="Enervision"
        [opened]="opened"
      >
        {{ 'Enervision' }}
      </sympheny-sidenav-item>
      <sympheny-sidenav-item
        *ngIf="organizationDb$ | async"
        link="database"
        icon="dataset"
        aria-hidden="false"
        [attr.aria-label]="'DATABASE.database' | transloco"
        [opened]="opened"
      >
        {{ 'DATABASE.database' | transloco }}
      </sympheny-sidenav-item>
      <sympheny-sidenav-expansion-item
        (click)="openSidenav()"
        [opened]="opened"
        aria-hidden="false"
        [attr.aria-label]="'Profile' | transloco"
        icon="account_circle"
        [label]="'Profile' | transloco"
      >
        <sympheny-sidenav-item link="account/my-profile" [opened]="opened">
          {{ 'My Profile' | transloco }}
        </sympheny-sidenav-item>
        <sympheny-sidenav-item link="account/organization" [opened]="opened">
          {{ 'Organization' | transloco }}
        </sympheny-sidenav-item>
        <sympheny-sidenav-item link="account/preferences" [opened]="opened">
          {{ 'Preferences' | transloco }}
        </sympheny-sidenav-item>
      </sympheny-sidenav-expansion-item>
      <sympheny-sidenav-item
        (click)="openSidenav()"
        href="mailto: support@sympheny.com"
        icon="help"
        aria-hidden="false"
        aria-label="Help"
        [opened]="opened"
      >
        {{ 'Help' | transloco }}
      </sympheny-sidenav-item>
    </div>
  </div>
</nav>
