import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { TechnologyAdvancedPriceComponentSchemaV1 } from './price-component.model';
import {
  HubEnergyCarrierSchema,
  TechnologyModeDtoV1,
  TechnologyModeSchema,
  TechnologyModeSchemaV1,
} from './technology.model';

export const ConversionTechnologySchemaV1 = z.object({
  advancedPVModel: z.boolean().nullish(),
  capacity: SymphenyNumber().nullish(),
  category: z.string().nullish(),
  comesFromDb: z.string().nullish(),
  conversionTechnologyGuid: z.string().nullish(),
  costComponents: z.array(TechnologyAdvancedPriceComponentSchemaV1).nullish(),
  created: SymphenyDate().nullish(),
  curtailmentLimitation: SymphenyNumber().nullish(),
  efficiencyPVCell: SymphenyNumber().nullish(),
  fixedEmbodiedCo2: SymphenyNumber().nullish(),
  fixedInvestmentCost: SymphenyNumber().nullish(),
  fixedOmCostChf: SymphenyNumber().nullish(),
  fixedOmCostPercent: SymphenyNumber().nullish(),
  hubs: z.array(HubEnergyCarrierSchema).nullish(),
  lifetime: SymphenyNumber().nullish(),
  maximumAnnualOutput: SymphenyNumber().nullish(),
  maximumCapacity: SymphenyNumber().nullish(),
  minimumAnnualOutput: SymphenyNumber().nullish(),
  minimumCapacity: SymphenyNumber().nullish(),
  mustBeInstalledInHubs: z.string().nullish(),
  mutuallyExclusiveGroup: z.string().nullish(),
  notes: z.string().nullish(),
  partOfTechnologyPackage: z.boolean().nullish(),
  processName: z.string().nullish(),
  pvSizingFactorWpeakM2: SymphenyNumber().nullish(),
  safetyMargin: SymphenyNumber().nullish(),
  systemEfficiency: SymphenyNumber().nullish(),
  technologyCapacity: z.string().nullish(),
  technologyCategory: z.string().nullish(),
  technologyModes: z.array(TechnologyModeSchemaV1),
  technologyOptional: z.boolean().nullish(),
  updated: SymphenyDate().nullish(),
  variableCapturedCo2: SymphenyNumber().nullish(),
  variableEmbodiedCo2: SymphenyNumber().nullish(),
  variableEmittedCo2: SymphenyNumber().nullish(),
  variableInvestmentCost: SymphenyNumber().nullish(),
  variableOmCost: SymphenyNumber().nullish(),
  variableOmCostYear: SymphenyNumber().nullish(),
  virtual: z.boolean().nullish(),
});

export const ConversionTechnologyDtoV1 = ConversionTechnologySchemaV1.pick({
  advancedPVModel: true,
  capacity: true,
  comesFromDb: true,
  costComponents: true,
  curtailmentLimitation: true,
  efficiencyPVCell: true,
  exchangeCurrency: true,
  exchangeRate: true,
  fixedEmbodiedCo2: true,
  fixedInvestmentCost: true,
  fixedOmCostChf: true,
  fixedOmCostPercent: true,
  lifetime: true,
  maximumAnnualOutput: true,
  maximumCapacity: true,
  minimumAnnualOutput: true,
  minimumCapacity: true,
  mustBeInstalledInHubs: true,
  mutuallyExclusiveGroup: true,
  notes: true,
  processName: true,
  pvSizingFactorWpeakM2: true,
  safetyMargin: true,
  source: true,
  suggested: true,
  systemEfficiency: true,
  technologyCapacity: true,
  technologyCategory: true,
  variableCapturedCo2: true,
  variableEmbodiedCo2: true,
  variableEmittedCo2: true,
  variableInvestmentCost: true,
  variableOmCost: true,
  variableOmCostYear: true,
  virtual: true,
}).extend({
  conversionTechnologyModes: z.array(TechnologyModeDtoV1),
  hubGuids: z.array(z.string()),
  exchangeCurrency: z.string().nullish(),
  exchangeRate: SymphenyNumber().nullish(),
});

export const ConversiontTechnologyListV1Schema =
  ConversionTechnologySchemaV1.pick({
    conversionTechnologyGuid: true,
    processName: true,
    created: true,
    updated: true,
    hubs: true,
    virtual: true,
    mustBeInstalledInHubs: true,
    lifetime: true,
  }).extend({ conversionTechnologyModes: z.array(TechnologyModeSchema) });

export type ConversionTechnologyV1 = z.infer<
  typeof ConversionTechnologySchemaV1
>;

export type ConversionTechnologyListV1 = z.infer<
  typeof ConversiontTechnologyListV1Schema
>;

export const ConversionTechnologyDatabaseSchema =
  ConversionTechnologySchemaV1.pick({
    category: true,
    minimumCapacity: true,
    maximumCapacity: true,
    minimumAnnualOutput: true,
    maximumAnnualOutput: true,
    curtailmentLimitation: true,
    lifetime: true,
    fixedInvestmentCost: true,
    variableInvestmentCost: true,
    variableOmCostYear: true,
    fixedOmCostChf: true,
    fixedOmCostPercent: true,
    variableOmCost: true,
    variableEmbodiedCo2: true,
    variableEmittedCo2: true,
    variableCapturedCo2: true,
    virtual: true,
    created: true,
    updated: true,
    costComponents: true,
    mustBeInstalledInHubs: true,
    technologyCapacity: true,
    technologyCategory: true,
    technologyModes: true,
    notes: true,
    source: true,
  }).extend({
    conversionTechnologyModes: z.array(TechnologyModeDtoV1),
    conversionTechGuid: z.string().nullish(),
    technologyName: z.string().nullish(),
    suggested: z.boolean().nullish(),
    exchangeCurrency: SymphenyNumber().nullish(),
    exchangeRate: z.string().nullish(),
  });

export type ConversionTechnologyDatabase = z.infer<
  typeof ConversionTechnologyDatabaseSchema
>;

export const ConversionTechnologyDatabaseSchemaToTechnology = (
  tech: ConversionTechnologyDatabase,
  ...omitFields: Array<keyof ConversionTechnologyDatabase>
): ConversionTechnologyV1 => {
  const conversionTechnology = ConversionTechnologySchemaV1.partial()
    .passthrough()
    .parse(tech);

  if (!omitFields.includes('conversionTechGuid'))
    conversionTechnology.conversionTechnologyGuid = tech.conversionTechGuid;
  if (!omitFields.includes('technologyName'))
    conversionTechnology.processName = tech.technologyName;

  return conversionTechnology;
};
