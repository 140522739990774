import { SymphenyDate } from '@sympheny/utils/data-access';
import { z } from 'zod';

export class Hub {
  hubGuid: string;
  hubName: string;
  updated: string;
  created: string;
}

export class HubDiagram {
  image: string;
}

export const HubSchema = z.object({
  hubGuid: z.string().nullish(),
  hubName: z.string().nullish(),
  created: SymphenyDate().nullish(),
  updated: SymphenyDate().nullish(),
});
