<form [formGroup]="newLayerForm" (ngSubmit)="onSave()">
  <mat-form-field>
    <mat-label>Layer name</mat-label>
    <input matInput type="text" formControlName="name" />
  </mat-form-field>
  <sympheny-color-picker
    formControlName="color"
    (ngModelChange)="changeColor()"
  >
    Layer color
  </sympheny-color-picker>
  <sympheny-checkbox formControlName="share" label="Share with other projects">
  </sympheny-checkbox>
  <div style="height: 100%"></div>
  <div class="actions">
    <button mat-icon-button color="warn" type="button" (click)="onCancel()">
      cancel
    </button>
    <button mat-icon-button color="primary" type="submit">Save</button>
  </div>
</form>
