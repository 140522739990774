import { Injectable } from '@angular/core';
import { ItemState } from '@fp-tools/angular-state';
import { LoadingStatus } from '@fp-tools/angular-state/lib/loading-status';
import { ProjectVersion } from '@sympheny/project/data-access';
import { Observable } from 'rxjs';

import { OtherParameters } from './other-parameter.model';
import { OtherParameterService } from './other-parameter.service';

// generalParams: {exchangeCurrency: 'CHF', exchangeRate: 1, interestRate: 0},

@Injectable()
export class OtherParameterCollection extends ItemState<OtherParameters, any> {
  private projectVersion: ProjectVersion = 'V1';
  private scenarioGuid!: string;

  public setScenario(scenarioGuid: string, projectVersion: ProjectVersion) {
    this.projectVersion = projectVersion;
    this.scenarioGuid = scenarioGuid;
    this.load();
  }

  constructor(private readonly otherParameterService: OtherParameterService) {
    super();
    this.initialize();
  }

  public override selectLoading<KEY extends keyof LoadingStatus>(key: KEY) {
    return super.selectLoading(key);
  }
  protected fetchApi(): Observable<OtherParameters> {
    return this.otherParameterService.list(
      this.projectVersion,
      this.scenarioGuid
    );
  }

  protected override saveApi(
    data: Partial<OtherParameters>
  ): Observable<OtherParameters> {
    return this.otherParameterService.update(
      this.projectVersion,
      this.scenarioGuid,
      data
    );
  }

  public get exchangeCurrency() {
    return this.getData()?.exchangeCurrency ?? 'CHF';
  }

  public get exchangeRate() {
    return this.getData()?.exchangeRate ?? 1;
  }
}
