<sympheny-crud-list
  (addItem)="onHubAdd()"
  (editItem)="onEditHub($event)"
  (deleteItem)="deleteHub($event)"
  [items]="hubs$ | async"
  key="hubName"
  subject="HUBS"
  tooltip="SCENARIO.tooltip.hubs"
  scenarioStoreField="hubs"
  data-cy="HUBS.list"
>
  <span title>{{ 'SCENARIO.hubs' | transloco }}</span>
</sympheny-crud-list>
