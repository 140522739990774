import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  GuardsCheckEnd,
  Router,
} from '@angular/router';

import { BreadcrumbModel } from './breadcrumb.model';
import { BreadcrumbState } from '../breadcrumb.state';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public readonly labels = this.breadcrumbState.labels;

  constructor(
    private readonly router: Router,
    private readonly breadcrumbState: BreadcrumbState,
  ) {
    this.router.events.subscribe((event) => this.checkForBreadcrumbs(event));
  }

  public set(key: string, value: string) {
    this.breadcrumbState.set(key, value);
  }

  private checkForBreadcrumbs(event: any) {
    if (!(event instanceof GuardsCheckEnd)) {
      return;
    }
    const route = (event as GuardsCheckEnd).state.root;
    const breadcrumbs = this.extractBreadCrumb(route, [], '');
    this.breadcrumbState.setBreadcrumbs(breadcrumbs);
  }

  private extractBreadCrumb(
    route: ActivatedRouteSnapshot,
    previousBreadcrumb: BreadcrumbModel[],
    url: string,
  ): BreadcrumbModel[] {
    const path = route.url.map((r) => r.path).join('/');
    const nextUrl = `${url}${path}/`;
    const breadcrumb = route.data['breadcrumb'];
    const nextBreadCrumbs = [...previousBreadcrumb];
    if (breadcrumb && !breadcrumb.skip) {
      let label = breadcrumb;
      let subLabel = '';
      if (typeof breadcrumb !== 'string') {
        label = breadcrumb['label'];
        subLabel = breadcrumb['subLabel'];
      }

      nextBreadCrumbs.push({
        label,
        key: label,
        subLabel,
        route: nextUrl,
        translateLabel: breadcrumb.translateLabel ?? true,
        translateSubLabel: breadcrumb.translateSubLabel ?? true,
      });
    }

    if (route.firstChild) {
      return this.extractBreadCrumb(route.firstChild, nextBreadCrumbs, nextUrl);
    }
    return nextBreadCrumbs;
  }
}
