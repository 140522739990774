import {
  AvailableResourceTypeV1,
  IrradianceProfileTypeV1,
  OnSiteSolarResourceV1,
  SolarHubV1,
} from './on-site-solar-resource.model.v1';
import {
  AvailableResourceTypeV2,
  IrradianceProfileTypeV2,
  OnSiteSolarResourceV2,
  SolarHubV2,
} from './on-site-solar-resource.model.v2';

export type AvailableResourceType = AvailableResourceTypeV2 &
  AvailableResourceTypeV1;
export type IrradianceProfileType = IrradianceProfileTypeV1 &
  IrradianceProfileTypeV2;

export type OnSiteSolarResource = OnSiteSolarResourceV1 & OnSiteSolarResourceV2;
export type OnSiteSolarResourceHub = SolarHubV1 & SolarHubV2;
