<sympheny-dialog-form
  [form]="form"
  [hideTitle]="hideTitle"
  [hideActions]="hideActions"
  [contentLabel]="contentLabel"
  [tooltip]="tooltip"
  [editMode]="editMode"
  [submitLabel]="submitLabel"
  (submitForm)="onSubmitForm()"
>
  <ng-container title>
    <ng-content select="[title]"></ng-content>
  </ng-container>
  <ng-container content>
    <ng-content></ng-content>
    <ng-content select="[content]"></ng-content>
  </ng-container>
  <ng-container actions>
    <ng-content select="[actions]"></ng-content>
  </ng-container>
  <ng-container dialog-actions-additional-buttons>
    <ng-content select="[additional-buttons]"></ng-content>
  </ng-container>
</sympheny-dialog-form>
