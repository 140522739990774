<mat-expansion-panel
  [hideToggle]="disabled"
  class="panel-profile"
  [expanded]="expanded"
  [disabled]="disabled"
>
  <mat-expansion-panel-header
    [expandedHeight]="'48px'"
    [collapsedHeight]="'48px'"
  >
    <mat-panel-title>
      <sympheny-toggle
        [formControl]="formControl"
        (toggleChange)="enableToggle($event)"
      ></sympheny-toggle>
      <span (click)="enableToggle(true)">
        <ng-content select="[section-title]"></ng-content>
        {{ title | transloco }}</span
      >
    </mat-panel-title>
    <mat-panel-description *ngIf="description">
      {{ description | transloco }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="panel-content">
    <ng-content select="[section-content]"></ng-content>
  </div>
</mat-expansion-panel>
