<div class="card mx-3 p-5 min-h-[150px]">
  <sympheny-crud-table-detail
    class="max-w-[1200px]"
    [items]="items"
    [loading]="loading$ | async"
    [disabled]="disabled$ | async"
    [readonly]="readonly$ | async"
    [canEdit]="canEdit$ | async"
    [tooltip]="tooltip"
    [columns]="columns"
    [definedActions]="definedActions"
    [customActions]="customActions"
    [actions]="actions"
    [labelParams]="labelParams"
    [subject]="subject"
  >
    <span title>
      <ng-content select="[title]"></ng-content>
    </span>
    <span title-actions>
      <ng-content select="[title-actions]"></ng-content>
    </span>
  </sympheny-crud-table-detail>
</div>
