import { sumBy } from 'lodash';

import { GraphData, Link } from '../model';

// Compute the value (size) and cycleness of each node by summing the associated links.
export const computeNodeValues = (data: GraphData) => {
  data.forEachNode((node) => {
    const sourceLinks = data.getSourceLinks(node);
    const targetLinks = data.getTargetLinks(node);

    const value = Math.max(
      sumBy(sourceLinks, (n: Link) => n.value ?? 0),
      sumBy(targetLinks, (n: Link) => n.value ?? 0),
    );

    let findCirculair = sourceLinks.find((l) => l.circular);
    if (!findCirculair) {
      findCirculair = targetLinks.find((l) => l.circular);
    }

    node.setValue('value', value);
    node.setValue('partOfCycle', !!findCirculair);
    node.setValue('circularLinkType', findCirculair?.circularLinkType);
  });
};

export const setNodeValue = (data: GraphData, value: number) => {
  data.forEachNode((node) => {
    const sourceLinks = data.getSourceLinks(node);
    const targetLinks = data.getTargetLinks(node);

    let findCirculair = sourceLinks.find((l) => l.circular);
    if (!findCirculair) {
      findCirculair = targetLinks.find((l) => l.circular);
    }

    node.setValue('value', value);
    node.setValue('partOfCycle', !!findCirculair);
    node.setValue('circularLinkType', findCirculair?.circularLinkType);
  });
};
