export interface OptionValue<T> {
  label: string;
  value: T;
}

export interface RadioValue<T = string> extends OptionValue<T> {
  hint?: {
    text: string;
    link?: string;
  };
}
