// Custom validator to check that two fields match
export function MinMaxCompare(controls: [string, string]) {
  return (formGroup: any) => {
    const minControl = formGroup.controls[controls[0]];
    const maxControl = formGroup.controls[controls[1]];

    if (!minControl || !maxControl) {
      return null;
    }

    // Set error on matchingControl if validation fails
    if (
      minControl.value &&
      maxControl.value &&
      minControl.value > maxControl.value
    ) {
      minControl.setErrors({ minMax: true });
      maxControl.setErrors({ minMax: true });
    } else {
      if (minControl.value < 0) {
        minControl.setErrors({ negative: true });
      } else {
        if (maxControl.value < 0) {
          maxControl.setErrors({ negative: true });
        } else {
          minControl.setErrors(null);
          maxControl.setErrors(null);
        }
      }
    }
    return {};
  };
}
