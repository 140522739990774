import { z } from 'zod';

import { ExecutionOption, ExecutionResult } from './project.model';
import { Scenario } from './scenario.model';
export class Analysis {
  public analysisGuid: string;
  public analysisName: string;
  public created: Date;
  public updated: Date;
  public scenarios: Scenario[];
}

export class AnalysisDetails extends Analysis {
  public executionStatus: string;
  public executionInProgress: boolean;
  public coverImage: string;
  public projectName: string;
  public executionOptions: ExecutionOption;
  public results: ExecutionResult;
}

export const TemporalResolutions = ['LOW', 'MEDIUM', 'HIGH'] as const;
export const TemporalResolutionEnum = z.enum(TemporalResolutions);
export type TemporalResolution = z.infer<typeof TemporalResolutionEnum>;

export const ObjectivesV2 = [
  'MIN_LIFE_CYCLE_COST',
  'MIN_CO2_EMISSIONS',
  'MIN_ANNUALIZED_COST',
  'MIN_INVESTMENT',
  'MIN_OM',
  'MIN_FUEL_IMPORTS',
  'MIN_REPLACEMENT',
  'MAX_EXPORTS',
  'MAX_SALVAGE',
] as const;

export const ObjectiveV2Enum = z.enum(ObjectivesV2);
export type ObjectiveV2 = z.infer<typeof ObjectiveV2Enum>;
export const ObjectivesV1 = [
  'MINIMIZE_LIFE_CYCLE_COSTS',
  'MINIMIZE_CO2_EMISSIONS',
] as const;
export const ObjectiveV1Enum = z.enum(ObjectivesV1);
export type ObjectiveV1 = z.infer<typeof ObjectiveV1Enum>;

export type Objective = ObjectiveV1 | ObjectiveV2;

export const AnalysisRequestV1DtoSchema = z.object({
  mipGap: z.number().optional().nullable(),
  numberOfParetoPoints: z.number(),
  objective1: ObjectiveV1Enum,
  objective2: ObjectiveV1Enum,
  scenarios: z.array(z.string()),
});

export const AnalysisRequestV2DtoSchema = z.object({
  objective1: ObjectiveV2Enum,
  objective2: ObjectiveV2Enum,
  scenarioGuid: z.string(),
  scenarioName: z.string(),
  name: z.string(),
  clientType: z
    .enum(['APP'])
    .optional()
    .nullable()
    .transform(() => 'APP'),
  temporalResolution: TemporalResolutionEnum,
  points: z.number(),
  timeLimit: z.number(),
  mipGap: z.number(),
  inputFile: z.string().nullable().optional(),
});

export type AnalysisRequestDtoV1 = z.infer<typeof AnalysisRequestV1DtoSchema>;
export type AnalysisRequestDtoV2 = z.infer<typeof AnalysisRequestV2DtoSchema>;

export type AnalysisRequestDtoV =
  | AnalysisRequestDtoV1
  | (AnalysisRequestDtoV2 & { scenarios: string[] });
