import { Injectable, OnDestroy } from '@angular/core';
import { NetworkLink, NetworkTechnology } from '@sympheny/project/data-access';
import { LayerType } from '@sympheny/project/scenario/data-access';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  map,
  mapTo,
  merge,
  Subject,
  tap,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { createLayer } from './create-layer';
import { DrawLayerService } from './draw-layer.service';
import { MapNetworkLayer } from './map-network-layer';

export const NETWORK_LAYERS_GROUP_TITLE = 'Network Link Layer';
export const NETWORK_LAYERS_ACTIVE_GROUP_TITLE = 'Network Link Layer active';

@Injectable()
export class DrawNetworkLayerService
  extends DrawLayerService
  implements OnDestroy
{
  private readonly destroySingleNetwork$ = new Subject<void>();
  private networkLinkLayer: VectorLayer<any> | null = null;
  private networkLayersActive: LayerGroup | null = null;
  private readonly networkTechnologies = new BehaviorSubject<
    NetworkTechnology[]
  >([]);

  protected override getEffects() {
    return [this.networkLayersEffect()];
  }

  protected override init() {
    if (this.enableLayer && !this.networkLinkLayer) {
      this.networkLinkLayer = createLayer({
        features: [],
        title: NETWORK_LAYERS_GROUP_TITLE,
        visible: true,
      });
      this.map.addLayer(this.networkLinkLayer);
    }
  }

  public getDownloadableLayers() {
    return this.networkLinkLayer ? [this.networkLinkLayer] : [];
  }

  public setNetworkTechnologies(networkTechnologies: NetworkTechnology[]) {
    this.networkTechnologies.next(networkTechnologies);
  }

  public drawNetworkLink(networkLink: NetworkLink) {
    this.destroySingleNetwork$.next();

    if (!this.config.networkLink.edit || !networkLink) {
      return;
    }

    merge(this.networkLayerSingleDrawEffect(networkLink))
      .pipe(takeUntil(this.destroy$), takeUntil(this.destroySingleNetwork$))
      .subscribe();
  }

  private networkLayerSingleDrawEffect(networkLink: NetworkLink) {
    this.networkLayersActive = new LayerGroup({
      properties: { title: NETWORK_LAYERS_ACTIVE_GROUP_TITLE },
    });
    this.map.addLayer(this.networkLayersActive);

    const networkLayers = this.scenarioMapStore.getLayers$(
      LayerType.networkLayers,
    );

    return networkLayers.pipe(
      map((layers) =>
        layers.find(
          (layer) =>
            layer.network_id === networkLink.networkTechnologyGuid &&
            layer.link_id === networkLink.networkLinkGuid,
        ),
      ),
      tap((layer) =>
        this.setLayerGroup(this.networkLayersActive, layer ? [layer] : []),
      ),
    );
  }

  private enableLayer() {
    return !this.config.enableLayer || this.config.visibleLayer.networkLinks;
  }

  private networkLayersEffect() {
    if (!this.enableLayer) {
      this.networkLinkLayer = null;
      return EMPTY;
    }

    const networkLayers = this.scenarioMapStore.getLayers$(
      LayerType.networkLayers,
    );

    return combineLatest([networkLayers, this.networkTechnologies]).pipe(
      map(([layers, networkTechnologies]) =>
        layers.map((layer: MapNetworkLayer) => {
          const technology =
            (networkTechnologies as any)?.find(
              ({ networkTechnologyGuid }) =>
                networkTechnologyGuid === layer.network_id,
            ) ?? null;
          return layer.customAction(technology);
        }),
      ),
      tap((layers) => this.setLayer(this.networkLinkLayer, layers)),
      mapTo(undefined),
    );
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.networkTechnologies.complete();
    this.destroySingleNetwork$.next();
    this.destroySingleNetwork$.complete();
  }
}
