<div class="maintenance-text text-left pl-2 text-md">
  <h2 class="text-center text-lg underline">
    <strong>Exciting News! Version 3 Pre-Release is Here!</strong>
  </h2>
  <p>
    Discover the exciting new features and enhancements in our latest release.
    Please visit our
    <a
      class="maintenance-link"
      target="_blank"
      href="https://support.app.dev.sympheny.com/tutorials/latest/how-to-migrate-a-v2-project-to-v3"
      >'What's New'</a
    >
    page to explore all the updates and see how they can enhance your
    experience.
  </p>
  <p>
    Explore our new optimization engine in Beta V3. For more details, read our
    <a
      class="maintenance-link"
      target="_blank"
      href="https://support.app.dev.sympheny.com/tutorials/latest/how-to-migrate-a-v2-project-to-v3"
      >migration guide</a
    >.
  </p>
</div>
