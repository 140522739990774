import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnologyCollection } from '@sympheny/database/model';
import { StorageTechnology } from '@sympheny/project/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

@Injectable()
export class OrgDatabaseStorageTechnologyCollectionService extends TechnologyCollection<StorageTechnology> {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, {
      db: 'database-org',
      technology: 'storage-tech',
      categoryMapper: 'technologyCategories',
      technologyMapper: 'databaseStorageTechnologies',
      guid: 'storageTechGuid',
    });
  }
}
