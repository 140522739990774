import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type BannerColor = 'error' | 'info' | 'default';

interface BaseConfig {
  color: BannerColor;
  disableDismiss?: boolean;
  dismiss?: () => void;
  uid: string;
}
export interface BannerComponentConfig<C> extends BaseConfig {
  component: ComponentType<C>;
}
export interface BannerTextConfig extends BaseConfig {
  text: string;
}

export type BannerConfig<C = any> = BannerComponentConfig<C> | BannerTextConfig;

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private banners = new Map<string, BannerConfig>();
  banners$ = new BehaviorSubject<BannerConfig[]>([]);

  openBanner<C>(param: BannerConfig<C>) {
    this.banners$.next([...this.banners$.value, param]);
    this.banners.set(param.uid, param);
    this.updateBanners();
  }

  dismissBanner(uid: string) {
    this.banners.delete(uid);
    this.updateBanners();
  }

  private updateBanners() {
    this.banners$.next(Array.from(this.banners.values()));
  }
}
