import { LayerType } from '@sympheny/project/scenario/data-access';

import {
  MapCustomerLayer,
  MapCustomerScenarioLayer,
} from '../layer/map-customer-layer';
import { MapHubLayer } from '../layer/map-hub-layer';
import { MapNetworkLayer } from '../layer/map-network-layer';

export interface MapperDataType {
  [LayerType.customerLayer]: MapCustomerLayer;
  [LayerType.customerScenarioLayer]: MapCustomerScenarioLayer;
  [LayerType.networkLayers]: MapNetworkLayer;
  [LayerType.hubs]: MapHubLayer;
}

export const mapper = {
  [LayerType.customerLayer]: MapCustomerLayer,
  [LayerType.customerScenarioLayer]: MapCustomerScenarioLayer,
  [LayerType.networkLayers]: MapNetworkLayer,
  [LayerType.hubs]: MapHubLayer,
};
