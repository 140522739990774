import { Project, ProjectVersion, SecondaryOwner } from '../model';

export class ProjectDto {
  private constructor(projectName: string, version: ProjectVersion) {
    this.projectName = projectName;
    this.version = version;
  }

  public projectName: string;
  public version: ProjectVersion;
  public gisCentroidX?: number;
  public gisCentroidY?: number;
  public zoomExtentXmax?: number;
  public zoomExtentXmin?: number;
  public zoomExtentYmax?: number;
  public zoomExtentYmin?: number;
  public favorite = false;
  public ownedByCurrentUser = false;
  public secondaryOwner: SecondaryOwner | null = null;
  public secondaryOwners: SecondaryOwner[] | null = null;

  public static createNew(projectName: string, version: ProjectVersion) {
    return new ProjectDto(projectName, version);
  }

  public static fromExistingProject(project: Project) {
    const dto = new ProjectDto(project.projectName, project.version);

    dto.favorite = project.favorite;

    dto.zoomExtentXmin = project.zoomExtentXmin;
    dto.zoomExtentYmin = project.zoomExtentYmin;
    dto.zoomExtentXmax = project.zoomExtentXmax;
    dto.zoomExtentYmax = project.zoomExtentYmax;

    dto.gisCentroidX = project.gisCentroidX;
    dto.gisCentroidY = project.gisCentroidY;

    dto.ownedByCurrentUser = project.ownedByCurrentUser;
    dto.secondaryOwner = project.secondaryOwner;
    dto.secondaryOwners = project.secondaryOwners;

    return dto;
  }

  public updateName(projectName: string) {
    this.projectName = projectName;
    return this;
  }

  public updateFavorite(favorite: boolean) {
    // TODO update your own
    if (this.ownedByCurrentUser) this.favorite = favorite;
    if (this.secondaryOwner) {
      this.secondaryOwner.favorite = favorite;
      this.updateSecondaryOwners();
    }
    return this;
  }

  private updateSecondaryOwners() {
    this.secondaryOwners = this.secondaryOwners.map((s) =>
      s.email === this.secondaryOwner.email ? this.secondaryOwner : s
    );
  }

  public setViewExtent(viewExtent: number[]) {
    this.zoomExtentXmin = viewExtent[0];
    this.zoomExtentYmin = viewExtent[1];
    this.zoomExtentXmax = viewExtent[2];
    this.zoomExtentYmax = viewExtent[3];
    return this;
  }

  public setLocation(location: number[]) {
    this.gisCentroidX = location[0];
    this.gisCentroidY = location[1];
    return this;
  }
}
