import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { ProfileService } from './service/profile.service';
import { UserState } from './state/user.state';

export function provideUserState(): Provider[] {
  return [ProfileService, UserState];
}
@NgModule({
  imports: [CommonModule],
})
export class UserDataAccessModule {
  public static forRoot(): ModuleWithProviders<UserDataAccessModule> {
    return {
      ngModule: UserDataAccessModule,
      providers: provideUserState(),
    };
  }
}
