type LAYERS =
  | 'hubs'
  | 'parcel'
  | 'roof'
  | 'facade'
  | 'networkLinks'
  | 'building';

export interface MapConfiguration {
  download: boolean;
  displayToggle: boolean;
  layerSwitcher: boolean;
  select: { features?: boolean; address?: boolean };
  hub: { draw?: boolean; edit?: boolean };
  networkLink: { edit?: boolean };
  dragAndDrop?: {
    enabled: boolean;
  } | null;
  customerLayers: boolean;
  importGisData: boolean;
  /**
   * If nothing is configured by default all layers are added
   */
  enableLayer: Partial<Record<LAYERS, boolean>> | null;
  visibleLayer: Partial<Record<LAYERS, boolean>> | null;
  fullHeight: boolean;
}

export const defaultConfiguration: MapConfiguration = {
  download: false,
  displayToggle: false,
  layerSwitcher: false,
  select: { features: false, address: false },
  hub: { draw: false, edit: false },
  networkLink: { edit: false },
  dragAndDrop: null,
  customerLayers: false,
  importGisData: false,
  enableLayer: null,
  visibleLayer: null,
  fullHeight: false,
};
