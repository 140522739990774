import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import {
  Dataset,
  DatasetAddress,
  DatasetField,
  DataSetState,
} from '@sympheny/gis/datasets';
import { Address } from '@sympheny/project/scenario/data-access';
import { CheckboxComponent } from '@sympheny/ui/form';
import { Observable } from 'rxjs';

import { HubProcessed } from '../../layer/map-hub-layer';
import { MapStore } from '../../store/map.store';
import { BuildingInformationFieldComponent } from '../building-information-field/building-information-field.component';
import { buildingInformationConfiguration } from '../model/building-information';
import { SelectedBuildingInfo } from '../model/select-building-info';
import { SelectBuildingInformationComponent } from '../select-building-information/select-building-information.component';
import { SelectDatasetComponent } from '../select-dataset/select-dataset.component';

@Component({
  selector: 'sympheny-building-information',
  templateUrl: './building-information.component.html',
  styleUrls: ['./building-information.component.scss'],
  standalone: true,
  imports: [
    CheckboxComponent,
    ReactiveFormsModule,
    FormsModule,
    SelectDatasetComponent,
    SelectBuildingInformationComponent,
    NgIf,
    NgFor,
    BuildingInformationFieldComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class BuildingInformationComponent implements OnChanges {
  @Input() public disabled?: boolean;
  @Input() public canEdit?: boolean;
  @Input() public address: Address | null;
  @Input() public addressProcessed: HubProcessed;
  @Input() public datasetAddress: DatasetAddress | null;
  @Input() public selectedDataset: Dataset | null;
  @Input() public buildingInfoOptions: SelectedBuildingInfo;

  @Output() public readonly changeBuildingInfo =
    new EventEmitter<SelectedBuildingInfo>();
  @Output() public readonly toggleCo2Range = new EventEmitter<boolean>();

  public co2Range = false;

  public display: Array<keyof SelectedBuildingInfo> = [];
  public readonly buildingInformationConfiguration =
    buildingInformationConfiguration;
  public readonly datasets$ = this.datasetState
    .datasets$ as unknown as Observable<Dataset[]>;
  public otherInfo: Array<DatasetField> = [];

  constructor(
    private readonly datasetState: DataSetState,
    private readonly scenarioMapStore: MapStore,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address'] || changes['datasetAddress']) {
      this.configureBuildingInformation();
    }

    if (changes['datasetAddress']) {
      this.otherInfo =
        this.datasetAddress?.fields?.filter((field) => !!field.custom_name) ??
        [];
    }
  }

  configureBuildingInformation() {
    this.display = [];
    if (!this.buildingInfoOptions || !this.address) {
      return;
    }
    const keys: Array<keyof SelectedBuildingInfo> = [
      'buildingInfo',
      'energyInfo',
      'roofInfo',
      'facadeInfo',
      'minergieInfo',
      'others',
    ];
    keys.forEach((key) => {
      if (!this.buildingInfoOptions[key]) {
        return;
      }
      this.display.push(key);
    });
  }

  onChangeBuildingInfo(buildingInfoOptions: SelectedBuildingInfo) {
    this.buildingInfoOptions = buildingInfoOptions;
    this.configureBuildingInformation();
    this.changeBuildingInfo.emit(buildingInfoOptions);
  }

  onDeleteDataset(dataSet: Dataset) {
    this.scenarioMapStore.changeDataset(null);
    this.datasetState.delete(dataSet);
  }

  onChangeDataset(dataSet: Dataset) {
    this.scenarioMapStore.changeDataset(dataSet);
  }
}
