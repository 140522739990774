<mat-form-field
  [class.full-width]="config?.fullWidth ?? true"
  [attr.data-cy]="dataCy || label"
  [attr.data-testid]="dataCy || label"
>
  <mat-label *ngIf="label" class="input__label">
    {{ label | transloco }}
  </mat-label>
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="formControl"
    #inputElement
    [mask]="mask"
    autocomplete="off"
    [errorStateMatcher]="matcher"
    thousandSeparator="'"
  />
  <mat-hint *ngIf="requiredHint"
    >* {{ 'FORM.requiredHint' | transloco }}</mat-hint
  >
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>

<sympheny-info-button *ngIf="hint" [tooltip]="hint" [link]="hintLink">
</sympheny-info-button>
