<div
  tabindex="0"
  class="collapse collapse-arrow"
  [class.collapse-arrow]="opened"
>
  <input #chexbox type="checkbox" />
  <div class="collapse-title items-center p-0 ml-5 sidenav__item text-gray-100">
    <mat-icon class="text-primary text-[34px]" fontSet="material-icons-outlined"
      >{{ icon }}
    </mat-icon>
    <span class="" *ngIf="opened">
      {{ label }}
    </span>
  </div>
  <div class="collapse-content sidenav__item--content">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #content>
  <mat-icon class="sidenav__item--icon" fontSet="material-icons-outlined"
    >{{ icon }}
  </mat-icon>
  <span class="sidenav__item--label" *ngIf="opened">
    {{ label }}
  </span>
</ng-template>
