<sympheny-select
  class="flex-1"
  [formControl]="buildingDataAll"
  [options]="buildingDataList"
  labelKey="label"
  valueKey="key"
  [multiple]="true"
  [disableSelectAll]="true"
  placeholder="Select the different data categories to display on the GIS for the selected building"
  (selectionChange)="buildingInfoChange()"
  hint="Please request further information about available data add-ons to your Sympheny customer service"
  hintLink="https://www.sympheny.com/packages#addons"
>
</sympheny-select>
