import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';

@Component({
  selector: 'sympheny-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [
    TranslocoPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InfoButtonComponent,
    MatInputModule,
    NgIf,
    MatCheckboxModule,
  ],
})
export class CheckboxComponent extends BaseFormComponent<
  boolean,
  FormFieldConfig
> {
  @Output() public readonly changeChecked = new EventEmitter<boolean>();

  public onChangeChecked() {
    this.changeChecked.emit(this.formControl.value);
  }
}
