import {
  ConversionTechnologyListV1,
  ConversionTechnologySchemaV1,
  ConversionTechnologyV1,
} from './conversion-technology.model.v1';
import {
  ConversionTechnologyListV2,
  ConversionTechnologySchemaV2,
  ConversionTechnologyV2,
} from './conversion-technology.model.v2';

export const ConversionTechnologySchema = ConversionTechnologySchemaV1.merge(
  ConversionTechnologySchemaV2,
);

export type ConversionTechnology = ConversionTechnologyV1 &
  ConversionTechnologyV2;
export type ConversionTechnologyList = ConversionTechnologyListV1 &
  ConversionTechnologyListV2;
