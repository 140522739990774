import { TransformFnParams } from 'class-transformer/types/interfaces';

export const parseTime = (value: string) => {
  if (!value || value.length === 5) {
    return value;
  }

  return `0${value}`;
};

export const transformTime = ({ value }: TransformFnParams) => parseTime(value);
