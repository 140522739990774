import {
  SymphenyDate,
  SymphenyNumber,
  UploadFileSchema,
} from '@sympheny/utils/data-access';
import { z } from 'zod';

export const AvailableResourceTypeV1Enum = z.enum([
  'Area',
  'Power output',
  'Generic',
]);
export type AvailableResourceTypeV1 = z.infer<
  typeof AvailableResourceTypeV1Enum
>;

export const IrradianceProfileTypeV1Enum = z.enum([
  'GENERATED',
  'UPLOADED',
  'UPLOADED_GIS',
  'SAVED',
]);
export type IrradianceProfileTypeV1 = z.infer<
  typeof IrradianceProfileTypeV1Enum
>;

export const SolarHubV1Schema = z.object({
  hubGuid: z.string().nullish(),
  hubName: z.string().nullish(),

  availableResourceType: AvailableResourceTypeV1Enum,
  availableSolarCollectorArea: SymphenyNumber().nullish(),
  technologyDimensioningStdValue: SymphenyNumber().nullish(),
});

export const OnSiteSolarResourceV1Schema = z.object({
  created: SymphenyDate().nullish(),
  energyCarrierGuid: z.string().nullish(),
  energyCarrierName: z.string().nullish(),
  hubs: z.array(SolarHubV1Schema),
  irradianceProfileType: IrradianceProfileTypeV1Enum.nullish(),
  name: z.string().nullish(),
  profileId: SymphenyNumber().nullish(),
  solarResourceGuid: z.string().nullish(),
  solarResourceMetadataDbOrganization: z.string().nullish(),
  solarResourceMetadataGuid: z.string().nullish(),
  solarResourceMetadataLocation: z.string().nullish(),
  solarResourceMetadataName: z.string().nullish(),
  solarResourceMetadataOrientation: z.string().nullish(),
  solarResourceMetadataSlope: SymphenyNumber().nullish(),
  solarResourceMetadataType: z.string().nullish(),
  stages: z.array(z.string()).nullish(),
  updated: SymphenyDate().nullish(),
});

export const OnSiteSolarResourceV1ListSchema = OnSiteSolarResourceV1Schema;

export const OnSiteSolarResourceV1DtoSchema = OnSiteSolarResourceV1Schema.pick({
  energyCarrierGuid: true,
  hubs: true,
  name: true,
  profileId: true,
  stages: true,
}).extend({
  databaseSolarResourceMetadataName: z.string().nullish(),
  userSolarResourceMetadataGuid: z.string().nullish(),
  solarResourceName: z.string().nullish(),
  profile: IrradianceProfileTypeV1Enum.nullish(),
  fileRequestDto: UploadFileSchema().nullish(),
});

export type SolarHubV1 = z.infer<typeof SolarHubV1Schema>;
export type OnSiteSolarResourceV1 = z.infer<typeof OnSiteSolarResourceV1Schema>;
export type OnSiteSolarResourceV1Dto = z.infer<
  typeof OnSiteSolarResourceV1DtoSchema
>;
export type OnSiteSolarResourceV1List = z.infer<
  typeof OnSiteSolarResourceV1ListSchema
>;
