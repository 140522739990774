import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SymphenyErrorHandler } from '../error.handler';

export const serverErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const errorHandler = inject(SymphenyErrorHandler);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error?.error?.status?.code?.includes('ESP')) {
        errorHandler.handleEspError(error);
      }

      return throwError(() => error);
    }),
  );
};
