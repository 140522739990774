import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoadDataService } from '@sympheny/utils/data-access';

import {
  DatabaseProfileExportCollection,
  EwzExportCollection,
  OrgDatabaseExportCollection,
} from './export';
import {
  DatabaseProfileImportCollection,
  EwzImportCollection,
  OrgDatabaseImportCollection,
} from './import';

const collections = [
  DatabaseProfileImportCollection,
  EwzImportCollection,
  OrgDatabaseImportCollection,
  // TODO NYI  UserImportCollection,
  DatabaseProfileExportCollection,
  EwzExportCollection,
  OrgDatabaseExportCollection,
  // TODO NYI UserExportCollection,
];
@NgModule({
  imports: [CommonModule],
})
export class DatabaseDataAccessImpexModule {
  public static forRoot(): ModuleWithProviders<DatabaseDataAccessImpexModule> {
    return {
      ngModule: DatabaseDataAccessImpexModule,
      providers: collections
        .map((collection) => [
          collection,
          {
            multi: true,
            provide: LoadDataService,
            useExisting: collection,
          },
        ])
        .flat(),
    };
  }
}
