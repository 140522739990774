import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@fp-tools/angular-state';
import { mapData, ResponseModel } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable } from 'rxjs';

import { PredefinedEnergyCarrier } from './predefined-energy-carriers.model';

@Injectable()
export class PredefinedEnergyCarriersCollection extends Collection<
  PredefinedEnergyCarrier,
  any
> {
  protected readonly idKey: any = null;

  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {
    super();
  }

  protected readonly base = this.environmentService.getValue('base');

  protected fetchApi(): Observable<PredefinedEnergyCarrier[]> {
    return this.http
      .get<
        ResponseModel<{
          predefinedCarriers: PredefinedEnergyCarrier;
        }>
      >(`${this.base}energy-carriers`)
      .pipe(mapData('predefinedCarriers'));
  }
}
