import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnologyCollection } from '@sympheny/database/model';
import {
  ConversionTechnology,
  ConversionTechnologyDatabaseSchema,
  ConversionTechnologyDtoV1,
  ConversionTechnologyDtoV2,
} from '@sympheny/project/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';

@Injectable()
export class UserConversionTechnologyCollection extends TechnologyCollection<ConversionTechnology> {
  constructor(http: HttpClient, environmentService: EnvironmentService) {
    super(http, environmentService, {
      db: 'user',
      technology: 'conversion-tech',
      saveUrl: `user-conversion-technologies/saved-profiles`,
      categoryMapper: 'technologyCategories',
      technologyMapper: 'databaseConversionTechnologies',
      guid: 'conversionTechGuid',
      responseSchema: ConversionTechnologyDatabaseSchema,
      requestSchema: ConversionTechnologyDtoV1,
      requestSchemaV2: ConversionTechnologyDtoV2,
    });
  }
}
