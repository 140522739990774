import { NgIf, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'sympheny-scenario-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  standalone: true,
  imports: [MatExpansionModule, NgIf, DatePipe],
})
export class SummaryComponent {
  @Input() public notes: string | null = null;
  @Input() public showLastUpdated: boolean;
  @Input() public lastUpdated: null | Date | string | false = false;
  @Input() public source: null | string | false = false;

  public get sourceLink() {
    const [_, link] = this.source ? this.source.split(';') : [null, null];

    return link?.trim() ?? this.source;
  }
}
