import { AtLeastOne } from './lib/at-least-one.validator';
import { conditionalValidator } from './lib/conditional.validator';
import { invalidCharacters } from './lib/invalidCharacters.validator';
import {
  MaxFileSizeBytesValidator,
  MaxFileSizeValidator,
} from './lib/max-file-size.validator';
import { NotNegative } from './lib/not-negative.validator';
import { PercentValidator } from './lib/percent.validator';
import { sameValueIgnoreCase } from './lib/same-value.validator';
import { Unique } from './lib/unique.validator';

export * from './lib/invalidCharacters.validator';
export * from './lib/min-max.validator';
export * from './lib/max-file-size.validator';
export * from './lib/not-negative.validator';
export * from './lib/required-if.validator';
export * from './lib/startsWithDigit.validator';
export * from './lib/unique.validator';

export const SymphenyValidator = {
  conditional: conditionalValidator,
  sameValueIgnoreCase,
  maxFileSizeValidator: MaxFileSizeValidator,
  maxFileSizeBytesValidator: MaxFileSizeBytesValidator,
  invalidCharacters,
  unique: Unique,
  notNegative: NotNegative,
  percentValidator: PercentValidator,
  atLeastOne: AtLeastOne,
};
