import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EnvironmentService } from '@sympheny/utils/environment';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { LoadDataService } from './load-data.service';

@Injectable()
export abstract class Collection<T> implements LoadDataService {
  private readonly data = new BehaviorSubject<T[]>([]);
  public readonly data$ = this.data.asObservable();
  public abstract readonly guidKey: keyof T | null;

  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService
  ) {}

  protected readonly base = this.environmentService.getValue('base');

  public load() {
    this.listDataApi().subscribe((data) => this.data.next(data));
  }

  public reset() {
    this.data.next([]);
  }

  public async create(data: Partial<T>) {
    const tech = await firstValueFrom(this.createApi(data));

    this.data.next([...this.data.value, tech]);

    return tech;
  }

  public async update(guid: string, data: Partial<T>) {
    const tech = await firstValueFrom(this.updateApi(guid, data));

    this.data.next(
      this.data.value.map((v) => (this.isValueGuid(guid, v) ? tech : v))
    );

    return tech;
  }

  public async delete(guid: string) {
    const deleteTech = await firstValueFrom(this.deleteApi(guid));

    this.data.next(this.data.value.filter((v) => !this.isValueGuid(guid, v)));

    return guid;
  }

  private isValueGuid(guid: string, value: T) {
    if (!this.guidKey) {
      return value === guid;
    }

    return value[this.guidKey] === guid;
  }

  protected listDataApi(): Observable<T[]> {
    throw new Error('implement create api call');
  }

  protected deleteApi(guid: string): Observable<string> {
    throw new Error('implement create api call');
  }

  protected updateApi(guid: string, data: Partial<T>): Observable<T> {
    throw new Error('implement create api call');
  }

  protected createApi(data: Partial<T>): Observable<T> {
    throw new Error('implement create api call');
  }
}
