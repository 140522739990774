import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UploadFile } from '@sympheny/ui/upload';
import { UserState, isEwzOrganization } from '@sympheny/user/data-access';
import { ResponseModel } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { combineLatest, firstValueFrom, map, shareReplay } from 'rxjs';

import { DB_TYPES, TECH, TECHNOLOGY_TYPE } from './const';

interface PresignedUrl {
  s3PresignedUrl: string;
}

@Injectable()
export class DatabaseService {
  public readonly enablOrgDb$ =
    this.userState.getPlanLimitation('organizationDb');

  public readonly databases$ = combineLatest([
    this.userState.selectOrganization('name'),
    this.userState.su$,
  ]).pipe(
    map(([organization, su]) =>
      [
        su && organization === 'Sympheny'
          ? { label: 'Sympheny database', value: 'database' }
          : null,
        su
          ? { label: `${organization} database`, value: 'database-org' }
          : null,
        //   { label: 'My User database', value: 'user' },
      ].filter((db) => !!db)
    ),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(
    private readonly userState: UserState,
    private readonly httpClient: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {}

  public async append(
    db: DB_TYPES,
    technology: TECH,
    file: UploadFile,
    organisationName: string
  ) {
    return this.appendNormal(db, technology.value, file, organisationName);
  }

  public async upload(
    db: DB_TYPES,
    technology: TECH,
    file: UploadFile,
    organisationName: string
  ) {
    if (technology.presigned) {
      return this.uploadPresigned(db, technology.value, file, organisationName);
    }
    return this.uploadNormal(db, technology.value, file, organisationName);
  }

  public async download(
    db: DB_TYPES,
    technology: TECH,
    organisationName: string
  ) {
    const url = this.getUrl(
      'db-download/',
      db,
      technology.value,
      '',
      organisationName
    );

    return firstValueFrom(this.httpClient.get(url));
  }
  private async uploadPresigned(
    db: DB_TYPES,
    technology: TECHNOLOGY_TYPE,
    file: UploadFile,
    organisationName: string
  ) {
    const presignedUrl = await this.getPresignedUrl(file);

    const url = `${this.getPresignedPutUrl(db, technology, organisationName)}`;

    return firstValueFrom(this.httpClient.put(url, presignedUrl));
  }

  private async uploadNormal(
    db: DB_TYPES,
    technology: TECHNOLOGY_TYPE,
    file: UploadFile,
    organisationName: string
  ) {
    const url = this.getPutUrl(db, technology, organisationName);

    return firstValueFrom(this.httpClient.put(url, file));
  }

  public async appendNormal(
    db: DB_TYPES,
    technology: TECHNOLOGY_TYPE,
    file: UploadFile,
    organisationName: string
  ) {
    const url = this.getPutUrl(db, technology, organisationName);

    return firstValueFrom(this.httpClient.post(url, file));
  }

  private getPresignedPutUrl(
    db: DB_TYPES,
    type: TECHNOLOGY_TYPE,
    organisationName: string
  ) {
    return this.getUrl(
      'db-update/',
      db,
      type,
      's3-presigned-url/',
      organisationName
    );
  }

  protected async getPresignedUrl(file: UploadFile) {
    const baseUrl = this.environmentService.getValue('base');
    const url = `${baseUrl}db-update/s3-presigned-url`;

    const { data } = await firstValueFrom(
      this.httpClient.get<ResponseModel<PresignedUrl>>(url)
    );
    await firstValueFrom(
      this.httpClient.put(data.s3PresignedUrl, file.file, {
        headers: {
          'content-type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
    );

    return data;
  }

  private getPutUrl(
    db: DB_TYPES,
    type: TECHNOLOGY_TYPE,
    organisationName: string
  ) {
    return this.getUrl('db-update/', db, type, '', organisationName);
  }

  private getUrl(
    prefix: string,
    db: DB_TYPES,
    type: TECHNOLOGY_TYPE,
    suffix: string,
    organisationName: string
  ) {
    const baseUrl = this.environmentService.getValue('base');
    const base = `${baseUrl}${prefix}${type}/${suffix}`;

    if (db === 'database') {
      return `${base}PROFILE_TYPE_DATABASE`;
    }
    if (db === 'database-org') {
      return `${base}${this.userState.organisationName}`;
    }
    if (db === 'customer') {
      return `${base}${organisationName}`;
    }

    return `${base}PROFILE_TYPE_USER`;
  }

  public getDatabases(
    enableEwz: boolean,
    showUser: boolean,
    showOrganisation = true,
    showSympheny = true
  ) {
    return combineLatest([
      this.userState.selectOrganization('name'),
      this.enablOrgDb$,
    ]).pipe(
      map(([organization, enablOrgDb]) =>
        [
          showSympheny
            ? { label: 'Sympheny Global database', value: 'database' }
            : null,
          showOrganisation && enablOrgDb
            ? { label: `${organization} database`, value: 'database-org' }
            : null,
          enableEwz && isEwzOrganization(organization)
            ? { label: 'ewz Technology Database', value: 'ewz' }
            : null,
          showUser && { label: 'My User database', value: 'user' },
        ].filter((o) => !!o)
      )
    );
  }
}
