<button
  [attr.data-cy]="dataCy ?? 'sympheny-button'"
  mat-button
  mat-raised-button
  [color]="color"
  [disabled]="disabled"
  [attr.type]="buttonType"
  (click)="action.emit()"
  [matTooltip]="tooltip"
>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  <span class="text-sm"> <ng-content></ng-content></span>
</button>
