<p *ngIf="hubs?.length === 0; else displayHubs" class="no-hubs">
  {{ 'HUBS.description' | transloco }}
</p>
<ng-template #displayHubs>
  <mat-tab-group
    class="hub-tab-group"
    [selectedIndex]="selectedIndex"
    *ngIf="selectedHubGuid$ | async as selectedHubGuid"
    id="auto_trigger"
    (selectedTabChange)="selectHub($event.index)"
    #tabGroup
  >
    <mat-tab
      class="tab-card"
      *ngFor="let hub of hubsSorted; let i = index"
      label="{{ hub.hubName }}"
    >
      <ng-template mat-tab-label>
        <span class="hub-tabs" [attr.data-guid]="hub.hubGuid">
          {{ hub.hubName }}
        </span>
      </ng-template>
      <sympheny-hub-diagram
        [scenarioId]="scenarioId"
        [hubId]="hub.hubGuid"
        [active]="selectedHubGuid === hub.hubGuid"
        [reloadHubDiagram]="reloadHubDiagram$ | async"
      ></sympheny-hub-diagram>
    </mat-tab>
  </mat-tab-group>
</ng-template>
