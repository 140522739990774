import { SymphenyNumber, SymphenyUtcDate } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const JobStatusEnum = z.enum([
  'PENDING',
  'QUEUED',
  'VALIDATING',
  'VALID',
  'RUNNING',
  'DONE',
  'INVALID',
  'STOPPED',
  'FAILED',
]);

export type JobStatus = z.infer<typeof JobStatusEnum>;

export const JobStatusTerminated: JobStatus[] = [
  'DONE',
  'STOPPED',
  'FAILED',
  'INVALID',
];
export const JobStatusInvalid: JobStatus[] = ['FAILED', 'INVALID'];

export const ScenarioJobSchema = z.object({
  name: z.string(),
  objective1: z.string(),
  objective2: z.string(),
  scenarioGuid: z.string(),
  scenarioName: z.string(),
  clientType: z.string(),
  temporalResolution: z.string(),
  points: SymphenyNumber(),
  timeLimit: SymphenyNumber(),
  mipGap: SymphenyNumber(),
  accountGuid: z.string(),
  batchId: z.string().nullish(),
  status: JobStatusEnum,
  statusMsg: z.string(),
  started: SymphenyUtcDate().nullish(),
  terminated: SymphenyUtcDate().nullish(),
  pointsCompleted: SymphenyNumber(),
  id: z.string(),
  created: SymphenyUtcDate(),
  inputFile: z.string().nullish(),
  outputFile: z.string().nullish(),
  infeasibilityInfo: z.string().nullish(),
});

export type ScenarioJob = z.infer<typeof ScenarioJobSchema>;
export const ScenarioJobEnrichedSchema = ScenarioJobSchema.extend({
  executionTime: SymphenyNumber().optional(),
});
export type ScenarioJobEnriched = z.infer<typeof ScenarioJobEnrichedSchema>;

export const ScenarioJobDetailSchema = ScenarioJobSchema.extend({
  inputFile: z.string().nullish(),
  outputFile: z.string().nullish(),
});
export type ScenarioJobDetail = z.infer<typeof ScenarioJobDetailSchema>;
