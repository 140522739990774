import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { PlanLimitation } from '@sympheny/user/data-access';
import { Subject, takeUntil, tap } from 'rxjs';

import { PlanService } from './plan.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPlan]',
  standalone: true,
})
export class HasPlanDirective implements OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(
    private planService: PlanService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  public set hasPlan(key: keyof PlanLimitation) {
    this.destroy$.next();
    this.planService
      .isEnabled$(key)
      .pipe(
        tap((display) => this.displayValue(display)),

        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private displayValue(display: boolean) {
    if (display) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
