import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseModel, mapDataZ } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectVersion, Scenario } from '../model';
import { ScenarioSchema } from '../model/scenario.model';

type ScenarioResponse = ResponseModel<Scenario>;

@Injectable()
export class ScenarioService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {}

  private readonly base = this.environmentService.getValue('base');
  private readonly gis =
    this.environmentService.getValue('apiServices') + 'gis/';

  public get(scenarioGuid: string, projectVersion: ProjectVersion) {
    return firstValueFrom(
      this.http
        .get<ResponseModel<Scenario>>(
          `${this.environmentService.base}scenario/${scenarioGuid}`,
        )
        .pipe(mapDataZ(ScenarioSchema)),
    );
  }

  /**
   * Create scenario for analysis
   */
  public createScenario(
    analysisGuid: string,
    scenarioName: string,
  ): Observable<Scenario> {
    return this.http
      .post<ResponseModel<Scenario>>(
        `${this.base}analysis/${analysisGuid}/scenario`,
        {
          scenarioName: scenarioName,
        },
      )
      .pipe(mapDataZ(ScenarioSchema));
  }

  /**
   * Delete scenario
   */
  public deleteScenario(scenarioGuid: string): Observable<any> {
    return this.http
      .delete(this.base + 'scenario/' + scenarioGuid)
      .pipe(tap(() => this.deleteGisScenario(scenarioGuid)));
  }

  private deleteGisScenario(scenarioId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.delete(
      `${this.gis}scenarios/${scenarioId}/hubs`,
      httpOptions,
    );
  }

  /**
   * Copy scenario
   */
  public copyScenario(scenarioGuid: string): Observable<Scenario> {
    return this.http
      .put<ScenarioResponse>(`${this.base}scenarios/copy/${scenarioGuid}`, '')
      .pipe(mapDataZ(ScenarioSchema));
  }

  /**
   * Rename scenario
   */
  public renameScenario(
    scenarioGuid: string,
    scenarioName: string,
  ): Observable<Scenario> {
    return this.http
      .put<ScenarioResponse>(`${this.base}scenarios/${scenarioGuid}`, {
        scenarioName: scenarioName,
      })
      .pipe(mapDataZ(ScenarioSchema));
  }

  /**
   * Finish a scenario
   *
   * @param scenarioGuid: string
   */
  public finishScenario(scenarioGuid: string, projectVersion: ProjectVersion) {
    if (projectVersion === 'V1')
      return firstValueFrom(
        this.http.put(`${this.base}scenarios/${scenarioGuid}/specs`, {}),
      );

    return firstValueFrom(
      this.http.put(`${this.base}v2/specs`, {
        scenarioGuids: [scenarioGuid],
      }),
    );
  }

  /**
   * Get Scenario status
   */
  public getScenarioStatus(scenarioGuid: string): Observable<boolean> {
    return this.http
      .get<ResponseModel<{ executionInProgress: boolean }>>(
        `${this.base}scenarios/${scenarioGuid}/status`,
      )
      .pipe(map((r) => r.data.executionInProgress));
  }
}
