import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
export type IconSize = 'small' | 'regular' | 'xsmall';

@Component({
  selector: 'sympheny-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class IconComponent {
  @Input() public tooltip = '';
  @Input() public size: IconSize = 'regular';
  @Input() public icon = '';

  @Input() public fontSet: string;
  @Input() public dataCy: string;
}
