export class SlopeAndOrientation {
  public dbOrganization: string | null;
  public name: string;
  public optimal: boolean;
  public orientation: string;
  public slope: number;
  public slopeAndOrientation: string;
}

export class SolarEnergyDatabaseProfile {
  public name: string;
  public location: string;
  public type: string;
  public slope: number;
  public azimuth: number;
  public orientation: string;
  public optimal: boolean;
  public notes: string;
  public source: string;
  public minimumProfileValue: number;
  public maximumProfileValue: number;
  public averageProfileValue: number;
  public dbOrganization?: any;
}
