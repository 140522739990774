import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { sortData } from '@sympheny/utils/sort';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { OptionValue } from '../model/option-value.model';

@Component({
  selector: 'sympheny-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  standalone: true,
  imports: [
    InfoButtonComponent,
    NgIf,
    NgFor,
    MatFormFieldModule,
    TranslocoPipe,
  ],
})
export class SelectListComponent<T>
  extends BaseFormComponent<any, FormFieldConfig>
  implements OnChanges
{
  @Input() public labelKey: keyof T | 'label' = 'label';
  @Input() public valueKey: keyof T | 'value' = 'value';
  @Input() public options: Array<OptionValue<T> | T> = [];
  public sortedOptions: Array<OptionValue<T> | T> = [];
  @Input() public multi = false;
  @Input() public sortByValue = false;

  public getLabel(option: any) {
    return option[this.labelKey];
  }

  public selectValue(option: any) {
    const value = this.multi ? this.getValues(option) : this.getValue(option);
    this.onChange(value);
  }

  public getValue(option: any) {
    return option[this.valueKey];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] || changes['labelKey'] || changes['sortByValue']) {
      this.sortedOptions = this.sortByValue
        ? sortData<OptionValue<T> | T>(this.options).byAttribute(
            this.labelKey as any,
            undefined,
            'asc',
          )
        : this.options;
    }
  }

  private getValues(option: any) {
    const value = this.getValue(option);
    const values = this.getSelectedValues().filter((v) => v !== value);

    if (!this.isSelected(option)) {
      values.push(value);
    }
    return values;
  }

  public isSelected(option: any) {
    const value = this.getValue(option);

    return this.getSelectedValues().includes(value);
  }

  private getSelectedValues(): any[] {
    return (
      (this.multi ? this.formControl.value : [this.formControl.value]) ?? []
    );
  }
}
