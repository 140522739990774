import { Injectable } from '@angular/core';
import { CrudService, ProjectVersion } from '@sympheny/project/data-access';
import { mapData, ResponseModel } from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo } from 'rxjs';

import { Profile } from './profile.model';

export enum ProfileApiTypes {
  database,
  save,
}

@Injectable()
export class ProfileService extends CrudService<Profile> {
  public readonly guidKey = 'id';
  public readonly key = 'profiles';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<ResponseModel<Profile[]>>(
        `${this.base}scenarios/${scenarioGuid}/profiles`,
      )
      .pipe(mapData());
  }

  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: any,
  ) {
    const url = `scenarios/${scenarioGuid}/profiles`;
    return firstValueFrom(
      this.http
        .post<ResponseModel<Profile>>(`${this.base}${url}`, data)
        .pipe(mapData()),
    );
  }

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: any,
  ) {
    const url = `scenarios/${scenarioGuid}/profiles/${guid}`;
    return firstValueFrom(
      this.http
        .put<ResponseModel<Profile>>(`${this.base}${url}`, data)
        .pipe(mapData()),
    );
  }

  public override get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    const url = `scenarios/${scenarioGuid}/profiles/${guid}`;

    return firstValueFrom(
      this.http
        .get<ResponseModel<Profile>>(`${this.base}${url}`)
        .pipe(mapData()),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    const url = `scenarios/${scenarioGuid}/profiles/${guid}`;
    return firstValueFrom(this.http.delete(url).pipe(mapTo(guid)));
  }
}
