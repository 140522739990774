import {
  FormControl,
  FormControlOptions,
  FormControlState,
} from '@angular/forms';
import { FormatValueConfig } from '@sympheny/format-value';

export type FormControlFormat<T> = Pick<
  FormatValueConfig<T>,
  'label' | 'suffix' | 'params' | 'hint' | 'hintLink'
>;

export class SymphenyFormControl<T> extends FormControl<T> {
  constructor(
    value: FormControlState<T> | T,
    public readonly format: FormControlFormat<T>,
    opts: FormControlOptions = {},
  ) {
    super(value ?? null, opts);
  }
}
