<sympheny-scenario-form-dialog
  [formGroup]="form"
  [form]="form"
  [editMode]="!!editHub"
  (submitForm)="submitHub()"
  contentLabel="HUBS.hub.label"
  tooltip="HUBS.description.drawing"
>
  <div content>
    <div class="flex gap-4 items-center">
      <sympheny-input
        [config]="{
          minLength: 1,
          maxLength: 100,
          initialFocus: true
        }"
        formControlName="name"
        label="Name"
        [errorMessages]="errorMessages"
        dataCy="HUBS.name"
      ></sympheny-input>
      <mat-slide-toggle formControlName="grouping" color="primary">
        {{ 'HUBS.label.on' | transloco }}
      </mat-slide-toggle>

      <ng-container *ngIf="form.value.grouping">
        <sympheny-select
          formControlName="dataset"
          label="HUBS.dataset"
          [options]="datasets$ | async"
          labelKey="dataset_name"
          valueKey="dataset_id"
          (selectionChange)="changeDataset($event)"
        ></sympheny-select>
        <sympheny-select
          formControlName="hubLabel"
          label="HUBS.hubLabel"
          [options]="hubLabels"
        ></sympheny-select>
      </ng-container>
    </div>

    <sympheny-scenario-map
      *ngIf="gisOn"
      [config]="scenarioMapConfiguration"
      [scenarioId]="scenarioId"
      [editableHubId]="editHub?.hubGuid"
      [newHub]="!editHub"
      [hubs]="definedHubs"
      (hubChange)="updateHubFeature($event)"
    ></sympheny-scenario-map>
    <mat-error *ngIf="hubNextError" class="step-level-err-message">{{
      hubNextError | transloco
    }}</mat-error>
  </div>

  <div actions>
    <sympheny-checkbox *ngIf="sepOn$ | async" formControlName="processSep">
      {{ 'HUBS.processSEP' | transloco }}
    </sympheny-checkbox>
    <sympheny-checkbox formControlName="processGeoadmin">
      {{ 'HUBS.processGeoadmin' | transloco }}
    </sympheny-checkbox>
  </div>
</sympheny-scenario-form-dialog>
<sympheny-scenario-map
  *ngIf="gisOn && false"
  [config]="scenarioMapConfiguration"
  [scenarioId]="scenarioId"
  [editableHubId]="editHub?.hubGuid"
  [newHub]="!editHub"
  [hubs]="definedHubs"
  (hubChange)="updateHubFeature($event)"
></sympheny-scenario-map>
