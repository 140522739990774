import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { EnergyCarrier, EnergyCarrierSchema } from './energy-carrier.model';
import { Hub, HubSchema } from './hub.model';
import {
  AdvancedPriceComponentSchema,
  TechnologyAdvancedPriceComponent,
} from './price-component.model';
export class IntraHubNetworkLink {
  public intraHubNetworkLinkGuid: string;
  public name: string;
  public networkLoss: number;
  public fixedEmbodiedCo2: number;
  public inputEnergyCarrier: EnergyCarrier;
  public outputEnergyCarrier: EnergyCarrier;
  public hub: Hub;
  public hubs: Hub[];
  public advancedCostComponents: TechnologyAdvancedPriceComponent[];
  public created: string;
  public updated: string;
  public stages: string[];
}

export const IntraHubNetworkLinkSchemaV1 = z.object({
  advancedCostComponents: AdvancedPriceComponentSchema,
  created: SymphenyDate().nullish(),
  fixedEmbodiedCo2: SymphenyNumber(),
  hubs: HubSchema,
  inputEnergyCarrier: EnergyCarrierSchema,
  intraHubNetworkLinkGuid: z.string(),
  name: z.string(),
  networkLoss: z.number(),
  outputEnergyCarrier: EnergyCarrierSchema,
  stages: z.array(z.string()).nullish(),
  updated: SymphenyDate().nullish(),
});
export type IntraHubNetworkLinkV1 = z.infer<typeof IntraHubNetworkLinkSchemaV1>;

export const IntraHubNetworkLinkSchemaV2 = IntraHubNetworkLinkSchemaV1.extend(
  {},
);

export type IntraHubNetworkLinkV2 = z.infer<typeof IntraHubNetworkLinkSchemaV2>;

export type IntraHubNetworkLink_ = IntraHubNetworkLinkV1 &
  IntraHubNetworkLinkV2;
