import z from 'zod';

export const ResultCostCo2Schema = z.object({
  point: z.number().nullish(),
  netPresent: z.number().nullish(),
  importCost: z.number().nullish(),
  exportRevenue: z.number().nullish(),
  investment: z.number().nullish(),
  replacement: z.number().nullish(),
  om: z.number().nullish(),
  salvageRevenue: z.number().nullish(),
  demandRevenue: z.number().nullish(),
  totalCo2: z.number().nullish(),
  techsCo2: z.number().nullish(),
  importsCo2: z.number().nullish(),
  exportsCo2: z.number().nullish(),
});

export type ResultCostCo2 = z.infer<typeof ResultCostCo2Schema>;
