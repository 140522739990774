import { Component } from '@angular/core';
import { SidenavService } from '@sympheny/navigation/navigation';
import { ProgressService } from '@sympheny/ui/progress';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public readonly navigationOpen$ = this.sidenavService.isOpen$;

  constructor(
    private readonly sidenavService: SidenavService,
    private readonly progressService: ProgressService
  ) {
    this.progressService.enableProgress();
  }
}
