import { groupBy } from 'lodash-es';

import { Graph, GraphData, Link, LinkImpl, Node, NodeImpl } from '../../model';

/**
 *  in this step we aggregate import/export nodes based on the energy carrier of the link
 * In this way we reduce the amount and improve readability of the diagram.
 * In this step aggregated nodes are created, these are a kind  of virtual nodes but they will remain existing in the schema, but invisible
 */

const createAggregateLink = (
  data: GraphData,
  source: Node,
  target: Node,
  originalLink: Link,
  originalNodeIds: string[],
) => {
  const aggregateLink = new LinkImpl({
    ...originalLink,
    source: source._id,
    target: target._id,
    value: 1,
    circular: false,
    type: 'aggregate',
    index: 9999,
    originalNodeIds,
  } as Link);
  data.addLink(aggregateLink);
};

// replace the links by one aggregate node
const createAggregateNode = (data: GraphData, node: Node) => {
  const aggregateNodeIndex = Math.random();
  const aggregateNode = new NodeImpl({
    ...node,
    color: 'orange',
    name: `aggregate ${node.name}`,
    _id: 'aggregateNode' + aggregateNodeIndex,
    aggregate: true,
    partOfCycle: false,
  } as any as Node);

  aggregateNode.partOfCycle = false;
  data.addNode(aggregateNode._id, aggregateNode);
  return aggregateNode;
};

const createAggregateTargetNode = (data: GraphData, links: Link[]) => {
  if (links.length < 2) return;

  const node = data.getNodeTarget(links[0]);

  const aggregateNode = createAggregateNode(data, node);
  createAggregateLink(
    data,
    aggregateNode,
    node,
    links[0],
    links.map((l) => l.source),
  );

  links.map((link) => {
    const source = data.getNodeSource(link);
    createAggregateLink(data, source, aggregateNode, link, [link.target]);
    link.setValue('type', 'replaced');
  });
};

const createAggregateSourceNode = (data: GraphData, links: Link[]) => {
  if (links.length < 2) return;

  const node = data.getNodeSource(links[0]);

  const aggregateNode = createAggregateNode(data, node);
  createAggregateLink(
    data,
    node,
    aggregateNode,
    links[0],
    links.map((l) => l.target),
  );

  links.map((link) => {
    const target = data.getNodeTarget(link);
    createAggregateLink(data, aggregateNode, target, link, [link.source]);
    link.setValue('type', 'replaced');
  });
};

export const EhubCreateAggregateNodes = (graph: Graph<any, any>) => {
  const { graph: data } = graph;

  const noSourceLinks: Link[] = [];
  const noTargetLinks: Link[] = [];
  const otherLinks: Link[] = [];

  data.forEachNode((node) => {
    const targetLinks = data.getTargetLinks(node);
    const sourceLinks = data.getSourceLinks(node);
    if (sourceLinks.length === 0) {
      noSourceLinks.push(...targetLinks);
    } else {
      otherLinks.push(...targetLinks);
    }
    if (targetLinks.length === 0) {
      noTargetLinks.push(...sourceLinks);
    } else {
      otherLinks.push(...sourceLinks);
    }
    // createAggregateNodes(data, sourceLinks, node);
  });

  const groupSources = groupBy(noSourceLinks, 'source');
  const groupTargets = groupBy(noTargetLinks, 'target');

  Object.values(groupSources).forEach((links: any) =>
    createAggregateSourceNode(data, links),
  );
  Object.values(groupTargets).forEach((links: any) =>
    createAggregateTargetNode(data, links),
  );

  data.removeLinksFromIndex('replaced');
};
