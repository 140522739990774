import { z } from 'zod';

export const ScenarioVariantSchema = z.object({
  guid: z.string(),
  variantScenarioGuid: z.string(),
  name: z.string(),
});
export const NewScenarioVariantSchema = z.object({
  masterScenarioGuid: z.string(),
  name: z.string(),
});

export type ScenarioVariant = z.infer<typeof ScenarioVariantSchema>;

export type NewScenarioVariant = z.infer<typeof NewScenarioVariantSchema>;
