<ng-container *ngIf="link; else showInfo">
  <a target="_blank" [href]="link">
    <mat-icon
      class="info-icon"
      [attr.aria-tooltip]="tooltip | transloco"
      [matTooltip]="tooltip | transloco"
      color="primary"
    >
      help_outline
    </mat-icon>
  </a>
</ng-container>
<ng-template #showInfo>
  <mat-icon
    class="info-icon"
    [attr.aria-tooltip]="tooltip | transloco"
    [matTooltip]="tooltip | transloco"
  >
    info_outline
  </mat-icon>
</ng-template>
