import { z } from 'zod';

export type MustBeInstalledInHubsValues =
  | 'canBeInstalled'
  | 'mustBeInstalled'
  | 'mustBeInstalledInAtLeastOneHub';
export const MustBeInstalledInHubsEnum = z.enum([
  'canBeInstalled',
  'mustBeInstalled',
  'mustBeInstalledInAtLeastOneHub',
]);

export type MustBeInstalledInHubs = z.infer<typeof MustBeInstalledInHubsEnum>;
