export class ResponseModel<DATA = any> {
  public data!: DATA;
  public status!: ResponseStatus;
}

export class ResponseStatus {
  public code!: ResponseStatusCode;
  public desc!: string;
  public message!: string;
}

export enum ResponseStatusCode {
  ESP_400_STATUS = 'ESP_400',
  ESP_401_STATUS = 'ESP_401',
  ESP_404_STATUS = 'ESP_404',
  ESP_406_STATUS = 'ESP_406',
  ESP_409_STATUS = 'ESP_409',
  ESP_4038_STATUS = 'ESP_4038',
  ESP_422_STATUS = 'ESP_422',
  ESP_4001_STATUS = 'ESP_4001',
  ESP_4003_STATUS = 'ESP_4003',
  ESP_4022_STATUS = 'ESP_4022',
  ESP_4023_STATUS = 'ESP_4023',
  ESP_4029_STATUS = 'ESP_4029',
  ESP_4030_STATUS = 'ESP_4030',
  ESP_4034_STATUS = 'ESP_4034',
  ESP_4035_STATUS = 'ESP_4035',
  ESP_4036_STATUS = 'ESP_4036',
  ESP_4042_STATUS = 'ESP_4042',
  ESP_4043_STATUS = 'ESP_4043',
}

export enum ResponseStatusMessages {
  ESP_4038 = 'The invite has expired. Please ask the original project author to share a copy of their project with you again.',
  ESP_4039 = 'Plan limit reached. Please consider upgrading.',
  ESP_404 = 'Page not found',
  ESP_500 = 'Error occured. Please contact administrator.',

  INVALID_LINK = 'The link is invalid.',
  INVALID_DATA_FORMAT = 'Invalid data format. Check the instructions and the template.',

  HUB_UNIQUE_NAME = 'The hub name must be unique inside the scenario.',
  SCENARIO_UNIQUE_NAME = 'The scenario name must be unique inside the project.',
  ANALYSE_UNIQUE_NAME = 'The analysis name must be unique inside the project.',
  NAME_INVALID_CHARACTER = 'Name contains an Invalid Character',
  DISMISS_USER_GUID_INFO = 'Error dimissing user guide info',
  MISSING_GTC_INFO = 'Error dimissing show GTC info',
  SHARING_NOT_AVAILABLE = 'Sharing project functionality is not available with WARM-UP account, please consider upgrading to another plan.',
  ERROR_DELETE_ENERGY_CARRIER = 'Can’t delete an energy carrier that is referenced in other steps',
  ERROR_DELETE_HUB = 'Can’t delete a hub that is referenced in other steps',
  ERROR_ONE_IMPORT_ONE_EXPER_PER_CARRIER = 'At most, there can be one import and one export per energy carrier.',
  ERROR_GENERATE_HUBS_DIAGRAM = 'Can’t generate hubs diagram',
  ERROR_STOP_EXECUTION = 'Error stopping execution. Please contact support!',
}
