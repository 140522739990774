<ng-container *ngIf="progress$ | async as progress"
  ><div *ngIf="!closed" class="border-2 rounded-xl bg-white m-2 w-96">
    <div class="p-2 bg-green-200 text-base flex justify-between items-center">
      <span>Progress</span>
      <div class="flex">
        <sympheny-icon-button
          [icon]="collapsed ? 'maximize' : 'minimize'"
          color="custom"
          (action)="collapsed = !collapsed"
        ></sympheny-icon-button>
        <sympheny-icon-button
          icon="close"
          color="custom"
          (action)="closeProgress()"
        ></sympheny-icon-button>
      </div>
    </div>
    <div *ngIf="!collapsed" class="p-2 max-h-96">
      <ul>
        <li
          class="border-b py-2 flex justify-between items-center"
          *ngFor="let p of progress"
        >
          <div>{{ p.name }}</div>
          <div>
            <mat-icon
              *ngIf="p.error"
              fontSet="material-icons-outlined"
              color="warn"
              >cancel</mat-icon
            >
            <mat-icon
              *ngIf="p.done && !p.error"
              fontSet="material-icons-outlined"
              color="primary"
              >check_circle</mat-icon
            >
            <mat-icon
              *ngIf="!p.done && !p.error"
              fontSet="material-icons-outlined"
              >circle</mat-icon
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
