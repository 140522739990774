import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoLazyModule } from '@sympheny/utils/translate';

import { ExecutionsLeftModalComponent } from './executions-left-modal/executions-left-modal.component';
import { HasPlanDirective } from './has-plan.directive';
import { PlanLimitReachedModalComponent } from './plan-limit-reached-modal/plan-limit-reached-modal.component';
import { PlanService } from './plan.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslocoLazyModule,
    HasPlanDirective,
    PlanLimitReachedModalComponent,
    ExecutionsLeftModalComponent,
  ],
  exports: [HasPlanDirective],
})
export class UserPlanModule {}

export const providePlan = () => {
  return [PlanService];
};
