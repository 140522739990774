import { Inject, Injectable } from '@angular/core';

import { ENVIRONMENT_CONFIGURATION } from './environment.config';
export type Apps = {
  enymap: string;
  sympheny: string;
};
export interface Environment {
  api: string;
  base: string;
  senseApi: string;
  apiServices: string;
  redirectUrl: string;
  production: boolean;
  apiExchangeRates: boolean;
  apiExchangeRatesClientID: boolean;
  featureFlag: Record<string, boolean>;
  supportedLanguages: string[];
  supportUrl: string;
  ci?: true;
  apps: Apps;
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService<T extends Partial<Environment> = Environment> {
  constructor(
    @Inject(ENVIRONMENT_CONFIGURATION) private readonly _configuration: T,
  ) {
    this._configuration.base =
      this._configuration.base ?? `${this._configuration.api}sympheny-app/`;
  }

  public get api() {
    return this.getValue('api');
  }
  public get base() {
    return this.getValue('base');
  }
  public get senseApi() {
    return `${this.api}sense-api/ext/`;
  }

  public get apiServices() {
    return this.getValue('apiServices');
  }

  public get backOfficeApi() {
    return `${this.api}backoffice/ext/`;
  }
  public get gisApi() {
    return `${this.apiServices}gis/`;
  }

  public getConfiguration(): T {
    return this._configuration;
  }

  public getValue<K extends keyof T>(key: K): T[K] {
    return this._configuration[key];
  }

  public getApp(app: keyof Apps) {
    return this._configuration.apps[app];
  }

  public isDev() {
    return !this._configuration.production;
  }
}
