import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { NgxMaskDirective } from 'ngx-mask';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { InputSuffixComponent } from '../input-suffix/input-suffix.component';
('@angular/material/core');

export type MatInputConfig = FormFieldConfig;

@Component({
  selector: 'sympheny-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskDirective,
    TranslocoPipe,
    InfoButtonComponent,
    NgIf,
    InputSuffixComponent,
  ],
})
export class InputNumberComponent extends BaseFormComponent<
  number,
  MatInputConfig
> {
  @Input() public allowNegativeNumbers = false;
  @Output() public readonly changeValue = new EventEmitter<void>();

  public value: string | null = null;
  public mask = 'separator.5';

  constructor(ngControl: NgControl) {
    super(ngControl);
  }

  @Input() public set digits(digits: number | null) {
    this.mask = `separator.${digits ?? 0}`;
  }
}
