import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const StageSchema = z.object({
  guid: z.string(),
  index: SymphenyNumber().nullish(),
  inflationRate: SymphenyNumber().nullish(),
  interestRate: SymphenyNumber().nullish(),
  length: SymphenyNumber().nullish(),
  name: z.string().nullish(),
});

export const StagDtoSchema = StageSchema.omit({ guid: true });

export type Stage = z.infer<typeof StageSchema>;
