import { Address } from '@sympheny/project/scenario/data-access';

import { SelectedBuildingInfo } from './select-building-info';

export interface KeyLabel {
  key: keyof Address;
  label: string;
  link?: boolean;
  suffix?: string;
  number?: boolean;
}

export interface KeysLabel extends KeyLabel {
  keys: Array<keyof Address>;
  calculate: (values: Array<string | number>) => number;
}

interface Info {
  label: string;
  values: Array<KeyLabel | KeysLabel>;
}

const multiply = (values: Array<any>) =>
  values.reduce(
    (previousValue: number, value: number) => previousValue * value,
    1
  );

const averageDemand = ([avgHeating, area, nbrFloor]: any[]) =>
  avgHeating / (area * nbrFloor);

const m2 = ' m<sup>2</sup>';
const m3 = ' m<sup>3</sup>';
const kWhM2Year = ' kWh/m<sup>2</sup>.year';
const kWhYear = ' kWh/year';
const kWp = ' kWp';

export const buildingInformationConfiguration: Record<
  keyof SelectedBuildingInfo,
  Info
> = {
  buildingInfo: {
    label: 'BUILDING_LAYER.buildingInfo',
    values: [
      {
        key: 'hub_label',
        label: 'BUILDING_LAYER.hub_label',
      },
      {
        key: 'egid',
        label: 'BUILDING_LAYER.egid',
      },
      {
        key: 'address',
        label: 'BUILDING_LAYER.address',
      },
      {
        key: 'building_category',
        label: 'BUILDING_LAYER.building_category',
      },
      {
        key: 'building_ground_area',
        label: 'BUILDING_LAYER.building_ground_area',
        suffix: m2,
        number: true,
      },
      {
        key: 'nbr_floor',
        keys: ['building_ground_area', 'nbr_floor'],
        label: 'BUILDING_LAYER.area_floor',
        calculate: multiply,
        suffix: m2,
        number: true,
      },
      { key: 'nbr_floor', label: 'BUILDING_LAYER.nbr_floor' },
      { key: 'nbr_household', label: 'BUILDING_LAYER.nbr_household' },
      { key: 'construction_end', label: 'BUILDING_LAYER.construction_end' },
      { key: 'renovation_end', label: 'BUILDING_LAYER.renovation_end' },

      { key: 'gstat', label: 'BUILDING_LAYER.gstat' },
      { key: 'gkat', label: 'BUILDING_LAYER.gkat' },
      { key: 'gklas', label: 'BUILDING_LAYER.gklas' },
      { key: 'gbauj', label: 'BUILDING_LAYER.gbauj' },
      { key: 'gbaup', label: 'BUILDING_LAYER.gbaup' },
      { key: 'garea', label: 'BUILDING_LAYER.garea', suffix: m2, number: true },
      { key: 'gvol', label: 'BUILDING_LAYER.gvol', suffix: m3, number: true },
      { key: 'gastw', label: 'BUILDING_LAYER.gastw' },
      { key: 'gazzi', label: 'BUILDING_LAYER.gazzi' },
    ],
  },
  energyInfo: {
    label: 'BUILDING_LAYER.energyInfo',
    values: [
      {
        key: 'co2_range',
        label: 'BUILDING_LAYER.co2_range',
      },
      {
        key: 'linkco2',
        label: 'BUILDING_LAYER.linkco2',
        link: true,
      },
      {
        key: 'gwaerzh1',
        label: 'BUILDING_LAYER.gwaerzh1',
      },
      {
        key: 'gwaerzh2',
        label: 'BUILDING_LAYER.gwaerzh2',
      },
      {
        key: 'genh1',
        label: 'BUILDING_LAYER.genh1',
      },
      {
        key: 'genh2',
        label: 'BUILDING_LAYER.genh2',
      },
      {
        key: 'gwaerzw1',
        label: 'BUILDING_LAYER.gwaerzw1',
      },
      {
        key: 'gwaerzw2',
        label: 'BUILDING_LAYER.gwaerzw2',
      },
      {
        key: 'genw1',
        label: 'BUILDING_LAYER.genw1',
      },
      {
        key: 'genw2',
        label: 'BUILDING_LAYER.genw2',
      },
      {
        key: 'gebf',
        label: 'BUILDING_LAYER.gebf',
        suffix: m2,
        number: true,
      },
      {
        key: 'avg_heating_egid',
        label: 'BUILDING_LAYER.avg_heating_egid',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'avg_hotwater_egid',
        label: 'BUILDING_LAYER.avg_hotwater_egid',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'avg_heating_egid_m2',
        label: 'BUILDING_LAYER.avg_heating_egid_m2',
        suffix: kWhM2Year,
        keys: ['avg_heating_egid', 'building_ground_area', 'nbr_floor'],
        calculate: averageDemand,
      },
      {
        key: 'avg_hotwater_egid_m2',
        label: 'BUILDING_LAYER.avg_hotwater_egid_m2',
        suffix: kWhM2Year,
        keys: ['avg_hotwater_egid', 'building_ground_area', 'nbr_floor'],
        calculate: averageDemand,
      },
      {
        key: 'pv_prod_power_egid',
        label: 'BUILDING_LAYER.pv_prod_power_egid',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'heating_power_egid',
        label: 'BUILDING_LAYER.heating_power_egid',
        suffix: kWp,
        number: true,
      },
      {
        key: 'elecDemandTotalPred',
        label: 'BUILDING_LAYER.elecDemandTotalPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandDevicesPred',
        label: 'BUILDING_LAYER.elecDemandDevicesPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandLightPred',
        label: 'BUILDING_LAYER.elecDemandLightPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandVentilationPred',
        label: 'BUILDING_LAYER.elecDemandVentilationPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandCoolingPred',
        label: 'BUILDING_LAYER.elecDemandCoolingPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandHeatingPred',
        label: 'BUILDING_LAYER.elecDemandHeatingPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandHotwaterPred',
        label: 'BUILDING_LAYER.elecDemandHotwaterPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandProcessesPred',
        label: 'BUILDING_LAYER.elecDemandProcessesPred',
        suffix: kWhYear,
        number: true,
      },
      {
        key: 'elecDemandBuildingtechPred',
        label: 'BUILDING_LAYER.elecDemandBuildingtechPred',
        suffix: kWhYear,
        number: true,
      },
    ],
  },
  roofInfo: {
    label: 'BUILDING_LAYER.roofInfo',
    values: [
      {
        key: 'sum_r_area',
        label: 'BUILDING_LAYER.sum_r_area',

        number: true,
        suffix: m2,
      },
      {
        key: 'sum_r_electricity_PV_kWh_y',
        label: 'BUILDING_LAYER.sum_r_electricity_PV_kWh_y',

        number: true,
        suffix: kWhYear,
      },
      {
        key: 'max_r_heat_PV_kWh_y',
        label: 'BUILDING_LAYER.max_r_heat_PV_kWh_y',

        number: true,
        suffix: kWhYear,
      },
      {
        key: 'sum_r_PV_peak_performance_kWp',
        label: 'BUILDING_LAYER.sum_r_PV_peak_performance_kWp',

        number: true,
        suffix: kWp,
      },
      {
        key: 'avg_r_area_pv_suitability',
        label: 'BUILDING_LAYER.avg_r_area_pv_suitability',
      },
    ],
  },
  facadeInfo: {
    label: 'BUILDING_LAYER.facadeInfo',
    values: [
      {
        key: 'sum_f_area',
        label: 'BUILDING_LAYER.sum_f_area',
        number: true,
        suffix: m2,
      },
      {
        key: 'sum_f_electricity_PV_kWh_y',
        label: 'BUILDING_LAYER.sum_f_electricity_PV_kWh_y',

        number: true,
        suffix: kWhYear,
      },
      {
        key: 'max_f_heat_PV_kWh_y',
        label: 'BUILDING_LAYER.max_f_heat_PV_kWh_y',
        number: true,
        suffix: kWhYear,
      },
    ],
  },
  minergieInfo: {
    label: 'BUILDING_LAYER.minergieInfo',
    values: [
      {
        key: 'certificate',
        label: 'BUILDING_LAYER.certificate',
      },
      {
        key: 'energy_ref_area_m2',
        label: 'BUILDING_LAYER.energy_ref_area_m2',
        suffix: m2,
      },
      {
        key: 'minergie_standard',
        label: 'BUILDING_LAYER.minergie_standard',
      },
    ],
  },
  infoSelect: { label: '', values: [] },
  others: {
    label: 'BUILDING_LAYER.others',
    values: [],
  },
};
