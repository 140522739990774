import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { parseEuDate, formatEuDate } from '@sympheny/utils/data-access';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  public override parse(value: any, parseFormat?: any): Date {
    return parseEuDate(value);
  }

  public override format(date: Date, displayFormat: any): string {
    return formatEuDate(date);
  }
}
