// Dev backends

import { SymphenyEnvironment } from './environment.model';

const api = 'https://eu-north-1-api.dev.sympheny.com/';
//eu-north-1-api.dev.sympheny.com/sense-api
//publicBaseApi: 'https://public.dev.sympheny.com/',
// ng serve or ng serve --configuration dev --> default config
export const environment: SymphenyEnvironment = {
  production: false,
  publicBaseApi: api,
  backendApi: 'https://eu-north-1-api.dev.sympheny.com/sympheny-app/',
  apiServices: 'https://eu-north-1-api.dev.sympheny.com/api-services/',
  apiExchangeRates: 'https://api.exchangeratesapi.io/v1/',
  apiExchangeRatesClientID: 'baf52f2ace0aa5d5bddd45690ce10a72',
  supportUrl: 'https://support.app.dev.sympheny.com/',
  templateUrl:
    'https://dev-eu-north-1-sympheny-public.s3.eu-north-1.amazonaws.com/static/',
  featureFlag: {
    v25: false,
    projectV2: true,
    share_dashboard: true,
  },
  auth0: {
    audience: api,
    domain: 'login.dev.sympheny.com',
    clientId: 'LmwbFU0iJxq7GFhKZvHUG8mavFrInSoz',
    allowedList: [api],
  },
  mapboxToken:
    'pk.eyJ1IjoianVsaWVubWFycXVhbnQiLCJhIjoiY2w2MjBpeDI4MDB1dTNkbzUxaXJhc25lciJ9.5hiTIAhN_Eic25vB_QoTXg',
  mapTillerToken: 'TLJRWUgVc7ZuP1JhCOK9',
  supportedLanguages: [
    { id: 'en', label: 'English' },
    { id: 'de', label: 'Deutsch' },
    { id: 'fr', label: 'Français' },
    { id: 'it', label: 'Italiano' },
    { id: 'es', label: 'Español' },
    { id: 'zh', label: '中文' },
    { id: 'ar', label: 'العربية' },
    { id: 'nl', label: 'Nederlands' },
  ],
  enableSw: false,
  apps: {
    enymap: 'https://enymap-app.dev.sympheny.com/',
    sympheny: 'https://app.dev.sympheny.com/',
  },
};
