import z from 'zod';

export const ResultConvDesignSchema = z.object({
  name: z.string(),
  installStage: z.string(),
  phaseoutStage: z.string(),
  hub: z.string(),
  capacity: z.number().nullish(),
  capacityBinary: z.number().nullish(),
  investment: z.number().nullish(),
  replacement: z.number().nullish(),
  salvage: z.number().nullish(),
  co2: z.number().nullish(),
  modes: z.array(z.object({ name: z.string(), capacity: z.number() })),
});

export type ResultConvDesign = z.infer<typeof ResultConvDesignSchema>;
