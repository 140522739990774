import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';
import { Address } from '@sympheny/project/scenario/data-access';

import {
  buildingInformationConfiguration,
  KeyLabel,
  KeysLabel,
} from '../../model/building-information';
import { SelectedBuildingInfo } from '../../model/select-building-info';

export interface WorksheetSelection {
  column: string | null;
  key: keyof SelectedBuildingInfo | 'other' | null;
  field: keyof Address | null;
  customName: keyof Address | null;
}

@Component({
  selector: 'sympheny-worksheet-column-selection',
  templateUrl: './worksheet-column-selection.component.html',
  styleUrls: ['./worksheet-column-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: WorksheetColumnSelectionComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    NgIf,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class WorksheetColumnSelectionComponent implements ControlValueAccessor {
  buildingInfo: Array<KeyLabel | KeysLabel> = [];
  readonly buildingInformationConfiguration = buildingInformationConfiguration;
  readonly keys: Array<keyof SelectedBuildingInfo> = [
    'buildingInfo',
    'energyInfo',
    'roofInfo',
    'facadeInfo',
    'minergieInfo',
  ];

  value: WorksheetSelection = {
    field: null,
    column: null,
    key: null,
    customName: null,
  };
  isDisabled = false;
  @Input() label: string | null = null;
  @Input() worksheets: string[] = [];
  @Input() selectedFields: Array<keyof Address> = [];

  onChange: (value: WorksheetSelection) => void;

  writeValue(value: WorksheetSelection): void {
    this.value = value;
  }

  registerOnChange(fn: (value: WorksheetSelection) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    //
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  changeCategory(value: keyof SelectedBuildingInfo) {
    const info = buildingInformationConfiguration[value];
    this.buildingInfo =
      info?.values.filter(
        (v) =>
          v.key === this.value.field || this.selectedFields.indexOf(v.key) < 0,
      ) ?? [];

    this.value.key = value;
  }

  change(value: keyof Address) {
    if (this.value?.key === 'other') {
      this.value.customName = value;
    } else {
      this.value.field = value;
    }
    this.onChange({ ...this.value });
  }

  clear() {
    this.value.key = null;
    this.value.field = null;
    this.buildingInfo = [];
    this.onChange(this.value);
  }
}
