import { Injectable } from '@angular/core';
import {
  findByGuid,
  findLatestData,
  mapData,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { Deserialize } from '@sympheny/utils/rxjs';
import { firstValueFrom, mapTo } from 'rxjs';

import { CrudService } from './crud.service';
import { IntraHubNetworkLink, ProjectVersion } from '../model';

@Injectable()
export class IntraHubNetworkLinksService extends CrudService<IntraHubNetworkLink> {
  public readonly guidKey = 'intraHubNetworkLinkGuid';
  public readonly key = 'intraHubNetworkLink';

  @Deserialize(IntraHubNetworkLink)
  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<
        ResponseModel<{ intraHubNetworkLinks: IntraHubNetworkLink[] }>
      >(`${this.base}scenarios/${scenarioGuid}/intra-hub-network-links`)
      .pipe(mapData('intraHubNetworkLinks'));
  }

  @Deserialize(IntraHubNetworkLink)
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<IntraHubNetworkLink>,
  ) {
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ intraHubNetworkLinks: IntraHubNetworkLink[] }>
        >(`${this.base}scenarios/${scenarioGuid}/intra-hub-network-links`, data)
        .pipe(findLatestData('created', 'intraHubNetworkLinks')),
    );
  }

  @Deserialize(IntraHubNetworkLink)
  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    intraHubNetworkLinkGuid: string,
    data: Partial<IntraHubNetworkLink>,
  ) {
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ intraHubNetworkLinks: IntraHubNetworkLink[] }>
        >(`${this.base}scenarios/intra-hub-network-links/${intraHubNetworkLinkGuid}`, data)
        .pipe(
          findByGuid(
            this.guidKey,
            intraHubNetworkLinkGuid,
            'intraHubNetworkLinks',
          ),
        ),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    intraHubNetworkLinkGuid: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(
          `${this.base}scenarios/intra-hub-network-links/${intraHubNetworkLinkGuid}`,
        )
        .pipe(mapTo(intraHubNetworkLinkGuid)),
    );
  }
}
