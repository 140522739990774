import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoLazyModule } from '@sympheny/utils/translate';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';

import { BuildingInformationComponent } from './building-information/building-information/building-information.component';
import { BuildingInformationFieldComponent } from './building-information/building-information-field/building-information-field.component';
import { SelectBuildingInformationComponent } from './building-information/select-building-information/select-building-information.component';
import { SelectDatasetComponent } from './building-information/select-dataset/select-dataset.component';
import { UploadGisDataComponent } from './building-information/upload-gis-data/upload-gis-data.component';
import { UploadGisFormComponent } from './building-information/upload-gis-data/upload-gis-form/upload-gis-form.component';
import { WorksheetColumnSelectionComponent } from './building-information/upload-gis-data/worksheet-column-selection/worksheet-column-selection.component';
import { AddCustomerLayerComponent } from './customer-layer/add-customer-layer/add-customer-layer.component';
import { LayerConfigurationComponent } from './customer-layer/layer-configuration/layer-configuration.component';
import { NewLayerConfigurationComponent } from './customer-layer/new-layer-configuration/new-layer-configuration.component';
import { ChangeBasemapComponent } from './map-gis/change-basemap/change-basemap.component';
import { FeatureInfoValueComponent } from './map-gis/feature-info/feature-info-value/feature-info-value.component';
import { FeatureInfoComponent } from './map-gis/feature-info/feature-info.component';
import { MapGisToggleComponent } from './map-gis/map-gis-toggle/map-gis-toggle.component';
import { MapGisComponent } from './map-gis/map-gis.component';
import { MapInfoComponent } from './map-gis/map-info/map-info.component';
import { MapLoadDirective } from './map-gis/on-load.directive';
import { SelectFeaturesComponent } from './map-gis/select-features/select-features.component';
import { ScenarioMapComponent } from './scenario-map/scenario-map.component';

@NgModule({
  imports: [
    MatCardModule,
    CommonModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSidenavModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatDialogModule,
    NgxFileDragDropModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    TranslocoLazyModule.forChild(),
    MapGisComponent,
    MapGisToggleComponent,
    SelectFeaturesComponent,
    ScenarioMapComponent,
    LayerConfigurationComponent,
    NewLayerConfigurationComponent,
    UploadGisDataComponent,
    WorksheetColumnSelectionComponent,
    BuildingInformationComponent,
    SelectBuildingInformationComponent,
    MapInfoComponent,
    ChangeBasemapComponent,
    UploadGisFormComponent,
    SelectDatasetComponent,
    BuildingInformationFieldComponent,
    AddCustomerLayerComponent,
    MapLoadDirective,
    FeatureInfoComponent,
    FeatureInfoValueComponent,
  ],
  exports: [ScenarioMapComponent],
})
export class ProjectScenarioMapGisModule {}
