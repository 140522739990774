<nav
  class="sympheny-breadcrumb"
  aria-label="breadcrumb"
  *ngIf="labels$() as labels"
>
  <ol *transloco="let t">
    <ng-container *ngFor="let label of labels; let i = index; let last = last">
      <li class="sympheny-breadcrumb__item">
        <a
          *ngIf="!last && label.route"
          [routerLink]="label.route"
          class="sympheny-breadcrumb__item-label"
          >{{ label.translateLabel ? t(label.label) : label.label }}
        </a>
        <span
          *ngIf="last || !label.route"
          class="sympheny-breadcrumb__item-label"
        >
          {{ label.translateLabel ? t(label.label) : label.label }}
        </span>

        <span class="sympheny-breadcrumb__item-subLabel">
          {{ label.translateSubLabel ? t(label.subLabel) : label.subLabel }}
        </span>
      </li>
      <li *ngIf="!last" class="sympheny-breadcrumb__seperator">/</li>
    </ng-container>
  </ol>
</nav>
