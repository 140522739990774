import { Injectable, OnDestroy } from '@angular/core';
import { ItemState } from '@fp-tools/angular-state';
import { UserState } from '@sympheny/user/data-access';
import { plainToClass } from 'class-transformer';
import { EMPTY, Observable, startWith, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Analysis, ProjectDetails, Scenario } from '../../model';
import { ProjectsService } from '../../service';
import { ProjectEvents } from '../project-events.service';

@Injectable()
export class ProjectDetailsState
  extends ItemState<ProjectDetails, any>
  implements OnDestroy
{
  private _projectGuid: string | null = null;
  private logoutActionId: string;
  private lockInProgress = new Map<string, boolean>();
  private unlockInProgress = new Map<string, boolean>();

  public readonly canEdit$ = this.data$.pipe(
    map((project) => project?.rights?.canEdit ?? false),
    distinctUntilChanged(),
  );
  public readonly isReadonly$ = this.canEdit$.pipe(
    map((canEdit) => !canEdit),
    distinctUntilChanged(),
  );
  public readonly lockDetails$ = this.data$.pipe(
    map((project) =>
      project?.lock
        ? {
            lockTime: project.lockTime,
            lockUserEmail: project.lockUserEmail,
          }
        : null,
    ),
    distinctUntilChanged(),
  );
  public readonly isLocked$ = this.data$.pipe(
    map((project) => project?.rights?.locked ?? false),
    distinctUntilChanged(),
  );

  public isOnwer$ = this.select('ownedByCurrentUser');

  public setProjectGuid(projectGuid: string) {
    this._projectGuid = projectGuid;
    this.load();
  }

  public get projectGuid() {
    return this._projectGuid;
  }
  public get projectVersion() {
    return this.get('version');
  }

  protected fetchApi(): Observable<ProjectDetails> {
    if (!this.projectGuid) {
      return EMPTY;
    }
    return this.projectService.getProjectDetails(this.projectGuid).pipe(
      switchMap((projectDetails) => this.enrichProject(projectDetails)),
      //tap((projectDetails) => this.lockProject(projectDetails)),
      switchMap((projectDetails) => this.mergeExternalUpdate(projectDetails)),
    );
  }

  public override reset() {
    this.unlockCurrentProject();
    this._projectGuid = null;
    super.reset();
  }

  public override getData(): ProjectDetails | null {
    return super.getData();
  }

  public async enrichProject(projectDetails: ProjectDetails) {
    const myEmail = await this.userState.waitForEmail();

    projectDetails.addRights(myEmail);

    return projectDetails;
  }

  public unlockCurrentProject() {
    const projectDetails = this.getData();
    if (this.projectGuid && this.projectGuid === projectDetails?.projectGuid) {
      this.unlock(this.getData());
    }
    return;
  }

  public lockCurrentProject() {
    const projectDetails = this.getData();
    if (this.projectGuid && this.projectGuid === projectDetails?.projectGuid) {
      this.lockProject(projectDetails);
    }
    return;
  }

  private unlock(projectDetails: ProjectDetails) {
    if (
      projectDetails.rights.lockedByMe &&
      !this.unlockInProgress.get(projectDetails.projectGuid)
    ) {
      this.unlockInProgress.set(projectDetails.projectGuid, true);
      this.projectService
        .unlock(projectDetails.projectGuid)
        .then(() =>
          this.unlockInProgress.set(projectDetails.projectGuid, false),
        );
    }
  }

  private lockProject(projectDetails: ProjectDetails) {
    if (
      projectDetails.rights.locked ||
      !projectDetails.rights.canEdit ||
      this.lockInProgress.get(projectDetails.projectGuid)
    ) {
      return projectDetails;
    }

    this.lockInProgress.set(projectDetails.projectGuid, true);
    this.projectService
      .lock(projectDetails.projectGuid)
      .then(() => this.lockInProgress.set(projectDetails.projectGuid, false));
    projectDetails.lockByMe();
    return projectDetails;
  }

  private mergeExternalUpdate(
    projectDetails: ProjectDetails,
  ): Observable<ProjectDetails> {
    return this.events.getProjectEvents(projectDetails.projectGuid).pipe(
      map((a) => {
        const project = a.project ?? projectDetails;
        return plainToClass(ProjectDetails, { ...projectDetails, ...project });
      }),
      startWith(projectDetails),
    );
  }

  constructor(
    private readonly projectService: ProjectsService,
    private readonly events: ProjectEvents,
    private readonly userState: UserState,
  ) {
    super();
    this.logoutActionId = this.userState.registerLogoutAction(() =>
      this.unlockCurrentProject(),
    );
  }

  public updateAnalysisData(updateFn: (analyses: Analysis[]) => Analysis[]) {
    if (!this.getData()) {
      return;
    }
    this.updateItem(
      plainToClass(ProjectDetails, {
        ...this.getData(),
        analyses: updateFn(this.get('analyses') ?? []),
      }),
    );
  }

  public updateAnalysisScenario(
    analysisGuid: string,
    updateFn: (scenario: Scenario[]) => Scenario[],
  ) {
    if (!this.getData()) {
      return;
    }

    this.updateAnalysisData((analyses) =>
      analyses.map((analysis) => {
        if (analysis.analysisGuid !== analysisGuid) {
          return analysis;
        }
        return {
          ...analysis,
          scenarios: updateFn(analysis.scenarios),
        };
      }),
    );
  }

  public override ngOnDestroy(): void {
    this.unlockCurrentProject();
    this.userState.removeLogoutAction(this.logoutActionId);

    super.ngOnDestroy();
  }
}
