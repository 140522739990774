import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  findLatestDataZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo, Observable } from 'rxjs';

import { CrudService } from './crud.service';
import { ProjectVersion } from '../model';
import {
  NetworkTechnology,
  NetworkTechnologyV1Schema,
  NetworkTechnologyV2Schema,
  NetworkTechnologyV1DtoSchema,
  NetworkTechnologyV2SDtochema,
} from '../model/network-technology';

@Injectable()
export class NetworkTechnologyService extends CrudService<NetworkTechnology> {
  public readonly guidKey = 'networkTechnologyGuid';
  public readonly key = 'networkTechnologies';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    const url =
      projectVersion === 'V2'
        ? `${this.base}v2/scenarios/${scenarioGuid}/network-technologies`
        : `${this.base}scenarios/${scenarioGuid}/network-technologies`;
    const mapper =
      projectVersion === 'V2'
        ? mapDataZ(NetworkTechnologyV2Schema, 'networkTechnologies')
        : mapDataZ(NetworkTechnologyV1Schema, 'networkTechnologies');

    return this.http
      .get<ResponseModel<{ networkTechnologies: NetworkTechnology[] }>>(url)
      .pipe(mapper);
  }

  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<NetworkTechnology>,
  ) {
    return firstValueFrom(
      projectVersion === 'V2'
        ? this.createV2(scenarioGuid, data)
        : this.createV1(scenarioGuid, data),
    );
  }

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    networkTechnologyGuid: string,
    data: Partial<NetworkTechnology>,
  ) {
    return firstValueFrom(
      projectVersion === 'V2'
        ? this.updateV2(networkTechnologyGuid, data)
        : this.updateV1(networkTechnologyGuid, data),
    );
  }

  public override get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    networkTechnologyGuid: string,
  ) {
    const url =
      projectVersion === 'V2'
        ? `${this.base}v2/scenarios/network-technologies/${networkTechnologyGuid}`
        : `${this.base}scenarios/network-technologies/${networkTechnologyGuid}`;

    const mapper =
      projectVersion === 'V2'
        ? mapDataZ(NetworkTechnologyV2Schema)
        : mapDataZ(NetworkTechnologyV1Schema);

    return firstValueFrom(
      this.http.get<ResponseModel<{ NetworkTechnology }>>(url).pipe(mapper),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    networkTechnologyGuid: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(
          `${this.base}scenarios/network-technologies/${networkTechnologyGuid}`,
        )
        .pipe(mapTo(networkTechnologyGuid)),
    );
  }
  /**
   * Get saved network technologies
   */
  public getSavedNetworkTechnologies(): Observable<any> {
    return this.http.get(this.base + 'user-network-technologies');
  }
  /**
   * Get network technologies from db
   */
  public getNetworkTechnologiesFromDb(): Observable<any> {
    return this.http.get(this.base + 'database-network-technologies');
  }
  /**
   * Get all details about network technology
   */
  public getNetworkTechnologyViewDetails(
    networkTechnologyGuid: string,
  ): Observable<any> {
    return this.http.get(
      this.base + 'scenarios/network-technologies/' + networkTechnologyGuid,
    );
  }

  private createV1(scenarioGuid: string, data: Partial<NetworkTechnology>) {
    return this.http
      .post<
        ResponseModel<{ networkTechnologies: NetworkTechnology[] }>
      >(`${this.base}scenarios/${scenarioGuid}/network-technologies`, mapDataRequest(NetworkTechnologyV1DtoSchema, data))
      .pipe(
        findLatestDataZ(
          NetworkTechnologyV1Schema,
          'created',
          'networkTechnologies',
        ),
      );
  }

  private createV2(scenarioGuid: string, data: Partial<NetworkTechnology>) {
    return this.http
      .post<
        ResponseModel<NetworkTechnology[]>
      >(`${this.base}v2/scenarios/${scenarioGuid}/network-technologies`, mapDataRequest(NetworkTechnologyV2SDtochema, data))
      .pipe(findLatestDataZ(NetworkTechnologyV2Schema, 'created'));
  }

  private updateV2(
    networkTechnologyGuid: string,
    data: Partial<NetworkTechnology>,
  ) {
    return this.http
      .put<
        ResponseModel<NetworkTechnology[]>
      >(`${this.base}v2/scenarios/network-technologies/${networkTechnologyGuid}`, mapDataRequest(NetworkTechnologyV2SDtochema, data))
      .pipe(
        findByGuidZ(
          NetworkTechnologyV2Schema,
          this.guidKey,
          networkTechnologyGuid,
        ),
      );
  }

  private updateV1(
    networkTechnologyGuid: string,
    data: Partial<NetworkTechnology>,
  ) {
    return this.http
      .put<
        ResponseModel<{ networkTechnologies: NetworkTechnology[] }>
      >(`${this.base}scenarios/network-technologies/${networkTechnologyGuid}`, mapDataRequest(NetworkTechnologyV1DtoSchema, data))
      .pipe(
        findByGuidZ(
          NetworkTechnologyV1Schema,
          this.guidKey,
          networkTechnologyGuid,
          'networkTechnologies',
        ),
      );
  }
}
