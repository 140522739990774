import { Injectable, OnDestroy } from '@angular/core';
import { LayerType } from '@sympheny/project/scenario/data-access';
import LayerGroup from 'ol/layer/Group';
import { combineLatest, EMPTY, map, ReplaySubject, tap } from 'rxjs';

import { DrawLayerService } from './draw-layer.service';
import { MapLayer } from './map-layer';

export const CUSTOMER_LAYER_GROUP_TITLE = 'Customer';

@Injectable()
export class DrawCustomerLayersService
  extends DrawLayerService
  implements OnDestroy
{
  private readonly editableLayers = new ReplaySubject<MapLayer[]>(1);
  public readonly editableLayers$ = this.editableLayers.asObservable();
  private customerLayerGroup: LayerGroup | null = null;

  protected override getEffects() {
    return [this.customerLayersEffect()];
  }

  protected override init() {
    if (!this.config.customerLayers) {
      if (this.customerLayerGroup) {
        this.map.removeLayer(this.customerLayerGroup);
      }
      this.customerLayerGroup = null;
      return;
    }

    this.customerLayerGroup = new LayerGroup({
      properties: { title: CUSTOMER_LAYER_GROUP_TITLE },
    });
    this.map.addLayer(this.customerLayerGroup);
  }

  public getDownloadableLayers(): any[] {
    return [];
  }

  private customerLayersEffect() {
    if (!this.config.customerLayers) {
      return EMPTY;
    }

    const customerLayers = this.scenarioMapStore.getLayers$(
      LayerType.customerLayer,
    );
    const customerScenarioLayers = this.scenarioMapStore.getLayers$(
      LayerType.customerScenarioLayer,
    );

    const layers = combineLatest([customerLayers, customerScenarioLayers]).pipe(
      map((l: any) => l.flat()),
    );

    return layers.pipe(
      tap((_layers) =>
        this.setLayerGroup(this.customerLayerGroup, _layers, true),
      ),
      tap((_layers) => this.editableLayers.next(_layers)),
    );
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.editableLayers.complete();
  }
}
