import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  findLatestDataZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
  SymphenyErrorHandler,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, mapTo } from 'rxjs';

import { CrudService } from './crud.service';
import {
  NetworkLink,
  NetworkLinkListV1Schema,
  NetworkLinkListV2Schema,
  NetworkLinkV1Dto,
  NetworkLinkV1Schema,
  NetworkLinkV2Dto,
  NetworkLinkV2Schema,
  ProjectVersion,
} from '../model';

@Injectable()
export class NetworkLinkService extends CrudService<NetworkLink> {
  public readonly guidKey = 'networkLinkGuid';
  public readonly key = 'networkLinks';

  constructor(
    http: HttpClient,
    environmentService: EnvironmentService,
    errorHandler: SymphenyErrorHandler,
  ) {
    super(http, environmentService, errorHandler);
  }

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    const url =
      projectVersion === 'V1'
        ? `${this.base}scenarios/${scenarioGuid}/network-links`
        : `${this.base}v2/scenarios/${scenarioGuid}/network-links`;
    const mapper =
      projectVersion === 'V1'
        ? mapDataZ(NetworkLinkListV1Schema, 'networkLinks')
        : mapDataZ(NetworkLinkListV2Schema);
    return this.http
      .get<ResponseModel<{ networkLinks: NetworkLink[] }>>(url)
      .pipe(mapper);
  }

  public override async create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<NetworkLink>,
  ) {
    const url =
      projectVersion === 'V1'
        ? `${this.base}scenarios/${scenarioGuid}/network-links`
        : `${this.base}v2/scenarios/${scenarioGuid}/network-links`;
    const mappedData =
      projectVersion === 'V1'
        ? mapDataRequest(NetworkLinkV1Dto, data)
        : mapDataRequest(NetworkLinkV2Dto, data);
    const mapper =
      projectVersion === 'V1'
        ? findLatestDataZ(NetworkLinkV1Schema, 'created', 'networkLinks')
        : findLatestDataZ(NetworkLinkV2Schema, 'created');

    return firstValueFrom(
      this.http
        .post<ResponseModel<{ networkLinks: NetworkLink[] }>>(url, mappedData)
        .pipe(mapper),
    );
  }

  public override async update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    networkLinkGuid: string,
    data: Partial<NetworkLink>,
  ) {
    const url =
      projectVersion === 'V1'
        ? `${this.base}scenarios/${scenarioGuid}/network-links/${networkLinkGuid}`
        : `${this.base}v2/scenarios/${scenarioGuid}/network-links/${networkLinkGuid}`;
    const mappedData =
      projectVersion === 'V1'
        ? mapDataRequest(NetworkLinkV1Dto, data)
        : mapDataRequest(NetworkLinkV2Dto, data);

    const mapper =
      projectVersion === 'V1'
        ? findByGuidZ(
            NetworkLinkV1Schema,
            this.guidKey,
            networkLinkGuid,
            'networkLinks',
          )
        : findByGuidZ(NetworkLinkV2Schema, this.guidKey, networkLinkGuid);

    return firstValueFrom(
      this.http
        .put<ResponseModel<{ networkLinks: NetworkLink[] }>>(url, mappedData)
        .pipe(mapper),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    networkLinkGuid: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(
          `${this.base}scenarios/${scenarioGuid}/network-links/${networkLinkGuid}`,
        )
        .pipe(mapTo(networkLinkGuid)),
    );
  }

  public override get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    networkLinkGuid: string,
  ) {
    const url =
      projectVersion === 'V1'
        ? `${this.base}scenarios/${scenarioGuid}/network-links/${networkLinkGuid}`
        : `${this.base}v2/network-links/${networkLinkGuid}`;
    const mapper =
      projectVersion === 'V1'
        ? mapDataZ(NetworkLinkListV1Schema)
        : mapDataZ(NetworkLinkListV2Schema);
    return firstValueFrom(
      this.http.get<ResponseModel<NetworkLink>>(url).pipe(mapper),
    );
  }
}
