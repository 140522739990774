import { Injectable } from '@angular/core';
import { Collection } from '@fp-tools/angular-state';
import {
  ResponseStatusCode,
  ResponseStatusMessages,
  SymphenyErrorHandler,
} from '@sympheny/utils/data-access';
import { EMPTY, firstValueFrom, mapTo, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ProjectDetailsState } from './project-details.state';
import { Analysis, AnalysisRequestDtoV, ProjectVersion } from '../../model';
import { AnalysisService } from '../../service';

@Injectable()
export class ProjectAnalysisState extends Collection<Analysis, 'analysisGuid'> {
  protected readonly idKey = 'analysisGuid';

  protected fetchApi(): Observable<Analysis[]> {
    return this.projectDetailsState
      .select('analyses')
      .pipe(map((analyses) => analyses ?? []));
  }

  protected override createApi({
    analysisName,
  }: Partial<Analysis> & { analysisName: string }): Observable<Analysis> {
    return this.analysisService.createAnalysis(this.projectGuid, analysisName);
  }

  protected override saveApi({
    analysisGuid,
    analysisName,
  }: Partial<Analysis> & {
    analysisGuid: string;
    analysisName: string;
  }): Observable<Analysis> {
    return this.analysisService
      .updateAnalysis(this.projectGuid, analysisGuid, analysisName)
      .pipe(
        catchError((error) => {
          this.errorHandler.handleEspError(error, {
            code: ResponseStatusCode.ESP_400_STATUS,
            message: ResponseStatusMessages.ANALYSE_UNIQUE_NAME,
          });
          return EMPTY;
        }),
      );
  }

  protected override deleteApi(analysisGuid: string): Observable<string> {
    return this.analysisService
      .deleteAnalysis(analysisGuid)
      .pipe(mapTo(analysisGuid));
  }

  private get projectGuid() {
    return this.projectDetailsState.projectGuid as string;
  }

  private get projectVersion() {
    return this.projectDetailsState.projectVersion as ProjectVersion;
  }

  public async copy(analysisGuid: string) {
    const newAnalysis = await firstValueFrom(
      this.analysisService.copyAnalysis(analysisGuid),
    );

    super.createItem(newAnalysis);

    return newAnalysis;
  }

  constructor(
    private projectDetailsState: ProjectDetailsState,
    private analysisService: AnalysisService,
    private errorHandler: SymphenyErrorHandler,
  ) {
    super();
  }

  public get analysis() {
    return this.getData();
  }

  public execute(analysisGuid: string, data: AnalysisRequestDtoV) {
    return firstValueFrom(
      this.analysisService.executeAnalysis(
        this.projectVersion,
        analysisGuid,
        data,
      ),
    )
      .then((analysis) => {
        if (this.projectVersion === 'V1') this.updateItem(analysis as any);
      })
      .catch();
  }
}
