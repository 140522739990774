import { PlanLimitation } from './plan-limitation.model';

export class Account {
  public planLimitationId: string;
  public email: string;
  public organizationId: string;
  public planExpiry: Date;
  public numberOfExecutionsLeft: number;
  public mfa: boolean;
  public deactivated: boolean;
  public superuser: boolean;
  public admin: boolean;
  public showMaintenanceInfo: boolean;
  public showGtc: boolean;
  public showUserGuide: boolean;
  public accountGuid: string;
  public created: Date;
  public updated: Date;
}

export class UserPreferences {
  public exchangeCurrencyDefault: string;
  public unitSystemDefault: string;
  public languageDefault: string;
  public interestRateDefault: number;
  public firstName: string;
  public lastName: string;
}

export class Organization {
  public name: string;
  public gisOn: boolean;
  public sepOn: boolean;
  public sepBuildingsLimit: number;
  public id: string;
  public created: Date;
  public updated: Date;
  public sepBuildingsConsumed: number;
}

export class Profile {
  public account: Account;
  public preferences: UserPreferences;
  public planLimitation: PlanLimitation;
  public organization: Organization;
  public profilePicture: string;
  public organizationPicture: string;
}
