import { NgIf } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'sympheny-sidenav-expansion-item',
  templateUrl: './sidenav-expansion-item.component.html',
  styleUrls: ['./sidenav-expansion-item.component.scss'],
  standalone: true,
  imports: [MatIconModule, NgIf],
})
export class SideNavExpansionItemComponent {
  @Input() public label!: string;
  @Input() public icon: string | undefined;
  @Input() public opened = false;
  @ViewChild('chexbox') private chexbox!: ElementRef;

  public closeAll() {
    (this.chexbox?.nativeElement as any).checked = false;
  }
}
