import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { SnackbarService } from '@sympheny/ui/snackbar';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EnvironmentService } from '@sympheny/utils/environment';

import {
  ResponseModel,
  ResponseStatusCode,
  ResponseStatusMessages,
} from './model/response.model';

const defaultMessage =
  'Something went wrong. Please try again. If the issue persists, contact support.';

@Injectable()
export class SymphenyErrorHandler implements ErrorHandler {
  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly environmentService: EnvironmentService
  ) {}

  /**
   * Hook for uncaught error responses
   *
   * @param error
   */
  handleError(error: HttpErrorResponse) {
    console.warn('handle error through angular error handler');
    if (!error?.error?.status?.code?.includes('ESP')) {
      console.error(error);
      console.warn('No ESP error');
      return;
    }
  }

  /**
   * Handle custom errors from the backend, show a snackbar when it happens
   *
   * @param error HttpErrorResponse
   * @param customMessage if a custom message should be shown instead of the description in the response
   */
  handleEspError(
    error: HttpErrorResponse,
    customMessage?: {
      code: ResponseStatusCode;
      message: ResponseStatusMessages;
    }
  ) {
    if (!this.environmentService.getValue('production')) console.warn(error);
    const res: ResponseModel = error.error;

    let message = res.status.message ?? res.status.desc ?? defaultMessage;

    if (customMessage && customMessage.code === res.status.code) {
      message = customMessage.message ?? message;
    }

    this.snackbarService.error(message);
  }
}
