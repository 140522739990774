import { nullOrUndefined } from '@sympheny/utils/rxjs';

import { CircularLinkType } from './link';

export type NodeData = { name: string; sortBy: string };

type NodeIndex = number | string;

export class NodeImpl {
  // Internal id derived from getNodeId
  public _id: string;

  public name: string;
  public index: NodeIndex;
  public virtual?: boolean;
  // public single: boolean;
  public sortBy: string;

  public values?: any[];
  public value: number;
  public circular?: boolean;
  public circularLinkType?: CircularLinkType;
  public partOfCycle?: boolean;
  public aggregate?: boolean;

  // #region position on grid
  public column: number;
  public row: number;
  public padding: { x: number; y: number };
  //#endregion

  // #region position on diagram
  public x0 = 0;
  public y0 = 0;
  public x1: number;
  public y1: number;
  private dimensions = { height: 0, width: 0, center: { y: 0, x: 0 } };
  //#endregion

  // Replaced link index

  // #region link values
  public replacedLink?: NodeIndex;
  public hasTarget: boolean;
  public hasSource: boolean;
  public sameSourceTarget: boolean;
  //#endregion

  constructor(node: Node) {
    Object.assign(this, node);
    this.calculateNodeDimensions();
  }

  public setValue(key: NodeKey, value: Node[NodeKey]) {
    (this as any)[key] = value;

    if (key in ['x1', 'y0', 'x0', 'y1']) this.calculateNodeDimensions();
  }

  public setCenterNodeY(center: number, height?: number) {
    const nHeight = height ?? this.nodeHeight;
    const y = center - nHeight / 2;
    this.setNodeY(y, nHeight);
    return this;
  }

  public setNodeY(y: number, height?: number) {
    if (nullOrUndefined(y) || isNaN(y)) return this;
    this.y0 = y;
    this.y1 = y + (height ?? this.dimensions.height);
    this.setY(y, height ?? this.nodeHeight);
    return this;
  }
  private setY(y: number, height: number) {
    if (nullOrUndefined(y) || isNaN(y)) return this;
    this.y0 = y;
    this.y1 = y + height;
    this.calculateNodeDimensions();
    return this;
  }

  public setNodeX(x: number, width?: number) {
    if (nullOrUndefined(x) || isNaN(x)) return this;
    this.setX(x, width ?? this.nodeWidth);
    return this;
  }

  public setCenterNodeX(center: number, width?: number) {
    if (nullOrUndefined(center) || isNaN(center)) return this;
    const nWidth = width ?? this.nodeWidth;
    const x = center - nWidth / 2;

    this.setX(x, nWidth);
    return this;
  }

  private setX(x: number, width: number) {
    this.x0 = x;
    this.x1 = x + width;
    this.calculateNodeDimensions();
    return this;
  }

  public get center() {
    return this.dimensions.center;
  }

  public get nodeHeight() {
    return this.dimensions.height;
  }

  public get nodeWidth() {
    return this.dimensions.width;
  }

  public get nodePositionCenterX() {
    return this.x0 + this.nodeWidth / 2;
  }
  public get nodePositionCenterY() {
    return this.y0 + this.nodeHeight / 2;
  }

  public setNodeDimensions(
    {
      height,
      width,
    }: {
      width: number;
      height: number;
    },
    padding?: { x; y },
  ) {
    this.padding = padding ?? this.padding;
    this.dimensions = {
      height,
      width,
      center: { ...this.dimensions.center },
    };
    this.y1 = this.y0 + height;
    this.x1 = this.x0 + width;
  }

  private calculateNodeDimensions() {
    this.dimensions = {
      height: this.y1 - this.y0,
      width: this.x1 - this.x0,
      center: {
        y: this.y0 + this.nodeWidth / 2,
        x: this.x1 + this.nodeHeight / 2,
      },
    };
  }
}

type NodeKey = keyof Node;
export type Node = Readonly<NodeImpl>;
