import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, ViewContainerRef } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { SnackbarService } from '@sympheny/ui/snackbar';

import {
  ConfirmationDialogComponent,
  DialogData,
} from './confirmation-dialog/confirmation-dialog.component';
import { DialogSummaryComponent } from './dialog-summary/dialog-summary.component';
import { DialogSummaryData } from './dialog-summary/model';
import {
  MarkdownDialogComponent,
  MarkdownDialogData,
} from './markdown-dialog/markdown-dialog.component';

export const DIALOG_WIDTH = {
  xsmall: '250px',
  small: '400px',
  medium: '650px',
  large: '950px',
  xlarge: '1100px',
  xl2: '1300px',
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly snackbarService: SnackbarService,
  ) {}

  public openConfirmationDialog<R>(
    data: DialogData,
    confirmedAction: () => void,
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: this.breakpointObserver.isMatched(Breakpoints.Handset)
        ? DIALOG_WIDTH.xsmall
        : DIALOG_WIDTH.medium,
      data: data,
      autoFocus: false,
    });

    if (!confirmedAction) {
      return;
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      confirmedAction();
    });
  }

  public openConfirmDelete(
    subject: string,
    confirmedAction: () => Promise<unknown> | void,
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: this.breakpointObserver.isMatched(Breakpoints.Handset)
        ? DIALOG_WIDTH.small
        : DIALOG_WIDTH.medium,
      data: {
        title: 'DELETE.dialog.title',
        question: `${subject}.delete.message`,
      },
      autoFocus: false,
    });

    if (!confirmedAction) {
      return;
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      const action = confirmedAction();

      if (!(action instanceof Promise)) return;

      action
        .then(() => this.snackbarService.success(`${subject}.delete.success`))
        .catch(() => this.snackbarService.error(`${subject}.delete.error`));
    });
  }

  public openSummaryDialog<DATA>(data: DialogSummaryData<DATA>) {
    const dialogRef = this.dialog.open(DialogSummaryComponent, {
      width: this.breakpointObserver.isMatched(Breakpoints.Handset)
        ? DIALOG_WIDTH.small
        : DIALOG_WIDTH.large,
      data: data,
      autoFocus: false,
    });

    dialogRef.afterClosed();
  }
  /**
   * Open dialog/popup
   */
  public openDialog<C, DATA>(
    dialogComponent: ComponentType<C>,
    data: DATA,
    size?: string | null,
    viewContainerRef?: ViewContainerRef,
    config?: MatDialogConfig,
  ): MatDialogRef<any> {
    const dialogRef = this.dialog.open(dialogComponent, {
      width: size ?? DIALOG_WIDTH.medium,
      data: data,
      autoFocus: false,
      viewContainerRef,
      ...config,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response?.message) {
        this.snackbarService.success(response.message);
      }
    });

    return dialogRef;
  }

  /**
   * Open dialog/popup
   */
  openDialogCustomConfig<C, DATA>(
    dialogComponent: ComponentType<C>,
    data: DATA,
    config?: MatDialogConfig,
  ): MatDialogRef<any> {
    return this.dialog.open(dialogComponent, {
      width: DIALOG_WIDTH.medium,
      data: data,
      autoFocus: false,
      ...config,
    });
  }

  public openMarkDownDialog(data: MarkdownDialogData): MatDialogRef<any> {
    return this.dialog.open(MarkdownDialogComponent, {
      width: this.breakpointObserver.isMatched(Breakpoints.Handset)
        ? DIALOG_WIDTH.xsmall
        : DIALOG_WIDTH.large,
      maxHeight: '90vh',
      data: data,
      autoFocus: false,
    });
  }
}
