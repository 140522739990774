<mat-form-field [class.full-width]="config?.fullWidth ?? true">
  <mat-label *ngIf="label">{{ label | transloco }}</mat-label>
  <textarea
    matInput
    [placeholder]="placeholder | transloco"
    [formControl]="formControl"
    [errorStateMatcher]="matcher"
    [rows]="5"
    #inputElement
  ></textarea>

  <mat-hint *ngIf="requiredHint"
    >* {{ 'FORM.requiredHint' | transloco }}
  </mat-hint>
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>

<sympheny-info-button *ngIf="hint" [tooltip]="hint"> </sympheny-info-button>
