import { SymphenyNumber } from '@sympheny/utils/data-access';
import z from 'zod';

const convertToOptimalObject = (values: number[], minIndex: number) => {
  const minValue = values[minIndex];
  return {
    minValue,
    minIndex,
    value: minIndex < 0 ? null : values[minIndex],
    values: values.map((value, index) => ({
      value,
      optimal: minIndex === index,
    })),
  };
};

export const ResultsParetoSchema = z
  .object({
    objective1: z.array(SymphenyNumber()),
    objective2: z.array(SymphenyNumber()),
  })
  .transform((results) => {
    return {
      ...results,
      objectives: {
        objective1: convertToOptimalObject(results.objective1, 0),
        objective2: convertToOptimalObject(
          results.objective2,
          results.objective2.length - 1,
        ),
      },
    };
  });

export type ResultsPareto = z.infer<typeof ResultsParetoSchema>;
