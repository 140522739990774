<form (ngSubmit)="onSubmit()" [formGroup]="uploadForm">
  <h1 mat-dialog-title>Add layer</h1>
  <ngx-file-drag-drop
    formControlName="file"
    activeBorderColor="#3F51B5"
    [multiple]="false"
    accept="application/vnd.geo+json"
    (valueChanged)="updateForm()"
  >
  </ngx-file-drag-drop>

  <div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close type="button">
      {{ 'Cancel' | transloco }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="uploadForm.invalid"
      type="submit"
    >
      Preview
    </button>
  </div>
</form>
