import { HttpClient } from '@angular/common/http';
import {
  Collection,
  mapData,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { Observable } from 'rxjs';

export class AbstractTechnologyPackageCategoriesCollection extends Collection<any> {
  readonly guidKey = null;

  constructor(
    http: HttpClient,
    environmentService: EnvironmentService,
    protected readonly baseUrl: string
  ) {
    super(http, environmentService);
  }

  protected override listDataApi(): Observable<string[]> {
    return this.http
      .get<ResponseModel<{ technologyPackages: string[] }>>(
        `${this.base}${this.baseUrl}`
      )
      .pipe(mapData('technologyPackages'));
  }
}
