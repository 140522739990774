import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { DatabaseLogCollection } from './database-log.collection';
import { DatabaseService } from './database.service';

@NgModule({
  imports: [CommonModule],
})
export class DatabaseModelModule {
  public static forRoot(): ModuleWithProviders<DatabaseModelModule> {
    return {
      ngModule: DatabaseModelModule,
      providers: [DatabaseService, DatabaseLogCollection],
    };
  }
}
