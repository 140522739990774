import { AbstractControl, ValidationErrors } from '@angular/forms';

export function PercentValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const inputNumber = control.value;
  if (inputNumber < 0 || inputNumber > 100) {
    return { percent: true };
  } else {
    return null;
  }
}
