import LayerGroup from 'ol/layer/Group';

import { OpenLayerStyles } from './base-map.const';
import { createMapboxBaseLayer } from './base-mapbox.layer';
import { createBaseOSMLayer } from './base-osm-layer';

export interface OsmConfig {
  type: 'osm';
}

export interface MapboxConfig {
  type: 'mapbox';
  style: OpenLayerStyles;
}

export type BaseMapConfig = OsmConfig | MapboxConfig;

export function createBaseMapLayer(
  baseMapLayerGroup: LayerGroup,
  config: BaseMapConfig
) {
  baseMapLayerGroup.getLayers().clear();

  switch (config.type) {
    case 'osm':
      return createBaseOSMLayer(baseMapLayerGroup);
    case 'mapbox':
      return createMapboxBaseLayer(
        baseMapLayerGroup,
        (config as MapboxConfig).style
      );
    default:
      throw new Error('base map layer type not supported');
  }
}
