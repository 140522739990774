import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { EnergyCarrierSchema } from './energy-carrier.model';
import { ImportExportTypeEnum } from './import-export.model';
import { AdvancedPriceComponentSchema } from './price-component.model';

export const TimeOfUseSchema = z.object({
  name: z.string(),
  // TODO limit to months
  months: z.array(z.string()),
  // TODO limit to days
  days: z.array(z.string()),
  // TODO limit to time
  startTime: z.string(),
  endTime: z.string(),
});
export const ImportExportV1Schema = z.object({
  capacityPriceCHFkWMonth: SymphenyNumber().nullish(),
  capacityPriceCHFkWYear: SymphenyNumber().nullish(),
  co2IntensityKgCo2kWhCo2CompensationKgCo2kWh: SymphenyNumber().nullish(),
  dynamicCo2IntensityCompensationGuid: z.string().nullish(),
  energyCarrier: EnergyCarrierSchema.extend({}).nullish(),
  energyPriceCHFkWh: SymphenyNumber().nullish(),
  fixedOmPriceCHFYear: SymphenyNumber().nullish(),
  guid: z.string().nullish(),
  hubs: z.array(
    z.object({
      hubCreated: z.string().nullish(),
      hubGuid: z.string().nullish(),
      hubName: z.string().nullish(),
      hubUpdated: z.string().nullish(),
      impexGuid: z.string().nullish(),
    }),
  ),
  maximumHourlyEnergyAvailableKWhH: SymphenyNumber().nullish(),
  notes: z.string().nullish(),
  priceComponents: z.array(AdvancedPriceComponentSchema).nullish(),
  product: z.string().nullish(),
  source: z.string().nullish(),
  suggested: z.boolean().nullish(),
  timeOfUse: z.array(TimeOfUseSchema).nullish(),
  totalAnnualEnergyAvailableKWhA: SymphenyNumber().nullish(),
  type: ImportExportTypeEnum.nullish(),
  updated: SymphenyDate().nullish(),
  created: SymphenyDate().nullish(),
  year: SymphenyNumber().nullish(),
});
export const ImportExportV1Listchema = ImportExportV1Schema;

export const ImportExportV1Dtochema = ImportExportV1Schema.pick({
  capacityPriceCHFkWMonth: true,
  capacityPriceCHFkWYear: true,
  co2IntensityKgCo2kWhCo2CompensationKgCo2kWh: true,

  energyPriceCHFkWh: true,
  fixedOmPriceCHFYear: true,

  maximumHourlyEnergyAvailableKWhH: true,
  notes: true,
  priceComponents: true,
  product: true,
  source: true,
  suggested: true,

  totalAnnualEnergyAvailableKWhA: true,
  type: true,
  year: true,
}).extend({
  dynamicCo2IntensityCompensation: z
    .object({
      encodedFile: z.string(),
      fileName: z.string(),
    })
    .nullish(),
  dynamicTariffs: z
    .array(
      z.object({
        name: z.string().nullish(),
        tariffs: z.object({
          encodedFile: z.string().nullish(),
          fileName: z.string(),
        }),
      }),
    )
    .nullish(),
  energyCarrierGuid: z.string().nullish(),
  hubs: z
    .array(z.object({ hubGuid: z.string(), impexGuid: z.string().nullish() }))
    .nullish(),
  timeOfUses: z.array(TimeOfUseSchema).nullish(),
});
