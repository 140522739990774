<button
  class="btn btn-circle bg-transparent border-none"
  type="button"
  [disabled]="disabled"
  [class.btn-sm]="size === 'small'"
  (click)="action.emit($event)"
  [matTooltip]="tooltip | transloco"
>
  <mat-icon
    [fontSet]="fontSet"
    [class]="disabled ? '' : active ? activeClass : cssClass"
  >
    {{ icon }}
  </mat-icon>
</button>
