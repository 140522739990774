import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const SeasonalOperationsSchema = z.object({
  displayName: z.string(),
  value: z.string(),
});

export const CustomSeaonalityValueSchema = z.object({
  month: z.string(),
  value: SymphenyNumber(),
});

export type SeasonalOperations = z.infer<typeof SeasonalOperationsSchema>;
