import { Injectable } from '@angular/core';
import { CrudService, ProjectVersion } from '@sympheny/project/data-access';
import {
  findByGuidZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  EnergyDemandV1,
  EnergyDemandV1Dto,
  EnergyDemandV1DtoSchema,
  EnergyDemandV1Schema,
} from './energy-demand-v1.model';

export enum EnergyDemandApiTypes {
  database,
  save,
}

@Injectable()
export class EnergyDemandV1Service extends CrudService<
  EnergyDemandV1,
  EnergyDemandV1,
  EnergyDemandV1Dto
> {
  public readonly guidKey = 'energyDemandGuid';
  public readonly key = 'energyDemands';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<ResponseModel<{ energyDemands: EnergyDemandV1[] }>>(
        `${this.base}scenarios/${scenarioGuid}/energy-demands`,
      )
      .pipe(mapDataZ(EnergyDemandV1Schema, 'energyDemands'));
  }

  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: EnergyDemandV1Dto,
    type: EnergyDemandApiTypes,
  ) {
    let url = '';
    switch (type) {
      case EnergyDemandApiTypes.database:
        url = `scenario/${scenarioGuid}/database-energy-demands`;
        break;
      case EnergyDemandApiTypes.save:
        url = `scenarios/${scenarioGuid}/energy-demands`;
        break;
      default:
        throwError('wrong type');
    }

    return firstValueFrom(
      this.http
        .post<ResponseModel<{ energyDemands: EnergyDemandV1[] }>>(
          `${this.base}${url}`,
          mapDataRequest(EnergyDemandV1DtoSchema, data),
        )
        // TODO check me
        .pipe(map((r) => r.data.energyDemands[0])),
    );
  }

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: EnergyDemandV1Dto,
    type: EnergyDemandApiTypes,
  ) {
    let url = '';
    switch (type) {
      case EnergyDemandApiTypes.database:
        url = `scenario/${scenarioGuid}/database-energy-demands/${guid}`;
        break;
      case EnergyDemandApiTypes.save:
        url = `scenarios/${scenarioGuid}/energy-demands/${guid}`;
        break;
      default:
        throwError('wrong type');
    }
    return firstValueFrom(
      this.http
        .put<ResponseModel<{ energyDemands: EnergyDemandV1[] }>>(
          `${this.base}${url}`,
          mapDataRequest(EnergyDemandV1DtoSchema, data),
        )
        .pipe(
          findByGuidZ(
            EnergyDemandV1Schema,
            'energyDemandGuid',
            guid,
            'energyDemands',
          ),
        ),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    type: string,
  ) {
    return firstValueFrom(
      this.http
        .delete<ResponseModel<EnergyDemandV1>>(
          `${this.base}scenarios/energy-demands/${guid}`,
        )
        .pipe(mapTo(guid)),
    );
  }
  /**
   * Get energy demand profile by Guid
   *
   * @param energyDemandGuid: string
   */
  public getDetail(
    energyDemandGuid: string,
    scenarioVariantGuid: string | null,
  ) {
    const params = scenarioVariantGuid
      ? { scenarioVariantGuid: scenarioVariantGuid }
      : null;

    return firstValueFrom(
      this.http
        .get<ResponseModel<EnergyDemandV1>>(
          `${this.base}energy-demands/${energyDemandGuid}`,
          {
            params,
          },
        )
        .pipe(mapDataZ(EnergyDemandV1Schema)),
    );
  }

  /**
   * Get energy demand profile by Guid
   *
   * @param energyDemandGuid: string
   */

  public download(energyDemandGuid: string): Observable<any> {
    return this.http.get(
      `${this.base}energy-demands/${energyDemandGuid}/profile/download`,
      { observe: 'response', responseType: 'arraybuffer' },
    );
  }
}
