import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { InputSuffixComponent } from '../input-suffix/input-suffix.component';

export interface MatInputConfig extends FormFieldConfig {
  minLength?: number;
  maxLength?: number;
}

@Component({
  selector: 'sympheny-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    TranslocoPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InfoButtonComponent,
    MatInputModule,
    NgIf,
    InputSuffixComponent,
  ],
})
export class InputComponent<T> extends BaseFormComponent<any, MatInputConfig> {
  @Input() public type = 'text';
}
