import { ThemePalette } from '@angular/material/core';

export interface IconButtonConfiguration {
  color?: ThemePalette | 'custom';
  disabled?: boolean;
  fontSet?: string;
  tooltip: string;
  icon: string;
  action: () => void | Promise<void>;
  dataCy?: string;
}

export interface IconButtonListConfiguration {
  buttons: IconButtonConfiguration[];
}
