import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { AnalysisService } from '@sympheny/project/data-access';
import { DialogService } from '@sympheny/ui/dialog';
import { PlanLimitation, UserState } from '@sympheny/user/data-access';
import { ResponseStatusCode } from '@sympheny/utils/data-access';
import { isNotNullOrUndefined } from '@sympheny/utils/rxjs';
import { distinctUntilChanged, firstValueFrom, map, Observable } from 'rxjs';

import {
  ExecutionsLeftModalComponent,
  ExecutionsLeftModalData,
} from './executions-left-modal/executions-left-modal.component';
import {
  PlanLimitReachedModalComponent,
  PlanLimitReachedModalData,
} from './plan-limit-reached-modal/plan-limit-reached-modal.component';
export enum PlanType {
  UNLIMITED = 'UNLIMITED',
  WARM_UP = 'WARM_UP',
  ENY_MAP = 'ENYMAP-APP',
}

@Injectable()
export class PlanService {
  // private currentPlan: string | null = null;
  // private currentPricingPlan: PricingPlan | null = null;

  constructor(
    private dialogService: DialogService,
    @Optional() private analysisService: AnalysisService,
    private userState: UserState,
  ) {}

  public isPlanLimitReached(
    key: keyof PlanLimitation,
    testLimitations: any[] = [],
  ): boolean {
    if (!this.userState.planLimitation) {
      return true;
    }

    if (this.isUnlimited()) {
      return false;
    }

    const planLimit = this.getPlanLimit(key);
    if (planLimit === null) {
      return false;
    }

    const totalElements = testLimitations?.length ?? 0;

    if (totalElements < planLimit) {
      return false;
    }

    this.showPlanLimitationsDialog();

    return true;
  }

  public showPlanLimitationsDialog(
    extraData: Partial<PlanLimitReachedModalData> = {},
  ) {
    const data: PlanLimitReachedModalData = {
      pricingPlan: this.userState.planLimitation,
      continue: false,
      limit: true,
      ...extraData,
    };
    return this.dialogService.openDialog(PlanLimitReachedModalComponent, data);
  }

  public executionsLeftDialog(executionsLeft: number, _continue: boolean) {
    const data: ExecutionsLeftModalData = {
      pricingPlan: this.userState.planLimitation,
      executionsLeft: executionsLeft,
      continue: _continue ?? false,
    };
    return this.dialogService.openDialog(ExecutionsLeftModalComponent, data);
  }
  public getPlan$() {
    return this.userState.planLimitation$.pipe(
      isNotNullOrUndefined(),
      map((limit) => limit.name?.toUpperCase() as PlanType),
    );
  }
  public isUnlimited(): boolean {
    return this.userState.planLimitation
      ? this.userState.planLimitation.name.toUpperCase() === PlanType.UNLIMITED
      : false;
  }
  public isEnymapApp(): boolean {
    return this.userState.planLimitation
      ? this.userState.planLimitation.name.toUpperCase() === PlanType.ENY_MAP
      : false;
  }

  public isWarmUp(): boolean {
    return this.userState.planLimitation
      ? this.userState.planLimitation.name.toUpperCase() === PlanType.WARM_UP
      : false;
  }

  public getPlanLimit<K extends keyof PlanLimitation>(key: K): number | null {
    if (!this.userState.planLimitation) {
      return null;
    }

    return (this.userState.planLimitation[key] as number) ?? null;
  }
  public isEnabled(key: keyof PlanLimitation): boolean {
    return this.isEnabled_(this.userState.planLimitation, key);
  }

  public isEnabled$(key: keyof PlanLimitation): Observable<boolean> {
    return this.userState.planLimitation$.pipe(
      map((limit) => this.isEnabled_(limit, key)),
      distinctUntilChanged(),
    );
  }

  private isEnabled_(
    planLimitation: PlanLimitation,
    key: keyof PlanLimitation,
  ): boolean {
    if (!planLimitation) {
      return false;
    }

    return (planLimitation[key] as boolean) ?? false;
  }

  public async shouldExecute(): Promise<boolean> {
    const limit = this.getPlanLimit('maxAnalyses');
    if (this.isUnlimited() || limit === null) {
      return true;
    }
    try {
      const executionInfo = await this.analysisService.getExecutionsInfo();

      const dialogRef = this.executionsLeftDialog(
        executionInfo.numberOfExecutionsLeft,
        true,
      );

      return firstValueFrom(
        dialogRef.afterClosed().pipe(map((result) => !!result.continueButton)),
      );
    } catch (e: unknown) {
      const { error } = e as HttpErrorResponse;

      if (error.code === ResponseStatusCode.ESP_4036_STATUS) {
        this.executionsLeftDialog(0, false);
      } else if (error.status.code === ResponseStatusCode.ESP_4035_STATUS) {
        this.showPlanLimitationsDialog({
          maxParallelExecutions: error.data.maxParallelExecutions,
          continue: false,
          analyses: error.data.analyses,
        });
      }
    }

    return false;
  }
}
