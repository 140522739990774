import z from 'zod';

export const ResultProfileSchema = z.object({
  name: z.string(),
  profile: z.array(z.number()),
});
export const ResultConvOpsModeSchema = z.object({
  name: z.string(),
  capacity: z.number(),
  energyIn: z.array(ResultProfileSchema),
  energyOut: z.array(ResultProfileSchema),
  curtailment: z.array(ResultProfileSchema),
  monthlyEnergyOut: z.array(ResultProfileSchema),
});

export const ResultConvOpsSchema = z.object({
  name: z.string(),
  stage: z.string(),
  hub: z.string(),
  capacity: z.number(),
  capacityBinary: z.number(),
  om: z.number(),
  co2: z.number(),
  modes: z.array(ResultConvOpsModeSchema),
});

export type ResultConvOps = z.infer<typeof ResultConvOpsSchema>;
export type ResultProfile = z.infer<typeof ResultProfileSchema>;

export type ResultConvOpsMode = z.infer<typeof ResultConvOpsModeSchema>;
