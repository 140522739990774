import { Injectable } from '@angular/core';
import { firstValueFrom, mapTo } from 'rxjs';

import { ExtraParam, LayerHttpService } from './layer-http.service';
import { HubFeature, HubGis } from './model/hub-gis';
import { LayerType } from './model/layer.type';

@Injectable()
export class HubGisService extends LayerHttpService<HubGis> {
  public readonly layerType = LayerType.hubs;

  private getBaseUrl(scenarioId: string) {
    return `${this.gis}scenarios/${scenarioId}/hubs`;
  }

  private getBaseBackgroundUrl(scenarioId: string) {
    return `${this.gis}background/scenarios/${scenarioId}/hubs`;
  }

  public override list(scenarioId: string) {
    return this.http.get<HubGis[]>(`${this.getBaseUrl(scenarioId)}`);
  }

  public override get(scenarioId: string, layerId: string) {
    return this.http.get<HubGis>(`${this.getBaseUrl(scenarioId)}/${layerId}`);
  }

  public override async create(
    scenarioId: string,
    l: any,
    extraParam: ExtraParam
  ) {
    const layer = l as unknown as HubFeature;
    const params = { sep: layer.sep, geoadmin: layer.geoadmin };

    const url =
      layer.geoadmin || layer.sep
        ? this.getBaseBackgroundUrl(scenarioId)
        : this.getBaseUrl(scenarioId);

    layer.feature.properties = { ...layer.feature.properties, ...params };

    const request = this.http.post<HubGis>(
      `${url}/${layer.hub_id}`,
      { ...layer, ...params },
      {
        params,
      }
    );
    const hub = await firstValueFrom(request);

    if (layer.sep || layer.geoadmin) {
      return { ...hub, name: `Process ${layer.hub_name}` };
    }

    return hub;
  }

  public override update(
    scenarioId: string,
    layerId: string,
    layer: HubGis,
    extraParam: ExtraParam
  ) {
    return this.create(scenarioId, layer, extraParam);
  }

  public override delete(scenarioId: string, layerId: string) {
    return firstValueFrom(
      this.http
        .delete(`${this.getBaseUrl(scenarioId)}/${layerId}`)
        .pipe(mapTo(layerId))
    );
  }
}
