import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  mapData,
  ResponseModel,
  SymphenyErrorHandler,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, Observable } from 'rxjs';

import { OnSiteSolarResource } from './on-site-solar-resource.model';
import { SolarResourcesV1Service } from './solar-resources.v1.service';
import { SolarResourcesV2Service } from './solar-resources.v2.service';
import { ProjectVersion } from '../model';
import { CrudService } from '../service';

@Injectable()
export class SolarResourcesService extends CrudService<
  OnSiteSolarResource,
  OnSiteSolarResource,
  any
> {
  public readonly guidKey = 'solarResourceGuid';
  public readonly key = 'onSiteSolarResources';
  constructor(
    http: HttpClient,
    environmentService: EnvironmentService,
    errorHandler: SymphenyErrorHandler,
    private readonly solarResourcesV1Service: SolarResourcesV1Service,
    private readonly solarResourcesV2Service: SolarResourcesV2Service,
  ) {
    super(http, environmentService, errorHandler);
  }

  private getService(
    projectVersion: ProjectVersion,
  ): CrudService<OnSiteSolarResource> {
    if (projectVersion === 'V1') return this.solarResourcesV1Service as any;

    return this.solarResourcesV2Service;
  }
  public override list(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
  ): Observable<OnSiteSolarResource[]> {
    return this.getService(projectVersion).list(projectVersion, scenarioGuid);
  }
  public override async get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    const resources = await firstValueFrom(
      this.list(projectVersion, scenarioGuid),
    );

    return resources.find((r) => r.solarResourceGuid === guid);
  }

  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: any,
  ) {
    return this.getService(projectVersion).create(
      projectVersion,
      scenarioGuid,
      data,
    );
  }

  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: any,
  ) {
    return this.getService(projectVersion).update(
      projectVersion,
      scenarioGuid,
      guid,
      data,
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    type: string,
  ): Promise<any> {
    return this.getService(projectVersion).delete(
      projectVersion,
      scenarioGuid,
      guid,
      type,
    );
  }

  /**
   * Get profile view for solar resource
   */
  getOnSiteSolarResourcesProfileView(
    onSiteSolarResourceGuid: string,
  ): Promise<any> {
    return firstValueFrom(
      this.http
        .get<
          ResponseModel<any>
        >(`${this.base}solar-on-site-resource/${onSiteSolarResourceGuid}/profiles`)
        .pipe(mapData()),
    );
  }

  /**
   * Get solar resource profile by Guid
   *
   * @param solarResourceGuid: string
   */
  public getSolarProfileByGuid(solarResourceGuid: string) {
    return firstValueFrom(
      this.http.get(
        `${this.base}solar-on-site-resource/${solarResourceGuid}/profiles/file`,
        { observe: 'response', responseType: 'arraybuffer' },
      ),
    );
  }

  /**
   * Save energy profile after successfully uploading/saving energy solar resource
   */
  saveSolarResourceUserProfile(data: any) {
    return firstValueFrom(
      this.http.post(`${this.base}solar-resources/saved-profiles`, data),
    );
  }
  /**
   * Get locations from db for solar resources
   */
  getSolarResourcesLocationsFromDb(): Observable<any> {
    return this.http.get(`${this.base}database-solar-resources/locations`);
  }

  /**
   * Get types for selected location from db for solar resources
   */
  getSolarResourcesTypesByLocation(location: string): Observable<any> {
    return this.http.get(
      `${this.base}database-solar-resources/locations/${location}/types`,
    );
  }

  /**
   * Get slope and azimuth for selected location and type from db for solar resources
   */
  getSolarResourcesSlopeAndAzimuthByType(
    location: string,
    type: string,
  ): Observable<any> {
    return this.http.get(
      `${this.base}database-solar-resources/locations/${location}/types/${type}`,
    );
  }

  /**
   * Get solar irradiation profile from db for solar resources
   */
  getSolarIrradiationProfille(name: string): Observable<any> {
    return this.http.get(`${this.base}database-solar-resources/${name}`);
  }

  /**
   * Get saved energy profiles for solar resources
   */
  getSolarResourceSavedProfiles(): Observable<any> {
    return this.http.get(`${this.base}user-solar-resources`);
  }
}
