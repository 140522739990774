import BaseLayer from 'ol/layer/Base';

export const skipFeaturesAtPixel = (layer: BaseLayer) => {
  return !layer.get('mapbox-source') && !layer.get('skipFeatureAtPixel');
};

export const layerAllowed = (layer: BaseLayer) => {
  return (
    skipFeaturesAtPixel(layer) &&
    !layer.get('rasterLayer') &&
    layer.get('visible')
  );
};
