import { Injectable, OnDestroy } from '@angular/core';
import { gisStyles, layerAllowed } from '@sympheny/gis/utils';
import * as ol from 'ol';
import { Feature, MapBrowserEvent } from 'ol';
import { Control } from 'ol/control';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import { DragBox } from 'ol/interaction';
import { Layer } from 'ol/layer';

export type SelectMode = 'CLICK' | 'DRAG';
export type SelectMethod = 'DELETE' | 'ADD';
@Injectable()
//TODO rename me
export class SelectFeaturesService implements OnDestroy {
  private map!: ol.Map;

  private selectMethod: SelectMethod = 'ADD';
  private clickEvent: string | null;
  private boxEvent: string | null;
  private hubId: string | null;

  private selectFeatures = new Map<string, Feature<any>>();
  private onChangeEvent: (features: Feature[]) => void;
  private dragBox = new DragBox();

  public init(
    map: ol.Map,
    scenarioId: string,
    onChangeEvent: (features: Feature[]) => void,
  ): void {
    this.map = map;

    // Default selection

    this.onChangeEvent = onChangeEvent;
    this.changeToClickMode();
  }

  public addControlBar(element: HTMLElement) {
    const controlBar = new Control({
      element,
    });
    this.map.addControl(controlBar);
  }

  public selectAll() {
    this.map?.getAllLayers?.().forEach((layer: any) => {
      const features = layer.getSource?.()?.getFeatures?.();

      if (!features) return;

      features.forEach((feature) => this.add(feature));
    });
    this.changeLayerEvent();
  }

  public changeMode(selectMode: SelectMode) {
    switch (selectMode) {
      case 'CLICK':
        this.changeToClickMode();
        break;
      case 'DRAG':
        this.changeToDragMode();
        break;
    }
  }

  public changeMethod(method: SelectMethod) {
    this.selectMethod = method;
  }

  public setHubId(hubId: string) {
    this.hubId = hubId;
    for (const [index, feature] of this.selectFeatures.entries()) {
      this.deSelectFeature(index, feature);
    }
    this.selectFeatures.clear();
  }

  private changeToClickMode() {
    this.clickEvent = this.map.on(
      'singleclick',
      (evt: MapBrowserEvent<any>) => {
        this.onMapClick(evt.coordinate);
      },
    ) as unknown as string;
    this.removeDragEvent();
  }

  private removeClickEvent() {
    if (this.clickEvent) this.map.unset(this.clickEvent);
    this.clickEvent = null;
  }

  private removeDragEvent() {
    if (this.boxEvent) this.map.unset(this.boxEvent);
    this.map.removeInteraction(this.dragBox);
    this.boxEvent = null;
  }

  private changeToDragMode() {
    this.removeClickEvent();
    this.map.addInteraction(this.dragBox);
    this.boxEvent = this.dragBox.on('boxend', () =>
      this.addSelectByDrag(),
    ) as unknown as string;
  }

  private onMapClick(coordinate: number[]) {
    const pixel = this.map.getPixelFromCoordinate(coordinate);
    this.map.forEachFeatureAtPixel(
      pixel,
      (feature, layer) => {
        if (!this.layerAllowed(layer)) return;

        this.add(feature as Feature);
      },
      {
        layerFilter: layerAllowed,
      },
    );
    this.changeLayerEvent();
  }

  private addSelectByDrag() {
    const extent = this.dragBox.getGeometry().getExtent();

    this.map.getAllLayers().forEach((layer: any) => {
      if (!this.layerAllowed(layer)) return;

      const features = layer.getSource?.()?.getFeatures?.();

      if (!features) return;

      features.forEach((feature) => {
        if (!feature.getGeometry().intersectsExtent(extent)) return;
        this.add(feature);
      });
    });

    this.changeLayerEvent();
  }

  private changeLayerEvent() {
    const selectedFeatures = Array.from(this.selectFeatures.values());
    this.onChangeEvent?.(selectedFeatures);
  }

  private layerAllowed(layer: Layer<any>) {
    return !layer.get('rasterLayer') && layer.get('visible');
  }

  private add(feature: Feature<any>) {
    if (!this.featureAllowed(feature)) return;

    const coordinates: number[] = feature.getGeometry().flatCoordinates;

    const index = this.getFeatureIndex(coordinates);

    if (this.selectMethod === 'DELETE') {
      this.deSelectFeature(index, feature);
    } else {
      this.selectFeature(index, feature);
    }
  }

  private featureAllowed(feature: Feature<any>) {
    if (feature.getGeometry() instanceof Point) return false;
    if (feature.getGeometry() instanceof LineString) return false;
    if (!this.hubId) return false;

    return feature.get('hub_id') === this.hubId;
  }

  private selectFeature(index: string, feature: Feature<any>) {
    if (this.selectFeatures.has(index)) {
      return;
    }
    feature.setStyle?.(gisStyles.selectedFeatures);
    this.selectFeatures.set(index, feature);
  }

  private deSelectFeature(index: string, feature: Feature<any>) {
    if (!this.selectFeatures.has(index)) {
      return;
    }

    feature.setStyle?.(gisStyles.savedFeatures);
    this.selectFeatures.delete(index);
  }

  private getFeatureIndex(coordinates: number[]) {
    return JSON.stringify(coordinates);
  }

  public ngOnDestroy(): void {
    this.selectMethod = 'DELETE';
    this.selectAll();
  }
}
