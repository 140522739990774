import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { Vector as VectorLayer } from 'ol/layer';

import { featureProjection } from '../model/projections';

export function downloadGisData(
  name: string,
  layers: Array<VectorLayer<any> | null>
) {
  const features: Feature<any>[] = [];

  const format = new GeoJSON({ featureProjection: featureProjection });
  layers.forEach((layer) => {
    if (!layer) {
      return;
    }
    features.push(...(layer.getSource()?.getFeatures() || []));
  });

  const json = format.writeFeatures(features);

  const a = document.createElement('a');
  a.href = `data:application/json;charset=utf-8,${encodeURIComponent(json)}`;
  a.download = `${name}.geojson`;
  a.click();
  a.remove();
}
