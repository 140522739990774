import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { Address } from '@sympheny/project/scenario/data-access';

import { ParseField } from '../excel-parser.service';
import {
  WorksheetSelection,
  WorksheetColumnSelectionComponent,
} from '../worksheet-column-selection/worksheet-column-selection.component';

@Component({
  selector: 'sympheny-upload-gis-form',
  templateUrl: './upload-gis-form.component.html',
  styleUrls: ['./upload-gis-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadGisFormComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    WorksheetColumnSelectionComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class UploadGisFormComponent implements ControlValueAccessor {
  value: ParseField[] = [];
  selectedFields: Array<keyof Address> = [];
  @Input() excludeField: string;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onChange: (value: ParseField[]) => void;

  registerOnChange(fn: (value: ParseField[]) => void): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(fn: any): void {}

  writeValue(value: ParseField[]): void {
    this.value = [...value];
    this.selectedFields = value.filter((f) => !!f.field).map((f) => f.field);
    this.cdr.markForCheck();
  }

  changeValue(field: ParseField, newValue: WorksheetSelection) {
    field.field = newValue.field!;

    this.selectedFields = this.value
      .filter((f) => !!f.field)
      .map((f) => f.field);
    this.onChange(this.value);
  }
}
