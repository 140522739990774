import { APP_INITIALIZER, Provider } from '@angular/core';

import {
  CheckForUpdateService,
  initCheckForUpdates,
} from './check-for-update.service';
import { ENVIRONMENT_CONFIGURATION } from './environment.config';
import { EnvironmentService } from './environment.service';

export function provideEnvironment<T>(
  configuration: T,
  enableSw = true,
): Provider[] {
  return [
    { provide: ENVIRONMENT_CONFIGURATION, useValue: configuration },
    EnvironmentService,
    CheckForUpdateService,
    enableSw
      ? {
          provide: APP_INITIALIZER,
          useFactory: initCheckForUpdates,
          deps: [CheckForUpdateService],
          multi: true,
        }
      : [],
  ].flat();
}
