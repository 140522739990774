import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconButtonComponent } from '@sympheny/ui/button';
import { tap } from 'rxjs';

import { ProgressService } from '../progress.service';

@Component({
  selector: 'sympheny-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  standalone: true,
  imports: [NgIf, IconButtonComponent, NgFor, MatIconModule, AsyncPipe],
})
export class ProgressComponent {
  public readonly progress$ = this.progressService.progress$.pipe(
    tap((progress) => {
      if (this.closed) {
        this.closed = progress.length === 0;
      }
    }),
  );

  public closed = true;
  public collapsed = false;

  constructor(private readonly progressService: ProgressService) {}

  public closeProgress() {
    this.closed = true;
    this.progressService.removeAllProgress();
  }
}
