import { EnergyDemandV1, EnergyDemandV1Dto } from './energy-demand-v1.model';
import {
  EnergyDemandV2,
  EnergyDemandV2DtoWithProfile,
} from './energy-demand-v2.model';

export class EnergyDemandType {
  public energyDemandType: string;
  public energyDemandTypeDisplayValue: string;
}

export type EnergyDemand = EnergyDemandV1 & EnergyDemandV2;
export type EnergyDemandDto = EnergyDemandV1 & EnergyDemandV2DtoWithProfile;
export type EnergyDemandDetail = EnergyDemandV1Dto & EnergyDemandV2;
