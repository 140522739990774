import { HttpClient } from '@angular/common/http';
import { DatabaseDetailsService } from '@sympheny/database/model';
import { ProjectVersion } from '@sympheny/project/data-access';
import {
  LoadDataService,
  mapData,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, map, Observable, of, ReplaySubject } from 'rxjs';

import { SlopeAndOrientation, SolarEnergyDatabaseProfile } from './model';

export abstract class AbstractSolarEnergyDatabaseProfileCollection
  implements DatabaseDetailsService<any>, LoadDataService
{
  private readonly base = this.environmentService.getValue('base');
  protected readonly idKey = '';

  public readonly categories$ = new ReplaySubject<any>(1);
  constructor(
    protected readonly fromOrg: boolean,
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
  ) {}
  public getTechnologyCategoryDetails([location]: string[]): Observable<any[]> {
    return this.byLocation(location);
  }
  public getDetails(
    projectVersion: ProjectVersion,
    location: string,
    type: string,
    exchangeRate: number,
  ): Observable<any> {
    return of(type);
  }

  public load(): void {
    firstValueFrom(this.getLocations()).then((categories) =>
      this.categories$.next(categories),
    );
  }

  public reload(): void {
    this.load();
  }
  /**
   * Get locations from db for solar resources
   */
  public getLocations(): Observable<{ label: string; value: string }[]> {
    return this.http
      .get<ResponseModel<{ locations: string[] }>>(
        `${this.base}database-solar-resources/locations`,
        { params: { fromOrg: this.fromOrg } },
      )
      .pipe(
        mapData('locations'),
        map((values) => values.map((v) => ({ label: v, value: v }))),
      );
  }

  /**
   * Get types for selected location from db for solar resources
   */
  public byLocation(
    location: string,
  ): Observable<{ label: string; value: string }[]> {
    return this.http
      .get<ResponseModel<{ types: string[] }>>(
        `${this.base}database-solar-resources/locations/${location}/types`,
        { params: { fromOrg: this.fromOrg } },
      )
      .pipe(
        mapData('types'),
        map((values) => values.map((v) => ({ label: v, value: v }))),
      );
  }

  public byFacade(
    location: string,
    type: string,
  ): Observable<SlopeAndOrientation[]> {
    return this.http
      .get<
        ResponseModel<{
          databaseSolarResourcesSlopesAndOrientation: SlopeAndOrientation[];
        }>
      >(
        `${this.base}database-solar-resources/locations/${location}/types/${type}`,
        { params: { fromOrg: this.fromOrg } },
      )
      .pipe(mapData('databaseSolarResourcesSlopesAndOrientation'));
  }

  public getResource(name: string): Observable<SolarEnergyDatabaseProfile> {
    return this.http
      .get<ResponseModel<SolarEnergyDatabaseProfile>>(
        `${this.base}database-solar-resources/${name}`,
        { params: { fromOrg: this.fromOrg } },
      )
      .pipe(mapData());
  }
  public deleteCategory(guid: string): Promise<string> {
    throw new Error('not implemented yet');
  }

  public create(
    projectVersion: ProjectVersion,
    partialData: Partial<any>,
    ...extraParams: any
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public update(
    projectVersion: ProjectVersion,
    gui: string,
    data: Partial<any>,
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }
  public delete(gui: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
