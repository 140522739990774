import { NetworkTechnology } from '@sympheny/project/data-access';
import { createEnergyCarrierStyle } from '@sympheny/project/energy-carrier/utils';
import { LayerType, NetworkGis } from '@sympheny/project/scenario/data-access';
import { Feature } from 'ol';

import { MapLayer } from './map-layer';

export class MapNetworkLayer extends MapLayer {
  public readonly network_id: string;
  public readonly link_id: string;
  public readonly originalFeature: Feature<any>;
  constructor(networkLayer: NetworkGis) {
    super(
      `${networkLayer.network_id}/${networkLayer.link_id}`,
      LayerType.networkLayers,
      'network layer',
      true
    );

    this.network_id = networkLayer.network_id;
    this.link_id = networkLayer.link_id;
    this.originalFeature = networkLayer.feature;

    this.addFeature(networkLayer.feature);
  }

  public override customAction(networkTechnology: NetworkTechnology | null) {
    const energyCarrier = networkTechnology?.energyCarrier || null;

    this.setStyle(
      energyCarrier ? createEnergyCarrierStyle(energyCarrier) : null
    );
    return super.customAction(networkTechnology);
  }
}
