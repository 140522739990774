import { z } from 'zod';

import { ImportExportV1Schema } from './import-export.model.v1';
import { ImportExportV2Schema } from './import-export.model.v2';

export const ImportExportTypeEnum = z.enum([
  'IMPORT',
  'EXPORT',
  'Import',
  'Export',
]);
export type ImportExportType = 'IMPORT' | 'EXPORT';

export function filterImportExport(
  importExports: ImportExport[],
  type: ImportExportType,
) {
  const t = type.toUpperCase();
  return importExports.filter((i) => i.type.toUpperCase() === t);
}

type ImportExportV1 = z.infer<typeof ImportExportV1Schema>;
type ImportExportV2 = z.infer<typeof ImportExportV2Schema>;

export type ImportExport = ImportExportV1 & ImportExportV2;
