<div
  class="radio-group mat-form-field"
  [attr.data-cy]="dataCy || label"
  [attr.data-testid]="dataCy || label"
>
  <mat-label *ngIf="label" class="radio-group__label">
    <strong>{{ label | transloco }}</strong>
    <sympheny-info-button *ngIf="hint" [tooltip]="hint"> </sympheny-info-button>
    <mat-hint *ngIf="requiredHint"
      >* {{ 'FORM.requiredHint' | transloco }}</mat-hint
    >
  </mat-label>
  <mat-radio-group
    [formControl]="formControl"
    [attr.aria-label]="ariaLabel | transloco"
    class="flex mb-2 flex-wrap"
    [class.flex-row]="direction === 'row'"
    [class.items-center]="direction === 'row'"
    [class.flex-col]="direction === 'col'"
    (change)="onSelectionChange($event)"
  >
    <mat-radio-button
      *ngFor="let option of options"
      color="primary"
      [value]="getValue(option)"
      class="radio-button"
      >{{ getLabel(option) | transloco }}
      <sympheny-info-button
        *ngIf="option.hint"
        [tooltip]="option.hint.text"
        [link]="option.hint.link"
      ></sympheny-info-button>
    </mat-radio-button>
  </mat-radio-group>
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</div>
