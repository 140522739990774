export class Address {
  address_id: number;
  address: string;
  lon: number;
  lat: number;
  building_category: string;
  building_ground_area: number;
  nbr_floor: number;
  nbr_household: number;
  construction_end: number;
  renovation_end: number;

  volume_egid: number;
  pv_prod_power_egid: number;

  avg_heating_egid: number;
  avg_hotwater_egid: number;
  avg_heating_egid_m2: number;
  avg_hotwater_egid_m2: number;

  heating_power_egid: number;
  elecDemandTotalPred: number;
  elecDemandDevicesPred: number;
  elecDemandLightPred: number;
  elecDemandVentilationPred: number;
  elecDemandCoolingPred: number;
  elecDemandHeatingPred: number;
  elecDemandHotwaterPred: number;
  elecDemandProcessesPred: number;
  elecDemandBuildingtechPred: number;

  sum_f_area: number;
  sum_f_electricity_PV_kWh_y: number;
  max_f_heat_PV_kWh_y: number;
  sum_f_PV_peak_performance_kWp: number;

  sum_r_area: number;
  sum_r_electricity_PV_kWh_y: number;
  max_r_heat_PV_kWh_y: number;
  sum_r_PV_peak_performance_kWp: number;
  sum_r_global_solar_radiation_kWh_y: number;
  avg_r_area_pv_suitability: string;

  certificate: string;
  energy_ref_area_m2: number;
  minergie_standard: string;

  egid: number;

  hub_label: string;

  gstat: string;
  gkat: string;
  gklas: string;
  gbauj: number;
  gbaup: string;
  garea: number;
  gvol: number;
  gastw: number;
  gazzi: number;

  gebf: number;

  gwaerzh1: string;
  gwaerzh2: string;
  genh1: string;
  genh2: string;
  gwaerzw1: string;
  gwaerzw2: string;
  genw1: string;
  genw2: string;

  co2_range: string;
  linkco2: string;
}
