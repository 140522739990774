import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { NgxMaskDirective } from 'ngx-mask';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';

@Component({
  selector: 'sympheny-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  standalone: true,
  imports: [
    TranslocoPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InfoButtonComponent,
    MatInputModule,
    NgIf,
    NgxMaskDirective,
  ],
})
export class TimePickerComponent extends BaseFormComponent<
  string,
  FormFieldConfig
> {}
