import MultiPoint from 'ol/geom/MultiPoint.js';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

// SAVED
const savedFill = new Fill({
  color: 'rgba(255, 255, 255, 0.1)',
});
const savedAddressPointFill = new Fill({
  color: '#828282',
});
const savedStroke = new Stroke({
  color: '#828282',
  width: 1,
});

// EDIT
const editFill = new Fill({
  color: 'rgba(255, 255, 255, 0.2)',
});
const editStroke = new Stroke({
  color: '#ffcc33',
  width: 2,
});

// SELECTED
const selectedFill = new Fill({
  color: 'rgba(32, 206, 136, 0.3)',
});
const selectedAddressPointFill = new Fill({
  color: 'rgba(255, 255, 255, 1)',
});
const selectedStroke = new Stroke({
  color: 'rgba(32, 206, 136, 1)',
  width: 3,
});

// STYLES

export const gisStyles = {
  savedFeatures: new Style({
    fill: savedFill,
    stroke: savedStroke,
    image: new CircleStyle({
      radius: 2,
      fill: savedAddressPointFill,
      stroke: savedStroke,
    }),
  }),

  selectedFeatures: new Style({
    fill: selectedFill,
    stroke: selectedStroke,
    image: new CircleStyle({
      radius: 3,
      fill: selectedAddressPointFill,
      stroke: selectedStroke,
    }),
  }),

  editFeatures: new Style({ fill: editFill, stroke: editStroke }),

  editVertices: new Style({
    image: new CircleStyle({
      radius: 4,
      fill: editFill,
      stroke: editStroke,
    }),
    geometry: function (feature) {
      // return the coordinates of the first ring of the polygon
      const geometry: any = feature.getGeometry();
      return new MultiPoint(geometry.getCoordinates()[0]);
    },
  }),
};
