import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  findLatestDataZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo, Observable } from 'rxjs';

import {
  ConversionTechnology,
  ConversionTechnologyDtoV1,
  ConversionTechnologyDtoV2,
  ConversionTechnologySchemaV1,
  ConversionTechnologySchemaV2,
  ConversiontTechnologyListV1Schema,
  ConversiontTechnologyListV2Schema,
  ProjectVersion,
  SeasonalOperations,
  SeasonalOperationsSchema,
} from '../model';
import { CrudService } from '../service/crud.service';

@Injectable()
export class ConversionTechnologyService extends CrudService<ConversionTechnology> {
  public readonly guidKey = 'conversionTechnologyGuid';
  public readonly key = 'conversionTechnologies';

  /**
   * Get seasonal operations
   */
  public getSeasonalOperations(): Observable<SeasonalOperations[]> {
    return this.http
      .get<ResponseModel<{ seasonalOperations: SeasonalOperations }>>(
        this.base + 'scenarios/conversion-technologies/seasonal-operations',
      )
      .pipe(mapDataZ(SeasonalOperationsSchema, 'seasonalOperations'));
  }

  /**
   * Get details about conversion technology
   */
  public override get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    conversionTechnologyGuid: string,
  ) {
    const mapResponseClass =
      projectVersion === 'V2'
        ? ConversionTechnologySchemaV2
        : ConversionTechnologySchemaV1;
    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    const url = `${prefix}scenarios/conversion-technologies/${conversionTechnologyGuid}`;
    return firstValueFrom(
      this.http
        .get<ResponseModel<ConversionTechnology>>(`${this.base}${url}`)
        .pipe(mapDataZ(mapResponseClass)),
    );
  }

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    const url = `${prefix}scenarios/${scenarioGuid}/conversion-technologies`;
    const schema =
      projectVersion === 'V2'
        ? ConversiontTechnologyListV2Schema
        : ConversiontTechnologyListV1Schema;
    return this.http
      .get<ResponseModel<{ conversionTechnologies: ConversionTechnology[] }>>(
        `${this.base}${url}`,
      )
      .pipe(mapDataZ(schema, 'conversionTechnologies'));
  }

  /**
   * Create a ConversionTechnology
   */
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<ConversionTechnology>,
  ) {
    const mapRequestClass =
      projectVersion === 'V2'
        ? ConversionTechnologyDtoV2
        : ConversionTechnologyDtoV1;
    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    const url = `${prefix}scenarios/${scenarioGuid}/conversion-technologies`;
    const schema =
      projectVersion === 'V2'
        ? ConversiontTechnologyListV2Schema
        : ConversiontTechnologyListV1Schema;
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ conversionTechnologies: ConversionTechnology[] }>
        >(`${this.base}${url}`, mapDataRequest(mapRequestClass, data))
        .pipe(findLatestDataZ(schema, 'created', 'conversionTechnologies')),
    );
  }

  /**
   * Update ConversionTechnology name
   */
  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    conversionTechnologyGuid: string,
    data: Partial<ConversionTechnology>,
  ) {
    const mapRequestClass =
      projectVersion === 'V2'
        ? ConversionTechnologyDtoV2
        : ConversionTechnologyDtoV1;
    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    const schema =
      projectVersion === 'V2'
        ? ConversiontTechnologyListV2Schema
        : ConversiontTechnologyListV1Schema;
    const url = `${prefix}scenarios/conversion-technologies/${conversionTechnologyGuid}`;

    return firstValueFrom(
      this.http
        .put<ResponseModel<{ conversionTechnologies: ConversionTechnology[] }>>(
          `${this.base}${url}`,
          mapDataRequest(mapRequestClass, data),
        )
        .pipe(
          findByGuidZ(
            schema,
            this.guidKey,
            conversionTechnologyGuid,
            'conversionTechnologies',
          ),
        ),
    );
  }

  /**
   * Delete ConversionTechnology
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    const url = `scenarios/conversion-technologies/${guid}`;

    return firstValueFrom(
      this.http.delete(`${this.base}${url}`).pipe(mapTo(guid)),
    );
  }
}
