import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, Observable } from 'rxjs';

import { LayerType } from './model/layer.type';

export interface ExtraParam {
  sepOn: boolean;
}

interface PresignedUrl {
  url: string;
}

@Injectable()
export abstract class LayerHttpService<T = any> {
  public abstract readonly layerType: LayerType;

  protected readonly gis =
    this.environmentService.getValue('apiServices') + 'gis/';
  private readonly presignedUrl =
    this.environmentService.getValue('apiServices') +
    'gis/layers-presigned-url';

  constructor(
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
  ) {}

  public abstract list(scenarioId: string): Observable<T[]>;

  public abstract get(scenarioId: string, layerId: string): Observable<T>;

  public abstract create(
    scenarioId: string,
    layer: T,
    extraParam: ExtraParam,
  ): Promise<T>;

  public abstract update(
    scenarioId: string,
    layerId: string,
    layer: T,
    extraParam: ExtraParam,
  ): Promise<T>;

  public abstract delete(scenarioId: string, layerId: string): Promise<string>;

  protected async getPresignedUrl(file: any, fields: Record<string, string>) {
    const presignedUrl = await firstValueFrom(
      this.http.get<PresignedUrl>(this.presignedUrl),
    );

    await firstValueFrom(this.http.put(presignedUrl.url, file));

    return {
      url: presignedUrl.url,
      fields,
    };
  }
}
