import {
  CustomerLayer,
  LayerType,
} from '@sympheny/project/scenario/data-access';

import { MapLayer } from './map-layer';

class MapCustomer extends MapLayer {
  constructor(layerType: LayerType, customerLayer: CustomerLayer) {
    super(customerLayer.layer_id, layerType, customerLayer.layer_name, true);
    this.url = customerLayer.url;
    // this.addFeatures(customerLayer.feature_collection);
    this.changeColor(customerLayer.layer_color);
    if (customerLayer.feature_collection) {
      this.addFeatures(customerLayer.feature_collection);
    }
  }
}

export class MapCustomerLayer extends MapCustomer {
  constructor(customerLayer: CustomerLayer) {
    super(LayerType.customerLayer, customerLayer);
  }
}

export class MapCustomerScenarioLayer extends MapCustomer {
  constructor(customerLayer: CustomerLayer) {
    super(LayerType.customerScenarioLayer, customerLayer);
  }
}
