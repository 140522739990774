import { Injectable, ViewContainerRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from '@sympheny/ui/dialog';
import { SnackbarService } from '@sympheny/ui/snackbar';

@Injectable()
export abstract class ScenarioEditDialogService {
  constructor(
    protected readonly dialogService: DialogService,
    protected readonly snackbarService: SnackbarService,
    protected readonly translateService: TranslocoService
  ) {}

  public abstract edit(guid: string, viewContainerRef: ViewContainerRef);

  protected onCloseDialog(result: { message?: string }) {
    if (result?.message) {
      this.snackbarService.success(
        this.translateService.translate(result.message)
      );
      return null;
    } else return result;
  }
}
