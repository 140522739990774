import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { SelectComponent } from '../select/select.component';

export interface Time {
  value: string;
}

export const times: Readonly<Time[]> = [
  { value: '00:00' },
  { value: '01:00' },
  { value: '02:00' },
  { value: '03:00' },
  { value: '04:00' },
  { value: '05:00' },
  { value: '06:00' },
  { value: '07:00' },
  { value: '08:00' },
  { value: '09:00' },
  { value: '10:00' },
  { value: '11:00' },
  { value: '12:00' },
  { value: '13:00' },
  { value: '14:00' },
  { value: '15:00' },
  { value: '16:00' },
  { value: '17:00' },
  { value: '18:00' },
  { value: '19:00' },
  { value: '20:00' },
  { value: '21:00' },
  { value: '22:00' },
  { value: '23:00' },
  { value: '24:00' },
] as const;

@Component({
  selector: 'sympheny-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, SelectComponent],
})
export class SelectTimeComponent extends BaseFormComponent<
  Time,
  FormFieldConfig
> {
  public readonly times = times as any;
  @Input() public multiple = false;
}
