<mat-form-field
  [class.w-full]="config?.fullWidth ?? true"
  [attr.data-cy]="dataCy || label"
  [attr.data-testid]="dataCy || label"
>
  <mat-label *ngIf="label" class="input__label">
    {{ label | transloco: labelParams }}
  </mat-label>
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="formControl"
    #inputElement
    [mask]="mask"
    autocomplete="off"
    thousandSeparator="'"
    [errorStateMatcher]="matcher"
    [allowNegativeNumbers]="allowNegativeNumbers"
    (blur)="changeValue.emit()"
  />
  <sympheny-input-suffix
    matSuffix
    [suffix]="suffix"
    [suffixLabel]="suffixLabel | transloco: labelParams"
  >
    <ng-content select="[input-suffix]"></ng-content
  ></sympheny-input-suffix>

  <mat-hint>
    <ng-container *ngIf="requiredHint"
      >* {{ 'FORM.requiredHint' | transloco }}
    </ng-container>
    <br />
    <ng-content select="[input-hint]"></ng-content>
  </mat-hint>
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</mat-form-field>

<sympheny-info-button *ngIf="hint" [tooltip]="hint" [link]="hintLink">
</sympheny-info-button>
