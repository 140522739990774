import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const PriceCostCategoryEnum = z.enum([
  'FIXED_INVESTMENT_COSTS_CHF',
  'VARIABLE_INVESTMENT_COSTS_CHF_KW',
  'FIXED_OM_COSTS_CHF_YEAR',
  'VARIABLE_OM_COSTS_CHF_KW_YEAR',
  'VARIABLE_OM_COSTS_CHF_KWH',
  'VARIABLE_OM_COSTS_PERCENT', //VARIABLE_OM_COSTS_PERCENT
  'NETWORK_RELATED_COST_CHF',
  'MEASUREMENT_CONTROL_REGULATION',

  'PIPE',
  'PUMPS',
]);
export type PriceCostCategory = z.infer<typeof PriceCostCategoryEnum>;

export const PriceCategoryEnum = z.enum([
  'ENERGY_DELIVERY',
  'NETWORK_USE',
  'TAXES',
  'REFUNDS_AND_REBATES',
  'TOTAL',
]);
export type PriceCategory = z.infer<typeof PriceCategoryEnum>;

export const PriceDimensionEnum = z.enum([
  'FIXED_OM_PRICE_CHF_YEAR',
  'CAPACITY_PRICE_CHF_KW_YEAR',
  'CAPACITY_PRICE_CHF_KW_MONTH',
  'ENERGY_PRICE_CHF_KWH',
]);
export type PriceDimension = z.infer<typeof PriceDimensionEnum>;

const PriceComponentTypeEnum = z.enum([
  'TIME_OF_USE',
  'FIXED',
  'DYNAMIC_TARIFF',
]);
export type PriceComponentType = z.infer<typeof PriceComponentTypeEnum>;

export const AdvancedPriceComponentSchema = z.object({
  name: z.string(),
  value: SymphenyNumber().nullish(),
  priceCategory: z.string().nullish(),
  priceCategoryId: PriceCategoryEnum.nullish(),
  priceDimension: z.string().nullish(),
  priceDimensionId: PriceDimensionEnum.nullish(),
  type: z.string().nullish(),
  timeOfUses: z.array(z.string()).nullish(),
  // TODO also return id
  dynamicTariff: z.string().or(PriceComponentTypeEnum).nullish(),
});

export type AdvancedPriceComponent = z.infer<
  typeof AdvancedPriceComponentSchema
>;

export const TechnologyAdvancedPriceComponentSchemaV1 = z.object({
  name: z.string(),
  value: SymphenyNumber().nullish(),
  category: z.string().nullish(),
  categoryId: PriceCostCategoryEnum.nullish(),
  guid: z.string().nullish(),
  lifetime: SymphenyNumber().nullish(),
  interestRate: SymphenyNumber().nullish(),
  length: SymphenyNumber().nullish(),
  complexityFactor: SymphenyNumber().nullish(),
  dataPoints: z.any().nullish(),
  numberOfPumps: SymphenyNumber().nullish(),
});
export const TechnologyAdvancedPriceComponentSchemaV2 = z.object({
  name: z.string(),
  value: SymphenyNumber().nullish(),
  category: z.string().nullish(),
  categoryId: PriceCostCategoryEnum.nullish(),
  guid: z.string().nullish(),
  length: SymphenyNumber().nullish(),
  dataPoints: z.any().nullish(),
  numberOfPumps: SymphenyNumber().nullish(),
});

export type TechnologyAdvancedPriceComponent = z.infer<
  typeof TechnologyAdvancedPriceComponentSchemaV1
> &
  z.infer<typeof TechnologyAdvancedPriceComponentSchemaV2>;

export class TechnologyCostSubcomponentCategory {
  public name: string;
  public value: string;
}
