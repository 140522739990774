import { ElementRef } from '@angular/core';

type SVG = d3.Selection<any, undefined, null, undefined>;

export const showTooltip = (
  text: string,
  color: string,
  tooltip: ElementRef,
  graphWidth: number,
  { layerX, layerY }: any,
) => {
  tooltip.nativeElement.innerHTML = text;
  tooltip.nativeElement.style['border-color'] = color;
  const width = tooltip.nativeElement.offsetWidth;
  let x = layerX;
  if (x + width > graphWidth) x = graphWidth - width;
  tooltip.nativeElement.style.transform = ` translate3d(${x}px, ${layerY}px, 0px)`;
  tooltip.nativeElement.style.opacity = 1;
};
