import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  findLatestDataZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo, Observable, throwError } from 'rxjs';

import { OnSiteSolarResource } from './on-site-solar-resource.model';
import {
  OnSiteSolarResourceV1,
  OnSiteSolarResourceV1Dto,
  OnSiteSolarResourceV1DtoSchema,
  OnSiteSolarResourceV1List,
  OnSiteSolarResourceV1ListSchema,
  OnSiteSolarResourceV1Schema,
} from './on-site-solar-resource.model.v1';
import { ProjectVersion } from '../model';
import { CrudService } from '../service';

@Injectable()
export class SolarResourcesV1Service extends CrudService<
  OnSiteSolarResourceV1List,
  OnSiteSolarResourceV1,
  OnSiteSolarResourceV1Dto
> {
  public readonly guidKey = 'solarResourceGuid';
  public readonly key = 'onSiteSolarResources';

  public override list(
    _: ProjectVersion,
    scenarioGuid: string,
  ): Observable<OnSiteSolarResourceV1List[]> {
    return this.http
      .get<
        ResponseModel<{ solarResources: OnSiteSolarResourceV1List[] }>
      >(`${this.base}scenarios/${scenarioGuid}/solar-on-site-resource`)
      .pipe(mapDataZ(OnSiteSolarResourceV1ListSchema, 'solarResources'));
  }

  public override create(
    _: ProjectVersion,
    scenarioGuid: string,
    data: OnSiteSolarResourceV1Dto,
  ): Promise<OnSiteSolarResourceV1> {
    let url = '';

    switch (data.profile.toUpperCase()) {
      case 'SAVED':
        url = `scenarios/${scenarioGuid}/user-solar-resources`;
        break;
      case 'GENERATED':
        url = `scenarios/${scenarioGuid}/database-solar-resources`;
        break;
      case 'UPLOADED':
        url = `scenarios/${scenarioGuid}/solar-on-site-resource`;
        break;
      default:
        throw Error('wrong type');
    }
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ solarResources: OnSiteSolarResourceV1[] }>
        >(`${this.base}${url}`, mapDataRequest(OnSiteSolarResourceV1DtoSchema, data))
        .pipe(
          findLatestDataZ(
            OnSiteSolarResourceV1Schema,
            'created',
            'solarResources',
          ),
        ),
    );
  }

  public override update(
    _: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: OnSiteSolarResourceV1Dto,
  ) {
    let url = '';
    switch (data.profile) {
      case 'SAVED':
        url = `scenarios/user-solar-resources/${guid}`;
        break;
      case 'GENERATED':
        url = `scenarios/database-solar-resources/${guid}`;
        break;
      case 'UPLOADED':
        url = `scenarios/solar-on-site-resource/${guid}`;
        break;
      case 'UPLOADED_GIS':
        data.profile = 'UPLOADED';
        url = `scenarios/solar-on-site-resource/${guid}`;
        break;
      default:
        throwError('wrong type');
    }
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ solarResources: OnSiteSolarResource[] }>
        >(`${this.base}${url}`, mapDataRequest(OnSiteSolarResourceV1DtoSchema, data))
        .pipe(
          findByGuidZ(
            OnSiteSolarResourceV1Schema,
            this.guidKey,
            guid,
            'solarResources',
          ),
        ),
    );
  }

  public override delete(
    _: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    type: string,
  ): Promise<any> {
    return firstValueFrom(
      this.http
        .delete<
          ResponseModel<OnSiteSolarResource>
        >(`${this.base}scenarios/solar-on-site-resource/${guid}`)
        .pipe(mapTo(guid)),
    );
  }
}
