import { FormGroup, Validators } from '@angular/forms';

export const setRequired = (form: FormGroup, formControlName: string) => {
  const formControl = form.get(formControlName);
  if (!formControl) return null;

  if (formControl.hasValidator(Validators.required)) return formControl;

  formControl.addValidators(Validators.required);
  formControl.updateValueAndValidity();
  formControl.setErrors(null);
  return formControl;
};

export const setNotRequired = (form: FormGroup, formControlName: string) => {
  const formControl = form.get(formControlName);
  if (!formControl) return null;
  if (!formControl.hasValidator(Validators.required)) return formControl;

  formControl.removeValidators(Validators.required);
  formControl.updateValueAndValidity();
  formControl.setErrors(null);
  return formControl;
};
