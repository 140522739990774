import { TitleStrategy } from '@angular/router';

import { BreadcrumbTitleStrategy } from './title-strategy.service';

export const provideBreadCrumbs = (appName: string) => {
  return [
    {
      provide: TitleStrategy,
      useClass: BreadcrumbTitleStrategy,
    },
    {
      provide: 'BREADCRUMB_TITLE_STRATEGY_PREFIX',
      useValue: appName,
    },
  ];
};
