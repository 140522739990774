import { Injectable } from '@angular/core';
import {
  findByGuidZ,
  findLatestDataZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo, Observable } from 'rxjs';

import { CrudService } from './crud.service';
import {
  ProjectVersion,
  StorageTechnology,
  StorageTechnologyListSchema,
  StorageTechnologySchema,
  StorageTechnologyWithProfileFiles,
} from '../model';

@Injectable()
export class StorageTechnologyService extends CrudService<StorageTechnology> {
  public readonly guidKey = 'storageTechnologyGuid';
  public readonly key = 'storageTechnologies';

  /**
   * Get all details about conversion technology
   */

  public override get(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    const url = `${prefix}scenarios/storage-technologies/${guid}`;

    return firstValueFrom(
      this.http
        .get<ResponseModel<StorageTechnology>>(`${this.base}${url}`)
        .pipe(mapDataZ(StorageTechnologySchema)),
    );
  }

  /**
   * Get saved storage technologies
   */
  public getSavedStorageTechnologies(): Observable<any> {
    return this.http.get(`${this.base}user-storage-technologies`);
  }

  /**
   * Get user saved storage technology details
   */
  public getUserStorageTechnologyDetails(
    Guid: string,
    exchangeRate: number,
  ): Observable<any> {
    return this.http.get(
      this.base +
        'storage-technologies/profile-types/user/' +
        Guid +
        `?exchangeRate=${exchangeRate}`,
    );
  }

  public getEwzStorageTechnologyDetails(
    Guid: string,
    exchangeRate: number,
  ): Observable<any> {
    return this.http.get(
      this.base +
        'storage-technologies/profile-types/ewz/' +
        Guid +
        `?exchangeRate=${exchangeRate}`,
    );
  }

  /**
   * Get db storage technology details
   */
  public getDbStorageTechnologyDetails(
    Guid: string,
    exchangeRate: number,
  ): Observable<any> {
    return this.http.get(
      this.base +
        'storage-technologies/profile-types/database/' +
        Guid +
        `?exchangeRate=${exchangeRate}`,
    );
  }

  /**
   * Get storage technologies from db
   */
  public getStorageTechnologiesFromDb(): Observable<any> {
    return this.http.get(this.base + 'database-storage-technologies');
  }

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    const url = `${prefix}scenarios/${scenarioGuid}/storage-technologies`;
    return this.http
      .get<
        ResponseModel<{ storageTechnologies: StorageTechnology[] }>
      >(`${this.base}${url}`)
      .pipe(mapDataZ(StorageTechnologyListSchema, 'storageTechnologies'));
  }

  /**
   * Create a StorageTechnology
   */

  public override async create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<StorageTechnology>,
  ) {
    const prefix = projectVersion === 'V2' ? 'v2/' : '';
    const url = `${prefix}scenarios/${scenarioGuid}/storage-technologies`;

    if (projectVersion === 'V2')
      await this.uploadV2Profiles(scenarioGuid, data);
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<{ storageTechnologies: StorageTechnology[] }>
        >(`${this.base}${url}`, mapDataRequest(StorageTechnologySchema, data))
        .pipe(
          findLatestDataZ(
            StorageTechnologyListSchema,
            'created',
            'storageTechnologies',
          ),
        ),
    );
  }

  /**
   * Update StorageTechnology name
   */
  public override async update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: Partial<StorageTechnology>,
  ) {
    const prefix = projectVersion === 'V2' ? 'v2/' : '';

    const url = `${prefix}scenarios/storage-technologies/${guid}`;
    if (projectVersion === 'V2')
      await this.uploadV2Profiles(scenarioGuid, data);
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<{ storageTechnologies: StorageTechnology[] }>
        >(`${this.base}${url}`, mapDataRequest(StorageTechnologySchema, data))
        .pipe(
          findByGuidZ(
            StorageTechnologySchema,
            this.guidKey,
            guid,
            'storageTechnologies',
          ),
        ),
    );
  }

  /**
   * Delete StorageTechnology
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    const url = `scenarios/storage-technologies/${guid}`;

    return firstValueFrom(
      this.http.delete(`${this.base}${url}`).pipe(mapTo(guid)),
    );
  }

  private async uploadV2Profiles(
    scenarioGuid: string,
    data: StorageTechnologyWithProfileFiles,
  ) {
    const updatePromise: Promise<void>[] = [];

    if (data.standByLossDynamic) {
      data.standbyLoss = null;
      updatePromise.push(
        this.uploadProfileAndUpdateId(
          scenarioGuid,
          data,
          'standByLossProfileFile',
          'standByLossProfileId',
        ),
      );
    } else {
      data.standByLossProfileId = null;
    }

    await Promise.all(updatePromise);
    return data;
  }
}
