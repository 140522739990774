import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  startWith,
} from 'rxjs';

function conditionalValidation(
  condition: boolean,
  formControl: AbstractControl,
  validator: ValidatorFn
): ValidationErrors | null {
  if (!condition) {
    return null;
  }

  return validator(formControl);
}

export interface Condition {
  conditionField: string;
  conditionValue?: any;
}

export interface ConditionField {
  conditionField: string;
  validator: ValidatorFn;
}

export interface Conditions {
  validator: ValidatorFn;
  conditions: Condition[];
}

function testCondition(
  formControl: AbstractControl,
  conditions: Condition
): Observable<boolean> {
  const conditionControl = formControl.parent?.get(conditions.conditionField);

  if (!conditionControl) {
    return of(true);
  }

  const conditionValue = conditions.conditionValue ?? [true];

  return conditionControl.valueChanges.pipe(
    startWith(conditionControl.value),
    distinctUntilChanged(),
    map((condition) => conditionValue.some((v: any) => v === condition)),
    distinctUntilChanged()
  );
}

function testAllConditions(
  formControl: AbstractControl,
  conditions: Condition[]
): Observable<boolean> {
  return combineLatest(
    conditions.map((c) => testCondition(formControl, c))
  ).pipe(map((condition) => condition.every((valid) => valid)));
}

export function conditionalValidator(
  params: ConditionField | Conditions
): AsyncValidatorFn {
  return (formControl: AbstractControl) => {
    const conditions =
      'conditionField' in params
        ? testCondition(formControl, params)
        : testAllConditions(formControl, params.conditions);

    return firstValueFrom(
      conditions.pipe(
        map((condition) =>
          conditionalValidation(condition, formControl, params.validator)
        )
      )
    );
  };
}
