export const SUPPORT = {
  INFO: { template: null, supportPage: 'user-guide/latest/general-settings' },
  ENERGY_DEMANDS: {
    template: 'energy-demands/Example+Energy+Demand+Profile.xlsx',
    supportPage: 'user-guide/latest/add-energy-demands',
  },
  HUBS: { template: null, supportPage: 'user-guide/latest/add-hubs' },
  ENERGY_CARRIER: {
    template: null,
    supportPage: 'user-guide/latest/add-energy-carrier-candidates',
  },
  ONSITE_RESOURCES: {
    template: 'energy-demands/Example+Energy+Demand+Profile.xlsx',
    supportPage: 'user-guide/latest/on-site-resource-candidates',
  },
  IMPORTS_EXPORTS: {
    template: 'energy-demands/Example+Energy+Demand+Profile.xlsx',
    supportPage: 'user-guide/latest/add-import-export-candidates',
  },
  SUPPLY_TECHNOLOGY: {
    template: null,
    supportPage: 'user-guide/latest/3-add-technology-candidates',
  },
  NETWORK_TECHNOLOGY: {
    template: null,
    supportPage: 'user-guide/latest/4-add-network-technology-candidates',
  },
} as const;

export type SUPPORT_KEYS = keyof typeof SUPPORT;
