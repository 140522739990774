// Iteratively assign the column for each node.
// Nodes are assigned the maximum column of incoming neighbors plus one;
// nodes with no incoming links are assigned column zero, while

import { nullOrUndefined } from '@sympheny/utils/rxjs';

import { Graph, GraphData, GraphSettings, Link, Node } from '../../model';
import { sortNodeBy } from '../../utils/node';

const setHigherColumn = (node: Node, column: number) => {
  if (!node.column || column > node.column) node.setValue('column', column);
};
const setLowerColumn = (node: Node, column: number) => {
  if (!node.column || column < node.column) node.setValue('column', column);
};

const setNextLinks = (
  data: Readonly<GraphData>,
  link: Link,
  column: number,
) => {
  if (link.circular) return column;

  const nextId = link.target;

  if (!nextId) return column;
  let maxColumn = column;
  const nextColumn = column + 1;

  // Test if the next target link is not the same as the active one
  const nextNode = data.getNode(nextId);
  const nextLink = data.getSourceLinks(nextNode);

  if (nextLink?.[0]?.target === link.source) {
    nextNode.setValue('column', nextColumn);
    // return;
  } else {
    setHigherColumn(nextNode, nextColumn);
  }

  data.getSourceLinks(nextNode).forEach((l) => {
    const d = setNextLinks(data, l, nextColumn);

    maxColumn = maxColumn < d ? d : maxColumn;
  });
  return maxColumn;
};
const setPreviousLink = (
  data: Readonly<GraphData>,
  link: Link,
  column: number,
) => {
  const nextId = link.source;

  if (!nextId) return column;
  let maxColumn = column;
  const nextColumn = column - 1;

  // Test if the next target link is not the same as the active one
  const nextNode = data.getNode(nextId);
  const nextLink = data.getTargetLinks(nextNode);
  // if (nextNode.column) return column;

  if (nextLink?.[0]?.source === link.target) {
    nextNode.setValue('column', nextColumn);

    if (link.circular) {
      if (!nextNode.column) setHigherColumn(nextNode, nextColumn - 1);
      return column;
    }
    // return;
  } else {
    if (link.circular) {
      if (!nextNode.column) setHigherColumn(nextNode, nextColumn - 1);
      return column;
    }

    if (nullOrUndefined(nextNode.column)) setLowerColumn(nextNode, nextColumn);
  }

  if (maxColumn < 3) maxColumn = 3;
  data.getTargetLinks(nextNode).forEach((l) => {
    const d = setPreviousLink(data, l, nextColumn);

    maxColumn = maxColumn < d ? d : maxColumn;
  });
  return maxColumn;
};

const computeNodeColumn = (
  data: Readonly<GraphData>,
  settings: GraphSettings,
) => {
  let nodes: Node[],
    nextColumn = 0;
  let maxColumn = 0;
  // const next = new Set<string>/
  const nextNodeIds = new Set<string>();

  const sortedNodes = data
    .getNodes()
    .sort(sortNodeBy('sortBy'))
    .filter((n) => !!n);
  const { nodeAllowedInFirstColumn, nodeAllowedInLastColumn } = settings;

  const firstColumnNodes = sortedNodes.filter(
    (n) => !n.hasSource && nodeAllowedInFirstColumn(n),
  );
  const sourceColumnsNotFirst = sortedNodes.filter(
    (n) => !n.hasSource && !nodeAllowedInFirstColumn(n),
  );

  const targetColumnNodes = sortedNodes.filter(
    (n) => !n.hasTarget && nodeAllowedInLastColumn(n) && !n.circular,
  );

  firstColumnNodes.forEach((node) => {
    node.setValue('column', 0);
    // get each time its target

    data.getSourceLinks(node).forEach((link: Link) => {
      nextColumn = setNextLinks(data, link, 0);
    });

    maxColumn = maxColumn < nextColumn ? nextColumn : maxColumn;
  });
  sourceColumnsNotFirst.forEach((node) => {
    node.setValue('column', 2);
    node.setValue('row', 1);
    // get each time its target

    data.getSourceLinks(node).forEach((link: Link) => {
      nextColumn = setNextLinks(data, link, 2);
    });

    maxColumn = maxColumn < nextColumn ? nextColumn : maxColumn;
  });

  maxColumn += 1;
  if (maxColumn < 5) maxColumn = 5;
  targetColumnNodes.forEach((node) => {
    node.setValue('column', maxColumn);
    // get each time its target

    data.getTargetLinks(node).forEach((link: Link) => {
      nextColumn = setPreviousLink(data, link, maxColumn);
    });

    if (nextColumn < 0) {
      console.warn('next column is wrong!!!');
    }
  });

  return maxColumn;
};

const moveHiddenNodes = (data: Readonly<GraphData>) => {
  data.forEachNode((node) => {
    if (!node.aggregate) {
      return;
    }

    const links = data.getSourceLinks(node);
    if (links.length > 1) {
      const n = data.getNodeTarget(links[0]);
      node.setValue('column', n.column - 1);
    }
  });
};

// nodes with no outgoing links are assigned the maximum column.
export const EhubComputeNodeColumns = (graph: Readonly<Graph<any, any>>) => {
  const { graph: data } = graph;
  computeNodeColumn(graph.graph, graph.settings);
  // assign column numbers, and get max value

  data.computeColumns();

  // Align end nodes to the end of the graph
  data.forEachNode((node: Node) => {
    node.setValue('column', node.column ?? data.maxColumns());
    node.setValue('row', node.row);

    //node.column = Math.floor(align.call(null, node, x));
  });

  moveHiddenNodes(data);

  // Put self linking nodes always to the column after the target
  data.forEachNode((node) => {
    if (!node.sameSourceTarget) return;

    const { sourceNodes } = data.getSourceLinksWithNodes(node);
    node.setValue('column', sourceNodes[0].column + 1);
  });
  data.computeColumns();
  return data;
};
