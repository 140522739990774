<div style="margin: 10px">
  <ng-container *ngIf="error$ | async as error">
    <h1>Authentication Error</h1>
    <p>
      Something went wrong when trying to authenticate. Please inspect the error
      below and send a request to our
      <a href="mailto: support@sympheny.com">support team</a>.
    </p>
    <div>
      <p>{{ error.error_description | titlecase }}</p>
    </div>
  </ng-container>
</div>
