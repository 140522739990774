import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { colors } from '../colors';

@Component({
  selector: 'sympheny-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ColorPickerComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [MatMenuModule, NgFor, MatButtonModule],
})
export class ColorPickerComponent implements ControlValueAccessor {
  public value: string | null | undefined;
  public onChange: (value: string) => void;
  public readonly colors = colors;
  public isDisabled = false;

  public writeValue(color: string | null | undefined): void {
    this.value = color;
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public registerOnTouched(fn: any): void {}

  public changeColor(color: string) {
    this.value = color;
    this.onChange(color);
  }

  @Input()
  public set disabled(disabled: boolean) {
    this.isDisabled = disabled;
  }

  public setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
