<h3>Map columns</h3>
<div>
  <ng-container *ngFor="let column of value">
    <sympheny-worksheet-column-selection
      *ngIf="column.column !== excludeField"
      [ngModel]="column"
      [selectedFields]="selectedFields"
      (ngModelChange)="changeValue(column, $event)"
    >
      {{ column.column }}
    </sympheny-worksheet-column-selection>
  </ng-container>
</div>
