import { z } from 'zod';
export const EnergyDiagramLinkSchema = z.object({
  source: z.string().nullish(),
  target: z.string().nullish(),
  energyCarrier: z.string().nullish(),
});
export const EnergyDiagramNodeSchema = z.object({
  type: z.string().nullish(),
  label: z.string().nullish(),
  name: z.string().nullish(),
  energyCarrier: z.string().nullish(),
});
export const EnergyDiagramSchema = z.object({
  nodes: z.array(EnergyDiagramNodeSchema),
  links: z.array(EnergyDiagramLinkSchema),
});
export type EnergyDiagram = z.infer<typeof EnergyDiagramSchema>;
export type EnergyDiagramNode = z.infer<typeof EnergyDiagramNodeSchema>;
export type EnergyDiagramLink = z.infer<typeof EnergyDiagramLinkSchema>;

export const NODE_TYPES = {
  EC: 'Energy Carrier',
  VL: 'Virtual Load',
  LS: 'Load Shedding',
  IMP: 'Import',
  EXP: 'Export',
  ONSITE: 'On site resource',
  STG: 'Storage',
  STG_DIS: 'Storage Discharge',
  STG_CHG: 'Storage Charge',
  INTRA: 'Intra Hub',
  LINK_OUT: 'Link Out',
  LINK_IN: 'Link In',
  DEMAND: 'Energy Demand',
  MODE: 'Conversion Mode',
};

export type NodeType = keyof typeof NODE_TYPES;

export const inputNodeTypes: NodeType[] = [
  'IMP',
  'DEMAND',
  'ONSITE',
  'LINK_IN',
] as const;
export const exportNodeTypes: NodeType[] = [
  'EXP',
  'DEMAND',
  'LINK_OUT',
] as const;

// export const getNodeTypeName = (nodeType: NodeType) => {
//   const type = NODE_TYPES[nodeType];

//   if (!type) console.warn('no type found for', nodeType);

//   return type ?? nodeType;
// };
