import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoadDataService } from '@sympheny/utils/data-access';

import { DatabaseConversionTechnologyCollection } from './conversion-technology/collections/database-conversion-technology-collection.service';
import { EwzConversionTechnologyCollection } from './conversion-technology/collections/ewz-conversion-technology-collection.service';
import { OrgDatabaseConversionTechnologyCollection } from './conversion-technology/collections/org-database-conversion-technology-collection.service';
import { UserConversionTechnologyCollection } from './conversion-technology/collections/user-conversion-technology-collection.service';
import {
  DatabaseNetworkTechnologyCollection,
  EwzNetworkTechnologyCollection,
  OrgDatabaseNetworkTechnologyCollection,
  UserNetworkTechnologyCollection,
} from './network-technology';
import { OrgDatabaseStorageTechnologyCollectionService } from './storage-technology';
import { DatabaseStorageTechnologyCollectionService } from './storage-technology/collections/database-storage-technology-collection.service';
import { EwzStorageTechnologyCollectionService } from './storage-technology/collections/ewz-storage-technology-collection.service';
import { UserStorageTechnologyCollectionService } from './storage-technology/collections/user-storage-technology-collection.service';
import { OrgDatabaseTechnologyPackageCollectionService } from './technology-package';
import { DatabaseTechnologyPackageCollectionService } from './technology-package/collections/database-technology-package-collection.service';
// import { EwzTechnologyPackageCollectionService } from './technology-package/collections/ewz-technology-package-collection.service';
import { UserTechnologyPackageCollectionService } from './technology-package/collections/user-technology-package-collection.service';

@NgModule({
  imports: [CommonModule],
})
export class ProjectSupplyTechnologyDataAccessModule {
  public static forRoot(): ModuleWithProviders<ProjectSupplyTechnologyDataAccessModule> {
    return {
      ngModule: ProjectSupplyTechnologyDataAccessModule,
      providers: [
        OrgDatabaseConversionTechnologyCollection,
        DatabaseConversionTechnologyCollection,
        EwzConversionTechnologyCollection,
        UserConversionTechnologyCollection,

        OrgDatabaseStorageTechnologyCollectionService,
        DatabaseStorageTechnologyCollectionService,
        EwzStorageTechnologyCollectionService,
        UserStorageTechnologyCollectionService,

        OrgDatabaseTechnologyPackageCollectionService,
        DatabaseTechnologyPackageCollectionService,
        //  EwzTechnologyPackageCollectionService,
        UserTechnologyPackageCollectionService,

        OrgDatabaseNetworkTechnologyCollection,
        DatabaseNetworkTechnologyCollection,
        EwzNetworkTechnologyCollection,
        UserNetworkTechnologyCollection,

        {
          multi: true,
          provide: LoadDataService,
          useExisting: OrgDatabaseNetworkTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: DatabaseNetworkTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: EwzNetworkTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: UserNetworkTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: DatabaseStorageTechnologyCollectionService,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: OrgDatabaseConversionTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: EwzStorageTechnologyCollectionService,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: OrgDatabaseStorageTechnologyCollectionService,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: UserStorageTechnologyCollectionService,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: DatabaseConversionTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: EwzConversionTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: UserConversionTechnologyCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: DatabaseTechnologyPackageCollectionService,
        },
        // {
        //   multi: true,
        //   provide: LoadDataService,
        //   useExisting: EwzTechnologyPackageCollectionService,
        // },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: UserTechnologyPackageCollectionService,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: OrgDatabaseTechnologyPackageCollectionService,
        },
      ],
    };
  }
}
