import { Injectable } from '@angular/core';
import { CrudService, ProjectVersion } from '@sympheny/project/data-access';
import {
  findByGuidZ,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, mapTo, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  EnergyDemandV2Schema,
  EnergyDemandV2ListSchema,
  EnergyDemandV2,
  EnergyDemandV2DtoWithProfile,
  EnergyDemandV2DtoSchema,
} from './energy-demand-v2.model';

export enum EnergyDemandApiTypes {
  database,
  save,
}

@Injectable()
export class EnergyDemandV2Service extends CrudService<
  EnergyDemandV2,
  EnergyDemandV2,
  EnergyDemandV2DtoWithProfile
> {
  public readonly guidKey = 'energyDemandGuid';
  public readonly key = 'energyDemands';

  private baseScenarios = `${this.base}v2/scenarios/`;
  private baseScenario = `${this.base}v2/scenario/`;

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    return this.http
      .get<ResponseModel<EnergyDemandV2[]>>(
        `${this.baseScenarios}${scenarioGuid}/energy-demands`,
      )
      .pipe(mapDataZ(EnergyDemandV2ListSchema));
  }

  public override async create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: EnergyDemandV2DtoWithProfile,
    type: EnergyDemandApiTypes,
  ) {
    let url = '';

    switch (type) {
      case EnergyDemandApiTypes.database:
        url = `${this.baseScenario}${scenarioGuid}/database-energy-demands`;

        break;
      case EnergyDemandApiTypes.save:
        url = `${this.baseScenarios}${scenarioGuid}/energy-demands`;

        break;
      default:
        throwError('wrong type');
    }
    const energyDemand = await this.uploadV2Profiles(scenarioGuid, data);
    return firstValueFrom(
      this.http
        .post<ResponseModel<EnergyDemandV2[]>>(
          url,
          mapDataRequest(EnergyDemandV2DtoSchema, energyDemand),
        )
        // TODO check me
        .pipe(
          mapDataZ(EnergyDemandV2Schema),
          map((e) => e[0]),
        ),
    );
  }

  public getDetail(guid: string, scenarioVariantGuid: string | null) {
    const params = scenarioVariantGuid
      ? { scenarioVariantGuid: scenarioVariantGuid }
      : null;
    const url = `v2/energy-demands/${guid}`;

    return firstValueFrom(
      this.http
        .get<ResponseModel<EnergyDemandV2>>(`${this.base}${url}`, {
          params,
        })
        .pipe(mapDataZ(EnergyDemandV2Schema)),
    );
  }

  public override async update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
    data: EnergyDemandV2DtoWithProfile,
    type: EnergyDemandApiTypes,
  ) {
    let url = '';

    switch (type) {
      case EnergyDemandApiTypes.database:
        url = `${this.baseScenario}${scenarioGuid}/database-energy-demands/${guid}`;
        break;
      case EnergyDemandApiTypes.save:
        url = `${this.baseScenarios}${scenarioGuid}/energy-demands/${guid}`;
        break;
      default:
        throwError('wrong type');
    }

    const energyDemand = await this.uploadV2Profiles(scenarioGuid, data);

    return firstValueFrom(
      this.http
        .put<ResponseModel<EnergyDemandV2[]>>(
          url,
          mapDataRequest(EnergyDemandV2DtoSchema, energyDemand),
        )
        .pipe(findByGuidZ(EnergyDemandV2Schema, 'energyDemandGuid', guid)),
    );
  }

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    guid: string,
  ) {
    const url = `${this.base}scenarios/${scenarioGuid}/energy-demands/${guid}`;
    return firstValueFrom(this.http.delete(url).pipe(mapTo(guid)));
  }

  private async uploadV2Profiles(
    scenarioGuid: string,
    energyDemand: EnergyDemandV2DtoWithProfile,
  ) {
    const updatePromise: Promise<void>[] = [];

    if (energyDemand.profile === 'uploaded') {
      // FIXME the energy demand guid
      // energyDemand.energyDemandGuid = null;

      updatePromise.push(
        this.uploadProfileAndUpdateId(
          scenarioGuid,
          energyDemand,
          'demandProfileFile',
          'demandProfileId',
        ),
      );
    } else {
      energyDemand.demandProfileId = null;
    }

    if (energyDemand.isDynamicSalePriceProfile) {
      energyDemand.demandSalePrice = null;
      updatePromise.push(
        this.uploadProfileAndUpdateId(
          scenarioGuid,
          energyDemand,
          'demandSalePriceProfileFile',
          'demandSalePriceProfileId',
        ),
      );
    } else {
      energyDemand.demandSalePriceProfileId = null;
    }

    await Promise.all(updatePromise);
    return energyDemand;
  }
}
