import { Injectable, ViewContainerRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ScenarioEditDialogService } from '@sympheny/project/scenario/edit';
import { DialogService } from '@sympheny/ui/dialog';
import { SnackbarService } from '@sympheny/ui/snackbar';
import { UserState } from '@sympheny/user/data-access';

import {
  HubFormData,
  HubsFormComponent,
} from './hubs-form/hubs-form.component';

@Injectable({
  providedIn: 'root',
})
export class HubsService extends ScenarioEditDialogService {
  constructor(
    private readonly userState: UserState,
    dialogService: DialogService,
    snackbarService: SnackbarService,
    translateService: TranslocoService
  ) {
    super(dialogService, snackbarService, translateService);
  }

  public edit(hubGuid: string, viewContainerRef: ViewContainerRef) {
    this.openDialog(viewContainerRef, hubGuid);
  }

  public add(viewContainerRef: ViewContainerRef) {
    this.openDialog(viewContainerRef);
  }

  private openDialog(viewContainerRef: ViewContainerRef, hubGuid?: string) {
    const data: HubFormData = {
      hubGuid: hubGuid ?? null,
      gisOn: this.userState.isGisOn(),
    };

    const dialogRef = this.dialogService.openDialog(
      HubsFormComponent,
      data,
      '90%',
      viewContainerRef
    );

    // Listen to changes after the dialog is closed
    dialogRef.afterClosed().subscribe((result) => {
      this.onCloseDialog(result);
    });
  }
}
