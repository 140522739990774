import {
  EnergyCarrier,
  EnergyDiagramLink,
  EnergyDiagramNode,
} from '@sympheny/project/data-access';
import { getEnergyCarrierColor } from '@sympheny/project/energy-carrier/utils';

export const mapLink = <T extends { energyCarrier: string }>(
  energyCarriers: EnergyCarrier[],
  value: EnergyDiagramLink,
): EnergyDiagramLink & { color: string } => {
  const ec = energyCarriers?.find(
    (e) => e.energyCarrierName === value.energyCarrier,
  );

  return { ...value, color: getEnergyCarrierColor(ec) };
};

export const mapNode = (
  energyCarriers: EnergyCarrier[],
  value: EnergyDiagramNode,
): EnergyDiagramLink & { color: string } => {
  const ec = energyCarriers?.find(
    (e) => e.energyCarrierName === value.energyCarrier,
  );

  return {
    ...value,
    color: getEnergyCarrierColor(ec),
    // type: getNodeTypeName(value.type as NodeType),
  };
};
