import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
const createKeyFromRequest = (request: HttpRequest<unknown>) => {
  return JSON.stringify(request);
};
@Injectable({
  providedIn: 'root',
})
export class LocalCacheService {
  private db: IDBDatabase | null = null;
  private readonly dbName = 'Sympheny';
  private readonly storeName = 'ETAG';

  constructor() {
    this.initDb();
    //.then(() => this.clearCache());
  }

  public clearCache() {
    const store = this.getStore('readwrite');
    store?.clear();
  }

  private initDb(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName);
        }
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        if (!this.db.objectStoreNames.contains(this.storeName)) {
          //  this.db.createObjectStore(this.storeName);
        }
        this.setData('LAST_INIT', Date.now());
        resolve();
      };

      request.onerror = (event) => {
        console.error(
          'Database error:',
          (event.target as IDBOpenDBRequest).error,
        );
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
  }

  public async getDataFromRequest(
    req: HttpRequest<unknown>,
  ): Promise<{ etag: string; response: HttpResponse<unknown> | null } | null> {
    const data = await this.getData(createKeyFromRequest(req));
    if (!data) return null;

    return data;
  }

  public setDataFromRequest(
    req: HttpRequest<unknown>,
    response: HttpResponse<unknown>,
  ) {
    const etag = response.headers.get('Etag');
    if (!etag) return;
    this.setData(createKeyFromRequest(req), { etag, response });
  }

  public getData(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const store = this.getStore('readonly');
      if (!store) {
        resolve(null);
        return;
      }
      const request = store.get(key);

      request.onsuccess = (event) => {
        resolve(
          (event.target as IDBRequest).result
            ? (event.target as IDBRequest).result
            : null,
        );
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  public setData(key: string, data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const store = this.getStore('readwrite');
      if (!store) {
        resolve();
        return;
      }

      const request = store.put(data, key);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  private getStore(mode: IDBTransactionMode) {
    if (!this.db) return null;
    if (
      mode === 'readonly' &&
      !this.db.objectStoreNames.contains(this.storeName)
    ) {
      console.warn(`Object store not initialize ${this.storeName}`);

      return null;
    }

    try {
      const transaction = this.db.transaction([this.storeName], mode);
      const store = transaction.objectStore(this.storeName);

      return store;
    } catch (error: any) {
      console.warn('Store not found', error);
      return null;
    }
  }
}
