import { HttpClient } from '@angular/common/http';
import { Collection } from '@fp-tools/angular-state';
import { ProjectVersion } from '@sympheny/project/data-access';
import {
  LoadDataService,
  LoadParameters,
  mapData,
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { ZodTypeAny } from 'zod';

import { DB_TYPES, TECHNOLOGY_TYPE } from './const';
import { DatabaseDetailsService } from './database-details.service';

class CategoryCollection extends Collection<string, any> {
  protected idKey: any;

  constructor(
    private readonly http: HttpClient,
    private readonly url: string,
    private readonly categoryMapper: string,
    private readonly fromOrg: boolean,
  ) {
    super();
  }

  protected fetchApi(): Observable<string[]> {
    return this.http
      .get<ResponseModel<any>>(`${this.url}`, {
        params: { fromOrg: this.fromOrg },
      })
      .pipe(map((d) => d.data?.[this.categoryMapper]?.filter((dd) => !!dd)));
  }
}

export interface Settings {
  db: DB_TYPES | 'ewz';
  technology: TECHNOLOGY_TYPE;
  saveUrl?: string;
  categoryMapper: string;
  technologyMapper: string;
  guid: string;
  responseSchema?: ZodTypeAny;
  requestSchema?: ZodTypeAny;
  requestSchemaV2?: ZodTypeAny;
}

const TechMapping: Partial<Record<TECHNOLOGY_TYPE, string>> = {
  'conversion-tech': 'conversion-technologies',
  'storage-tech': 'storage-technologies',
  'network-tech': 'network-technologies',
  imports: 'impex',
  exports: 'impex',
};

const DbMapping: Partial<Record<DB_TYPES | 'ewz', string>> = {
  user: 'user',
  database: 'database',
  'database-org': 'database',
  ewz: 'ewz',
};

export class TechnologyCollection<TECHNOLOGY>
  implements LoadDataService, DatabaseDetailsService<TECHNOLOGY>
{
  private readonly categoryCollection: CategoryCollection;

  public readonly categories$: Observable<any[]>;
  public categories;

  private readonly fromOrg: boolean;

  private readonly url: string;
  private readonly urlV2: string;
  private readonly saveUrl: string;
  private readonly categoryUrl: string;

  private loadData = true;
  protected organization = '';

  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly environmentService: EnvironmentService,
    protected readonly settings: Settings,
  ) {
    this.fromOrg = settings.db === 'database-org';
    this.url = `${this.environmentService.getValue('base')}${
      TechMapping[this.settings.technology]
    }/profile-types/${DbMapping[this.settings.db]}`;
    this.urlV2 = `${this.environmentService.getValue('base')}v2/${
      TechMapping[this.settings.technology]
    }/profile-types/${DbMapping[this.settings.db]}`;
    this.saveUrl = settings.saveUrl
      ? `${this.environmentService.getValue('base')}${settings.saveUrl}`
      : this.url;
    this.categoryUrl = `${this.url}/technology-categories`;

    this.categoryCollection = new CategoryCollection(
      httpClient,
      this.categoryUrl,
      this.settings.categoryMapper,
      this.fromOrg,
    );
    this.categoryCollection.initialize();
    this.categories$ = this.categoryCollection.data$;

    this.categories$.subscribe((c) => (this.categories = c));
  }

  protected get tech() {
    return TechMapping[this.settings.technology];
  }

  public create(projectVersion: ProjectVersion, data: Partial<TECHNOLOGY>) {
    const tech = TechMapping[this.settings.technology];
    const profileType =
      this.settings.db === 'database-org'
        ? this.organization
        : DbMapping[this.settings.db];
    const base =
      projectVersion === 'V1'
        ? `${this.environmentService.base}`
        : `${this.environmentService.base}v2/`;
    const url = `${base}${tech}/profile-types/${profileType}`;
    const requestSchema =
      projectVersion === 'V1'
        ? this.settings.requestSchema
        : this.settings.requestSchemaV2 ?? this.settings.requestSchema;

    return firstValueFrom(
      this.httpClient.post<
        ResponseModel<{ conversionTechnologies: TECHNOLOGY[] }>
      >(
        url,
        this.settings.requestSchema
          ? mapDataRequest(requestSchema, data)
          : data,
        { params: { fromOrg: this.fromOrg } },
      ),
    ).then(() => this.categoryCollection.load());
  }

  public load(params: LoadParameters) {
    this.loadData = this.settings.db !== 'ewz' || params.ewz;
    this.organization = params.organizationId;
  }

  public reload(): void {
    if (!this.loadData) {
      return;
    }
    this.categoryCollection.load();
  }

  public getTechnologyCategoryDetails(categories: string[]): Observable<any[]> {
    return this.httpClient
      .post<ResponseModel<any>>(
        this.categoryUrl,
        { technologyCategories: categories, fromOrg: this.fromOrg },
        { params: { fromOrg: this.fromOrg } },
      )
      .pipe(mapData(this.settings.technologyMapper));
  }

  public getDetails(
    projectVersion: ProjectVersion,
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any> {
    const url = projectVersion === 'V2' ? this.urlV2 : this.url;
    return this.httpClient
      .get<ResponseModel<any>>(`${url}/${guid}`, {
        params: { exchangeRate, fromOrg: this.fromOrg },
      })
      .pipe(
        this.settings.responseSchema
          ? mapDataZ(this.settings.responseSchema)
          : mapData(),
      );
  }

  public update(
    projectVersion: ProjectVersion,
    guid: string,
    data: Partial<TECHNOLOGY>,
  ) {
    const tech = TechMapping[this.settings.technology];
    const profileType =
      this.settings.db === 'database-org'
        ? this.organization
        : DbMapping[this.settings.db];
    const base =
      projectVersion === 'V1'
        ? `${this.environmentService.base}`
        : `${this.environmentService.base}v2/`;
    const url = `${base}${tech}/profile-types/${profileType}/${guid}`;
    const requestSchema =
      projectVersion === 'V1'
        ? this.settings.requestSchema
        : this.settings.requestSchemaV2 ?? this.settings.requestSchema;

    return firstValueFrom(
      this.httpClient.put<
        ResponseModel<{ conversionTechnologies: TECHNOLOGY[] }>
      >(
        `${url}`,
        requestSchema
          ? mapDataRequest(this.settings.requestSchema, data)
          : data,
        { params: { fromOrg: this.fromOrg } },
      ),
    ).then(() => this.reload());
  }

  public delete(guid: string) {
    return firstValueFrom(
      this.httpClient
        .delete(`${this.saveUrl}/${guid}`, {
          // params: { fromOrg: this.fromOrg },
        })
        .pipe(map(() => guid)),
    );
  }

  public async deleteCategory(category: string): Promise<string> {
    const techs = await firstValueFrom(
      this.getTechnologyCategoryDetails([category]),
    );

    await Promise.all(techs?.map((t) => this.delete(t[this.settings.guid])));

    return category;
  }
}
