<div [attr.data-cy]="dataCy || label" [attr.data-testid]="dataCy || label">
  <mat-checkbox
    [formControl]="formControl"
    color="primary"
    (change)="onChangeChecked()"
  >
    {{ label | transloco }}
    <ng-content></ng-content>

    <sympheny-info-button *ngIf="hint" [tooltip]="hint" [link]="hintLink">
    </sympheny-info-button>
  </mat-checkbox>
  <mat-error *ngIf="invalid">
    {{ getErrorMessage() | transloco }}
  </mat-error>
</div>
