import { maxBy } from 'lodash-es';

import { Graph } from '../../model';

export const calculateGraphDimsions = ({
  settings,
  graph,
}: Readonly<Graph>) => {
  const { width, height, padding } = settings;

  const x0 = padding;
  const x1 = width - padding;
  const y0 = padding;
  const y1 = height - padding;

  graph.setExtendValue('x0', x0);
  graph.setExtendValue('x1', x1);
  graph.setExtendValue('y0', y0);
  graph.setExtendValue('y1', y1);

  return { maxWidth: x1 - x0, maxHeight: y1 - y0 };
};

export const EhubComputeNodeSize = (graph: Graph<any, any>) => {
  const { settings, graph: data } = graph;
  const { nodeDimensions, minNodePaddingHorizontal, minNodePaddingVertical } =
    settings;

  data.forEachNode((node) => {
    node.setNodeDimensions(nodeDimensions(node));
  });
  let totalNodeWidth = 0;
  let maxNodeHeight = 0;
  const { maxWidth, maxHeight } = calculateGraphDimsions(graph);

  // search for the smallest node height
  data.forEachColumn((nodes) => {
    const maxNodeW = maxBy(nodes, (n) => n.nodeWidth).nodeWidth;
    const maxNodeH =
      maxBy(nodes, (n) => n.nodeHeight).nodeHeight + minNodePaddingHorizontal;

    // if (totalNodeHeight < totalH) {
    //   totalNodeHeight = totalH;
    // }

    totalNodeWidth += maxNodeW + settings.minNodePaddingVertical;
    if (maxNodeHeight < maxNodeH) maxNodeHeight = maxNodeH;
  });

  let heightRatio = 1;
  let widthRatio = 1;
  const totalNodeHeight = maxNodeHeight * data.maxNodesInColumns();
  if (maxHeight < totalNodeHeight) {
    heightRatio = maxHeight / totalNodeHeight;
  }

  if (maxWidth < totalNodeWidth) {
    widthRatio = maxWidth / totalNodeWidth;
  }

  // graph.graph.setExtendValue('maxNodeWidth', maxNodeWidth);
  // settings.nodeHeight = nodeHeight;

  if (heightRatio === 1 && widthRatio === 1) {
    return;
  }

  data.forEachNode((node) => {
    const width = node.nodeWidth * widthRatio;
    const height = node.nodeHeight * heightRatio;

    if (node.nodeWidth === node.nodeHeight) {
      // it was a square so take the lowest value to recreate the square
      const min = Math.min(width, height);

      node.setNodeDimensions({ height: min, width: min });
    } else {
      //use the new dimension values
      node.setNodeDimensions({ width, height });
    }
  });

  // Resize the elements to a lower resolution
};
