import { Control } from 'ol/control';

const buttonClassFullScreen = 'ol-full-screen-false';
const buttonContentFullScreen = '⤢';
const buttonClassCloseFullScreen = 'ol-full-screen-true';
const buttonContentCloseFullScreen = 'x';

export class FullScreenControl extends Control {
  private initialHeight = '';
  private button: HTMLElement;
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = buttonContentFullScreen;
    button.title = 'Toggle fullscreen';

    button.className = buttonClassFullScreen;
    const element = document.createElement('div');
    element.className = 'ol-full-screen ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleFullscreen.bind(this), false);
    this.button = button;
  }

  private handleFullscreen() {
    const map = this.getMap();
    if (!map) {
      return;
    }
    const doc = map.getOwnerDocument();
    const mapElement = map.getTargetElement();
    const id = mapElement.id + '__container';
    if (this.isFullScreen(doc)) {
      this.exitFullScreen(mapElement);
    } else {
      this.requestFullScreen(mapElement, document.getElementById(id));
    }
  }

  private exitFullScreen(mapELement: HTMLElement) {
    mapELement.style.height = this.initialHeight;
    this.button.className = buttonClassFullScreen;
    this.button.innerHTML = buttonContentFullScreen;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    }
  }

  public requestFullScreen(
    mapELement: HTMLElement,
    targetElement: HTMLElement
  ) {
    this.initialHeight = mapELement.style.height;
    this.button.className = buttonClassCloseFullScreen;
    this.button.innerHTML = buttonContentCloseFullScreen;
    mapELement.style.height = '100VH';

    if (targetElement.requestFullscreen) {
      targetElement.requestFullscreen();
    } else if (targetElement['webkitRequestFullscreen']) {
      targetElement['webkitRequestFullscreen']();
    }
  }

  private isFullScreen(doc) {
    return !!(doc['webkitIsFullScreen'] || doc.fullscreenElement);
  }
}
