export type LinkData = { source: string; target: string };

type LinkIndex = number | string;

export type CircularLinkType = 'top' | 'bottom';
export type ArrowPosition = 'start' | 'end' | 'none' | 'both';
export type OrthogonalPathData = {
  x: number;
  y: number;
  height?: number;
  vertical?: boolean;
};
export type OrthogonalPathDataObj = {
  source: OrthogonalPathData;
  target: OrthogonalPathData;
  verticalLink?: { source: OrthogonalPathData; target: OrthogonalPathData };
  breakOffset: number;
  arrowPosition: ArrowPosition;
  arrowRotate?: boolean;
};

export class LinkImpl {
  // internal link id
  public _id: string;

  public circular = false;
  public circularLinkType?: CircularLinkType;
  public width: number;
  public target: string;
  public source: string;
  public value: number;
  public type: 'replaced' | 'normal' | 'virtual' | 'aggregate';
  public x0: number;
  public y0: number;
  public x1: number;
  public y1: number;
  public index: number | string;
  public _index: { source: string; target: string };
  public circularLinkID?: number;
  public circularPathData?: any;
  public orthogonalPathData?: OrthogonalPathDataObj;
  public path: string | null;
  public parentLink?: LinkIndex;
  public originalNodeIds?: string[];

  public isSelfLinking = false;

  private dimensions = { height: 0, center: { y: 0, x: 0 } };
  constructor(link: Link) {
    Object.assign(this, link);
    this.calculateDimensions();
  }

  public setValue(key: LinkKey, value: Link[LinkKey]) {
    (this as any)[key] = value;

    if (key in ['x1', 'y0', 'x0', 'y1']) this.calculateDimensions();
  }

  public setLinkY(y: number, height?: number) {
    const h = height ?? this.dimensions.height;
    this.y0 = y;
    this.y1 = y + h;
    return this;
  }

  public setLinkX(x: number, width?: number) {
    const w = width ?? this.width;
    this.x0 = x;
    this.x1 = x + w;
    this.calculateDimensions();
    return this;
  }

  private calculateDimensions() {
    this.dimensions = {
      height: this.y1 - this.y0,
      center: {
        y: (this.y0 + this.y1) / 2,
        x: (this.x1 + this.x0) / 2,
      },
    };
  }
}

type LinkKey = keyof Link;
export type Link = Readonly<LinkImpl>;
