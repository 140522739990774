import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'sympheny-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatTooltipModule, TranslocoPipe],
})
export class ButtonIconComponent {
  @Input() public disabled = false;
  @Input() public icon: string;
  @Input() public active: boolean;
  @Input() public size: 'small';
  @Input() public fontSet: string;
  @Input() public tooltip: string;
  @Input() public activeClass = 'text-primary';
  @Input() public cssClass = 'text-gray-800';

  @Output() public readonly action = new EventEmitter<MouseEvent>();

  public onAction() {
    if (this.disabled) {
      return;
    }
    this.action.emit();
  }
}
