import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';

import { MoreButtonConfiguration, MoreMenuItem } from './more-button.model';

@Component({
  selector: 'sympheny-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    NgFor,
    TranslocoPipe,
  ],
})
export class MoreButtonComponent {
  @Input() public disabled = false;
  @Input() public configuration!: MoreButtonConfiguration;

  public onAction(item: MoreMenuItem) {
    item.action();
  }
}
