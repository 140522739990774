import {
  AdditionalConfig,
  BLANK,
  FormatValueComponentConfig,
  FormatValueConfig,
  FormatValueDefault,
  FormatValueTemplate,
} from '@sympheny/format-value';
import { ZodTypeAny } from 'zod';

export interface DialogSummaryValueBase<T> {
  key: keyof T;
}

export type DialogSummaryValueDefault<T> = FormatValueDefault<T> &
  DialogSummaryValueBase<T>;

export type DialogSummaryValueComponentConfig<T> =
  FormatValueComponentConfig<T> & DialogSummaryValueBase<T>;

export type DialogSummaryValueTemplate<T> = FormatValueTemplate<T> &
  DialogSummaryValueBase<T>;

export type DialogSummaryValueConfig<T> =
  | DialogSummaryValueDefault<T>
  | DialogSummaryValueComponentConfig<T>
  | DialogSummaryValueTemplate<T>;

export type Summary<DATA> = DialogSummaryValueConfig<DATA>;

export interface DialogSummaryData<DATA> {
  title: string;
  schema: ZodTypeAny;
  data: DATA;
  params?: Record<string, unknown>;
  fieldConfig: Record<keyof DATA, FormatValueConfig<DATA>>;
  summaryFields: Array<keyof DATA | BLANK>;
  additionalConfig?: AdditionalConfig<DATA>;
}
