import { ModuleWithProviders, NgModule, Optional } from '@angular/core';

import { BannerEffects } from './services/banner.effects';
import { DismissService } from './services/dismiss.service';

@NgModule({})
export class UserBannerModule {
  constructor(@Optional() bannerEffects: BannerEffects) {
    bannerEffects?.init();
  }

  public static forRoot(): ModuleWithProviders<UserBannerModule> {
    return {
      ngModule: UserBannerModule,
      providers: [BannerEffects, DismissService],
    };
  }
}
