import {
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
  PortalModule,
} from '@angular/cdk/portal';
import {
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgFor,
  NgSwitchDefault,
  DecimalPipe,
  DatePipe,
  JsonPipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoDirective } from '@ngneat/transloco';
import { IconButtonComponent } from '@sympheny/ui/button';

import {
  FormatValueAction,
  FormatValueComponentConfig,
  FormatValueConfig,
  FormatValueTemplate,
} from '../model/format-value';

@Component({
  selector: 'sympheny-format-value',
  templateUrl: './format-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PortalModule,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    MatTooltipModule,
    NgSwitchDefault,
    DecimalPipe,
    DatePipe,
    TranslocoDirective,
    JsonPipe,
    IconButtonComponent,
    TranslocoDirective,
  ],
  providers: [DecimalPipe],
})
export class FormatValueComponent<T> implements OnChanges {
  @Input() public format!: FormatValueConfig<T>;
  @Input() public element!: T;
  @ViewChild(CdkPortalOutlet, { static: true })
  public portalOutlet!: CdkPortalOutlet;

  public maxValues = 5;
  public value: any;
  public translatePrefix = '';
  public type:
    | 'action'
    | 'array'
    | 'template'
    | 'component'
    | 'datetime'
    | 'number'
    | 'integer'
    | 'translate'
    | '' = '';
  public tooltip = '';

  constructor(
    private readonly injector: Injector,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly decimalPipe: DecimalPipe,
  ) {}

  public ngOnChanges() {
    if (!this.format || !this.element) {
      this.value = null;
      return;
    }

    this.detachPortal();

    if (this.format.type === 'action' || 'action' in this.format) {
      const format = this.format as FormatValueAction<T>;
      this.type = 'action';
      this.value = format.readOnly?.(this.element) ? null : format.action;
      return;
    }
    if (this.format.type === 'template') {
      this.createTemplate(this.format as FormatValueTemplate<T>);
      this.type = 'template';
      return;
    }
    if (this.format.type === 'component') {
      this.createPortal(this.format as FormatValueComponentConfig<T>);
      this.type = 'component';
      return;
    }

    if (this.format.type === 'translate' || 'translatePrefix' in this.format) {
      this.format.type = 'translate';
      this.translatePrefix = this.format.translatePrefix ?? '';
    }

    this.setValue();
  }

  private setValue() {
    let value: any = this.element[this.format.key as keyof T];

    if (value && Array.isArray(value)) {
      this.type = 'array';
      this.maxValues = this.format.maxValues ?? 5;
      this.value = value.map((v: any) => {
        const columnValue = this.format.childKey ? v[this.format.childKey] : v;

        return this.formatValue(columnValue ?? this.format.defaultValue);
      });
      this.tooltip = this.value.join(' || ');
    } else {
      if (value && this.format.childKey) {
        value = value[this.format.childKey];
      }

      this.value = this.formatValue(value ?? this.format.defaultValue);
    }
  }

  private formatValue(value: any) {
    if (value === undefined || value === null) {
      return value;
    }

    this.type = this.format.type as any;

    if (value instanceof Date) {
      this.type = 'datetime';
      return value;
    }

    if (typeof value === 'number') {
      const type = this.type === 'integer' ? 'integer' : 'number';
      if (this.type !== 'array') {
        this.type = 'number';
      }
      return this.decimalPipe.transform(
        value,
        type === 'integer' ? '1.0' : '1.2',
      );
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    return value;
  }

  private createPortal({
    component,
    data,
    token,
  }: FormatValueComponentConfig<T>) {
    const portalInjector = Injector.create({
      providers: [
        {
          provide: token,
          useValue: {
            element: this.element,
            data: {
              ...data,
              suffix: this.format.suffix,
              params: this.format.params,
            },
          },
        },
      ],
      parent: this.injector,
    });

    this.portalOutlet.attach(
      new ComponentPortal(component, null, portalInjector),
    );
  }

  private createTemplate({ template }: FormatValueTemplate<T>) {
    if (!template) {
      console.warn(`template not found for: ${this.format.key as string}`);
      this.detachPortal();
      return;
    }

    this.portalOutlet.attach(
      new TemplatePortal(template, this.viewContainerRef, {
        $implicit: this.element,
      }),
    );
  }

  private detachPortal() {
    if (this.hasAttachedPortal) {
      this.portalOutlet.detach();
    }
  }

  private get hasAttachedPortal(): boolean {
    return this.portalOutlet && this.portalOutlet.hasAttached();
  }
}
