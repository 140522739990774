<div class="p-6">
  <h2>
    {{ data.title | transloco }}
  </h2>
  <div class="pb-4 max-h-[75VH] overflow-auto">
    <p>{{ data.question | transloco }}</p>
  </div>

  <sympheny-dialog-actions
    [submitLabel]="data.submitLabel ?? data.isCopy ? 'Continue' : 'Delete'"
    [submitColor]="data.submitLabel ?? data.isCopy ? 'primary' : 'warn'"
    (submitAction)="submitAction()"
  >
  </sympheny-dialog-actions>
</div>
