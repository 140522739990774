<ng-container *transloco="let t">
  <div class="p-6">
    <h2>
      <ng-container *ngIf="title">
        {{ t(title) }}
      </ng-container>
      <ng-content select="[title]"></ng-content>
    </h2>

    <div class="pb-8 max-h-[75VH] overflow-auto">
      <div class="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-8">
        <sympheny-dialog-summary-value
          *ngFor="let summary of summaries"
          [summary]="summary"
          [element]="data"
        ></sympheny-dialog-summary-value>
      </div>
    </div>
    <sympheny-dialog-actions [hideSubmit]="true" cancelLabel="Close">
    </sympheny-dialog-actions></div
></ng-container>
