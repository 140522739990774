import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';

import {
  BaseFormComponent,
  FormFieldConfig,
} from '../base-form/base-form.component';
import { InputSuffixComponent } from '../input-suffix/input-suffix.component';

export interface SliderComponentInput extends FormFieldConfig {
  min?: number;
  max?: number;
  step?: number;
}

@Component({
  selector: 'sympheny-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [
    TranslocoPipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    InfoButtonComponent,
    MatInputModule,
    NgIf,
    InputSuffixComponent,
    JsonPipe,
    NgFor,
  ],
})
export class SliderComponent extends BaseFormComponent<
  number,
  SliderComponentInput
> {
  @Input() public formatTick = (val: number) => '|';
  public get min() {
    return this.config?.min ?? 0;
  }
  public get max() {
    return this.config?.max ?? 0;
  }
  public get steps() {
    const totalsteps = this.max - this.min;
    return Array.from({ length: totalsteps + 1 }).map((_, idx) => ({
      step: idx + this.min,
      label: this.formatTick(idx + this.min),
    }));
  }
}
