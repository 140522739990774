import { ElementRef } from '@angular/core';

export const mouseOut = (svg, tooltip: ElementRef) => () => {
  svg.selectAll('rect').style('opacity', opacity.highlight);
  svg.selectAll('.sankey-link').style('opacity', opacity.normal);
  svg.selectAll('text').style('opacity', 1);
  tooltip.nativeElement.style.opacity = 0;
};

export const opacity = {
  highlight: 1,
  normal: 0.7,
  noHighlight: 0.3,
};
