import { Tile as TileLayer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import { OSM } from 'ol/source';

import { openStreetMapAttributions } from './base-map.const';

export function createBaseOSMLayer(baseMapLayerGroup: LayerGroup) {
  const osmLayer = new TileLayer({
    source: new OSM({ attributions: openStreetMapAttributions }),
    properties: {
      displayInLayerSwitcher: false,
      rasterLayer: true,
      skipFeatureAtPixel: true,
    },
  });

  baseMapLayerGroup.getLayers().push(osmLayer);

  return baseMapLayerGroup;
}
