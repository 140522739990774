import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'sympheny-button',
  templateUrl: './button.component.html',
  standalone: true,
  imports: [MatButtonModule, MatTooltipModule, MatIconModule, NgIf],
})
export class ButtonComponent {
  @Input() public color: ThemePalette | 'custom' | null = 'primary';
  @Input() public buttonType: 'button' | 'submit' = 'button';
  @Input() public disabled = false;
  @Input() public tooltip = '';

  @Input() public icon?: string;
  @Input() public dataCy?: string;

  @Output() public readonly action = new EventEmitter<void>();
}
