import { maxBy } from 'lodash-es';

import { Graph, GraphData, Node } from '../../model';
import { centerFromNodes, sortNodeBy } from '../../utils/node';

export const resolveNodeCollisions = (data: GraphData, nodePadding: number) => {
  data.forEachColumn((nodes, columnIndex) => {
    let nextY1 = 0;
    if (nodes.length === 1) return;

    nodes.sort(sortNodeBy('y0'));

    nodes.forEach((node) => {
      if (node.y0 <= nextY1) {
        if (node.sameSourceTarget) {
          const { sourceNodes } = data.getSourceLinksWithNodes(node);
          const source = sourceNodes[0];
          if (source.y0 < node.y0) node.setNodeY(source.y0 - node.padding.y);
          else node.setNodeY(source.y1 + node.padding.y);
          return;
        }

        // move the node down
        node.setNodeY(nextY1);
      }

      nextY1 = node.y1 + nodePadding;
    });
    return;
    // TODO test if it doesn't go under
    if (nextY1 - nodePadding > data.extend.y1) {
      let nextY0 = data.extend.y1; //- height;
      console.warn('it goes out of the graph reverse node direction');
      nodes
        .sort(sortNodeBy('y0'))
        .reverse()
        .forEach((node) => {
          if (nextY0 < node.y0) {
            node.setNodeY(nextY0);
            nextY0 -= nodePadding + node.nodeHeight;
          } else {
            nextY0 = node.y0 - nodePadding;
          }
        });

      // TODO move everything a bit up
    }
  });
};

const centerNodesFromLeftToRight = (data: GraphData) => {
  data.forEachColumn((nodes, columnIndex) => {
    // Skip the first column
    if (columnIndex === 0) return;

    nodes.sort(sortNodeBy('y0')).forEach((node) => {
      const { sourceNodes } = data.getSourceLinksWithNodes(node);

      if (sourceNodes.length > 1) {
        const { center } = centerFromNodes(sourceNodes);

        node.setCenterNodeY(center);
      } else {
        const { targetNodes } = data.getTargetLinksWithNodes(node);
        if (sourceNodes.length === 1 || targetNodes.length === 1) {
          const { center } = centerFromNodes([targetNodes, sourceNodes].flat());
          //   data.setNodeY(node, center - height / 2, height);
        }
      }
    });
  });
};
const centerNodesFromRightToLeft = (data: GraphData) => {
  const totalColumns = data.maxColumns();
  data.forEachColumnReverse((nodes, columnIndex) => {
    // Skip the first column
    if (columnIndex === totalColumns) return;

    nodes.sort(sortNodeBy('y0')).forEach((node) => {
      const { targetNodes } = data.getTargetLinksWithNodes(node);
      if (targetNodes.length > 1) {
        const { center } = centerFromNodes(targetNodes.flat());
        node.setCenterNodeY(center);
      } else {
        const { sourceNodes } = data.getSourceLinksWithNodes(node);
        if (sourceNodes.length === 1 || targetNodes.length === 1) {
          const { center } = centerFromNodes([targetNodes, sourceNodes].flat());
          node.setCenterNodeY(center);
        }
      }
    });
  });
};

export const alignNodes = (data: GraphData) => {
  const { extend } = data;
  const contentHeight = extend.y1 - extend.y0;
  const calculateMiddle: Array<{
    node: Node;
    calculateOn: Node[];
  }> = [];
  data.forEachColumn((nodes, columnIndex) => {
    if (columnIndex === 0) {
      const nodeDistance =
        contentHeight / maxBy(nodes, (node) => node.nodeHeight).nodeHeight;
      nodes.forEach((node, index) => {
        const nextY = nodeDistance * index;
        // realign them on the right height
        node.setNodeY(nextY);
      });
      return;
    }

    nodes.forEach((node) => {
      const { sourceNodes } = data.getSourceLinksWithNodes(node);
      const { targetNodes } = data.getTargetLinksWithNodes(node);

      if (sourceNodes.length === 1 && targetNodes.length === 0) {
        const { targetNodes: sTarget } = data.getTargetLinksWithNodes(
          sourceNodes[0],
        );
        if (sTarget.length === 1) {
          node.setNodeY(node.y0);
        }
      } else if (sourceNodes.length === 1 && targetNodes.length === 1) {
        if (sourceNodes[0].column === targetNodes[0].column) return;
        // TODO check for the source sources and the target sources

        const { sourceNodes: tSource } = data.getSourceLinksWithNodes(
          targetNodes[0],
        );
        const { targetNodes: sTarget } = data.getTargetLinksWithNodes(
          sourceNodes[0],
        );

        node.setNodeY(sourceNodes[0].y0);
        if (tSource.length === 1 && sTarget.length === 1) {
          node.setNodeY(sourceNodes[0].y0);
        } else if (tSource.length === 1 && sTarget.length === 0) {
          // data.setNodeY(node, sourceNodes[0].y0, height);
        } else if (tSource.length === 0 && sTarget.length === 1) {
          // data.setNodeY(node, sourceNodes[0].y0, height);
        } else {
          node.setNodeY(sourceNodes[0].y0);
          return;
          // add them to a list to align them end the end, on the middle of sources and targets
          calculateMiddle.push({
            node,
            calculateOn: [sourceNodes, targetNodes].flat(),
          });
        }
      }
    });
  }, 'y0');

  calculateMiddle.forEach(({ node, calculateOn }) => {
    const { center } = centerFromNodes(calculateOn);

    node.setCenterNodeY(center);
  });
};

const centerHiddenNodes = (data: GraphData) => {
  data.forEachNode((node) => {
    if (!node.aggregate) return;
    const { sourceNodes } = data.getSourceLinksWithNodes(node);
    const { targetNodes } = data.getTargetLinksWithNodes(node);
    const nodes = sourceNodes.length > 1 ? targetNodes : sourceNodes;
    const nextY = centerFromNodes(nodes).center;
    node.setCenterNodeY(nextY);
  });
};

export const EhubResolveCollison = (graph: Readonly<Graph<any, any>>) => {
  const { graph: data, settings } = graph;
  // TODO maybe we need to make iterations dynamic based on the graph data?
  const iterations = graph.settings.iterations;
  //  resolveCollisionsAndRelax(graph);

  // for (let alpha = 1, n = iterations; n > 0; --n) {
  //   relaxLeftAndRight((alpha *= 0.99), data.extend, data);
  //   resolveCollisions(data, data.extend, graph.settings);
  // }
  for (let alpha = 1, n = iterations; n > 0; --n) {
    //relaxLeftAndRight((alpha *= 0.99), data.extend, data);
    // relaxLeftAndRight((alpha *= 0.99), data.extend, data);
    //1. Make sure there is no node overlap
    resolveNodeCollisions(data, settings.minNodePaddingHorizontal);

    break;
    // 2. Make sure there is no link passing by in that path
    centerNodesFromLeftToRight(data);
    // 3. Go from right to left to fix the ones that wil overlap with links
    centerNodesFromRightToLeft(data);
  }
  // 4. Reolve node collisions if there are any left?
  resolveNodeCollisions(data, settings.minNodePaddingHorizontal);
  // Try to put the same nodes on the same line
  // then run resolveNodeCollisions

  alignNodes(data);
  centerHiddenNodes(data);
  return data;
};
