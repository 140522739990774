<sympheny-icon-button
  [class.!hidden]="hidden"
  [tooltip]="action.tooltip"
  [tooltipParams]="action.tooltipParams"
  [icon]="action.icon"
  (action)="action.action(element, index)"
  [color]="action.color || 'primary'"
  [disabled]="disabled"
  [dataCy]="action.dataCy ?? action.icon"
>
  {{ action.label }}
</sympheny-icon-button>
