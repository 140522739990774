// Populate the sourceLinks and targetLinks for each node.

import findCircuits from 'elementary-circuits-directed-graph';

import { Graph } from '../model';

export const identifyCircles = (graph: Readonly<Graph<any, any>>) => {
  const { graph: data } = graph;
  let circularLinkID = 0;
  // Building adjacency graph
  const adjList: number[][] = [];

  data.forEachLink((link) => {
    const { source: s, target: t } = data.getNodeLinks(link);
    const source = s.index as number;
    const target = t.index as number;

    if (!adjList[source]) adjList[source] = [];
    if (!adjList[target]) adjList[target] = [];

    // Add links if not already in set
    if (adjList[source].indexOf(target) === -1) adjList[source].push(target);
  });

  // Find all elementary circuits
  const cycles = findCircuits(adjList);

  // Sort by circuits length
  cycles.sort(function (a, b) {
    return a.length - b.length;
  });

  const circularLinks = {};
  for (let i = 0; i < cycles.length; i++) {
    const cycle = cycles[i];
    const last = cycle.slice(-2);
    if (!circularLinks[last[0]]) circularLinks[last[0]] = {};
    circularLinks[last[0]][last[1]] = true;
  }

  data.forEachLink((link) => {
    const { source: s, target: t } = data.getNodeLinks(link);
    const source = s.index as number;
    const target = t.index as number;
    // If self-linking or a back-edge
    if (
      target === source ||
      (circularLinks[source] && circularLinks[source][target])
    ) {
      link.setValue('circular', true);
      link.setValue('circularLinkID', circularLinkID);

      circularLinkID = circularLinkID + 1;
    } else {
      link.setValue('circular', false);
    }
  });

  return data;
};
