import { NgIf, AsyncPipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Component({
  selector: 'sympheny-auth-callback',
  templateUrl: './auth-callback.component.html',
  standalone: true,
  imports: [NgIf, AsyncPipe, TitleCasePipe],
})
export class AuthCallbackComponent implements OnInit {
  public error$: Observable<any> = this.auth.auth0.error$;

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    timer(0)
      .pipe(takeUntil(this.error$))
      .subscribe(() => {
        this.router.navigateByUrl('/');
      });
  }
}
