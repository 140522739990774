import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoadDataService } from '@sympheny/utils/data-access';

import {
  EnergyDemandSavedUserProfileCollection,
  EnergyDemandDatabaseCollection,
  OrgEnergyDemandDatabaseCollection,
} from './collection';

@NgModule({})
export class DatabaseDataAccessEnergyDemandsModule {
  public static forRoot(): ModuleWithProviders<DatabaseDataAccessEnergyDemandsModule> {
    return {
      ngModule: DatabaseDataAccessEnergyDemandsModule,
      providers: [
        EnergyDemandSavedUserProfileCollection,
        EnergyDemandDatabaseCollection,
        OrgEnergyDemandDatabaseCollection,
        {
          multi: true,
          provide: LoadDataService,
          useExisting: EnergyDemandSavedUserProfileCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: EnergyDemandDatabaseCollection,
        },
        {
          multi: true,
          provide: LoadDataService,
          useExisting: OrgEnergyDemandDatabaseCollection,
        },
      ],
    };
  }
}
