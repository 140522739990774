import z from 'zod';

export const ResultStgOpsSchema = z.object({
  name: z.string(),
  stage: z.string(),
  hub: z.string(),
  capacity: z.number(),
  capacityBinary: z.number(),
  om: z.number(),
  energyFlow: z.array(z.number()),
  soc: z.array(z.number()),
  energyCarrier: z.string(),
});

export type ResultStgOps = z.infer<typeof ResultStgOpsSchema>;
