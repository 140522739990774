import { NgIf, AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ScenarioStore } from '@sympheny/project/scenario/data-access';
import { SelectComponent } from '@sympheny/ui/form';
import { BehaviorSubject, map, switchMap } from 'rxjs';

@Component({
  selector: 'sympheny-hub-select',
  templateUrl: './hub-select.component.html',
  styleUrls: ['./hub-select.component.scss'],
  standalone: true,
  imports: [NgIf, SelectComponent, ReactiveFormsModule, AsyncPipe],
})
export class HubSelectComponent implements ControlValueAccessor, OnInit {
  @Input() public required = false;
  @Input() public multiple = false;
  @Input() public hint: string;
  @Input() public dataCy: string;
  @Output() public readonly selectionChange = new EventEmitter<any | null>();
  public formControl = null;
  public changeFn: (option: string) => void;
  public touchFn: () => void;

  public errorMessages = {
    required: 'Hub is required',
  };

  private readonly excludedGuids$ = new BehaviorSubject<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly hubs$ = this.scenarioStore
    .selectValue('hubs')
    .pipe(
      switchMap((ec) =>
        this.excludedGuids$.pipe(
          map((guids) =>
            guids.length ? ec.filter((e) => guids.indexOf(e.hubGuid) < 0) : ec,
          ),
        ),
      ),
    );

  constructor(
    private readonly ngControl: NgControl,
    private readonly scenarioStore: ScenarioStore,
  ) {
    this.ngControl.valueAccessor = this;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public writeValue(obj: any): void {}

  public registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  public ngOnInit() {
    this.formControl = this.ngControl.control as FormControl;
  }

  @Input() public set excludedGuids(guids: string[]) {
    this.excludedGuids$.next(guids);
  }
  @Input() public set excludedGuid(guid: string) {
    if (!guid) this.excludedGuids = [];
    else this.excludedGuids = [guid];
  }
}
