import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'sympheny-map-gis-toggle',
  templateUrl: './map-gis-toggle.component.html',
  styleUrls: ['./map-gis-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
  ],
})
export class MapGisToggleComponent implements OnInit {
  @Output() toggle = new EventEmitter<boolean>();

  showGisUser = false;

  onToggleChange($event: any, index: number) {
    const checked = $event.checked;
    const showGisIndex = index;

    this.toggle.emit(checked);

    if (showGisIndex === 1) {
      if (checked) {
        localStorage.setItem('showGisUser', 'true');
      } else {
        localStorage.removeItem('showGisUser');
      }
    }
  }

  ngOnInit(): void {
    this.showGisUser = localStorage.getItem('showGisUser') ? true : false;
    this.toggle.emit(this.showGisUser);
  }
}
