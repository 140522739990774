import { AbstractControl } from '@angular/forms';

// custom validator to check that two fields match
export function Unique(arr: any[], attr: string) {
  return (formControl: AbstractControl) => {
    // set error on matchingControl if validation fails
    if (arr && arr.some((res) => res[attr] === formControl.value)) {
      return { unique: true };
    } else {
      return null;
    }
  };
}
