import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { makeState } from '@fp-tools/angular-state';
import { debounceTime, Subject, takeUntil } from 'rxjs';

export interface FormLogStatus {
  redo: boolean;
  undo: boolean;
}

const initialState: FormLogStatus = {
  redo: false,
  undo: false,
};

@Injectable()
export class FormLogService implements OnDestroy {
  private readonly state = makeState(initialState);

  private readonly destroy$ = new Subject<void>();
  private changeLogUndo: any[] = [];
  private changeLogRedo: any[] = [];
  private lastChange: any = null;
  private form: FormGroup | undefined;
  private undoLastChange = false;
  private redoLastChange = false;

  public readonly status$ = this.state.select((state) => state);

  registerForm(form: FormGroup) {
    this.destroy$.next();

    this.form = form;
    this.changeLogUndo = [];
    this.lastChange = this.form.value;
    this.state.reset();

    this.form.valueChanges
      .pipe(debounceTime(400), takeUntil(this.destroy$))
      .subscribe((change) => this.registerChange(change));
  }

  registerChange(change: any) {
    if (this.redoLastChange) {
      this.redoLastChange = false;
      return;
    }
    if (this.undoLastChange) {
      this.undoLastChange = false;
      return;
    }

    this.changeLogRedo = [];
    this.changeLogUndo.push(this.lastChange);
    this.lastChange = change;
    this.updateState();
  }

  undo() {
    if (this.changeLogUndo.length === 0) {
      this.updateState();
      return;
    }

    this.undoLastChange = true;
    this.changeLogRedo.push(this.lastChange);
    this.lastChange = this.changeLogUndo.pop();
    this.updateState();

    this.form?.patchValue(this.lastChange);
  }

  redo() {
    if (this.changeLogRedo.length === 0) {
      this.updateState();
      return;
    }

    this.redoLastChange = true;
    this.changeLogUndo.push(this.lastChange);
    this.lastChange = this.changeLogRedo.pop();
    this.updateState();

    this.form?.patchValue(this.lastChange);
  }

  private updateState() {
    this.state.set((state) => ({
      ...state,
      undo: this.changeLogUndo.length !== 0,
      redo: this.changeLogRedo.length !== 0,
    }));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
