import * as mapboxgl from 'mapbox-gl';

import { mapTillerStyles, openLayerStyles } from './base-map';

export interface MapUtilTokens {
  mapboxToken: string;
  mapTillerToken: string;
}

export function initTokens(tokens: MapUtilTokens) {
  (mapboxgl.accessToken as any) = tokens.mapboxToken;

  mapTillerStyles.forEach(
    (style) =>
      (openLayerStyles[
        style
      ] = `${openLayerStyles[style]}?key=${tokens.mapTillerToken}`)
  );
}
