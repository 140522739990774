import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoPipe } from '@ngneat/transloco';
import { InfoButtonComponent } from '@sympheny/ui/button';
import { NgxMaskDirective } from 'ngx-mask';

import { FormFieldConfig } from '../base-form/base-form.component';
('@angular/material/core');

export type MatInputConfig = FormFieldConfig;

const suffixValues = {
  percent: '%',
  kw: 'kW',
  kwh: 'kWh',
  kwhYear: 'kWh/year',
  m: 'm',
  kwYear: 'kW/year',
  year: 'year',
} as const;

export type Appereance = keyof typeof suffixValues;

@Component({
  selector: 'sympheny-input-suffix',
  templateUrl: './input-suffix.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskDirective,
    TranslocoPipe,
    InfoButtonComponent,
    NgIf,
  ],
})
export class InputSuffixComponent {
  @Input() public suffix: Appereance | null;
  @Input() public suffixLabel: string | null;

  public get suffixValue() {
    return this.suffix ? suffixValues[this.suffix] : null;
  }
}
