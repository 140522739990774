import { Injectable, OnDestroy } from '@angular/core';
import { gisStyles, skipFeaturesAtPixel } from '@sympheny/gis/utils';
import { Map } from 'ol';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { Draw } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { unByKey } from 'ol/Observable';
import { Vector as VectorSource } from 'ol/source';
import { getLength } from 'ol/sphere';
import { ReplaySubject } from 'rxjs';

export interface NetworkLinkChangeEvent {
  length: number;
  hubs: {
    start: string | null;
    end: string | null;
  };
  geoJson: any | null;
}

const defaultValue = (): NetworkLinkChangeEvent => ({
  length: 0,
  hubs: {
    start: null,
    end: null,
  },
  geoJson: null,
});

@Injectable()
export class DrawNetworkLinkService implements OnDestroy {
  private currentNetworkLinkChange: NetworkLinkChangeEvent;
  private changeDetails = new ReplaySubject<NetworkLinkChangeEvent>(1);
  public changeDetails$ = this.changeDetails.asObservable();

  private map: Map | null = null;
  private tempSource: VectorSource<any> | null = null;
  private tempLayer: VectorLayer<any> | null = null;

  public ngOnDestroy(): void {
    this.changeDetails.complete();

    if (this.map && this.tempLayer) {
      this.map.removeLayer(this.tempLayer);
    }
  }

  public init(map: Map): void {
    this.currentNetworkLinkChange = defaultValue();
    this.map = map;

    this.createTemplayer();
    this.addDrawInteraction();
  }

  private createTemplayer() {
    this.tempSource = new VectorSource({ format: new GeoJSON() });

    this.tempLayer = new VectorLayer({
      source: this.tempSource,
      style: gisStyles.editFeatures,
      properties: {
        displayInLayerSwitcher: false,
      },
    });

    this.map.addLayer(this.tempLayer);
  }

  private addDrawInteraction() {
    const draw = new Draw({
      source: this.tempSource,
      type: 'LineString',
      minPoints: 1,
    });

    // Add interactions to map
    this.map.addInteraction(draw);

    let drawListener: any = null;
    let clickListener: any = null;

    // Add event listeners
    draw.on('drawstart', (drawEvent: any) => {
      // Remove previous drawing on start
      this.currentNetworkLinkChange = defaultValue();
      this.changeDetail();
      this.tempSource.clear();

      drawListener = this.startDraw(drawEvent.feature);

      clickListener = this.map.on('click', (event) => {
        this.setHubAtPixel(event.pixel);
      });
    });

    draw.on('drawend', (drawEnd) => {
      this.sendGeoJson(drawEnd.feature);
      unByKey(clickListener);
      unByKey(drawListener);
    });
  }

  private sendGeoJson(feature: Feature<any>) {
    const parser = new GeoJSON();
    feature.setProperties({});
    this.currentNetworkLinkChange.geoJson = parser.writeFeatureObject(feature, {
      featureProjection: 'EPSG:3857',
    });
    // BE except this is not null
    this.currentNetworkLinkChange.geoJson.properties =
      this.currentNetworkLinkChange.geoJson.properties ?? {};

    this.changeDetail();
  }

  private startDraw(feature: Feature<any>) {
    return feature.getGeometry().on('change', (evt: any) => {
      const line = evt.target;

      this.currentNetworkLinkChange.length = +getLength(line).toFixed(5);

      this.changeDetail();
    });
  }

  private setHubAtPixel(coordinates: number[]) {
    const features = this.map.getFeaturesAtPixel(coordinates, {
      layerFilter: (l) => {
        return skipFeaturesAtPixel(l);
      },
    });
    const hubFeature = features.find((feature) => feature.get('hub_id'));
    if (!hubFeature) {
      return;
    }

    const hubId = hubFeature.get('hub_id');

    if (!this.currentNetworkLinkChange.hubs.start) {
      this.currentNetworkLinkChange.hubs.start = hubId;
    } else {
      this.currentNetworkLinkChange.hubs.end = hubId;
    }

    this.changeDetail();
  }

  private changeDetail() {
    this.changeDetails.next(this.currentNetworkLinkChange);
  }
}
