import { Injectable } from '@angular/core';
import {
  addCollectionItem,
  removeCollectionItem,
  updateCollectionItem,
} from '@fp-tools/angular-state';
import { Direction, sortByDate } from '@sympheny/utils/sort';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AnalysisScenarioState } from './analysis-scenario.state';
import { getDefaultImage } from './get-default-image';
import { ProjectAnalysisState } from './project-analysis.state';
import { ProjectDetailsState } from './project-details.state';
import { Analysis, Scenario } from '../../model';
import { ProjectEvents } from '../project-events.service';

function findActiveAnalysis(analysis: Analysis[], analysisGuid: string) {
  return (analysis ?? []).find((a) => a.analysisGuid === analysisGuid) ?? null;
}

@Injectable()
export class ProjectState {
  private readonly activeAnalysisGuid$ = new BehaviorSubject<string>(null);
  public readonly project$ = this.projectDetailsState.data$;
  public readonly version$ = this.projectDetailsState.select('version');

  public readonly lockDetails$ = this.projectDetailsState.lockDetails$;
  public readonly canEdit$ = this.projectDetailsState.canEdit$;
  public readonly isReadonly$ = this.projectDetailsState.isReadonly$;
  public readonly isLocked$ = this.projectDetailsState.isLocked$;
  public readonly isOnwer$ = this.projectDetailsState.isOnwer$;
  public readonly isBetaProject$ = this.projectDetailsState
    .select('version')
    .pipe(map((version) => version === 'V2'));
  public readonly activeAnalysis$: Observable<Analysis | null> =
    this.projectDetailsState
      .select('analyses')
      .pipe(
        switchMap((analysis) =>
          this.activeAnalysisGuid$.pipe(
            map((active) => findActiveAnalysis(analysis, active)),
          ),
        ),
      );
  public readonly activeAnalysisName$ = this.activeAnalysis$.pipe(
    map((a) => a?.analysisName),
  );

  public readonly projectName$ = this.projectDetailsState.select('projectName');
  public readonly analysis$ = this.analysisState.data$.pipe(
    map((analysis: Analysis[] | null) =>
      sortByDate(analysis ?? []).byAttribute('created', Direction.DESC),
    ),
  );
  public readonly image$ = this.projectDetailsState
    .select('images')
    .pipe(map(getDefaultImage), distinctUntilChanged());

  constructor(
    private readonly projectDetailsState: ProjectDetailsState,
    private readonly analysisState: ProjectAnalysisState,
    private readonly analysisScenarioState: AnalysisScenarioState,
    private readonly events: ProjectEvents,
  ) {
    this.projectDetailsState.initialize();
    this.analysisState.initialize();
    this.analysisScenarioState.initialize();
    this.analysisState.load();
  }

  public get activeAnalysis() {
    return findActiveAnalysis(this.analysis, this.activeAnalysisGuid$.value);
  }

  public get version() {
    return this.projectDetailsState.getData()?.version;
  }

  public setActiveAnalyis(analysisId: string | null) {
    this.activeAnalysisGuid$.next(analysisId);
  }

  public getScenario(scenarioGuid: string) {
    return this.activeAnalysis$.pipe(
      map((a) => a?.scenarios?.find((s) => s.scenarioGuid === scenarioGuid)),
    );
  }

  public createScenario(analysisGuid: string, scenario: Partial<Scenario>) {
    return this.analysisScenarioState
      .create(scenario, analysisGuid)
      .then((newScenario) => {
        this.projectDetailsState.updateAnalysisScenario(analysisGuid, (items) =>
          addCollectionItem(items, newScenario),
        );
      });
  }

  public saveScenario(analysisGuid: string, scenario: Partial<Scenario>) {
    return this.analysisScenarioState.save(scenario).then((newScenario) => {
      this.projectDetailsState.updateAnalysisScenario(analysisGuid, (items) =>
        updateCollectionItem('scenarioGuid', items, newScenario),
      );
    });
  }

  public deleteScenario(analysisGuid: string, scenarioGuid: string) {
    return this.analysisScenarioState.delete(scenarioGuid).then(() => {
      this.projectDetailsState.updateAnalysisScenario(analysisGuid, (items) =>
        removeCollectionItem('scenarioGuid', items, scenarioGuid),
      );
    });
  }

  public copyScenario(analysisGuid: string, scenarioGuid: string) {
    return this.analysisScenarioState.copy(scenarioGuid).then((newScenario) => {
      this.projectDetailsState.updateAnalysisScenario(analysisGuid, (items) =>
        addCollectionItem(items, newScenario),
      );
    });
  }

  public createAnalysis(name: string) {
    return this.analysisState
      .create({ analysisName: name })
      .then((analysis) => {
        this.projectDetailsState.updateAnalysisData((items) =>
          addCollectionItem(items, analysis),
        );
      });
  }

  public copyAnalysis(analysisGuid: string) {
    return this.analysisState.copy(analysisGuid).then((analysis) => {
      this.projectDetailsState.updateAnalysisData((items) =>
        addCollectionItem(items, analysis),
      );
    });
  }

  public renameAnalysis(analysisGuid: any, analysisName: string) {
    return this.analysisState
      .save({ analysisGuid, analysisName })
      .then((analysis) => {
        this.projectDetailsState.updateAnalysisData((items) =>
          updateCollectionItem('analysisGuid', items, analysis),
        );
        this.events.updateAnalysis(analysis);
        return analysis;
      });
  }

  public deleteAnalysis(analysisGuid: any) {
    return this.analysisState.delete(analysisGuid).then(() => {
      this.projectDetailsState.updateAnalysisData((items) =>
        removeCollectionItem('analysisGuid', items, analysisGuid),
      );
    });
  }

  public load(projectGuid: string) {
    this.analysisState.reset();
    this.projectDetailsState.setProjectGuid(projectGuid);
    this.analysisState.load();
  }

  public async reset() {
    this.projectDetailsState.reset();
    this.analysisState.reset();
  }

  public get analysis() {
    return this.analysisState.analysis ?? [];
  }

  public get projectName() {
    return this.projectDetailsState.getData()?.projectName;
  }

  public get projectId() {
    return this.projectDetailsState.projectGuid;
  }

  public executeAnalysis(analysisGuid: string, data: any) {
    return this.analysisState.execute(analysisGuid, data);
  }

  public get projectDetails() {
    return this.projectDetailsState.getData();
  }

  public reload() {
    return this.projectDetailsState.reload();
  }

  public lockProject() {
    return this.projectDetailsState.lockCurrentProject();
  }
  public unLockProject() {
    return this.projectDetailsState.unlockCurrentProject();
  }
}
